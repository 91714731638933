import "./nowidgetdata.css";
import { t } from "i18next";

function NoWidgetData(props) {
  return (
    <div className="ph-item">
      <div className="ph-col-12 placeholder-glow">
        <div className="ph-row placeholder-glow">
          <div className="ph-col-12 ph-message lg-ph placeholder">
            <p className="p-3 px-4 m-0">
              <span className="text-danger">
                {t("error.nowidget.ifmessage")}
              </span>
              <br />
              <span className="text-warning ps-3">
                {t("error.nowidget.printmessage")}
              </span>
              <br />
              <span className="text-danger ps-3">
                {t("error.nowidget.printmessageone")}
                <span id={props.id}></span>
              </span>
              <br />
              <span className="text-success ps-3">
                {t("error.nowidget.printmessagetwo")}
              </span>
              <br />
            </p>
          </div>
        </div>
        <div className="ph-row placeholder-glow">
          <div className="ph-col-10 placeholder big"></div>
          <div className="ph-col-2 empty big"></div>
        </div>

        <div className="ph-row placeholder-glow">
          <div className="ph-col-6 placeholder big"></div>
          <div className="ph-col-4 empty big"></div>
        </div>
        <div className="ph-row placeholder-glow">
          <div className="ph-col-4 placeholder big"></div>
          <div className="ph-col-8 empty big"></div>
        </div>
      </div>
    </div>
  );
}

export default NoWidgetData;
