import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "@codemirror/view";
import { python } from "@codemirror/lang-python";
import { useCallback, useLayoutEffect } from "react";
import { SpiderScript } from "../SpiderScript";
import { setGlobalState } from "../../../components/common/GlobalVariables";


function ContentOutput(props) {
  const onChangeSql = useCallback((value, viewUpdate) => {
    SpiderScript.content = value;
    setGlobalState("spidertestClicked", false);
  }, []);
  useLayoutEffect(() => {
    const container = document.querySelector(".Spider_container");
    container.scrollTo(0, container.scrollHeight);
  });

  return (
    <>
      <CodeMirror
        className={"bnfx_spider_codemirror "}
        id="bnfx_spider_codemirror"
        value={props.output?.content}
        basicSetup={{
          lineNumbers: true,
          highlightActiveLineGutter: false,
          highlightActiveLine: false,
          lineWrapping: true,
        }}
        editable="true"
        height="100%"
        style={{ tabSize: 10 }}
        theme={"dark"}
        extensions={[python({}), EditorView.lineWrapping]}
        onChange={onChangeSql}
      />
    </>
  );
}
export default ContentOutput;
