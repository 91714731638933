import { ajax } from "../../components/common/Service";
import { requestURI } from "../../components/common/RequestURIs";
import { showError } from "../../components/common/DisplayError";
import OrganizationTable from "./Table/OrganizationTable";
import Pagination from "../../components/common/pagination/Pagination";
import Swal from "sweetalert2";
import { getGlobalState, setGlobalState } from "../../components/common/GlobalVariables";
import ToastPopup from "../../components/common/ToastPopup";
import NoContent from "../../components/common/NoContent";
import OrganizationRow from "./Table/OrgainzationRow";
import { DisplayErrorToast } from "../../components/error/DisplayErrorToast";
import { t } from "i18next";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import jwt from 'jwt-decode'
import ShowErrorToastPopup from "../../components/common/ShowErrorToastPopup";
const token = localStorage.getItem("token");
export const Organization = {
  searchtimeout: [],
  selected_kind: null,
  selected_groups: null,
  selected_group_list: null,
  selectKind: (kind) => {
    Organization.selected_kind = kind;
  },

  loadgroupselectionoptions: () => {
    Organization.tenantoptions(0);
    Organization.loadStreamList(0);
  },
  loadroleselectionoptions: () => {
    Organization.loadUrlList(0);
    Organization.tenantoptions(0);
  },

  loaduserselectionoptions: () => {
    setGlobalState("super_admin",false);
    Organization.loadOptionsListOfGroups();
    Organization.loadOptionsListOfRoles();
    Organization.tenantoptions(0);
    let singletenant = getGlobalState("singletenant");

  if (singletenant) {
    const userdetails = jwt(token);
    let tenants=userdetails.tenants
    setGlobalState("selectedUserTenants",tenants)}
  },

  loadUser: (offset) => {
    Organization.loadUserList(offset);
    Organization.getTotal("securityuser", "user_search");
  },
  loadRole: (offset) => {
    Organization.loadRoleList(offset);
    Organization.getTotal("securityrole", "role_search");
  },
  loadTenant: (offset) => {
    Organization.loadTenantList(offset);
    Organization.getTotal("tenant", "tenant_search");
  },
  tenantoptions:(offset)=>{
    
    const userdetails = jwt(token);
    
    let tenantcount=userdetails?.tenants?.length
    if(tenantcount === null || tenantcount===0 ||tenantcount===undefined){
      Organization.loadListOfTenant(offset)
    }else{
      let tokentenantlist = userdetails.tenants
      const tenantList = tokentenantlist.map(item => ({
        value: item,
        label: item,
        
      }));
  
      setGlobalState("TenantOptions", tenantList);
    }

  },
  loadListOfTenant: (offset) => {
    ajax(
      "get",
      `${requestURI["list.tenant"]}?offset=${offset}`,
      null,
      null,
      Organization.renderTenantList,
      showError
    );
  },

  renderTenantList: (response) => {
    let tenantlist = response.data.response;

    const tenantData = tenantlist.map((item) => ({
      value: item.name,
      label: item.name,
    }));

    setGlobalState("TenantOptions", tenantData);
  },
  loadUrlList: (offset) => {
    ajax(
      "get",
      `${requestURI["list.url"]}?offset=${offset}`,
      null,
      null,
      Organization.renderUrlList,
      showError,
      [offset]
    );
  },

  renderUrlList: (response, args) => {
    let originalData = response.data.response;

    const urlData = originalData.map((item) => ({
      value: item.name,
      label: item.name,
    }));
    if (args[0] === 0) {
      const selectAllOption = {
        value: "SELECT ALL",
        label: "SELECT ALL",
      };
      urlData.unshift(selectAllOption);
    }

    setGlobalState("urlOptions", (prevUrlOptions) => [...prevUrlOptions, ...urlData]);
  },


  loadTenantList: (offset) => {
    Organization.selectKind("tenant");
    let freetext = CommonTemplate.getSearchText("tenant_search");
    let endpoint = null;
    if (freetext) {
      endpoint = "?offset=" + offset + "&query=" + freetext;
    } else {
      endpoint = "?offset=" + offset;
    }
    ajax(
      "get",
      requestURI["list.tenant"] + endpoint,
      null,
      null,
      Organization.renderTenant,
      showError
    );
  },

  renderTenant: (response) => {
    let data = null;
    let tenantlist = response.data.response;
    const container = document.querySelector("#addtenant");

    if (tenantlist.length === 0) {
      data = (
        <NoContent
          id={"nodatafound"}
          title={t("error.organizationmessage.title")}
          description={t("error.organizationmessage.description")}
        />
      );
    } else {
      data = <OrganizationTable data={tenantlist} id="tenant" />;
    }

    CommonTemplate.ContainerAvailability(container, data);
  },

  loadOptionsListOfGroups: () => {
    Organization.selectKind("securitygroup");

    ajax(
      "get",
      requestURI["list.group"],
      null,
      null,
      Organization.renderListOfGroups,
      showError
    );
  },

  renderListOfGroups: (response, args) => {
    let originalData = response.data.response;
    const groupData = originalData.map((item) => ({
      
      value: item.name,
      label: item.tenant ? `${item.name} (${item.tenant})` : item.name,
    }));
    setGlobalState("AllgroupOptions", groupData);
  },

  loadOptionsListOfRoles: () => {
    ajax(
      "get",
      requestURI["list.role"],
      null,
      null,
      Organization.renderListOfRoles,
      showError
    );
  },

  renderListOfRoles: (response) => {
    let originalData = response.data.response;
    const roleData = originalData.map((item) => ({
      value: item.name,
      label: item.tenant ? `${item.name} (${item.tenant})` : item.name,
    }));
    setGlobalState("AllRoleOptions", roleData);
  },

  loadStreamList: (offset) => {
    const request = {
      limit: 3,
      offset: offset ? offset : 0,
      sortcol: "name",
      sortorder: false,
      enabled: false,
      allContent: true,
      type: "packages.streams",
    };

    ajax(
      "post",
      requestURI["list.stream"],
      null,
      request,
      Organization.renderStreamList,
      showError,
      [offset]
    );
  },

  renderStreamList: (response, args) => {
    let originalData = response.data.response;

    const stream = originalData.map((item) => ({
      value: item.name,
      label: item.title,
    }));
    if (args[0] === 0) {
      const selectAllOption = {
        value: "SELECT ALL",
        label: "SELECT ALL",
      };
     
      stream.unshift(selectAllOption);
    }

    setGlobalState("StreamsOptions", (prevUrlOptions) => [...prevUrlOptions, ...stream]);
  },

  loadGroup: (offset) => {
    Organization.loadGroupList(offset);
    Organization.getTotal("securitygroup", "group_search");
  },

  getTotal: (id, searchid) => {
    let freetext = CommonTemplate.getSearchText(searchid);

    let endpoint = null;
    if (freetext) {
      endpoint = "/" + id + "?query=" + freetext;
    } else {
      endpoint = "/" + id;
    }
    ajax(
      "get",
      requestURI["total.organization"] + endpoint,
      null,
      null,
      Organization.getPagination,
      showError,
      [id]
    );
  },
  getPagination: (result, args) => {
    Organization.total = null;

    const total = result.data.response;
    Organization.total = total;

    Organization.createPagination(total, args[0]);
    Organization.total = null;
  },
  createPagination: (total, id) => {
    let paginationComponent = null;
    let limit = 10;

    const container = document.querySelector("#pagination" + id);

    if (!container) {
      return;
    }

    const paginationData = {
      limit: limit,
      total: total,
      offset: 0,
    };
    if (id === "securityuser") {
      paginationComponent = (
        <Pagination
          pagination={paginationData}
          onClickFn={Organization.loadUserList}
        />
      );
    } else if (id === "securityrole") {
      paginationComponent = (
        <Pagination
          pagination={paginationData}
          onClickFn={Organization.loadRoleList}
        />
      );
    } else if (id === "securitygroup") {
      paginationComponent = (
        <Pagination
          pagination={paginationData}
          onClickFn={Organization.loadGroupList}
        />
      );
    } else {
      paginationComponent = (
        <Pagination
          pagination={paginationData}
          onClickFn={Organization.loadTenantList}
        />
      );
    }
    if (total > limit) {
      CommonTemplate.ContainerAvailability(container, paginationComponent);
    }
    else {
      const paginationComponent = null
      CommonTemplate.ContainerAvailability(container, paginationComponent);

    }

    Organization.setTotal(total, "total" + id);
  },
  setTotal: (total, id) => {
    const container = document.querySelectorAll("#" + id);
    container.forEach((content) => {
      content.innerText = total;
    });
  },

  addGroup: (formData) => {
    let stream = formData.streams;

    const formatted_streams = stream.map((entry) =>
      entry.value
    );

    // Create the result object
    const result = { formatted_streams };
    


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.emailaddress?.trim())) {
        ShowErrorToastPopup(t("invalid.email.address"));
        return;
    }

    const request = {
      name: formData.Groupname?.trim(),
      email: formData.emailaddress?.trim(),
      status: formData.groupstatus.value?.trim(),
      tenant: formData.Tenants.value?.trim(),
      streams: result.formatted_streams,
    };

    ajax(
      "post",
      requestURI["create.group"],
      null,
      request,
      Organization.rendergroupdata,
      DisplayErrorToast
    );
  },

  rendergroupdata: (response) => {
    ToastPopup(t("message.added"));
    setTimeout(() => {
      Organization.loadGroup(0);
    }, 500);
  },
  addTenant: (formData) => {
    const request = {
      name: formData.Name.toUpperCase()?.trim(),
      description: formData.Description?.trim(),
      status: formData.tenantstatus.value?.trim(),
      colorcode: formData.Colorcode,
    };
    ajax(
      "post",
      requestURI["create.tenant"],
      null,
      request,
      Organization.rendertenantdata,
      DisplayErrorToast
    );
  },

  rendertenantdata: (response) => {
    ToastPopup(t("message.added"));
    setTimeout(() => {
      Organization.loadTenant(0);
    }, 500);
  },


  loadGroupList: (offset, callback) => {
    let freetext = CommonTemplate.getSearchText("group_search");
    let endpoint = freetext
      ? `?offset=${offset}&query=${freetext}`
      : `?offset=${offset}`;
    Organization.selectKind("securitygroup");

    ajax(
      "get",
      requestURI["list.organization"] + "/securitygroup" + endpoint,
      null,
      null,
      (response) => {
        const grouplist = response.data.response;

        if (grouplist.length === 0) {
          // If result is not present, render the NoEvent component
          const container = document.querySelector("#addgroup");
          const data = (
            <NoContent
              id={"nodatafound"}
              title={t("error.organizationmessage.title")}
              description={t("error.organizationmessage.description")}
            />
          );
          CommonTemplate.ContainerAvailability(container, data);
        } else {
          Organization.selected_groups = grouplist;
          const container = document.querySelector("#addgroup");
          const result = (
            <OrganizationTable data={grouplist} id="securitygroup" />
          );

          CommonTemplate.ContainerAvailability(container, result);

          if (typeof callback === "function") {
            callback(grouplist); // Call the callback function with the fetched data
          }
        }
      },
      showError
    );
  },

  addRole: (formData) => {
    let url = formData.urls;
    const formatted_urls = Object.values(url).map((entry) => entry.label);

    const result = { formatted_urls };

    const request = {
      name: formData.Rolename?.trim(),
      tenant: formData?.Tenants?.trim(),
      urls: result?.formatted_urls,
    };

    ajax(
      "post",
      requestURI["create.role"],
      null,
      request,
      Organization.renderroledata,
      DisplayErrorToast
    );
  },

  renderroledata: (response) => {
    ToastPopup(t("message.added"));
    setTimeout(() => {
      Organization.loadRole(0);
    }, 500);
  },
  loadRoleList: (offset) => {
    let freetext = CommonTemplate.getSearchText("role_search");
    let endpoint = null;
    if (freetext) {
      endpoint = "?offset=" + offset + "&query=" + freetext;
    } else {
      endpoint = "?offset=" + offset;
    }

    Organization.selectKind("securityrole");
    ajax(
      "get",
      requestURI["list.organization"] + "/securityrole" + endpoint,
      null,
      null,
      Organization.renderrolelist,
      showError
    );
  },
  renderrolelist: (response) => {
    let rolelist = response.data.response;

    if (rolelist.length === 0) {
      // If result is not present, render the NoEvent component
      const container = document.querySelector("#addrole");
      const data = (
        <NoContent
          id={"nodatafound"}
          title={t("error.organizationmessage.title")}
          description={t("error.organizationmessage.description")}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      const container = document.querySelector("#addrole");
      const result = <OrganizationTable data={rolelist} id="securityrole" />;

      CommonTemplate.ContainerAvailability(container, result);
    }
  },

  resetFormFields: (formId) => {
    var form = document.getElementById(formId);
    var inputFields = form.querySelectorAll("input");
    var textareaFields = form.querySelectorAll("textarea");
    var selectFields = form.querySelectorAll("select");

    for (var i = 0; i < inputFields.length; i++) {
      inputFields[i].value = "";
    }

    for (i = 0; i < textareaFields.length; i++) {
      textareaFields[i].value = "";
    }

    for (i = 0; i < selectFields.length; i++) {
      selectFields[i].selectedIndex = 0; // Reset to the first option
    }
  },
  renderuserdata: (response) => {
    ToastPopup(t("message.added"));
    setTimeout(() => {
      Organization.loadUser(0);
    }, 500);
  },
  loadUserList: (offset) => {
    let freetext = CommonTemplate.getSearchText("user_search");

    let endpoint = freetext
      ? "?offset=" + offset + "&query=" + freetext
      : "?offset=" + offset;

    Organization.selectKind("securityuser");
    ajax(
      "get",
      requestURI["list.organization"] + "/securityuser" + endpoint,
      null,
      null,
      Organization.renderuserlist,
      showError
    );
  },
  renderuserlist: (response) => {
    let userlist = response.data.response;
    if (userlist.length === 0) {
      // If result is not present, render the NoEvent component
      const container = document.querySelector("#adduser");
      const data = (
        <NoContent
          id={"nodatafound"}
          title={t("error.organizationmessage.title")}
          description={t("error.organizationmessage.description")}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      const container = document.querySelector("#adduser");
      const result = <OrganizationTable data={userlist} id="securityuser" />;

      CommonTemplate.ContainerAvailability(container, result);
    }
  },

  handleedit: (data, id) => {
    if (data) {
      if (id === "securityuser") {
        Organization.loaduserselectionoptions();
        setGlobalState("isEdit", true);
        setGlobalState("_id", data._id);
        const nameParts = data.Name.split(" ");

        document.getElementById("user_firstname").value = nameParts[0];
        document.getElementById("user_lastname").value = nameParts
          .slice(1)
          .join(" ");
        document.getElementById("user_email").value = data.email;

        document.getElementById("user_username").value = data.username;
        const currentstatus = { value: data.status, label: data.status };
        const groupData = data.groups?.map((item) => ({
          value: item.name,
          label: `${item.name} (${item.tenant})`,
        }));
        const roleData = data.roles?.map((item) => ({
          value: item.name,
          label: `${item.name} (${item.tenant})`,
        }));
        const tenantData = data.tenants?.map((label, index) => ({
          value: label,
          label: label,
        }));
        const hasGroupAdmin = data.groups.some(group => group.name === "GROUP_ADMIN");
        const hasRoleCustomerAdmin = data.roles.some(role => role.name === "ROLE_CUSTOMER_ADMIN");
        if (hasGroupAdmin && hasRoleCustomerAdmin) {
          setGlobalState("super_admin",true);
        } else {
          setGlobalState("super_admin",false);
        }
        setGlobalState("selectedGroups", groupData);
        setGlobalState("selectedUserTenants", tenantData);
        setGlobalState("selectedRoles", roleData);
        setGlobalState("selectedStatus", currentstatus);
      } else if (id === "securityrole") {
        Organization.loadroleselectionoptions();
        setGlobalState("isEdit", true);
        setGlobalState("_id", data._id);
        document.getElementById("role_name").value = data.name;
        const result = { value: data.tenant, label: data.tenant };
        const convertedUrls = data.urls.map((label, index) => ({
          value: label,
          label: label,
        }));

        setGlobalState("selectedUrls", convertedUrls);
        setGlobalState("selectedTenants", result);
      } else if (id === "securitygroup") {
        Organization.loadgroupselectionoptions();
        setGlobalState("isEdit", true);
        setGlobalState("_id", data._id);

        // Your code here
        document.getElementById("group_name").value = data.name;
        document.getElementById("group_email").value = data.email;

        // list =  document.querySelectorAll(".inputGroups");
        // list.getAttribute("name");
        // data[] =
        const convertedStreams = data.streams.map((label, index) => ({
          value: label,
          label: label,
        }));
        const result = { value: data.tenant, label: data.tenant };
        const currentstatus = { value: data.status, label: data.status };
        setGlobalState("selectedStreams", convertedStreams);
        setGlobalState("selectedTenants", result);
        setGlobalState("selectedStatus", currentstatus);
      } else {
        setGlobalState("isEdit", true);
        setGlobalState("_id", data._id);
        document.getElementById("tenant_name").value = data.name;
        document.getElementById("tenant_description").value = data.description;
        document.getElementById("tenant_colorcode").value = data.colorcode;
        const currentstatus = { value: data.status, label: data.status };
        setGlobalState("selectedStatus", currentstatus);
      }
    }
  },

  delete: (id, data, title) => {
    ajax(
      "delete",
      requestURI["delete.organization"] + "/" + id + "/" + data,
      null,
      null,
      Organization.loadlist,
      showError,
      [title]
    );
  },
  loadlist: (result, args) => {

    const deletetrue = t("Deleted")
    const succesfulldelete = t("succesfull.delete.toast")
    let success = t("success")
    Swal.fire(deletetrue, args[0] + succesfulldelete, success);
    setTimeout(() => {
      if (Organization.selected_kind === "securitygroup") {
        Organization.loadGroup(0);
      } else if (Organization.selected_kind === "securityrole") {
        Organization.loadRole(0);
      } else if (Organization.selected_kind === "securityuser") {
        Organization.loadUser(0);
      } else if (Organization.selected_kind === "tenant") {
        Organization.loadTenant(0);
      }
    }, 500);
  },
  deleteConfirmation: (title, id, data) => {
    Swal.fire({
      title: t("delete.title.confirmation"),
      text: t("delete.description.confirmation") + title,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("stream.delete.yes"),
    }).then((result) => {

      if (result.isConfirmed) {
        Organization.delete(id, data, title);

      }
    });
  },
  handlesorting: (orderby, asc, id) => {
    if (orderby === "Name") {
      orderby = "firstname";
    }

    ajax(
      "get",
      requestURI["list.organization"] +
      "/" +
      id +
      "?orderby=" +
      orderby +
      "&asc=" +
      asc,
      null,
      null,
      Organization.rendersortlist,
      showError,
      [id]
    );
  },
  rendersortlist: (response, args) => {
    let container = null;
    let result = null;

    let userlist = response.data.response;
    // Extract sorting order from args

    let columns = null;
    if (args[0] === "securityuser") {
      columns = [
        "Name",
        "username",
        "email",
        "status",
        "tenants",
        "roles",
        "groups",
        "updatedon",
        "updatedby",
      ];
      container = document.querySelector("#addrow");
      const transformedUserList = userlist.map((user, index) => ({
        Name: `${user.firstname} ${user.lastname}`,
        username: user.username,
        email: user.email,
        status: user.status, // You can set the status as needed
        tenants: user.tenants,
        roles: user.roles,
        groups: user.groups,
        updatedon: user.updatedon,
        updatedby: user.updatedby,
      }));

      result = (
        <OrganizationRow
          columns={columns}
          data={transformedUserList}
          id={"securityuser"}
        />
      );
    } else if (args[0] === "securityrole") {
      columns = ["name", "tenant", "urls", "updatedon", "updatedby"];
      container = document.querySelector("#addrow");
      result = (
        <OrganizationRow
          columns={columns}
          data={userlist}
          id={"securityrole"}
        />
      );
    } else if (args[0] === "securitygroup") {
      columns = [
        "name",
        "email",
        "status",
        "tenant",
        "streams",
        "updatedon",
        "updatedby",
      ];
      container = document.querySelector("#addrow");
      result = (
        <OrganizationRow
          columns={columns}
          data={userlist}
          id={"securitygroup"}
        />
      );
    } else if (args[0] === "tenant") {
      columns = [
        "name",
        "description",
        "shortcode",
        "status",
        "updatedon",
        "updatedby",
      ];
      container = document.querySelector("#addrow");
      result = (
        <OrganizationRow columns={columns} data={userlist} id={"tenant"} />
      );
    }

    CommonTemplate.ContainerAvailability(container, result);
  },

  search: (id) => {
    //get the text value and call list dashboard to get dashboard list
    const freetext = document.querySelector("#" + id).value;
    /*Apply condition freetext length is greater than three then execute if block  */

    if (freetext.length > 3 || freetext.length === 0) {
      if (Organization.searchtimeout && Organization.searchtimeout.length > 0) {
        for (
          let index = 0;
          index < Organization.searchtimeout.length;
          index++
        ) {
          window.clearTimeout(Organization.searchtimeout[index]);
        }
      }
      Organization.searchtimeout.push(
        setTimeout(() => {
          if (id === "user_search") {
            Organization.loadUser(0);
          } else if (id === "role_search") {
            Organization.loadRole(0);
          } else if (id === "group_search") {
            Organization.loadGroup(0);
          } else {
            Organization.loadTenant(0);
          }
        }, 500)
      );
    }
  },

  update_group: (formData, kind, id) => {
    let stream = formData.streams;

    const formatted_streams = stream.map((entry) =>
      entry.value
    );

    // Create the result object
    const result = { formatted_streams };

    const request = {
      email: formData.emailaddress?.trim(),
      status: formData.groupstatus.value?.trim(),
      streams: result.formatted_streams,
    };
    ajax(
      "post",
      requestURI["update.organization"] + kind + "/" + id,
      null,
      request,
      Organization.renderUpdatedList,
      DisplayErrorToast
    );
  },
  renderUpdatedList: (response) => {
    setGlobalState("isEdit", false);
    setGlobalState("_id", "");
    ToastPopup(t("message.updated"));
    Organization.loadGroupList(0);
    // setGlobalState("urlOptions", urlData);
  },

  update_role: (formData, kind, id) => {
    let url = formData.urls;
    const formatted_urls = Object.values(url).map((entry) => entry.label);

    const result = { formatted_urls };

    const request = {
      urls: result.formatted_urls,
    };
    ajax(
      "post",
      requestURI["update.organization"] + kind + "/" + id,
      null,
      request,
      Organization.renderRoleList,
      DisplayErrorToast
    );
  },
  renderRoleList: (response) => {
    setGlobalState("isEdit", false);
    setGlobalState("_id", "");
    ToastPopup(t("message.updated"));
    Organization.loadRoleList(0);
    // setGlobalState("urlOptions", urlData);
  },
  update_tenant: (formData, kind, id) => {
    const request = {
      description: formData.Description?.trim(),
      status: formData.tenantstatus.value?.trim(),
      colorcode: formData.Colorcode,
    };

    ajax(
      "post",
      requestURI["update.organization"] + kind + "/" + id,
      null,
      request,
      Organization.renderupdateTenantList,
      DisplayErrorToast
    );
  },
  renderupdateTenantList: (response) => {
    setGlobalState("isEdit", false);
    setGlobalState("_id", "");
    ToastPopup(t("message.updated"));
    Organization.loadTenantList(0);
    // setGlobalState("urlOptions", urlData);
  },
  addUser: (formData) => {
    let tenant = formData.Tenants;
    const formatted_tenants = Object.values(tenant).map((entry) =>
      entry.value
    );
    let role = formData.roles;
    const formatted_roles = Object.values(role).map((entry) =>
      entry.label
    );
    let group = formData.groups;
    const formatted_groups = Object.values(group).map((entry) =>
      entry.label
    );

    const groups = [];
    const roles = [];

    formatted_groups.forEach((item) => {
      const match = item.match(/^(.*?)\((.*?)\)$/);
      if (match) {
        const name = match[1];
        const tenant = match[2];

        groups.push({
          name: name,
          tenant: tenant,
        });
      }
    });
    let groupdata = groups.map((group) => ({
      name: group.name?.trim(),
      tenant: group.tenant,
    }));
    formatted_roles.forEach((item) => {
      const match = item.match(/^(.*?)\((.*?)\)$/);
      if (match) {
        const name = match[1];
        const tenant = match[2];

        roles.push({
          name: name,
          tenant: tenant
        });
      }
    });
    
    let roledata = roles.map((group) => ({
      name: group.name?.trim(),
      tenant: group.tenant,
    }));
    
    
    const result = { formatted_tenants, roledata, groupdata };
    const request = {
      firstname: formData.Firstname?.trim(),
      lastname: formData.Lastname?.trim(),
      username: formData.Username.toLowerCase(),
      email: formData.emailaddress?.trim(),
      password: formData.password?.trim(),
      status: formData.userstatus.value?.trim(),
      tenants: result.formatted_tenants,
      roles: roledata,
      groups: groupdata,
    };
    ajax(
      "post",
      requestURI["create.user"],
      null,
      request,
      Organization.renderuserdata,
      DisplayErrorToast
    );
  },

  update_user: (formData, kind, id) => {
    let tenant = formData.Tenants;
    const formatted_tenants = Object.values(tenant).map((entry) =>
      entry.value
    );
    let role = formData.roles;
    const formatted_roles = Object.values(role).map((entry) =>
      entry.label?.trim()
    );
    let group = formData.groups;
    const formatted_groups = Object.values(group).map((entry) =>
      entry.label?.trim()
    );
    const groups = [];
    const roles = [];

    formatted_groups.forEach((item) => {
      const match = item.match(/^(.*?)\((.*?)\)$/);
      if (match) {
        const name = match[1];
        const tenant = match[2];

        groups.push({
          name: name,
          tenant: tenant,
        });
      }
    });
    let groupdata = groups.map((group) => ({
      name: group.name.trim(),
      tenant: group.tenant,
    }));
    formatted_roles.forEach((item) => {
      const match = item.match(/^(.*?)\((.*?)\)$/);
      if (match) {
        const name = match[1];
        const tenant = match[2];

        roles.push({
          name: name,
          tenant: tenant,
        });
      }
    });
    let roledata = roles.map((group) => ({
      name: group.name?.trim(),
      tenant: group.tenant,
    }));
   
    const result = { formatted_tenants, roledata, groupdata };
    const request = {
      firstname: formData.Firstname?.trim(),
      lastname: formData.Lastname?.trim(),
      email: formData.emailaddress?.trim(),
      status: formData.userstatus.value?.trim(),
      tenants: result.formatted_tenants,
      roles: roledata,
      groups: groupdata,
      ...(formData.password !== "" ? { password: formData.password } : {})
    };
    ajax(
      "post",
      requestURI["update.organization"] + kind + "/" + id,
      null,
      request,
      Organization.renderUserList,
      DisplayErrorToast
    );
  },
  renderUserList: (response) => {
    setGlobalState("isEdit", false);
    setGlobalState("_id", "");
    ToastPopup(t("message.updated"));
    Organization.loadUserList(0);
    // setGlobalState("urlOptions", urlData);
  },
  clearform: () => {
    const formid = document.getElementById("offcanvasAddUser");
    formid.innerHTML = "";
    setGlobalState("super_admin",false);
  },

  showClass:()=>{
    const container=document.querySelector("#modalname")
    const addDiv = document.createElement('div');
    addDiv.className = 'customeModal-backgrop';
    container?.replaceWith(addDiv);
  },

  removeClass:()=>{
    const container=document.querySelector(".customeModal-backgrop")
    const removeDiv=document.createElement('div');
    removeDiv.id='modalname';
    container?.replaceWith(removeDiv);
  },
   getfilteredgroup:()=>{
    let selectedtennat=getGlobalState("selectedUserTenants")
    
    let  selectedTenants=null
    if(selectedtennat[0]?.value!==undefined){
      selectedTenants= selectedtennat.map((option) => option.value);}
    else{
      selectedTenants=selectedtennat

    }
   
    const newGroupOptions = Organization.fetchGroupOptions(selectedTenants);
    setGlobalState("groupOptions", newGroupOptions);
    // setGlobalState("selectedGroups",[]);

  },
 getfilteredrole:()=>{
    let selectedtennat=getGlobalState("selectedUserTenants")
    let  selectedTenants=null
    if(selectedtennat[0]?.value!==undefined){
      selectedTenants= selectedtennat.map((option) => option.value);}
    else{
      selectedTenants=selectedtennat

    }

    const newRoleOptions = Organization.fetchRoleOptions(selectedTenants);
    setGlobalState("RoleOptions", newRoleOptions);
    // setGlobalState("selectedRoles", []);
  },
  fetchRoleOptions:(selectedTenants) => {
    const AllRoleOptions = getGlobalState("AllRoleOptions");

    const selectedRoleOptions = [];

    for (const option of AllRoleOptions) {
      for (const tenant of selectedTenants) {
        if (option.label.includes(`(${tenant})`)) {
          selectedRoleOptions.push(option);
          break;
        }
      }
    }

    return selectedRoleOptions;
  },

  // Function to fetch group options based on selected tenants
  fetchGroupOptions:(selectedTenants) => {
    const selectedGroupOptions = [];
    const AllgroupOptions = getGlobalState("AllgroupOptions");

    for (const option of AllgroupOptions) {
      for (const tenant of selectedTenants) {
        if (option.label.includes(`(${tenant})`)) {
          selectedGroupOptions.push(option);
          break;
        }
      }
    }

    return selectedGroupOptions;
  },
};
