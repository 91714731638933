import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
} from "reactflow";
import "reactflow/dist/style.css";

import SpiderListLayout from "../list/SpiderListLayout";
import SpiderDetailContainer from "./SpiderDetailContainer";
import { SpiderScript } from "../SpiderScript";
import StreamNode from "../list/StreamNode";
import DataSource from "../list/DataSource";
import CircleNode from "../list/CircleNode";
import dagre from "dagre";
import {
  getGlobalState,
  setGlobalState,
} from "../../../components/common/GlobalVariables";
import ReportNode from "../list/ReportNode";

let id = 0;
const getId = () => `dndnode_${id++}`;
const nodeTypes = {
  streamnode: React.memo((props) => <StreamNode {...props} />),
  datasource: React.memo((props) => <DataSource {...props} />),
  circlenode: React.memo((props) => <CircleNode {...props} />),
  reportnode: React.memo((props) => <ReportNode {...props} />),
};

function DragElements({ intialnodes, initialedges }) {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(intialnodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [selectedNodeLabel, setSelectedNodeLabel] = useState(null);
  const connectionLineStyle = { stroke: "#fff" };
  const dagreGraph = new dagre.graphlib.Graph();
  let selectedStreams = getGlobalState("selectedStreams");

  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  // const [collapsed] = useGlobalState(
  //   "global_spider_detail_right_panel_collapsed"
  // );
  const onNodeClick = useCallback(
    (event, node) => {
      if (node.type !== "datasource" && node.type !== "circlenode") {
        // if(!collapsed){
        //   setGlobalState("global_spider_detail_right_panel_collapsed",false)
        // }
        // else{
        event.preventDefault();
        setSelectedNodeLabel(node);

        const arrowbutton = document.querySelector(".arrow_forward_ios");
        if (arrowbutton) {
          arrowbutton.classList.remove("d-none");
        }

        const removebutton = document.querySelector(
          ".SpiderCustomBtn.saveBtn.button"
        );
        if (removebutton) {
          removebutton.classList.remove("d-none");
        }
        const removecancelbutton = document.querySelector(
          ".SpiderCustomBtn.cancelBtn.button"
        );
        if (removecancelbutton) {
          removecancelbutton.classList.remove("d-none");
        }

        const showpythoncode = document.querySelector(
          ".Custom_rightArea_spiderLayout"
        );
        if (showpythoncode) {
          showpythoncode.classList.add("d-none");
        }
        const showbutton = document.querySelector(".spiderContentButton");
        if (showbutton) {
          showbutton.classList.add("d-none");
        }

        // const button = document.getElementById("panelOpenerButton");
        // if (button) {
        //   button.click();
        // }
      } else if (node.type === "datasource" && node.id === "datasource") {
        const modal = document.getElementById("SpiderStream");
        // Set modal style to display it
        modal.classList.add("show");
        // Handle edit when modal is opened
        SpiderScript.handleedit(selectedStreams.value);
      }
    },
    [selectedStreams.value]
  );

  const connectNodesWithEdges = (source) => {
    let perviousnode = "";
    let nextnode = "";
    let type = source.data.label;

    if (type === "dictionary") {
      perviousnode = "datasource";
      nextnode = "dictionary-filter";
    } else if (type === "filter") {
      perviousnode = "dictionary-filter";
      nextnode = "filter-transformation";
    } else if (type === "transformation") {
      perviousnode = "filter-transformation";
      nextnode = "transformation-detection";
    } else if (type === "detection") {
      perviousnode = "transformation-detection";
      nextnode = "detection-automation";
    } else if (type === "automation") {
      perviousnode = "detection-automation";
      nextnode = "automation-sequence";
    } else if (type === "sequence") {
      perviousnode = "automation-sequence";
      nextnode = "sequence-correlation";
    } else if (type === "correlation") {
      perviousnode = "sequence-correlation";
      nextnode = "correlation-report";
    } else if (type === "report") {
      perviousnode = "correlation-report";
      nextnode = "";
    } else if (type === "stylus_note") {
      perviousnode = "correlation-report";
      nextnode = "";
    }
    let edge1 = {
      id: `e1-${source?.id}`,
      source: source.id,
      target: nextnode,
      targetHandle: "a",
      animated: false,
      style: { stroke: "#fff" },
      type: "default",
    };
    let edge2 = {
      id: `e2-${source?.id}`,
      source: perviousnode,
      target: source.id,
      targetHandle: "d",
      animated: false,
      style: { stroke: "#fff" },
      type: "default",
    };
    setEdges((currentEdges) => [...currentEdges, edge1, edge2]);
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);
  const handleWrapperClick = () => {
    setGlobalState("menuopenforstreamlist", false);
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");

      if (typeof type === "undefined" || !type) {
        return;
      }

      if (type === "dictionary") {
        const hasDictionaryNode = nodes.some(
          (node) => node.data.label === "dictionary"
        );
        if (hasDictionaryNode) {
          return;
        }
      }
      const position = reactFlowInstance.project({
        x: event.clientX,
        y: event.clientY,
      });
      let newid = getId();
      const newNode = {
        id: newid,
        type: "streamnode",
        position,
        data: { label: `${type}`, value: newid },
      };

      setNodes((nds) => nds?.concat(newNode));

      connectNodesWithEdges(newNode);
    },
    [reactFlowInstance, setNodes, nodes]
  );

  const nodeWidth = 180;
  const nodeHeight = 40;

  const getLayoutedElements = (nodes, edges, direction = "TB") => {
    const isHorizontal = direction === "TB";
    dagreGraph.setGraph({ rankdir: direction });

    nodes?.forEach((node, i) => {
      dagreGraph.setNode(node.id, {
        width: i === 0 ? 0 : nodeWidth,
        height: nodeHeight,
      });
    });

    edges?.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes?.forEach((node, i) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";

      node.position = {
        x: nodeWithPosition.x - nodeWidth,
        y: nodeWithPosition.y - nodeHeight,
      };

      return node;
    });

    return { nodes, edges };
  };

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    intialnodes,
    initialedges
  );
  useEffect(() => {
    if (selectedNodeLabel) {
      SpiderScript.selected_option= null;
      SpiderScript.fillupdetails(selectedNodeLabel);
    }
  }, [selectedNodeLabel]);

  useEffect(() => {
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [setNodes, layoutedNodes, setEdges, layoutedEdges]);
  setGlobalState("spidernodeslist", nodes);
  const isEmpty =
    (nodes === null || nodes.length === 0) &&
    (edges === null || edges.length === 0);
  return (
    <div id="root">
      <div className="dndflow">
        <ReactFlowProvider>
          <SpiderListLayout />
          <div
            className="reactflow-wrapper row m-0"
            ref={reactFlowWrapper}
            onClick={handleWrapperClick}
          >
            <div className="col">
              {isEmpty ? null : (
                <ReactFlow
                  className="col-12"
                  nodes={nodes}
                  onNodeClick={onNodeClick}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  onInit={setReactFlowInstance}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  fitView
                  nodeTypes={nodeTypes}
                  deleteKeyCode={[]}
                  connectionLineStyle={connectionLineStyle}
                >
                  <Controls />
                </ReactFlow>
              )}

              <style>
                {`
                .react-flow__attribution {
                  display: none; /* Hide attribution message */
                }
              `}
              </style>
              {/* <div className="col-12 TextOutput" id="contentcompileoutput"></div> */}
            </div>
            <SpiderDetailContainer nodeName={selectedNodeLabel} />
          </div>
        </ReactFlowProvider>
      </div>
    </div>
  );
}

export default DragElements;
