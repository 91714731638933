import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";

function RoiCard(props) {
  let title = props.data.stattype;
  let total = props.data.total_count;

  return (
    <>
      <div className="card-body roiCardBox pt-0">
        <h1 className="text-white mb-0">{title === "AUTOMATIONS"
          ? total.toFixed(1) + "%"
          : CommonTemplate.formatNumber(total)}
        </h1>
        {title === "AUTOMATIONS" || title === "Corelation Engine" ? (
          <i className="text-muted fw-normal mt-0" title="Users">
            {title === "AUTOMATIONS"
              ? "Performed on total detections"
              : title === "Corelation Engine"
              ? "Entities"
              : null}
          </i>
        ) : null}
      </div>
    </>
  );
}

export default RoiCard;
