import { useLayoutEffect } from "react";
import "../../dashboard/mitredashboardwidget.css";
import { t } from "i18next";

function MiterDashboardWidget(props) {
  let occurrences = props.data.occurrence;
  const columns = [
    "Reconnaissance",
    "Delivery",
    "Exploit",
    "Command and Control",
    "Impact",
  ];

  let rows = props.data.data;

  useLayoutEffect(() => {
    const grid = document.querySelector(".miter-body");
    grid.style.maxHeight =
      grid.closest(".grid-stack-item-content ").clientHeight - 40 + "px";
  });

  const formatDateParts = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    // Split the formatted date into parts
    const parts = formattedDate.split(" ");
    let day = parts[1].replace(",", ""); // Remove comma from day part
    const month = parts[0];
    const year = parts[2];

    return { day, month, year };
  };

  const calculateDateDifference = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Calculate difference in milliseconds
    const differenceMs = Math.abs(endDate - startDate);

    // Convert milliseconds to days
    const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

    return differenceDays;
  };
  // .mitre_table .mitre-cell.high

  const priorityMapping = {
    critical: 4,
    high: 3,
    medium: 2,
    low: 1,
  };


  const handleRowClick = (row) => {
    props.onClickFn(row,props.id);
  };

  
  return (
    <>
      <div className="mitre_section table px-3">
        <div className="miter-body data">
        <div className="mitre_header_section">
          <div className="row">
            {columns.map((colname, index) => {
              let count = 0;
              let criticality = "low";
              const startDate = occurrences[colname]
                ? formatDateParts(occurrences[colname].first_occurrence)
                : { day: "N/A", month: "N/A", year: "N/A" };
              const endDate = occurrences[colname]
                ? formatDateParts(occurrences[colname].latest_occurrence)
                : { day: "N/A", month: "N/A", year: "N/A" };
              let differenceText = "N/A";
              if (startDate && endDate) {
                const differenceDays = calculateDateDifference(
                  occurrences[colname].first_occurrence,
                  occurrences[colname].latest_occurrence
                );
                differenceText =
                  differenceDays === 0 ? "Same day" : `${differenceDays} days`;
              }

              rows[colname].map((row, rowIndex) => {
                count = count + row.entities;
                if (
                  priorityMapping[row.criticality.toLowerCase()] >
                  priorityMapping[criticality]
                ) {
                  criticality = row.criticality.toLowerCase();
                }
              });

              return (
                <div className="col" key={index}>
                  <div className="miterBox">
                    <div className="miterHead d-flex align-items-center justify-content-between">
                      <p>{colname}</p>
                      <b className={criticality}>{count}</b>
                    </div>
                    <div className="miterContent mb-3">
                      {startDate.month !== "Invalid" ? (
                        <>
                          <div className="dateStart">
                            <b>{startDate.day}</b>
                            <span>{startDate.month} </span>
                          </div>
                          <div className="dateTo">
                            <p>{t("to")}</p>
                            <span>{differenceText}</span>
                          </div>
                          <div className="dateEnd">
                            <b>{endDate.day}</b>
                            <span>{endDate.month} </span>
                          </div>
                        </>
                      ) : (
                        <div className="dateStart">
                          <span>{t("no_detection_found_msg")}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mitre_content_section">
          <div className="row">
            {columns.map((colname, columnIndex) => (
              <div className={`column col`} key={columnIndex} >
                {rows[colname].length > 0 ? (
                  rows[colname].map((row, rowIndex) => (
                    <div className="technique-cell-container" key={rowIndex}  data-bs-toggle={"offcanvas"}
                    data-bs-target={"#bxOffcanvasPanel"} onClick={() => handleRowClick(row)}>
                      <div
                        className={`technique-cell mitre-cell pointer-cursor ${row.criticality.toLowerCase()}`}
                      >
                        <div className="miter_attack_card p-2">
                          <div
                            className="miter_attack_entitycount"
                            key={columnIndex}
                          >
                            {row.entities}
                          </div>
                          <div
                            className="miter_attack_description"
                            title={row.name}
                          >
                            {row.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default MiterDashboardWidget;
