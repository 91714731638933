import React, { useLayoutEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock'; // Use highstock for stock charts

const StockTrendChart = (props) => {
  const chartContainer = useRef(null);

  const container = props.container;
  // Generate zigzag dummy data
  const generateZigZagData = () => {
    const data = [];
    let time = Date.UTC(2021, 0, 1); // Start date: Jan 1, 2021
    const increment = 3600 * 1000; // 1 hour in milliseconds
    let value = 100;

    for (let i = 0; i < 100; i++) { // 100 data points
      value += (Math.random() > 0.5 ? 1 : -1) * Math.random() * 10; // Random zigzag changes
      data.push([time, value]);
      time += increment;
    }
    return data;
  };

  const dummyData = generateZigZagData();

  useLayoutEffect(() => {
    let height = props?.height;
    if (chartContainer.current) {
      Highcharts.stockChart(container, {
        chart: {
          height: height ? height + 'px' : '400px',
          spacingTop: 0, // Remove padding from the top
          spacingBottom: 0, // Remove padding from the bottom // Fallback to 400px if height is not found
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: '' // Remove x-axis title
          },
          labels: {
            style: {
              fontSize: '7px',
            //   display:'None' // Adjust font size for x-axis labels
            }
          },
          gridLineWidth: 0,
        },
        yAxis: {
          title: {
            text: '' // Remove y-axis title
          },
          opposite: false, // Position the Y-axis on the left
          labels: {
            enabled:false
          },
          gridLineWidth: 0,
        },
        rangeSelector: {
          enabled: false, // Disable the range selector buttons
        },
        navigator: {
          enabled: false, // Disable the x-axis scroller/navigator
        },
        scrollbar: {
          enabled: false, // Ensure the scrollbar is fully disabled
        },
        series: [
          {
            name: 'AAPL',
            type: 'area',
            data: dummyData, // Use the zigzag dummy data
            gapSize: 5,
            color: '#ffa50066', 
            tooltip: {
              valueDecimals: 2,
            },
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, 'rgba(255, 165, 0, 0.4)'], // Custom color #ffa50066 at the top (40% opacity)
                [1, 'rgba(255, 165, 0, 0)'],   // Fade to transparent at the bottom
              ],
            },
            threshold: null,
          },
        ],
        credits: {
          enabled: false,
        },
      });
    }
  }, [container]);

  return <div ref={chartContainer} id={container} />;
};

export default StockTrendChart;
