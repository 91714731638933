import { useEffect } from "react";
import { Navigation } from "../../components/common/mainHeader/leftHeader/Navigation";
import { useLocation } from "react-router";
import "../content/content.css";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import LayoutPanel from "./contentpanels/LayoutPanel";

function ContentTemplate() {
  const location = useLocation();

  useEffect(() => {
    Navigation.onnavigate(location.pathname);
    CommonTemplate.removeSaveSearchModal();
  }, [location.pathname]);

  return (
    <div>
      <div id="wrapper" className="bxContent contentEditPage">
        <LayoutPanel />
      </div>
    </div>
  );
}

export default ContentTemplate;