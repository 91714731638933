import { useEffect } from "react";
import ActionData from "./ActionData";
import { Stream } from "../StreamScript";
import { t } from "i18next";

function ActionList(props) {
  let viewclose = null;
  let backicon;

  useEffect(() => {
    if (props.enabled) {
      Stream.loadChartData(props.stream?._id);
    }
  }, [props.stream, props.enabled]);

  const filterData = props.stream?.filters?.filters;
  const filterCount = props.stream?.filters?.count;
  const detectionsData = props.stream?.detections?.detections;
  const detectionsCount = props.stream?.detections?.count;
  const automationsData = props.stream?.automations?.automations;
  const automationsCount = props.stream?.automations?.count;
  const reportsData = props.stream?.reports?.reports;
  const reportsCount = props.stream?.reports?.count;
  const sequencesData = props.stream?.sequences?.sequences;
  const sequencesCount = props.stream?.sequences?.count;
  if (props.enabled) {
    viewclose = (
      <div className="offcanvas-footer bg_canvas p-2 text-end">
        <span
          type="button"
          className="text-white me-3"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          {t("close")}
        </span>
      </div>
    );
  } else {
    backicon = (
      <button
        href="#streamList"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#bnfxAddNewStreams"
        aria-controls="offcanvasRight"
        aria-label="Close"
      >
        <span className="material-symbols-outlined">arrow_back</span>
      </button>
    );
  }
  let linkeddetails = props.stream.details;

  return (
    <>
      <div
        className="offcanvas-header border-bottom"
        id={
          props.enabled
            ? "enabled-stream-form"
            : "available-stream-form" + props.stream?._id
        }
      >
        <h5
          className="offcanvas-title text-white"
          href={"#" + props.stream?._id}
          identifier={props.stream?._id}
          title={props.stream.title}
        >
          {props.stream.title}
          <p className="bxStreamDiscription_link bnfxstreamviewpanel" title= {props.stream.description}>
            {props.stream.description}
          </p>
          {linkeddetails ? null : (
            <span className="badge rounded-pill bg-secondary p-1 mb-2 text-uppercase mt-3">
              {t("name.datasource")}&nbsp;&nbsp;{props.stream.datasource}
            </span>
          )}
        </h5>
        {backicon}
      </div>

      <div className="offcanvas-body">
        {filterData && (
          <ActionData
            data={filterData}
            count={filterCount}
            dataType={t("filters")}
          />
        )}
        {detectionsData && (
          <ActionData
            data={detectionsData}
            count={detectionsCount}
            dataType={t("meta.detection")}
          />
        )}
        {automationsData && (
          <ActionData
            data={automationsData}
            count={automationsCount}
            dataType={t("meta.automation")}
          />
        )}
        {reportsData && (
          <ActionData
            data={reportsData}
            count={reportsCount}
            dataType={t("meta.report")}
          />
        )}
        {sequencesData && (
          <ActionData
            data={sequencesData}
            count={sequencesCount}
            dataType={t("meta.sequence")}
          />
        )}
       </div>

      {props.enabled && (
        <div className="col-12 mb-1 ms-3">
          <span className="badge rounded-pill bg-secondary p-1">
            {" "}
            {t("enabled.graph")}
          </span>
        </div>
      )}
        <div
          className="col-12 mb-4"
          id={"bnfxStreamColumnChart" + props.stream?._id}
        >
          {/* here you will replace graph for every stream */}
        </div>
     
      {viewclose}
    </>
  );
}

export default ActionList;
