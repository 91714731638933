import { Dashboard } from "../DashboardScript";
import { useGlobalState } from "../../../components/common/GlobalVariables";
import { useLayoutEffect } from "react";
import DashboardListLayout from "./DashboardListLayout";

function DashboardListContainer() {
  const [collapsed] = useGlobalState("global_left_panel_collapsed");

  useLayoutEffect(() => {
    let tasktab = document.getElementById("tasks-switch");
    if (tasktab && !tasktab.getAttribute("loaded")) {
      Dashboard.loadEnabledDashboardLayout(0);
      tasktab.setAttribute("loaded", true);
    }
  });

  return (
    <>
      <div
        id="tasks-switch"
        className={`tab-pane show active ${collapsed ? "collapsed" : ""}`}
        role="tabpanel"
        aria-labelledby="leftSwitch-tab"
      >
        <div className={`collapse-sidebar dashboard ${collapsed ? "right" : ""}`}>
          <i
            className="material-symbols-outlined text-white"
            onClick={Dashboard.handleDashboardToggleCollapse}
          >arrow_forward_ios</i>
        </div>
        <section
          className="left-task left-sidebar float-start"
          id="bnfxDashboardList"
        >
          <DashboardListLayout />
        </section>
      </div>
    </>
  );
}

export default DashboardListContainer;
