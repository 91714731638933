import { t } from "i18next";import { Automation } from "./AutomationScript";
import { useGlobalState } from "../GlobalVariables";

function AutomationTaskmap() {
  const [globaldetectiongraphid] = useGlobalState("globalautomationworkflowid")

  return (
    <>
      <div
        className="offcanvas Custom_offcanvas offcanvas-end"
        id="bnfxautomationtaskmap"
        aria-labelledby="bnfxAutomationLayout"
        data-bs-backdrop={false}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="staticBackdropLabel">
            {t("automation.workflow.header.text")}{" "}
            {globaldetectiongraphid.title?.toUpperCase()}
          </h5>
        </div>
        <div className="bnfxAutomatinConatiner" id={"bnfxtaskmap"+globaldetectiongraphid._id}></div>
        <div className="offcanvas-footer bg_canvas p-2 text-end">
          <button
            type="button"
            className="me-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              Automation.removeClass();
            }}
          >
            {t("close")}
          </button>
        </div>
      </div>
    </>
  );
}

export default AutomationTaskmap;
