import { t } from "i18next";

function DashboardFilterLayout() {
  return (
    <>
      <div
        className="modal bxPopup fade"
        id="bxFilterTop"
        tabIndex="-1"
        aria-labelledby="bxFilterTop"
        aria-hidden="true"
        data-bs-backdrop={false}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="filterHead modal-header">
              <h1>{t("filters")}</h1>
              <button
                type="button"
                className="material-symbols-outlined bxClose"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                close
              </button>
            </div>
            <div className="filterBody modal-body">
              <div
                id="bnfxfilterPopupLoadingIcon"
                className="loading-icon"
                style={{ display: "none" }}
              >
                <div className="spinner-border"></div>
              </div>
              <form action="">
                <div className="form-group mb-2" id="filterselectdropdown">
                  {/* append filter */}
                </div>
              </form>
            </div>
            <div className="filterFooter modal-footer">
              <div>
                <button
                  type="button"
                  className=" selectpicker btn-outline-light text-white me-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardFilterLayout;
