import { t } from "i18next";
import React, { memo, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import { Incidence } from "../common/IncidencePanel/IncidenceScript";
import { useGlobalState } from "../common/GlobalVariables";

export default memo(({ data, isConnectable }) => {
  let incidentdata = data.incidentrequest.incidentrequest;
  let requestdata=data.incidentrequest.requestdata
  const [globaldetectiongraphid] = useGlobalState("globaldetectiongraphid");
  let entry = {
    tenant: incidentdata?.tenant,
    detectionid: incidentdata.detectionid,
  };
  const [previousIncidentData, setPreviousIncidentData] = useState(null);
  useEffect(() => {
    // Check if incident data has changed
    if (
      !previousIncidentData ||
      previousIncidentData.detectionid !== incidentdata.detectionid
    ) {
     
      Incidence.getincidentid(
        entry,
        entry.detectionid,
        incidentdata.streamid,
        incidentdata.entity,
        incidentdata.entitytype,
        "bnfxgraphincident"
      );

      setPreviousIncidentData(incidentdata);
    }
  }, [incidentdata, previousIncidentData]);
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="left"
        style={{ left: -10, top: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />

      <div className="cardBox_outer">
        <div className="BFNX_Bg_color">
          <div className="dataSource cardBox cardResult">
            <span className="material-symbols-outlined iconImg">
            atr
            </span>
            <p className="bxEventKey bnfxgraphkey">{data?.type}: </p>
          </div>
        </div>
      </div>
      <div className="BFNX_data_attack">
        <div className=" bxNetWorkArrow">
          {data.label}
          <li
            id={
              "bnfxdetectiongraphcreateincident" +
              incidentdata.entitytype +
              incidentdata.entity +
              incidentdata.detectionid
            }
          >
            <button
              className="dropdown-item bnfx_btn_create_incident"
              id="createincidenttab"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              data-bs-dismiss="offcanvas"
              onClick={() =>
                Incidence.loadformdata(
                  entry,
                  incidentdata.streamid,
                  incidentdata.entity,
                  incidentdata.entitytype,
                  globaldetectiongraphid
                )
              }
            >
              <span className="material-symbols-outlined">
              destruction
              </span>{" "}
              {t("title.createincidence")}
            </button>
          </li>

          <li className="incidentclass">
            <span
              className="badge rounded-pill  bg-secondary p-1 cursorclass"
              data-bs-toggle="offcanvas"
              data-bs-target="#bxOffcanvasIncidence"
            >
              {" "}
              <div
                id={
                  "bnfxgraphincident" +
                  incidentdata.entitytype +
                  incidentdata.entity +
                  incidentdata.detectionid
                }
                onClick={(e) =>
                  Incidence.loaddetectiongraphincidencemetadata(e,requestdata)
                }
              >
                {" "}
              </div>
            </span>
          </li>
        </div>
      </div>
    </>
  );
});
