
function CommonModel() {
  return (
    <>
      <div
        className="modal bxPopup fade"
        id="bnfxnewmodal"
        tabIndex="-1"
        aria-labelledby="newSearchLabel"
        aria-hidden="true"
      >
         <div className="modal-dialog modal-dialog-centered w-75 h-75" id="bnfxstreamview">
         {/* <we will render viewlayout/> */}
         </div>
      </div>
    </>
  );
}

export default CommonModel;
