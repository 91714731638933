import React from "react";
import { useTranslation } from "react-i18next";

const DataExpandable = ({ column, rowData, maxToShow }) => {
  const { t } = useTranslation();
  const renderEntries = () => {
    return (
      <>
      <div className="d-flex align-items-center">
        {rowData[column]
          .slice(0, maxToShow)
          .map((entry, index) => (
            <div className="text-capitalize bnfxTooltipBox" key={index}>
              {column === "roles" || column === "groups" ? (
                <>
                  <span className="bnfxBadge zIndex" >
                    {entry?.name?.charAt(0)}
                  </span>
                  <div className="bnfxTooltip" title={entry.name}> {entry?.tenant ? `${entry.name} (${entry.tenant})` : entry?.name}</div></>
              ) : (
                <>
                  <span className="bnfxBadge zIndex">
                    {entry?.charAt(0)}

                  </span>
                  <div className="bnfxTooltip" title={entry}>{entry}</div>
                </>
              )}
            </div>
          ))}
          </div>
      </>
    );
  };

  return (
    <>
      {rowData[column]?.length > maxToShow && (
        <>
          <div className="d-flex align-items-center">
            {renderEntries()}
              <div className="bnfxTooltipBox ms-2">
                <span className="text-primary " >
                  +{rowData[column]?.length-2} {t("more.text")}
                </span>
                <div className="bnfxTooltip">
                  {Array.isArray(rowData[column]) ? (
                    rowData[column].map((value, index) => (
                      <p key={index}>{value}</p>
                    ))
                  ) : (
                    <p>{rowData[column]}</p> /* Render non-array data as a single paragraph */
                  )}
                </div>

              </div>
            </div>
        </>
      )}
      {rowData[column]?.length <= maxToShow && renderEntries()}
    </>
  );
};

export default DataExpandable;
