import { useEffect, useRef } from "react";
import { Health } from "./HealthScript";

function HealthPageLayout() {
    const pageloaded = useRef(false);
    useEffect(() => {
        if (pageloaded.current) {
            return;
        }
        Health.getRedisData();
        pageloaded.current = true;
      }, []);

  return (
    <>
      <div className="col-11 m-auto">
        <div className="HealthCardsSec">
          <div className="HealthTopName">
            <img className="" src="../images/redis.png" alt="" />
            <span className="healthTitle">Redis</span>
          </div>
          <div className="HealthCards row g-1" id="bnfxRedisData">
            {/* <RedisTemplate/> */}
          </div>
        </div>

        <div className="HealthCardsSec">
          <div className="HealthTopName">
            <img className="" src="../images/database.png" alt="" />
            <span className="healthTitle">Database</span>
          </div>
          <div className="HealthCards row g-1" id="bnfxPostgressData">
            {/* <PostgressTemplate/> */}
          </div>
        </div>

        <div className="HealthCardsSec">
          <div className="HealthTopName">
            <img className="" src="../images/kafka.png" alt="" />
            <span className="healthTitle">kafka</span>
          </div>

          <div className="HealthCards row g-1">
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">EventReceiver</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor1">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">Indexer</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor2">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">StreamFilterOutput</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor3">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">EventReceiver</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor4">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">Indexer</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor5">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">StreamFilterOutput</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor6">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">EventReceiver</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor7">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">Indexer</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor8">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">StreamFilterOutput</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor9">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">EventReceiver</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor10">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">Indexer</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor1">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">StreamFilterOutput</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor2">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">EventReceiver</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor2">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">Indexer</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor3">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">StreamFilterOutput</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor4">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">EventReceiver</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor5">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">Indexer</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor6">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">StreamFilterOutput</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor7">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">EventReceiver</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor8">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">Indexer</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor9">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">StreamFilterOutput</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor10">512</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCards_outer">
                <p className="HealthCardsSmTitle mb-2">EventReceiver</p>
                <div className="HealthCardsInner">
                  <span className="HealthCardsSmTitle">Records</span>
                  <p className="HealthCardsLgTitle HColor1">512</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="HealthCardsSec">
          <div className="HealthTopName">
            <img className="" src="../images/lens.png" alt="" />
            <span className="healthTitle">Lens</span>
          </div>
          <div className="HealthCards row g-1">
            <div className="col-sm-2">
              <div className="HealthCardsInner">
                <span className="HealthCardsSmTitle">Service Name</span>
                <p className="HealthCardsLgTitle HColor1">Endpoint service</p>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCardsInner">
                <span className="HealthCardsSmTitle">Status </span>
                <p className="HealthCardsLgTitle HColor2">Process</p>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCardsInner">
                <span className="HealthCardsSmTitle">Memory </span>
                <p className="HealthCardsLgTitle HColor3">345mb</p>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCardsInner">
                <span className="HealthCardsSmTitle">Service Name</span>
                <p className="HealthCardsLgTitle HColor4">Content service</p>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCardsInner">
                <span className="HealthCardsSmTitle">Status </span>
                <p className="HealthCardsLgTitle HColor5">Process</p>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="HealthCardsInner">
                <span className="HealthCardsSmTitle">Memory </span>
                <p className="HealthCardsLgTitle HColor6">345mb</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HealthPageLayout;
