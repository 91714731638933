import React from "react";
import { flatten } from "flat";
import { SearchTemplate } from "./SearchScript";
import moment from "moment";

function SearchEventLayout(props) {
  let data = flatten(props.searchData);
  const date = new Date(data.timestamp);
  let title = data.title;
  let condition = title;
  let querytype = props.querytype;
  let tablename = props.tablename;
  const detectionDate = moment(new Date(date)).format('DD  MMM hh:mm');
const {
  messageid: messageIdVar,
  "messageid.set": messageIdSet,
  ...requireDetectionData
} = data;
  return (
    <>
      <div className="bxSearchEventDetection">
        <div className="bxSearchHead">
          <p className=" d-flex align-items-center">
            <span className="material-symbols-outlined me-2 ayu">
            pace
            </span>
            {detectionDate}
          </p>

          {condition?<h4 className="text-uppercase ">
            <span className="material-symbols-outlined titleicon me-2">
            label
            </span>
            <span
              className="m-0 double-space bxcursor"
              data-bs-target={
                querytype === "incidentdetails"
                  ? "#bxOffcanvasIncidence"
                  : "#bxOffcanvasPanel"
              }
              data-bs-toggle="offcanvas"
              onClick={() => SearchTemplate.loadrowonclick(data, querytype)}
            >
              {condition}
            </span>
          </h4>:null}
        </div>
        <div className="p-0">
          <p className="bxJsonText m-0">
            {Object.keys(requireDetectionData)
              .filter((key) => key !== "timestamp")
              .map((key) => (
                <React.Fragment key={key}>
                  <>
                    <span className="bxEventKey" onDoubleClick={() =>
                        SearchTemplate.handleDoubleClick(
                          key,
                          requireDetectionData[key],
                          tablename
                        )
                      }>{key}</span>
                    {(querytype === "detection" &&
                      (key === "entity" || key === "streamname")) ||
                    (querytype === "entityscoring" &&
                      (key === "detectionname" || key === "streamname")) ||
                    (querytype === "incidentdetails" &&
                      (key === "_detectionname" ||
                        key === "_streamname" ||
                        key === "entity")) ? (
                      <span
                        className="bxEventValue bxhighlight-search "
                        data-bs-target="#bxOffcanvasPanel"
                        data-bs-toggle="offcanvas"
                        onClick={() =>
                          SearchTemplate.loadrowonclick(data, querytype, key)
                        }
                      >
                        {requireDetectionData[key]}
                      </span>
                    ) : (
                      <span className="bxEventValue" onDoubleClick={() =>
                        SearchTemplate.handleDoubleClick(
                          key,
                          requireDetectionData[key],
                          tablename
                        )
                      }>{key==="expiry"?moment(new Date(requireDetectionData[key])).format('DD  MMM hh:mm'):requireDetectionData[key]}</span>
                    )}
                  </>
                </React.Fragment>
              ))}
          </p>
        </div>
      </div>
    </>
  );
}

export default SearchEventLayout;
