import { t } from "i18next";
import { Terminal } from "../../pages/terminal/TerminalScript";

function CloseMenuButton({outerclass}) {

   return <>
   
   <div className="MenuBar">

        <div className="ph-row float-end">
          <div className="ph-col bg-danger bnfxclosebutton" title='close'><button className="material-symbols-outlined close-button" onClick={Terminal.closeconfirmation}>{t("close")}</button></div>
          {/* <div className="ph-col bg-warning mx-2 exitfullscreen d-none" title='exit full screen'><span className="material-symbols-outlined close-button" onClick={Terminal.closeFullscreen}>{t("name.close_fullscreen")}</span></div>
          <div className="ph-col bg-success mx-2 enterfullscreen" title='enter full screen'><span className="material-symbols-outlined close-button" onClick={()=>{Terminal.openFullscreen(outerclass)}}>{t("name.fullscreen")}</span></div> */}
        </div>

      </div>
   </>;
}

export default CloseMenuButton;
