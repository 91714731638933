import { t } from "i18next";

function OnboardPage() {
  return (
    <div className="row align-items-center bxLoginArea onboard_page">
      <div className="col-lg-5 col-xxl-4 mx-auto">
        <div className="bxLeftBox text-white p-lg-0 p-3 text-center">
          <img src="../images/tick.png" alt="Binaryflux" height="200" />
          <br />
          <br />
          <h3 className="logDis">{t("onboardingpage.title")}</h3>
          <h5 className="logDis">
         {t("onboardingpage.description")}
          </h5>
          <br /> <br />
          <div className="bxRightBox text-white">
            <button type="button" className="btn btn-primary">
              <a href="/" className="text-white">
                {t("login.name")}
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnboardPage;
