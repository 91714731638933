import { useLayoutEffect } from "react";
import $ from 'jquery';

import IncidenceAssignBox from "./IncidenceAssignBox";
import IncidenceStatusBox from "./IncidenceStatusBox";
import IncidenceCriticalityBox from "./IncidenceCriticalityBox";
import IncidenceReopen from "./IncidenceReopen";
import { Incidence } from "./IncidenceScript";
import { t } from "i18next";
import moment from "moment";
import IncidenceWorflowBox from "./IncidenceWorflowBox";


function IncidenceOverview(props) {
  let result = props.data

  useLayoutEffect(() => {

    $(".description-box, .assignee-box, .entity-box, .criticality-box, .status-box, .reopen-box, .worflow-box").hide();
    document.getElementById('description-text').value = result.description


  });

  const handleFormSubmit = (texttypeid, type) => {
    let text = document.getElementById(texttypeid);
    let data = {}

    if (text !== null) {

      data["description"] = text.value
      Incidence.loadupdate(props.id, data)
    }
  };
  return (
    <div
      className="tab-pane fade show"
      id="Overview-tab-pane"
      role="tabpanel"
      aria-labelledby="Overview-tab"
      tabIndex="0"
    >
      <div className="bxTabHeight">
        
        {result.detectionname?<div className="overview_sec overview_Headsec px-3">
          <div className="WhatHappen w-100 pe-0">
            <span className="text-uppercase d-block">{t("whathappen")}</span>
            <div className="overview_sec px-0">
              <p className="p-3 border w-100"><span className="text-capitalize">{result.detectionname}</span></p>
            </div>
          </div>
        </div>:null}
        
        <div className="overview_sec text-uppercase  px-3">{t("context")}</div>
        <div className="overview_sec">
          <label>
          {t("incidence.panel.overview,id.text")} :{" "}            
          </label>
          <div className="bg_color_light no_span ">
              <p className="badge rounded-pill bg-secondary p-1">{result.id}</p>
          </div>
        </div>

        
        <div className="overview_sec">
          <label>
            {t("descripition")}:{" "}            
          </label>
          <div className="bg_color_light text-capitalize">
          <p>
            {" "}
            {result.description?result.description:t("title.nodescription")} 
          </p>
          <span
              className="description-btn material-symbols-outlined cursorclass"
              style={{ fontSize: '16px' }}
              onClick={(editorclass) => Incidence.display('description-box',)}
            >
             border_color
            </span>
            </div>
        </div>
        <div className="overview_sec">
          <label >{t("workflow")}:
          </label>
          <div className="bg_color_light text-capitalize">
          <p>{result.workflowname} </p>
          <span
              className="worflow-btn material-symbols-outlined cursorclass"
              style={{ fontSize: '16px' }}
              onClick={(editorclass) => Incidence.loadworflow()}
            >
           border_color
            </span>
            </div>
        </div>
        <div className="overview_sec">
          <label>
            {t("incidence.assigny.plus.assignytpe")}:{" "}
          </label>
          <div className="bg_color_light">
            <p>
              {" "}
              {result.assignee}{" "}
              <span className="badge badge-info-lighten p-1 ms-3 px-3">
              {result.asigneetype.toUpperCase()}
              </span>
            </p>
            <span
                className="assignee-btn material-symbols-outlined cursorclass"
                style={{ fontSize: '16px' }}
                onClick={(editorclass) => Incidence.display('assignee-box')}
              >
              border_color
              </span>
            </div>
        </div>
       {result.streamname? <div className="overview_sec">
          <label htmlFor=""> {t("stream_title")}:</label>
          <div className="bg_color_light no_span text-capitalize"><p>{result.streamname}</p></div>
        </div>:null}
        <div className="overview_sec">
          <label>
            {t("incidence.entity.plus.entitytype")}:{" "}
          </label>
          <div className="bg_color_light no_span"><p>
            {" "}
            {result.entity}{" "}
            <span className="badge badge-info-lighten p-1 ms-3 px-3">
              {result.entitytype.toUpperCase()}
            </span>
          </p>
          </div>
        </div>
        <div className="overview_sec">
          <label>
            {t("create.incidence.criticality")}:{" "}
            
          </label>
          <div className="bg_color_light">
          <p>
            {" "}
            <span className={` badge  ${Incidence.badgecriticalityColor(result.criticality)}`}>
              {result.criticality}
            </span>
          </p>
          <span
              className="criticality-btn material-symbols-outlined cursorclass"
              style={{ fontSize: '16px' }}
              onClick={(editorclass) => Incidence.display('criticality-box')}

            >
          border_color
            </span>
          </div>
        </div>
        <div className="overview_sec">
          <label className="">
            {t("status")}:{" "}
          </label>

          <div className="bg_color_light">
          <p>
            {" "}
            <span className="badge badge-success-lighten p-1 px-3">
              {result.status.toUpperCase()}
            </span>
          </p>
          {result.completed !== true ? (
              <span
                className="status-btn material-symbols-outlined cursorclass"
                style={{ fontSize: '16px' }}
                onClick={(editorclass) => Incidence.loadstatus(result.workflow, result.status)}
              >
              border_color
              </span>
            ) : <button className="btn btn-primary p-0 px-2 ms-2" onClick={(editorclass) => Incidence.display('reopen-box')}>
              {t("reopen.icon")}
            </button>}
            </div>
        </div>
        <div className="overview_sec">
          <label htmlFor="probetime">{t("last.probe.time")}:</label>
          <div className="bg_color_light no_span"><p> {result.lastprobetime ? moment(new Date(result.lastprobetime)).format('DD  MMM hh:mm') : "N/A"}</p></div>
        </div>
        <div className="description-box p-3">
          <label htmlFor="description-text" className="mt-0"> {t("create.incidence.description")}:</label>
          <textarea
            name="description"
            className="bxDF-control form-control mb-3"
            id="description-text"
            placeholder="please type description.."
          ></textarea>
          <button type="submit" value="Save" className="btn btn-primary" onClick={(e) => handleFormSubmit('description-text')}>
            {t("name.save")}
          </button>
          <button type="button" className="btn text-white mt-0" onClick={(editorclass) => Incidence.displayback('description-box')}>{t("cancel")}</button>
        </div>

        <IncidenceCriticalityBox  data={result} />
        <IncidenceStatusBox data={result}/>
        <IncidenceAssignBox data={result} />
        <IncidenceReopen data={result} />
        <IncidenceWorflowBox  data={result}/>
        {/* <div id="bnfxStatusProgressBar"></div> */}
      </div>
     
    </div>
  );
}

export default IncidenceOverview;