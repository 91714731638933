import { Organization } from "./OrganizationScript";

import Select from "react-select";
import {
  getGlobalState,
  setGlobalState,
  useGlobalState,
} from "../../components/common/GlobalVariables";
import classNames from "classnames";
import { useState } from "react";
import { t } from "i18next";

function AddGroup() {
  const [TenantOptions] = useGlobalState("TenantOptions");
  let singletenant = getGlobalState("singletenant");
  const [StreamsOptions] = useGlobalState("StreamsOptions");
  const [isEdit] = useGlobalState("isEdit");
  const [offset, setOffset] = useState(0);
  const [_id] = useGlobalState("_id");
  const [selectedStreams, setSelectedStreams] =
    useGlobalState("selectedStreams");
  const [selectedTenants, setSelectedTenants] =
    useGlobalState("selectedTenants");
  const [selectedStatus, setSelectedStatus] = useGlobalState("selectedStatus");

  const handleStreamSelectChange = (selectedOptions) => {
    // Check if "Select All" is selected
    const isSelectAllSelected = selectedOptions.some(
      (option) => option.value === "SELECT ALL"
    );

    // If "Select All" is selected, set it as the only selected option
    if (isSelectAllSelected) {
      setSelectedStreams([{ value: "SELECT ALL", label: "SELECT ALL" }]);
    } else {
      // Remove "Select All" from the selected options
      const filteredOptions = selectedOptions.filter(
        (option) => option.value !== "SELECT ALL"
      );
      setSelectedStreams(filteredOptions);
    }
  };

  const handleTenantSelectChange = (selectedOptions) => {
    setSelectedTenants(selectedOptions);
  };

  let statusoptions = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "DEPRECATED", label: "DEPRECATED" },
  ];

  const handlestatusChange = (selectedOptions) => {
    setSelectedStatus(selectedOptions);
  };

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const handleInputChange = () => {
    const requiredFields = ["group_name", "group_email"];
    let areAllFieldsFilled = true;

    requiredFields.forEach((fieldId) => {
      const field = document.getElementById(fieldId);
      if (!field || field.value.trim() === "") {
        areAllFieldsFilled = false;
        field.classList.add("border-danger"); // Add the class if the field is empty
      } else {
        field.classList.remove("border-danger"); // Remove the class if the field is filled
      }
    });
    setIsSaveEnabled(areAllFieldsFilled);
  };

  const formclear = () => {
    setGlobalState("StreamsOptions", []);
    document.getElementById("group_name").value = "";
    document.getElementById("group_email").value = "";
    setSelectedStreams([]);
    setSelectedTenants("");
    setGlobalState("isEdit", false);
    const field = document.getElementById("group_name");

    field.classList.remove("border-danger");
  };

  const handleFormSubmit = (e) => {
    setGlobalState("StreamsOptions", []);
    const formData = {
      Groupname: document.getElementById("group_name").value,
      emailaddress: document.getElementById("group_email").value,
      groupstatus: selectedStatus,
      Tenants: selectedTenants,
      streams: selectedStreams,
    };
    if (isEdit) {
      Organization.update_group(formData, "securitygroup", _id);
    } else {
      Organization.addGroup(formData);
    }
    formclear();
  };

  const loadMore = () => {
    setOffset(offset + 3); // Adjust the increment according to your needs
    Organization.loadStreamList(offset + 3);
  };

  let tenantoption = null;
  if (singletenant) {
    tenantoption = null;
  } else {
    tenantoption = (
      <>
        <div className="col-sm-6">
          <div className=" mb-2">
            <label htmlFor="group_tenants" className="form-label">
              {" "}
              {t("organization.tenants")}{" "}
            </label>
            <Select
              placeholder="Please select tenant"
              id="group_tenants"
              className={classNames("form-control", {
                "low-opacity": isEdit,
              })}
              options={TenantOptions}
              value={selectedTenants}
              onChange={handleTenantSelectChange}
              isDisabled={isEdit}
            ></Select>
            <small className="d-block text-end bxFontSize">
              {t("required")}
            </small>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className="bxOffcanvasAddOrg AddGroup offcanvas bg-dark offcanvas-end"
        tabIndex="-1"
        id="offcanvasAddGroup"
        aria-labelledby="offcanvasAddGroupLabel"
        data-bs-backdrop="false"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title text-white"
            id="offcanvasAddGroupLabel"
          >
            {isEdit
              ? t("organization.update.group.header.name")
              : t("organization.add.group.header.name")}
          </h5>
        </div>
        <div className="offcanvas-body p-0" id="bnfxaddGroupform">
          <div className="bxAddGroupForm p-4">
            <form action="">
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="group_name" className="form-label">
                      {t("organization.add.group.name")}
                    </label>
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "low-opacity": isEdit,
                      })}
                      id="group_name"
                      placeholder="Please enter group name"
                      autoComplete="off"
                      name="Groupname"
                      required
                      readOnly={isEdit}
                      onChange={!isEdit && handleInputChange}
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="group_email" className="form-label">
                      {t("organization.email.address")}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="group_email"
                      placeholder="dc-marvel@abc.com"
                      autoComplete="off"
                      name="emailaddress"
                      required
                      onChange={handleInputChange}
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label className="form-label">
                      {" "}
                      {t("status")}
                    </label>
                    <Select
                      placeholder="Please select criticality"
                      id="criticality"
                      aria-label="Default select example"
                      name="criticality"
                      options={statusoptions}
                      value={selectedStatus}
                      onChange={handlestatusChange}
                    ></Select>
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label className="form-label">
                      {" "}
                      {t("streams")}{" "}
                    </label>
                    <Select
                      placeholder="Please select streams"
                      id="group_streams"
                      className="form-control"
                      isMulti
                      options={StreamsOptions}
                      value={selectedStreams}
                      onChange={handleStreamSelectChange}
                      onMenuScrollToBottom={loadMore}
                    ></Select>
                  </div>
                </div>
                {tenantoption}
              </div>
            </form>
          </div>
        </div>
        <div className="offcanvas-footer py-2 px-3">
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="text-white me-3"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                formclear(); // Assuming clearfrom is a function
                Organization.removeClass();
              }}
            >
              {" "}
              {t("cancel")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                handleFormSubmit();
                Organization.removeClass();
              }}
              disabled={!isEdit && !isSaveEnabled}
            >
              {" "}
              {t("name.save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddGroup;
