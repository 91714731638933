import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import GraphDataExpandable from "./GraphDataExpandable";

export default memo(({ data, isConnectable }) => {
  let icon = null;

  if (data?.type === "context") {
    icon = (
      <span className="material-symbols-outlined iconImg">
        {" "}
        emoji_objects
      </span>
    );
  } else {
    icon = null
  }

  let labeldata = null;

  if (Array.isArray(data.label)) {
    if (data.label.length === 1) {
      labeldata = (
        <div className="dropdown bxNetWorkArrow">
          <p
          >
            {data.label}
          </p>
        </div>
      );

    } else {

      const completedata = data.label

      // labeldata = (
      //   <div className="dropdown bxNetWorkArrow">
      //     <button
      //       className={`btn btn-secondary dropdown-toggle ${data?.type === "context" ? "graphcontextclass" : ""
      //         }`}
      //       title={data.label[0]}
      //       type="button"
      //       data-bs-toggle="dropdown"
      //       aria-expanded="false"
      //     >
      //       {data.label[0]}
      //     </button>

      //     <ul className="dropdown-menu">
      //       {completedata.map((item, index) => (
      //         <li key={index}>
      //           <button type="button" className="dropdown-item">
      //             {item}
      //           </button>
      //         </li>
      //       ))}
      //     </ul>
      //   </div>
      // );
      labeldata = <GraphDataExpandable
        rowData={completedata}
        maxToShow={1}
      />
    }
  } else {

    labeldata = (
      <div className="dropdown bxNetWorkArrow">
        <p
        >
          {data.label}
        </p>
      </div>
    );
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="left"
        style={{ left: -10, top: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
      <div className={` ${data?.type !== "context" ? "bnfxattackpatternatrifcacts" : ""
        }`}>
        <div className="cardBox_outer">

          <div className={`BFNX_Bg_color ${data?.type !== "context" ? "BFXNResult" : ""
            }`}>

            <div className={`dataSource cardBox cardResult ${data?.type === "context" ? "graph_Context" : ""
              }`}>

              {icon}
              <p className="bxEventKey bnfxgraphkey">
                {data?.type}:{" "}
                {Array.isArray(data.label) ? (
                  data.label.length > 1 ? (
                    <span className="badge text-bg-secondary count">
                      {data.label.length}
                    </span>
                  ) : undefined
                ) : undefined}
              </p>
            </div>
          </div>
        </div>
        <div className="BFNX_data_attack BFXDataShowMore">
          {labeldata}
        </div>
      </div>
    </>
  );
});
