import { t } from "i18next";
import { SearchTemplate } from "./SearchScript";

function SearchCardLayout(props) {
  let data = props.searchData;
  return (
    <>
      <div className="save_card">
        <div className="d-flex align-items-center justify-content-between">
          <div className="saved_dataTitle">
            <div className="d-flex align-items-center">
              <h6 className="m-0"> {data?.query} </h6>
             { data?.schedule?<span className="custom_scheduleBadge">
                <h7 className="m-0 ms-1">{data?.schedule}</h7>
                <span className="material-symbols-outlined ml-2 schedule_icon">schedule</span>
              </span>:null}
            </div>
            <p>{data?.title}</p>
            <p className="m-0 saveS_des"> {data?.description}</p>

            {/* {data.schedule?<div className="d-block">{data.schedule}</div>:null} */}
          </div>
          <div className="d-block">
            <small className="">
              {SearchTemplate.formatTimestamp(data?.updateon)}
            </small>
            <div className="edit_btn_sec_box">
              <button
                type="button"
                className="delete_btn"
                title="Delete Metadata"
                onClick={() => {
                  SearchTemplate.removeSearch(data);
                }}
              >
                <span className="material-symbols-outlined">
                  {" "}
                  delete
                </span>
              </button>
              <button
                className="edit_btn me-3"
                type="button"
                id="editButton"
                title="Edit Metadata"
                onClick={() => {
                  SearchTemplate.handleedit(data);
                }}
                data-bs-toggle="modal"
                data-bs-target="#newSearch"
              >
                <span className="material-symbols-outlined">
                  {" "}
                  edit
                </span>
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  SearchTemplate.clickOnView(data.query);
                }}
              >
                {t("name.execute")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchCardLayout;
