import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { t } from "i18next";


export default memo(({isConnectable }) => {
  return (
    <>
    <Handle
        type="target"
        position={Position.Top}
        id="end"
        style={{ top: -10, left: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
      <div className="bnfx_automationtask_end_node">
        <div className="automation_Taskflow aT_end">
          
          <span className="material-symbols-outlined">arrow_and_edge</span>
          <span>{t("end")}</span>
        </div>
      </div>
      
    </>
  );
});