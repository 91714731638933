import Select from 'react-select'
import { CommonTemplate } from './CommonScript';
import { useGlobalState } from '../GlobalVariables';

function TenantLists(props) {
    const [selectedSearchTenants] = useGlobalState(
        "global_selectedtenant"
      );
   
    return (
      <Select
        id="bnfx_globaltenants"
        className="form-control"
        options={props.tenants}
        defaultValue={selectedSearchTenants?selectedSearchTenants:props.tenants[0]} 
        required
        onChange={CommonTemplate.selectTenantChange}
      ></Select>
    );
}
export default TenantLists;