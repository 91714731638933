import React, { useState } from "react";

import Select from "react-select";
import { setGlobalState, useGlobalState } from "../GlobalVariables";
import { Incidence } from "./IncidenceScript";
import { t } from "i18next";


function CreateIncident(props) {

  const [assigntype, setAssignType] = useGlobalState("assigntype");
  const [assignee, setAssignee] = useGlobalState("assignee");
  const [WorkFlowOptions] = useGlobalState("WorkFlowOptions");
  const [WorkFlowId, setWorkFlowId] = useGlobalState("WorkFlowId");
  const [dashboarduseroptions] = useGlobalState("dashboarduseroptions");
  const [dashboardgroupoptions] = useGlobalState("dashboardgroupoptions");
  const [assigneeoptions] = useGlobalState("assigneeoptions");

  const [criticality, setcriticality] = useState("");



  const handlecriticalityChange = (selectedOptions) => {
    setcriticality(selectedOptions);

  };


  const handleAssignChange = (selectedOptions) => {
    setAssignee(selectedOptions);

  };

  const handleWorkFlowChange = (selectedOptions) => {
    setWorkFlowId(selectedOptions)

  };

  const handleAssignTypeChange = (selectedOptions) => {
    setAssignType(selectedOptions);

    if (selectedOptions.label === 'USER') {

      setGlobalState("assigneeoptions", dashboarduseroptions)
    } else if (selectedOptions.label === "GROUP") {

      setGlobalState("assigneeoptions", dashboardgroupoptions)
    }

  }

  const assigntypeoptions = [
    { value: 'user', label: 'USER' },
    { value: 'group', label: 'GROUP' },
  ];

  const handleFormSubmit = (e) => {


    const formData = {
      name: document.getElementById('incidentname').value,
      description: document.getElementById('incidentdescription').value,
      criticality: criticality,
      asigneetype: assigntype, assignee: assignee, workflowid: WorkFlowId
    };

    Incidence.createincident(formData, props.incidentfrom)
    clearfrom()

  }
  let criticalityoptions = [
    { value: 'HIGH', label: 'HIGH' },
    { value: 'MEDIUM', label: 'MEDIUM' },
    { value: 'LOW', label: 'LOW' }
  ];
  const clearfrom = () => {
    document.getElementById('incidentname').value = '';
    document.getElementById('incidentdescription').value = '';
    setcriticality("")
    setAssignType([]);
    setAssignee([]);
    setGlobalState("WorkFlowId", "")

  };

  return (
    <>

      <div className="modal-header">
        <h4 className="BXf-18 text-white"><span className="material-symbols-outlined me-3">business_center</span> {props.entity}</h4>
        <h5 className="BXf-14 d-block">{props.title}</h5>
        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <form className="addIncidentForm">
          <p className="p-0">{t("create.incident.metadata")}</p>

          <div className="from-group">
            <label htmlFor="incidentname" className="form-label p-0">{t("input.name")}</label>
            <input type="text" className="form-control" id="incidentname" placeholder="Enter Name" name="name" autoComplete="off" />
            <small className="d-block text-end BXf-12">{t("required")}</small>
          </div>
          <div className="from-group">
            <label htmlFor="incidentdescription" className="form-label p-0">{t("create.incidence.description")}</label>
            <textarea placeholder="Enter Description" className="form-control" id="incidentdescription" ></textarea>
            <small className="d-block text-end">&nbsp;</small>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="bnfxworkflow" className="form-label p-0"> {t("create.incidence.workflow")}</label>
              <Select id="bnfxworkflow" aria-label="Default select example" name="status" options={WorkFlowOptions}
                value={WorkFlowId}
                onChange={handleWorkFlowChange}>
              </Select>
              <small className="d-block text-end BXf-12">{t("login.required")}</small>
            </div>
            <div className="col-sm-6">
              <label htmlFor="criticality" className="form-label p-0">{t("create.incidence.criticality")}</label>
              <Select id="criticality" aria-label="Default select example" name="criticality"
                options={criticalityoptions} value={criticality} onChange={handlecriticalityChange} >
              </Select>

              <small className="d-block text-end BXf-12">{t("login.required")}</small>
            </div>
          </div>

          <hr className="mb-4"></hr>
          <p className=" p-0"> {t("create.incidence.assign.header")}</p>
          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="bnfxassigntype" className="form-label p-0" > {t("create.incidence.assigntype")}</label>
              <Select id="bnfxassigntype" aria-label="Default select example" name="status" options={assigntypeoptions}
                value={assigntype}
                onChange={handleAssignTypeChange}>
              </Select>
              <small className="d-block text-end BXf-12">{t("login.required")}</small>
            </div>
            <div className="col-sm-6">
              <label htmlFor="bnfxassign" className="form-label p-0"> {t("create.incidence.assign")}</label>
              <Select id="bnfxassign" aria-label="Default select example" name="status" options={assigneeoptions}
                value={assignee}
                onChange={handleAssignChange}>
              </Select>
              <small className="d-block text-end BXf-12">{t("login.required")}</small>
            </div>
          </div>
          <div
          className="ToggleButton d-flex justify-content-between align-item-center mb-2 mt-3"
          id="bnfxincidentToggle"
        >
          <p className="badgestreamnote">
            {" "}
            {t("create.incident.notification")}
          </p>
          <label htmlFor="togBtn" className="switch streamswitch">
            <input
              type="checkbox"
              id="togBtn"
              onChange={(event)=>{Incidence.handleToggleChange(event)}}
            />
            <div className="slider round">
              <span className="off">{t("stream.accross")}</span>
              <span className="on">{t("stream.specific")}</span>
            </div>
          </label>
        </div>


        </form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-bs-toggle="offcanvas"
          data-bs-target="#bxOffcanvasPanel" onClick={clearfrom}> {t("close")}</button>
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" data-bs-toggle="offcanvas"
          data-bs-target="#bxOffcanvasPanel" onClick={handleFormSubmit}>{t("apply")}</button>
      </div>


    </>
  );
}

export default CreateIncident;