import React from "react";
import { Link } from "react-router-dom";
import { Navigation } from "./Navigation";

const MainNavLink = ({ iconx, title, description, path }) => {
  return (
    <div className="col-md-4">
      <div className="bxNavlist p-3">
        <Link
          className="bxNavlistLink row "
          title={title}
          description={description}
          icon={iconx}
          to={path}
          onClick={(e) => Navigation.onnavigate(e)}
        >
          <div className="col-auto">
            <span className="btn btn-dark">
              <span className="material-symbols-outlined">{iconx}</span>
            </span>
          </div>
          <div className="col">
            <h5>{title}</h5>
            <small>{description}</small>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MainNavLink;
