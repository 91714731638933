import StreamListLayout from "./StreamListLayout";
import { useLayoutEffect } from "react";
import { Stream } from "../StreamScript";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";

function StreamListContainer() {

  useLayoutEffect(() => {
    let tasktab = document.getElementById("tasks-switch");
    if (tasktab && !tasktab.getAttribute("loaded")) {
      Stream.loadEnabledLayout(0);
      tasktab.setAttribute("loaded", true);
    }
  }, []);

  return (
    <>
      <div
        id="tasks-switch"
        className="tab-pane show active"
        role="tabpanel"
        aria-labelledby="leftSwitch-tab"
      >
        <div
          id="bnfxtoggle"
          className="collapse-sidebar"
        >
          <i
            className="material-symbols-outlined text-white"
            onClick={CommonTemplate.handleToggleCollapse}
          >arrow_forward_ios</i>
        </div>
        <section className="left-task left-sidebar float-start">
          <StreamListLayout />
        </section>
      </div>
    </>
  );
}

export default StreamListContainer;
