import { OnboardingScript } from "./OnboardingScript";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { t } from "i18next";

function Verification(props) {
  let token = props.token;

  return (
    <div className="row align-items-center bxLoginArea">
      <div className="col-lg-5 col-xxl-4 mx-auto">
        <div className="bxLeftBox text-white p-lg-0 p-3 text-center">
          <img src="../images/email.png" alt="Binaryflux" height="150" />
          <br />
          <br />
          <h3 className="logDis">{t("verfiry.title")}</h3>
          <br /> <br />
          <div className="bxRightBox text-white">
            <PrimaryButton
              width="w-100"
              text="Verify"
              onClick={() => OnboardingScript.getVerification(token)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verification;
