import { useLayoutEffect } from "react";
import "../../dashboard/mitre.css";

function MitreWidget(props) {
  let intialrow = props.data?.mapping[0];
  let columns = Object?.keys(intialrow);
  let rows = props.data?.mapping;
  let counts = props.data?.counts;

  useLayoutEffect(() => {
    const grid = document.querySelector(".matrix-body");
    grid.style.maxHeight =
      grid.closest(".grid-stack-item-content ").clientHeight - 100 + "px";
  });

  return (
    <>
      <div className="matrix-body flex-row">
        <div className="column data">
          <div className="attack-matrix">
            <div className="matrix-wrapper">
              <div className="matrix_table">
                <div className="row matrix-header-row">
                  {columns.map((colname, index) => (
                    <div className="column" key={index}>
                      <div className="column tableHeader bold mini-font">
                        {colname}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row matrix-grid-row">
                  {columns.map((colname, columnIndex) => (
                    <div className="column" key={columnIndex}>
                      {" "}
                      {/* Add key here */}
                      {rows.map((row, rowIndex) => (
                        <div key={rowIndex}>
                          {" "}
                          {/* Add key here */}
                          {row[colname] ? (
                            <div className="technique-cell-container">
                              <div
                                className={`technique-cell matrix-cell pointer-cursor ${
                                  counts[row[colname]] ? "active" : "inactive"
                                }`}
                              >
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="parenthesis-marker">&gt;</div>
                                  <div className="description-span">
                                    {row[colname]}
                                  </div>
                                  <div
                                    key={columnIndex}
                                    className="analytics-count"
                                  >
                                    {counts[row[colname]]}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MitreWidget;
