import React, { useEffect } from "react";
import { Stream } from "../../../pages/stream/StreamScript";

function ArtifactData(props) {
  const output = props.data;

  const artifact = props.artifact;

  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  useEffect(() => {
    if (props.newcallback) {
      props.newcallback();
    }
  });
  


  return (
   <>
      <div className="row align-items-center" id="bnfxartifactlists">
        {output?.map((entry, index) => {
          const dynamicKey = Object.keys(entry).find(key => key !== 'count');
          return (
            <li
              key={index}
              className="list-group-item d-flex justify-content-between align-items-center bnfxdarkbackground"
              onDoubleClick={() => Stream.handleDoubleClick(artifact, entry[dynamicKey])}
            >
              {entry[dynamicKey]}
              <span className="badge bg-secondary">{entry.count}</span>
            </li>
          );
        })}
      </div>
      <div id="bnfxArtifactsDataPagination">
        {/* we will replace pagination here */}
      </div>
</>
    
  );
}

export default ArtifactData;
