import React from 'react';
import AnimatedNumbers from 'react-animated-numbers';
import { CollectorsScript } from '../../pages/collectors/CollectorsScript';

const EpsComponent = ({ eps ,totaldata}) => {
  const customTransitions = (index) => ({
    type: "tween",
    duration: 3, // Adjust this value to control the animation duration
  });
  const roundedNumber = Math.ceil(eps);
  return (
    <div className="d-flex time-section">
     <small className={`time-text ${eps === 0 ? "zeroeps" : ""} low-opacity`}>
  EPS <span className="differntiaterawdata">/</span> <span className="rawdatacount">{CollectorsScript.formatNumber(totaldata)}</span>
</small>

      <div className='epstime'>
        <AnimatedNumbers
          animateToNumber={roundedNumber?roundedNumber:0}
          includeComma={true} // Depending on your preference
          fontStyle={{ fontSize: 18, fontWeight: 400 }}
          transitions={customTransitions} // Adjust animation duration as needed
        />
      </div>
     
    </div>
  );
};

export default EpsComponent;
