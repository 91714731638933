

function NoCardData(props) {
    return (
      <div className="ph-item top-card" id={props.id}>
        <div className="ph-col-12">
            <div className="ph-row placeholder-glow">
              <div className="ph-col-4 placeholder big"></div>
              <div className="ph-col-8 empty big"></div>
              <div className="ph-col-6 placeholder big"></div>
              <div className="ph-col-4 empty big"></div>
            </div>
            </div>
        </div>
    );
  }
  
  export default NoCardData;
  