import React from "react";
import moment from "moment";
import NoContent from "../../components/common/NoContent";
import { DownloadsScript } from "./DownloadsScript";
import { t } from "i18next";

function DownloadItems(props) {
  let downloads = props?.downloads;

  let content = null;

  if (downloads.length > 0) {
    content = (
      <>
        {downloads?.map((file) => (
          <div className="dataCollectors_Sec">
            <div className="dataCollectors_iconArea">
              <div className="dataCollectors_tagsTitle">
                <h3 className="dataCollectors_title" title={file?.name}>
                  {file?.name}
                </h3>
                {/* <ul className="dataCollectors_tags m-0 p-0">
                  <li className="dataCollectors_tagsName">
                    <span>{file.type}</span>
                  </li>
                </ul> */}
                <p className="m-0">
                  <span className="material-symbols-outlined me-2">
                    schedule
                  </span>
                  {moment(new Date(file?.lastupdated)).format(
                    "DD MMM YYYY hh:mm A"
                  )}
                </p>
              </div>
            </div>

            <div className="dataCollectors_Toggle">
              <span
                className="material-symbols-outlined smallIconD"
                onClick={() => {
                  DownloadsScript.downloadapicall(file.type, file.name);
                }}
              >
                downloading
              </span>
              <label
                htmlFor="bnfxdownloadcheckbox"
                className="toggle-switch d-none"
              >
                <input type="checkbox" id="bnfxdownloadcheckbox" />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        ))}{" "}
      </>
    );
  } else {
    content = (
      <NoContent
        id={"noautomationtask"}
        title={t("nodownloads.title")}
        description={t("nodownloads.description")}
      />
    );
  }
  return content;
}

export default DownloadItems;
