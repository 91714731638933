import Highcharts from "highcharts";
import React, { useLayoutEffect, useRef } from "react";

const TrendChart = (props) => {
  const chartRef = useRef(null);
  const series = props.series;
  const categories = props.categories;
  const container = props.container;
  const color = props.color;

  useLayoutEffect(() => {
    const width = props.hidex
      ? document.getElementById(container).closest(".bxDashboardGraph")
          .clientWidth - 90
      : null;
    
    if (chartRef.current) {
      var orgseries = null;
      var avg = null;
      if(props.hidex){
       
        avg = 0;
        orgseries = [];
        var max = 0;
       
        for (let index = 0; index < series?.length; index++) {
          orgseries[index] =  series[index] 
          avg += series[index]
        } 

        avg  = parseInt(avg /series?.length);
       
        if(avg ===0){
          avg =1;
        }
        
        for (let index = 0; index < series?.length; index++) {
          var updatedvalue =  parseInt(series[index] / avg)
        
          if(max < updatedvalue){
            max = updatedvalue;
          }
          series[index] = updatedvalue
        } 

        for (let index = 0; index < series?.length; index++) {
           updatedvalue = series[index];
          if (updatedvalue === 0 && orgseries[index] > 0) {
            series[index] = parseFloat(orgseries[index] / max);
          }
        } 
        
      }
     

      const height = props?.height;
      Highcharts.chart(container, {
        chart: {
          type: "areaspline",
          height: height + "px",
          width: width,
        },
        title: {
          text: "",
        },
        yAxis: {
          title: {
            text: "",
          },
          visible: false,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: categories,
          tickInterval: props.hidex ? 1 : props.tickInterval,
          visible: props.hidex ? false : true,
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },
        tooltip: {
          borderWidth: 0,
          backgroundColor: "#fff",
          borderRadius: 0,
          shadow: false,
          useHTML: true,
          percentageDecimals: 2,
          formatter: function () {
            return (
              '<div class="tooltip_trend">' +
              this.point.category +
              "<br /><b>" +
              Highcharts.numberFormat(
                orgseries ? orgseries[this.point.index] : this.y
              ).replace(",", " ") +
              "</b></div>"
            );
          },
        },
        plotOptions: {
          areaspline: {
            color: color ? color : "#ffa50066",
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: props.hidex ? 1.8 : 1.2,
              },
              stops: [
                [0, color ? color : "#ffa50066"],
                [1, "transparent"],
              ],
            },
          },
          series: {
            marker: {
              radius: 0,
            },
            label: {
              connectorAllowed: false,
            },
            pointStart: 0,
            point: {
              events: {},
            },
          },
        },
        series: [
          {
            threshold: props.hidex ? (avg === 1 ?-0 : -5) : -0.1,
            showInLegend: false,
            data: series,
          },
        ],
      });
    }
  }, [series, categories, container,color,props.hidex, props.height, props.tickInterval]);

  return <div ref={chartRef} id={container} />;
};

export default TrendChart;
