import React from "react";
import "../common/nocontent.css";

function NoContent(props) {
  let container = null;
  let src = null;
  let alt = null;
  let width = null;

  if (props.id === "noenablestream") {
    container = "container";
    src = "images/gif-2.gif";
    alt = "gif-2.gif";
    width = "50px";
  } else if (props.id === "nosearchlist") {
    container = "container mt-4";
    src = "images/gif-2.gif";
    alt = "gif-2.gif";
    width = "50px";
  } else if (props.id === "nodquerydata") {
    container = "container mt-4";
    src = "images/gif-2.gif";
    alt = "gif-2.gif";
    width = "100px";
  } else if (props.id === "noavailablestream") {
    container = "container mt-4";
    src = "images/gif-1.gif";
    alt = "gif-1.gif";
    width = "50px";
  }else if (props.id === "nospiderdetails_section") {
    container = "container mt-4";
    src = "images/gif-2.gif";
    alt = "gif-2.gif";
    width = "100px";
  } else if (props.id === "nodata") {
    container = "container mt-4";
    src = "images/gif-1.gif";
    alt = "gif-1.gif";
    width = "100px";
  }else if (props.id === "nospiderdetailcode") {
    container = "container mt-4";
    src = "images/gif-1.gif";
    alt = "gif-1.gif";
    width = "200px";
    
  }else if (props.id === "nographfound") {
    container = "container mt-1";
    src = "images/gif-1.gif";
    alt = "gif-1.gif";
    width = "50px";
  }else if (props.id === "noautomationtask") {
    container = "container mt-4";
    src = "images/gif-1.gif";
    alt = "gif-1.gif";
    width = "100px";
  }else if (props.id === "nosummarydata") {
    container = "container mt-4";
    src = "images/gif-1.gif";
    alt = "gif-1.gif";
    width = "100px";
  } else {
    container = " mt-4";
    src = "images/gif-3.gif";
    alt = "gif-3.gif";
    width = "100px";
  }
  
  return (
    <div className="rightSideCardLgBox bxContentEmpty" id={props.id}>
      <div className={container}>
        <div className="card mb-0">
          <div className="card-body p-0">
            <div className="p-md-5 p-0">
              <img className="mb-4" src={src} alt={alt} width={width} />
              <h5 className="text-white">{props.title}</h5>
              <p className="p-0">{props.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoContent;
