import { useEffect } from "react";
import { Content } from "../ContentScript";
import { STREAMS_DROPDOWN_OPTIONS } from "../contentConstants";
import ContentSelectDropDown from "./ContentSelectDropDown";
import Select from "react-select";
import { t } from "i18next";

function CreateStream({
  streamFields,
  type,
  onCloseEditOrCreate,
  dispatch,
  errors,
  tenantsList,
}) {
  useEffect(() => {
    Content.getTenantsList(dispatch);
  }, []);
  return (
    <>
      <div className="bxContent_header p-0">
        <div className="btn_Group float-end d-flex align-items-center">
          {streamFields?.url && (
            <>
              <button
                type="button"
                className="btn btn-primary px-5 me-2 contentbutton"
                // data-bs-dismiss="offcanvas"
                onClick={() =>
                  Content.updateStreamData(dispatch, streamFields, type)
                }
              >
                {t("name.save")}
              </button>
              {/* <a
             onClick={() => Content.updateStreamData(streamFields)}
             className="btn btn-danger px-5 text-white me-1 contentbutton" 
           >
             Delete
           </a> */}
              <div className="content-options-dropdown">
                <button
                  className="dropdown-toggle arrow-none new-group-btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="material-symbols-outlined text-white">
                    more_vert
                  </i>
                </button>
                <ul className="dropdown-menu contentactiondots">
                  <h6 className="ps-2 border-bottom pb-2">Explore</h6>
                  <li
                    className="contentDelete d-flex align-items-center"
                    title="Delete"
                    onClick={() =>
                      Content.deleteContentConfirmation(
                        dispatch,
                        streamFields,
                        type
                      )
                    }
                  >
                    <span className="material-symbols-outlined">delete</span>
                    Delete
                  </li>
                  <li
                    className="contentPublish d-flex align-items-center"
                    title="Delete"
                    // onClick={() =>
                    //   Stream.loadStreamrowOnclick(detectionData, "detection")
                    // }
                    onClick={() =>
                      Content.publishComponent(
                        "stream",
                        streamFields?.componentData?.name,
                        streamFields.tenant,
                        true
                      )
                    }
                  >
                    <span className="material-symbols-outlined">publish</span>
                    Publish
                  </li>
                </ul>
              </div>
            </>
          )}

          {!streamFields?.url && (
            <button
              type="button"
              className="btn btn-primary px-5 me-2 contentbutton"
              // data-bs-dismiss="offcanvas"
              onClick={() =>
                Content.saveStreamData(streamFields, dispatch, type)
              }
            >
              {t("name.save")}
            </button>
          )}
        </div>
        <div className="d-flex align-items-center mt-2">
          <button
            className="BackArrow"
            type="button"
            aria-label="Close"
            onClick={() => onCloseEditOrCreate()}
          >
            <span className="material-symbols-outlined">arrow_back</span>
          </button>
          <h4 className="m-0 text-white ms-2">
            {streamFields.url ? streamFields.title : "Add Stream"}
          </h4>
        </div>
      </div>
      <div
        id="content_createtsream_form"
        className="bxSettingPage_editListBox mt-3"
      >
        <form action="" className="bxSP_ELBox_form">
          <div className="bnfx-content-namedesc-details">
            {tenantsList && tenantsList.length > 1 && (
              <div className="bxSTP-states form-group mb-3">
                <label>Tenant</label>
                <Select
                  className={`form-control ${
                    errors?.tenant ? "border-danger " : ""
                  }`}
                  options={tenantsList || []}
                  onChange={(option) =>
                    Content.onStreamItemChange(
                      { target: { name: "tenant", value: option.value } },
                      dispatch
                    )
                  }
                  value={(tenantsList || [])?.find(
                    (option) => option.value === streamFields?.tenant
                  )}
                ></Select>
              </div>
            )}
            <div className="bxSTP-states form-group mb-3">
              <label>title</label>

              <input
                type="text"
                className={`bxform-control ${
                  errors?.title ? "border-danger " : ""
                }`}
                id="streamname"
                placeholder="Enter Name"
                name="title"
                autoComplete="off"
                value={streamFields.title}
                onChange={(e) => Content.onStreamItemChange(e, dispatch)}
              />
              <small className="d-block text-end bxFontSize">
                {t("required")}
              </small>
            </div>
            <div className="bxSTP-states form-group">
              <label>description</label>
              <textarea
                name="description"
                id="descriptionarea"
                cols="30"
                rows="2"
                className={`bxform-control ${
                  errors?.description ? "border-danger " : ""
                }`}
                value={streamFields.description}
                onChange={(e) => Content.onStreamItemChange(e, dispatch)}
              ></textarea>
              <small className="d-block text-end bxFontSize">
                {t("required")}
              </small>
            </div>
          </div>

          <div className="bnfx-content-datasource-details">
            <div className="bxSTP-states form-group mb-3">
              <label>Provider</label>
              <input
                name="provider"
                className={`bxform-control ${
                  errors?.provider ? "border-danger " : ""
                }`}
                id="streamprovider"
                placeholder="Enter Name"
                autoComplete="off"
                value={streamFields.provider}
                onChange={(e) => Content.onStreamItemChange(e, dispatch)}
              />
              <small className="d-block text-end bxFontSize">
                {t("required")}
              </small>
            </div>
            <div className="bxSTP-states form-group mb-3">
              <label>Type</label>
              <input
                type="text"
                className={`bxform-control ${
                  errors?.type ? "border-danger " : ""
                }`}
                id="streamtype"
                placeholder="Enter Name"
                name="type"
                autoComplete="off"
                value={streamFields.type}
                onChange={(e) => Content.onStreamItemChange(e, dispatch)}
              />
              <small className="d-block text-end bxFontSize">
                {t("required")}
              </small>
            </div>
            <div className="bxSTP-states form-group mb-3">
              <label>Group</label>
              <input
                type="text"
                className={`bxform-control ${
                  errors?.group ? "border-danger " : ""
                }`}
                id="streamgroup"
                placeholder="Enter Name"
                name="group"
                autoComplete="off"
                value={streamFields.group}
                onChange={(e) => Content.onStreamItemChange(e, dispatch)}
              />
              <small className="d-block text-end bxFontSize">
                {t("required")}
              </small>
            </div>
          </div>

          <div className="bnfx-content-contentoptions-details">
            {STREAMS_DROPDOWN_OPTIONS.map((item, index) => {
              return (
                <div key={index} className="bxSTP-states form-group mb-3">
                  <label>{item.title}</label>
                  <ContentSelectDropDown
                    className={`${
                      errors?.dictionary && item.name === "dictionaries"
                        ? "border-danger "
                        : ""
                    }`}
                    contenttype={item.name}
                    streamFields={streamFields[item.name] || []}
                    tenant={streamFields.tenant}
                    onChange={(option) =>
                      Content.onStreamItemChange(
                        { target: { name: item.name, value: option } },
                        dispatch
                      )
                    }
                    multi={
                      item.name === "dictionaries" ||
                      item.name === "correlations"
                        ? false
                        : true
                    }
                  />
                </div>
              );
            })}
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateStream;
