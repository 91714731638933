import { useLayoutEffect } from "react";
import { setGlobalState } from "../../../components/common/GlobalVariables";
import DatePickerComponent from "../../../components/common/calender/DatePickerComponent";
import { Dashboard } from "../DashboardScript";
import Search from "../../../components/search/Search";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";

function WidgetLayout(props) {
  const widget = props.widget;
  const index = props.index;

  const substringsToCheck1 = [
    "ingestion_data",
    "total_detection_data",
    "automation_on_low_critical_detections_data",
    "correlation_engine_data",
  ];

  useLayoutEffect(() => {
    setTimeout(() => {
      let widgetdiv = document.getElementById(
        "grid-stack-item-" + widget.identifier
      );
      if (widgetdiv) {
        Dashboard.grid.addWidget(widgetdiv);
        widgetdiv.classList.remove("opacity-none");
        widgetdiv.classList.add("opacity-full");
        
        if (document.getElementById("bnfxWidgetFilter-" + widget.identifier)) {
          let element = document.getElementById(
            "bnfxWidgetFilter-" + widget.identifier
          );

          element.outerHTML = element.outerHTML;

          element = document.getElementById(
            "bnfxWidgetFilter-" + widget.identifier
          );

          element.addEventListener("click", function () {
            Dashboard.loadflitersoptions(widget.identifier);
            setGlobalState("globalwidget", widget);
          });

          const searchbar = document.getElementById(
            "bnfxtablelistsearch" + widget.identifier
          );
          
          if (searchbar) {
            searchbar.addEventListener("keydown", function () {
              Dashboard.search(
                "bnfxtablelistsearch" + widget.identifier,
                widget
              );
            });
            searchbar.addEventListener("input", function () {
              if (searchbar.value === "") {
                document.getElementById(
                  "bnfxtablelistsearch" + widget.identifier
                ).value = null;
                Dashboard.renderWidgetData(widget, 0, false);
              }
            });
          }
        }else{
          const searchbar = document.getElementById(
            "bnfxtablelistsearch" + widget.identifier
          );
          
          if (searchbar) {
            searchbar.addEventListener("keydown", function () {
              Dashboard.search(
                "bnfxtablelistsearch" + widget.identifier,
                widget
              );
            });
            searchbar.addEventListener("input", function () {
              if (searchbar.value === "") {
                document.getElementById(
                  "bnfxtablelistsearch" + widget.identifier
                ).value = null;
                Dashboard.renderWidgetData(widget, 0, false);
              }
            });
          }
        }
      }

      if (props.callback) {
        props.callback();
      }
    }, index * 10);
  }, [widget, index, props]);

  let widgetlayout = null;
  let paginationContainer = null;
  let issearchable = null;
  let datepicker = null;

  if (widget.configure.datepicker === true) {
    
    datepicker = (
      <DatePickerComponent
        className="bxDateTimePicker w-100 me-3 mt-1"
        dashboardid={props.dashboardid}
        id={"bnfxWidgetDatePicker-" + widget.identifier}
        onClickFn={Dashboard.renderDatePicker}
        singleDate={false}
      ></DatePickerComponent>
    );
  }
  

  if (widget.configure.searchable === true) {
    issearchable = (
      <Search id={"bnfxtablelistsearch" + widget.identifier} defaultValue="" />
    );
  }

  if (
    widget.configure.properties.type === "table" ||
    widget.configure.properties.type === "my_queue"
  ) {
    paginationContainer = (
      <div id={"bnfxTablePagination" + widget.identifier}>
        {/* we will replace pagination here */}
      </div>
    );
  }

  if (widget.configure.properties.layout === "card") {
    widgetlayout = (
      <div className="bxDashboardCard">
        <div
          className="bxDashboardWidget bxDashboardGraph"
          widgetid={widget.identifier}
          id={"bnfxWidgetStatCard" + widget.identifier}
        >
          {/* here we will replace widget data layout */}
        </div>
      </div>
    );
  } else if (widget.configure.properties.layout === "conciselayout") {
    widgetlayout = (
      <div className="bxDashboardConciseLayout">
        <span
          className="text-uppercase bxDashboardConcise_widget_title"
          title={widget.title}
        >
          {widget.title}
        </span>
        <div
          className="bxDashboardWidget"
          widgetid={widget.identifier}
          id={"bnfxWidgetContainer-" + widget.identifier}
        >
          {/* here we will replace widget data layout */}
        </div>
      </div>
    );
  } else {
    widgetlayout = (
      <div className="card widget-flat">
        <div className="card-body p-0">
          {widget.title ? (
            <div className="card-head p-3">
              <div className="row">
                <div className="col-12">
                  <div className="float-end">
                    <div
                      className={
                        "bxActionBtns d-flex align-items-end" +
                        (widget.filtervisible ? "" : " d-none")
                      }
                    >
                      <div className="bxDropdown">
                        <button
                          className="bxDropdown-toggle arrow-none"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target={"#bxFilterTop"}
                          id={"bnfxWidgetFilter-" + widget.identifier}
                        >
                          <i className="material-symbols-outlined text-white">
                          filter_alt
                          </i>
                          <span
                            className="bxNoOfAccount d-none"
                            id={"bnfxFSCount-" + widget.identifier}
                          ></span>
                          {/* {filter popup} */}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="widgetHead-flat">
                    <div className={widget.configure.icon ? "row" : ""}>
                      <div
                        className={widget.configure.icon ? "col-2" : "d-none"}
                      >
                        <span
                          className={
                            "bxIcon_link " +
                            (widget.configure.icon
                              ? widget.configure.icon.toLowerCase()
                              : "d-none")
                          }
                        ></span>
                      </div>
                      <div className={widget.configure.icon ? "col-10" : ""}>
                        {CommonTemplate.notIncludesAny(
                          widget.identifier,
                          substringsToCheck1
                        ) ? (
                          <h5
                            className="text-uppercase text-white fw-normal m-0"
                            title={widget.title}
                          >
                            {widget.title}
                          </h5>
                        ) : (
                          <div className="d-flex align-iten-center justify-content-between roiDashTitle">
                            <h5
                              className="text-uppercase text-white fw-normal m-0"
                              title={widget.title}
                            >
                              {widget.title}
                            </h5>

                            <span className="material-symbols-outlined">
                              arrow_forward
                            </span>
                          </div>
                        )}
                        {datepicker}
                      </div>
                    </div>
                    {issearchable}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div
            className={
              "bxDashboardWidget bxDashboard" +
              (widget.configure.properties.type === "table" ? "Table" : "Graph")
            }
            widgetid={widget.identifier}
            id={"bnfxWidgetContainer-" + widget.identifier}
          ></div>
          {paginationContainer}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="grid-stack-item item-1 opacity-none"
        gs-w={widget.configure.dimension.width}
        gs-x={widget.configure.dimension.x}
        gs-y={widget.configure.dimension.y}
        gs-h={widget.configure.dimension.height}
        id={"grid-stack-item-" + widget.identifier}
      >
        <div className="grid-stack-item-content " widgetid={widget.identifier}>
          {widgetlayout}
        </div>
      </div>
    </>
  );
}
export default WidgetLayout;
