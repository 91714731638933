import { requestURI } from "../RequestURIs";
import { ajax } from "../Service";
import { showError } from "../DisplayError";
import ArtifactListLayout from "./ArtifactListLayout";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import { Stream } from "../../../pages/stream/StreamScript";
import moment from "moment";
import ArtifactData from "./ArtifactData";
import Pagination from "../../common/pagination/Pagination";

export const Artifact = {
  artifactstreamid: null,
  artifacttotal: null,
  searchtimeout: [],
  artifactname: null,


  
  getArtifactList: (streamid) => {
    const request = {
      streamid: streamid,
    };
    ajax(
      "post",
      requestURI["get.artifact.list"],
      null,
      request,
      Artifact.renderArtifactList,
      Artifact.renderArifactListError,
      [streamid]
    );
  },

  renderArtifactList: (response, args) => {
    let artifactlist = null;
    let status = response?.data?.response?.status;
    if (status === "failed") {
      artifactlist = null;
    } else {
      let data = response.data.response.result;
      data = data.filter((item) => item !== "messageid");
      let streamid = args[0];
      //   const transformedData = data.map(item => item.artifactname);

      artifactlist = <ArtifactListLayout data={data} streamid={streamid} />;
    }
    let container = document.getElementById("bnfxArtifactsList");
    CommonTemplate.ContainerAvailability(container, artifactlist);
  },
  renderArifactListError: () => {
    let container = document.getElementById("bnfxArtifactsList");
    let artifactlist = null;
    CommonTemplate.ContainerAvailability(container, artifactlist);
  },
  getArtifactCount: (container, artifact, streamid, onlclick) => {
    const endtime = moment();
    const starttime =
      Stream.selected_days === 1
        ? moment().startOf("day")
        : moment().subtract(Stream.selected_days - 1, "days");
    const stime = CommonTemplate.timeconversion(starttime);
    const etime = CommonTemplate.timeconversion(endtime);
    const request = {
      streamid: streamid,
      artifact: artifact,
      starttime: stime,
      endtime: etime,
      query: Stream.selected_freetext ? Stream.selected_freetext : undefined,
      timePhrase: "Last " + Stream.selected_days + " Days",
    };
    ajax(
      "post",
      requestURI["get.artifact.count"],
      null,
      request,
      Artifact.renderArtifactCount,
      showError,
      [artifact, streamid, container, onlclick]
    );
  },
  renderArtifactCount: (response, args) => {
    let streamid = args[1];
    let value = args[0];
    let newcontainer = args[2];
    let onlclick = args[3];
    let data = response.data.response?.result;
    let badgedata = null;
    let container = null;

    if (data !== undefined && data !== 0) {
      if (onlclick) {
        badgedata = (
          <span
            className={"custom_BageStrem bnfxstreamartifact mb-2" + value}
            id={"bnfxArtifact" + value}
            onClick={() => Artifact.loadonclick(value, streamid)}
            data-bs-toggle="modal"
            data-bs-target="#bnfxArtifactModal"
          >
            <p title={value}>{value} </p>
            <div className="artificatcount">
              {CommonTemplate.formatNumber(data)}
            </div>
          </span>
        );
        container = document.getElementById(newcontainer + streamid + value);
       
      } else {
        badgedata = (
          <span
            className={"custom_BageStrem mb-2" + value}
            id={"bnfxArtifact" + value}
          >
            <p title={value}>{value} </p>
            <div className="artificatcount">
              {CommonTemplate.formatNumber(data)}
            </div>
          </span>
        );
        container = document.getElementById(newcontainer);
      }

      CommonTemplate.ContainerAvailability(container, badgedata);
    } else {
      let artifactcontainer = document.getElementById("bnfxArtifact" + value);
      let artifactlist = null;
      CommonTemplate.ContainerAvailability(artifactcontainer, artifactlist);
    }
  },
  getArtifactDetails: (artifact, streamid, offset) => {
    Artifact.clearvariables()
   
    Artifact.artifactstreamid = streamid;
    Artifact.artifactname = artifact;
    let freetext = CommonTemplate.getSearchText("bnfxartifactdata_search");

    if(freetext !=null){
      freetext = freetext.trim();
    }
    const endtime = moment();
    const starttime =
      Stream.selected_days === 1
        ? moment().startOf("day")
        : moment().subtract(Stream.selected_days - 1, "days");
    const stime = CommonTemplate.timeconversion(starttime);
    const etime = CommonTemplate.timeconversion(endtime);
    const request = {
      streamid: streamid,
      artifact: artifact,
      starttime: stime,
      endtime: etime,
      freetext: freetext ? freetext : undefined,
      query: Stream.selected_freetext ? Stream.selected_freetext : undefined,
      timePhrase: "Last " + Stream.selected_days + " Days",
      limit: 6,
      offset: offset ? offset : 0,
    };
    ajax(
      "post",
      requestURI["get.artifact.details"],
      null,
      request,
      Artifact.renderArtifactDetails,
      Artifact.renderArtifactDetailsError,
      [artifact, streamid, offset]
    );
  },
  renderArtifactDetails: (response, args) => {
    let artifact = args[0];
    let streamid = args[1];
    let offset = args[2];

    let total = response.data.response.total;

    if (offset === 0) {
      Artifact.artifacttotal = total;
    }
    let data = response.data.response.result;
    let artifactdata = (
      <ArtifactData
        data={data}
        artifact={artifact}
        callback={() => {
          Artifact.renderPagination(artifact, offset, 6);
        }}
        newcallback={() => {
          Artifact.getArtifactCount(
            "bnfxartifactdetailcount",
            artifact,
            streamid,
            false
          );
        }}
      />
    );
    let container = document.getElementById("bnfxArtifactData");
    CommonTemplate.ContainerAvailability(container, artifactdata);
  },
  renderArtifactDetailsError: (response) => {
    let artifactdata = "No data Found";
    let container = document.getElementById("bnfxArtifactData");
    CommonTemplate.ContainerAvailability(container, artifactdata);
  },
  renderPagination: (artifact, offset, limit) => {
    if (Artifact.artifacttotal > limit) {
      const paginationData = {
        total: Artifact.artifacttotal,
        limit: limit,
        offset: offset ? offset : 0,
      };

      const container = document.getElementById("bnfxArtifactsDataPagination");

      const data = (
        <Pagination
          pagination={paginationData}
          fnArg={artifact}
          onClickFn={Artifact.getPaginationArtifact}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      const paginationcontainer = document.getElementById(
        "bnfxArtifactsDataPagination"
      );

      const paginationdata = null;
      CommonTemplate.ContainerAvailability(paginationcontainer, paginationdata);
    }
  },
  getPaginationArtifact: (offset, artifact) => {
    Artifact.getArtifactDetails(artifact, Artifact.artifactstreamid, offset);
  },

  search: (id) => {
    const freetext = document.querySelector("#" + id).value;

    if (freetext.length > 3 || freetext.length === 0) {
      if (Artifact.searchtimeout && Artifact.searchtimeout.length > 0) {
        for (let index = 0; index < Artifact.searchtimeout.length; index++) {
          window.clearTimeout(Artifact.searchtimeout[index]);
        }
      }

      Artifact.searchtimeout.push(
        setTimeout(() => {
          if (id === "bnfxartifactdata_search") {
            Artifact.getArtifactDetails(
              Artifact.artifactname,
              Artifact.artifactstreamid,
              0
            );
          }
        }, 2000)
      );
    }
  },
  getArtifactDetailslist: (offset) => {
    Artifact.getArtifactDetails(
      Artifact.artifactname,
      Artifact.artifactstreamid,
      offset
    );
  },
  clearvariables:()=>{
    Artifact.artifactstreamid= null;
    Artifact.artifactname= null;
    
 
  },
  loadonclick:(value,streamid)=>{
    document.getElementById("bnfxartifactdata_search").value = null;
    Artifact.getArtifactDetails(value, streamid, 0)
  }
};
