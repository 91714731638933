import CodeMirror from "@uiw/react-codemirror";
import { sql, PostgreSQL } from "@codemirror/lang-sql";
import { Stream } from "../StreamScript";
import { useCallback } from "react";
import { useGlobalState } from "../../../components/common/GlobalVariables";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";
import { SearchTemplate } from "../../search/SearchScript";
import SavedQuery from "../../search/SavedQuery";

function LoadDetectionSearch(props) {
  const autosuggestions = props.autosuggestions;
  const [searchText] = useGlobalState("bnfx_global_stream_detction_serachbar");
  const [isExpanded] = useGlobalState("search_collapsed");
  let detectionDataType=props.detectionDataType;

  const onChangeSql = useCallback((value, viewUpdate) => {
    Stream.detectionSql = value;
  }, []);

  return (
    <div
      className="bxRightSearchBox mt-1"
      id={"bxRightSearchBox" + props.serachtype+props.streamid}
    >
      <div className="sidebar-search">
        <CodeMirror
          value={searchText}
          placeholder={"Type query to search " + props.serachtype + "...."}
          basicSetup={{
            lineNumbers: false,
            highlightActiveLineGutter: false,
            highlightActiveLine: false,
          }}
          theme={"dark"}
          minHeight="50px"
          extensions={sql({
            defaultTable: "detections",
            dialect: PostgreSQL,
            schema: {
              detections: autosuggestions ? autosuggestions : [],
            },
          })}
          onChange={onChangeSql}
        />
        <div
          className="streamdownlaodicon"
          data-bs-toggle="modal"
          data-bs-target="#bxDownloadModal"
          onClick={() => {
            CommonTemplate.removedownloadnone();
            CommonTemplate.clearfillupdetails();
          }}
        >
          <span className="material-symbols-outlined ">download</span>
        </div>
        <button
          className={`btn stream_saved_search_btn collapsed ${detectionDataType === "aggregationdata" ? "d-none" : ""
          } ${isExpanded ? "rotate" : ""}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSaveSearchStream"
          aria-expanded="false"
          aria-controls="collapseSaveSearch"
          onClick={() => SearchTemplate.handleToggle()}
        >
          <span className="material-symbols-outlined">expand_more</span>
        </button>

        <button
          className=" btn btn-primary"
          type="submit"
          onClick={() => {
            Stream.serachresultonlclick(props.streamid,props.serachtype);
          }}
        >
          <span className="material-symbols-outlined">send</span>
        </button>
      </div>
      <SavedQuery></SavedQuery>
    </div>
  );
}

export default LoadDetectionSearch;
