import { useLocation } from "react-router";
import { Navigation } from "../../components/common/mainHeader/leftHeader/Navigation";
import { useEffect } from "react";
import StreamsWrapperContainer from "./StreamsWrapperContainer";
import "../stream/stream.css";
import "../../../src/components/common/Badge/badgepanel.css";
import "../stream/allicons.css";
import "../stream/summarytab.css";

function StreamTemplate() {

  const location = useLocation();
  useEffect(() => {
    // Navigation.register(Stream.init)
    Navigation.onnavigate(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className="fullwidth main-inner StreamPage" id="bxFullwidthContent">
        <div className="tab-content">
          <StreamsWrapperContainer />
        </div>
      </div>
    </>
  );


}


export default StreamTemplate;