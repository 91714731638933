import { useEffect } from "react";
import AutomationListItem from "./AutomationListItem";

function AutomationListData(props) {
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <>
      {props.data.map((dataObj, i) => (
        <AutomationListItem key={dataObj.taskname} taskdata={dataObj} executionid={props.executionid}/>
      ))}
    </>
  );
}

export default AutomationListData;
