import { t } from "i18next";
import Search from "../../../components/search/Search";
import { Stream } from "../StreamScript";
import { useLayoutEffect } from "react";

function StreamListLayout() {

  useLayoutEffect(() => {

    const searchbar = document.getElementById("enabled_stream_search")
    if (searchbar) {
      searchbar.addEventListener("keydown", function () {
        Stream.search("enabled_stream_search");
      });
      searchbar.addEventListener("input", function () {
        if (searchbar.value === "") {
          document.getElementById("enabled_stream_search").value = null;
          Stream.loadEnabledLayout(0);
        }
      });
    }

  });
  return (
    <>
      <div className="bxStreamLeftSideBar">
        <div className="bxStreamLeftSideBar_searchArea p-2">
          <div className="sidebar-search">
            <Search
              id="enabled_stream_search"
              defaultValue=""
            />
          </div>
        </div>

        <div className="bxStreamList_box" id="bnfxEnabledStreamList">
          {/* here streamlistitem replace */}
        </div>

        <nav
          className="bxPagination float-star d-flex align-items-center"
          aria-label="Page navigation example"
        >
          <span className="ms-2">
            {t("enabled.stream.total")}
            <b className="text-white ms-2 me-4 bnfxEnabledStreamTotal">
              {/* here we will replace total number of streams */}
            </b>
          </span>
          <div id="bnfxEnabledPagination">
            {/* here we will replace pagination */}
          </div>
        </nav>

        <div className="bxFloatingAddSteramBtn">
          <button
            title="Add Stream"
            data-bs-toggle="offcanvas"
            data-bs-target="#bnfxAddNewStreams"
            aria-controls="offcanvasRight"
            href="#streamList"
            className="action-icon"
            onClick={() => {
              Stream.loadAvailableLayout(0);
            }}
          >
            <span className="material-symbols-outlined">
              add
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default StreamListLayout;
