import { useEffect, useRef, useState } from "react";
import { setGlobalState, useGlobalState } from "../../GlobalVariables";
import { CommonTemplate } from "../../containerAvailability/CommonScript";
import MainProfile from "./MainProfile";
import MainSearch from "./MainSearch";
// import Spider from "./Spider";
import { t } from "i18next";
import jwt from "jwt-decode";
import { CollectorsScript } from "../../../../pages/collectors/CollectorsScript.js";
import { DownloadsScript } from "../../../../pages/download/DownloadsScript.js";
import { BadgePanelScript } from "../../Badge/BadgePanelScript.js";
import SelectDropDown from "../../SelectDropDown/SelectDropDown";
import TimerCountDown from "../../TimerCountDown.js";

function TopRightHeader() {
  const [region_list] = useGlobalState("region_list");
  let deafultregion = localStorage.getItem("bnfxSelectedRegion");
  const pageloaded = useRef(false);
  const currentstatus = { value: "DEAFULT", label: deafultregion };

  useEffect(() => {
    if (pageloaded.current) {
      return;
    }

    CommonTemplate.getRegionList();
    pageloaded.current = true;
  }, []);

  const [showTimer, setShowTimer] = useState(false);

   const checktimeExpire=()=>{
     const token = localStorage.getItem("token");
     const userdetails = jwt(token);
     let expiredtime=userdetails.exp * 1000;
     const expiryDate = new Date(expiredtime);
     const alertTime = new Date(expiryDate.getTime() - 20 * 60 * 1000);

     const alertTimeMillis = alertTime.getTime();
     const currentTime = new Date();
     const currenttimeillis=currentTime.getTime();
     if(currenttimeillis>=alertTimeMillis){

       <TimerCountDown initialTime={20 * 60}/>
       setShowTimer(true);
     }
   };

  useEffect(() => {
    const intervalId = setInterval(() => {
      CollectorsScript.getEps();
    }, 10000); // 10000 milliseconds = 10 seconds
    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

useEffect(() => {
  const intervalId = setInterval(() => {
    if(!showTimer){
      checktimeExpire()}
  }, 60000); // 1800000 milliseconds = 30 minutes
  return () => clearInterval(intervalId); // Clean up the interval on component unmount
}, [showTimer]);
  // State for toggling the off-canvas menu
  const [menuOpen, setMenuOpen] = useState(false);

  // Function to toggle the menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <>
      <div
        className={` topbar-menu ${menuOpen ? "show" : ""}`}
        onMouseLeave={closeMenu}
      >
        <div className="float-end epsTimerBox" id="bnfxeventspersecond" title="Events per Second"></div>
        <span
          className={`material-symbols-outlined float-end mb-0 topheader`}
          onMouseEnter={toggleMenu}
        >
          {menuOpen ? "chevron_right" : "chevron_left"}
        </span>

        <div className="bnfxtoprightheader">
          {/* Your provided list elements */}
          <ul className="list-unstyled mb-0 d-flex align-items-center">
            <li className="region_list">
              <div id="bnfxRegionList" className="">
                <SelectDropDown
                  id="bnfxRegionList"
                  icon="south_america"
                  options={region_list}
                  onChange={CommonTemplate.handleRegionChange}
                  value={
                    currentstatus.label !== null
                      ? currentstatus
                      : region_list[0]
                  }
                />
              </div>
            </li>
            <li
              className="BadgeList_Panel"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBadge"
              aria-controls="offcanvasBadge"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                BadgePanelScript.completeBadgeList("totalBadge");
                setGlobalState("StreamsOptions", []);
                BadgePanelScript.setStreamOffset(0);
                setGlobalState("GroupTypeOption", []);
                BadgePanelScript.getGroups();
                BadgePanelScript.loadStreamList(0);
                BadgePanelScript.TenantsList();
              }}
            >
              <div className="nav-link newBadge">
                <span className="material-symbols-outlined">badge</span>

                <small>{t("badgeheader")}</small>
              </div>
              {/* <OverallBadgeListLayout /> */}
            </li>

            <li
              className="Collectors_Panel"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas-collectors-content"
              aria-controls="offcanvasRight"
            >
              <div
                className="nav-link newCollector"
                onClick={() => CollectorsScript.loadconnector()}
              >
                <span className="material-symbols-outlined">data_table</span>

                <small>{t("collectors.title")}</small>
              </div>
            </li>
            <li
              className="Downloads_Panel"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas-downloads-content"
              aria-controls="offcanvasRight"
            >
              <div
                className="nav-link Downloads"
                onClick={() => DownloadsScript.loadDownloads()}
              >
                <span className="material-symbols-outlined">
                  download_2
                </span>

                <small>{t("download_title")}</small>
              </div>
            </li>
            {/* {spidervisiblity==="true"?<li className="Spider_Tab ">
          <Spider/>
            </li>:null} */}
            <li className="MainSearch_Tab ">
              <MainSearch />
            </li>
            <li className="MainProfile " onMouseLeave={CommonTemplate.removeCursorPointer}
            onClick={()=>CommonTemplate.handleClick()}>
              <MainProfile />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default TopRightHeader;
