import "../error/error.css"

function Error(props) {
  return (
    <>
      <div className="bxErrorMessage d-none">{props.children}</div>
    </>
  );
}

export default Error;
