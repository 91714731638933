import StellarChart from "../../components/highChart/StellarChart";
import "../risk/newriskpage.css";

function NewRiskPage() {
  return (
    <>
      <div className="row g-1">
        <div className="col">
          <div className="RPLP_card riskLevel">
            <p className="m-0">Total Score</p>
            <h2 className="RiskLevelNumber">55</h2>
          </div>
        </div>

        <div className="col">
          <div className="RPLP_card riskLevel">
            <p className="m-0">Critical Risk</p>
            <h2 className="RiskLevelNumber">20</h2>
          </div>
        </div>

        <div className="col">
          <div className="RPLP_card riskLevel">
            <p className="m-0">High Risk</p>
            <h2 className="RiskLevelNumber">15</h2>
          </div>
        </div>

        <div className="col">
          <div className="RPLP_card riskLevel">
            <p className="m-0">Medium Risk</p>
            <h2 className="RiskLevelNumber">10</h2>
          </div>
        </div>

        <div className="col">
          <div className="RPLP_card riskLevel">
            <p className="m-0">Low Risk</p>
            <h2 className="RiskLevelNumber">10</h2>
          </div>
        </div>
      </div>

      <div className="row g-1 mt-3">
        <div className="col-md-4">
          <div className="RPLP_card top_rightCard">
            <h5>Latest Events (20)</h5>
            <div className="detectionCard">
              <div className="Inner_cardEvents">
                <p className="cardEvents_title">
                  Disabled guest participants recognition.
                </p>
                <p className="cardEvents_entity">
                  Entity - arun@binaryflux.com
                </p>
                <div className="d-flex justify-content-between">
                  <span>25/6/2024</span>
                  <span>5:30PM</span>
                </div>
              </div>
              <div className="Inner_cardEvents">
                <p className="cardEvents_title">
                  Disabled guest participants recognition.
                </p>
                <p className="cardEvents_entity">
                  Entity - arun@binaryflux.com
                </p>
                <div className="d-flex justify-content-between">
                  <span>25/6/2024</span>
                  <span>5:30PM</span>
                </div>
              </div>
              <div className="Inner_cardEvents">
                <p className="cardEvents_title">
                  Disabled guest participants recognition.
                </p>
                <p className="cardEvents_entity">
                  Entity - arun@binaryflux.com
                </p>
                <div className="d-flex justify-content-between">
                  <span>25/6/2024</span>
                  <span>5:30PM</span>
                </div>
              </div>
              <div className="Inner_cardEvents">
                <p className="cardEvents_title">
                  Disabled guest participants recognition.
                </p>
                <p className="cardEvents_entity">
                  Entity - arun@binaryflux.com
                </p>
                <div className="d-flex justify-content-between">
                  <span>25/6/2024</span>
                  <span>5:30PM</span>
                </div>
              </div>
              <div className="Inner_cardEvents">
                <p className="cardEvents_title">
                  Disabled guest participants recognition.
                </p>
                <p className="cardEvents_entity">
                  Entity - arun@binaryflux.com
                </p>
                <div className="d-flex justify-content-between">
                  <span>25/6/2024</span>
                  <span>5:30PM</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="RPCP_Content">
            <StellarChart container="bnfxStellarChart" />
            {/* <img className="investigation_svg" src="../../images/investigation_svg.png" alt=""/> */}
            <div className="RPCP_footer">
              <img src="../../images/barline_graph.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewRiskPage;
