// LoadingBar.js
import React, { useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';

const TopLoadingBar = ({ children, onClick }) => {
  const loadingBarRef = useRef(null);

  const handleClick = async () => {
    loadingBarRef.current.continuousStart();

    // Simulate a delay (replace with your actual async operation)
    new Promise((resolve) => setTimeout(resolve, 1000));

    // Assuming onClick returns a promise or is asynchronous
    await onClick();

    // Simulate additional delay (replace with your actual async operation)
    await new Promise((resolve) => setTimeout(resolve, 500));

    // Complete the loading bar
    loadingBarRef?.current?.complete();
  };

  return (
    <div>
      <LoadingBar color="#0b77a0" ref={loadingBarRef} />
      <div className="generic-container" onClick={handleClick}>
        {children}
      </div>
    </div>
  );
};

export default TopLoadingBar;
