import React, { useState, useRef, useLayoutEffect } from "react";
import Search from "../../components/search/Search";
import { SpiderScript } from "./SpiderScript";
import { useGlobalState } from "../../components/common/GlobalVariables";

function SpiderStreamDropDown({
  id,
  options,
  onChange,
  value,
  onMenuScrollToBottom,
  searchrequired,
}) {
  const [, setSelectedOption] = useState(value);
  const [menuOpen, setMenuOpen] = useGlobalState("menuopenforstreamlist");
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleOptionSelect = (option) => {
    setMenuOpen(false);
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };

  const handleScroll = () => {
    if (menuRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = menuRef.current;
      const isAtBottom = scrollHeight - scrollTop <= clientHeight + 1; // Adding a small buffer to ensure precision
      if (isAtBottom && onMenuScrollToBottom) {
        onMenuScrollToBottom();
      }
    }
  };



  useLayoutEffect(() => {
    const searchbar = document.getElementById("spiderstream_search");
    if (searchbar) {
      searchbar.addEventListener("keydown", function () {
        SpiderScript.StreamSearch("spiderstream_search");
      });
      searchbar.addEventListener("input", function () {
        if (searchbar.value === "") {
          searchbar.value = null;
          SpiderScript.setStreamOffset(0);
          SpiderScript.loadStreamList(0);
        }
      });
    }
  }, []); 
  

  
  return (
    <>
    <div className="custom-select-wrapper">
      <div className="spiderdropdown" id={id}>
        <div className="d-flex align-items-center">
          <div className="StreamLogo p">
            <span className="bxIcon_link">
              {value?.value?.title?.charAt(0)}
            </span>
          </div>
          <div
            className="Stram_areaBox"
            type="button"
            onClick={searchrequired ? toggleMenu : null}
            
          >
            <h3 className="m-0 text-white" title={value?.value?.title}>
              {value?.value?.title}
            </h3>
            <p className="m-0" title={value?.value?.description}>
              {value?.value?.description}
            </p>
          </div>
        </div>
        <div
          className={`spiderselectlist ${
            searchrequired ? (menuOpen ? "show" : "") : "show"
          }`}
        >
          {searchrequired ?<div className="bnfxcustomdropdown">
             <Search id="spiderstream_search" defaultValue=""/> 
          </div>:null}
          {options ? (
            <ul
              ref={menuRef}
              className="customselectoptions p-1"
              onScroll={handleScroll}
            >
              {options?.map((option, index) => (
                <li
                  className="listName"
                  key={index}
                  onClick={() => handleOptionSelect(option)}
                >
                  {option?.label}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
      </div>
    </>
  );
}
export default SpiderStreamDropDown;
