import React, { useState, useRef, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { CollectorsScript } from "./CollectorsScript";
import { StreamLanguage } from "@codemirror/language";
import { properties } from "@codemirror/legacy-modes/mode/properties";
import { setGlobalState, useGlobalState } from "../../components/common/GlobalVariables";
import { t } from "i18next";

function ConnectionNode(props) {
  const [config, setConfig] = useState(props.config);
  const [selectedText, setSelectedText] = useState("");
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const editorRef = useRef(null);
  const [encryptValue] = useGlobalState("encryptValue");

  useEffect(() => {
    const handleMouseUp = () => {
      const editor = editorRef.current.view;
      
      if (editor) {
        const state = editor.state;
        const selection = state.selection.main;
        const selectedText = state.doc.sliceString(selection.from, selection.to);
        const lineText = state.doc.lineAt(selection.from).text;

        const equalIndex = lineText.lastIndexOf('=', selection.from - state.doc.lineAt(selection.from).from);
        
        if (selectedText && equalIndex !== -1 && selection.from > equalIndex) {
          const start = state.doc.lineAt(selection.from).from + equalIndex + 1;
          const end = selection.to;
          const adjustedSelectedText = state.doc.sliceString(start, end).trim();
          
          if (adjustedSelectedText) {
            setSelectedText(adjustedSelectedText);
            setSelectionStart(start);
            setSelectionEnd(end);
          }
        } else {
          setSelectedText("");
          setSelectionStart(null);
          setSelectionEnd(null);
        }
      }
    };

    CollectorsScript.setIniConfig(config);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [config]);

  const handleEncrypt = () => {
    if (selectedText && selectionStart !== null && selectionEnd !== null) {
      const beforeSelection = config.slice(0, selectionStart);
      const afterSelection = config.slice(selectionEnd);
      const updatedConfig = beforeSelection + encryptValue + afterSelection;
      setConfig(updatedConfig);
      CollectorsScript.setIniConfig(updatedConfig);
      setSelectedText(""); 
      setGlobalState("encryptValue","")
    }
  };

  useEffect(() => {
    if (encryptValue) {
      handleEncrypt();
    }
  }, [encryptValue]);

  return (
    <>
      <main
        className="collectors_node"
        style={{ height: "100%", width: "100%", position: "relative" }}
        id="collectors-node-properties"
      >
        <CodeMirror
          ref={editorRef}
          className="bnfx_collectors_properties_codemirror"
          value={config}
          placeholder={"No connection configs present"}
          basicSetup={{
            lineNumbers: false,
            highlightActiveLineGutter: false,
            highlightActiveLine: false,
          }}
          theme={"dark"}
          extensions={[StreamLanguage.define(properties)]}
          onChange={(value) => setConfig(value)}
          height="100%"
        />

        <button
          type="button"
          className="btn btn-primary encryptBtn"
          onClick={() => {
            CollectorsScript.getEncryptData(selectedText);
          }}
        >
          <span className="material-symbols-outlined encryptBtn_Icon">lock</span>
          <label className="encryptLabel">{t("encrypt.label")}</label>
        </button>
      </main>
    </>
  );
}

export default ConnectionNode;
