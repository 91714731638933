import { OnboardingScript } from "../../pages/customeronboarding/OnboardingScript";

function SignupInputField(props) {
  return (
    <>
      <input
        type={props.type}
        placeholder={props.placeholder}
        id={props.id}
        onChange={OnboardingScript.handleInputChange}
        className="form-control"
        autoComplete={props.autoComplete}
      />
    </>
  );
}

export default SignupInputField;
