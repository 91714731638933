
function RiskTemplate() {


  return (
    <>
      <div className="fullwidth main-inner RiskScorePage" id="bxFullwidthContent">
        <div className="tab-content riskPageBody">
          <div className="container-fulid ToggleButton">
          <label htmlFor="togBtn" className="switch streamswitch">
            <input
              type="checkbox"
              id="togBtn"
            //   onChange={() => setshowToggle(!showToggle)}
            />
            <div className="slider round">
              <span className="on">ACROSS</span>
              <span className="off">SPECIFIC</span>
            </div>
          </label>
            <div className="riskPageLeftPanel">

              <div className="riskPageLeftPanel_outer">
                <div className="RPLP_card riskLevel">
                  <p className="m-0">Risk Level</p>
                  <h2 className="RiskLevelNumber">47%</h2>
                </div>
                <div className="RPLP_card InvestigationTotal">
                  <p className="m-0 d-block">Investigation</p>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="ITotal_box">
                      <div className="d-flex">
                        <p className="m-0"> RECENT</p>
                        <span class="material-symbols-outlined">filter_alt</span>
                      </div>
                      <h4>22</h4>
                    </div>
                    <div className="ITotal_box">
                      <div className="d-flex">
                        <p className="m-0"> TOTAL</p>
                        <span class="material-symbols-outlined">filter_alt</span>
                      </div>
                      <h4>55</h4>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mt-3">
                    <div className="ITotal_box ILabel">
                      <div className="d-flex">
                        <p className="m-0"> LOW</p>
                        <span class="material-symbols-outlined">chevron_right</span>
                      </div>
                      <h4>5</h4>
                    </div>
                    <div className="ITotal_box ILabel">
                      <div className="d-flex">
                        <p className="m-0"> MEDIUM</p>
                        <span class="material-symbols-outlined">chevron_right</span>
                      </div>
                      <h4>10</h4>
                    </div>
                    <div className="ITotal_box ILabel">
                      <div className="d-flex">
                        <p className="m-0"> HIGH</p>
                        <span class="material-symbols-outlined">chevron_right</span>
                      </div>
                      <h4>40</h4>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="riskPageRightPanel">
              <div className="RPLP_card top_rightCard">
                <h5>Latest Events (20)</h5>
                <span>4 new in the last 24 hours</span>

                <div className="Inner_cardEvents">
                  <p className="cardEvents_title">Disabled guest participants recognition.</p>
                  <p className="cardEvents_entity">Enity -arun@binaryflux.com</p>
                  <div className="d-flex justify-content-between">
                    <span> 25/6/2024</span>
                    <span> 5:30PM</span>
                  </div>
                </div>
                <div className="Inner_cardEvents">
                  <p className="cardEvents_title">Disabled guest participants recognition.</p>
                  <p className="cardEvents_entity">Enity -arun@binaryflux.com</p>
                  <div className="d-flex justify-content-between">
                    <span> 25/6/2024</span>
                    <span> 5:30PM</span>
                  </div>
                </div>
                <div className="Inner_cardEvents">
                  <p className="cardEvents_title">Disabled guest participants recognition.</p>
                  <p className="cardEvents_entity">Enity -arun@binaryflux.com</p>
                  <div className="d-flex justify-content-between">
                    <span> 25/6/2024</span>
                    <span> 5:30PM</span>
                  </div>
                </div>
                <div className="Inner_cardEvents">
                  <p className="cardEvents_title">Disabled guest participants recognition.</p>
                  <p className="cardEvents_entity">Enity -arun@binaryflux.com</p>
                  <div className="d-flex justify-content-between">
                    <span> 25/6/2024</span>
                    <span> 5:30PM</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="riskPageCenterPanel">
              <div className="RPCP_header">
                <p className="RPCP_header_title">Investigation Over Time</p>
                <div className="RPCP_Color">
                  <div className="RPCP_Color_low">Low</div>
                  <div className="RPCP_Color_medium">Medium</div>
                  <div className="RPCP_Color_high">High</div>
                </div>
              </div>


              <div className="RPCP_Content">
                <img src="../../images/investigation_svg.png" alt=""/>
              </div>
            </div>

            <div className="riskPageBottomPanel">
              <div className="RPBP_Content">
                  <img src="../../images/barline_graph.png" alt=""/>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );


}


export default RiskTemplate;