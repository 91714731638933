import React from "react";
import BadgeTabDetail from "./BadgeTabDetail";
import BadgeEntity from "./BadgeEntity";
import BadgeStreams from "./BadgeStreams";
import { BadgePanelScript } from "./BadgePanelScript";
import { t } from "i18next";

function BadgeTab(props) {
  let result = props.data;
  let type = props.type;
  let edit = props.edit;

  return (
    <div>
      <ul className="nav nav-tabs bxNavTabs " id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link tab BadgeTab active"
            id="detailstab"
            onClick={(event) => BadgePanelScript.openTab(event, "tabDetails")}
          >
            {t("details")}
          </button>
        </li>

        <li className="nav-item" role="presentation">
          <button
            className="nav-link tab BadgeTab mx-3"
            id="entitytab"
            onClick={(event) => BadgePanelScript.openTab(event, "tabEntities")}
          >
            {t("entity.group")}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link tab BadgeTab"
            id="streamtab"
            onClick={(event) => BadgePanelScript.openTab(event, "tabStreams")}
          >
            {t("streams")}
          </button>
        </li>
      </ul>
      <div className="tab-content h-100">
        <div id="tabDetails" className="badge-content active">
          <BadgeTabDetail data={result} isedit={edit} />
        </div>

        <div id="tabEntities" className="badge-content">
          <BadgeEntity data={result} isedit={edit} type={type} />
        </div>

        <div id="tabStreams" className="badge-content">
          <BadgeStreams data={result} isedit={edit} />
        </div>
      </div>
    </div>
  );
}

export default BadgeTab;
