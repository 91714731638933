import { useEffect } from "react";
import { CommonTemplate } from "./containerAvailability/CommonScript";

function RoiTableList(props) {
  let data = props.data;
  let column = data.columns;
  let rows = data.rows;
 

  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <>
      <div className="bxTabelRelations lists Investigatn_Dash">
        <table className="table table-centered mb-0">
          <tbody id="bnfxTableBody">
            {rows?.map((row, rowIndex) => (
              <tr
                className={row["icon"] !== undefined ? "bnfxStreamTable" : null}
                key={rowIndex}
              >
                {column?.map((colname, columnIndex) => (
                  <td className={row["icon"]?"bnfxSourceIcon":"bnfxcolummn"} key={columnIndex}>
                    {columnIndex === 0 && row["icon"] === undefined && (
                      <span className={"bxbg-danger " + CommonTemplate.dashboardcriticalityColor(row["detectioncriticality"])}>
                      &nbsp;
                  </span>
                    )}
                    <div className="d-flex align-item-center">

                    <p className="m-0 " title={row[colname]}>
                      {" "}
                      {CommonTemplate.formatNumber(row[colname])}
                    </p>
                </div>
                    
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default RoiTableList;
