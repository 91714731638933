import React, { useEffect } from "react";
import { CommonTemplate } from "../../containerAvailability/CommonScript";
import { Automation } from "../AutomationScript";
import { t } from "i18next";

function PlaybookAutomationLayout(props) {
  const data = props.data;

  
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });
  return (
    <>
    
      <div id="bnfxPlaybookExecutionPagination">
        {/* we will replace pagination */}
      </div>
      <div className="offcanvas-body p-0">
        <div className="tab-content" id="myTabContenta">
          {data?.map((automationdata, index) => (
            <React.Fragment key={index}>
              <div className="bxSEDHeadDashboard d-flex align-items-center justify-content-between">
                <div className="bxStream_link ps-0">
                  <span className="bxIcon_link customeIcon">
                    {automationdata.title.charAt(0)}
                  </span>
                  <div className="bxStream_linkContent dashboard-link border-0" onClick={()=>Automation.taskmapworkflow(automationdata)}>
                    <h3 className="bxStreamTitle_link" title={automationdata.title}>
                      {automationdata.title}
                    </h3>

                    <p className="bxStreamDiscription_link" title={automationdata.description}>
                      {automationdata.description}
                    </p>
                  </div>
                </div>
                <button
                  type="button"
                  title="Play"
                  className="text-end float-end"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#bnfxAutomationLayout"
                  onClick={() => Automation.getPlaybookExecute(automationdata)}
                >
                  <span className="material-symbols-outlined">
                  play_circle
                  </span>
                </button>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="offcanvas-footer bg_canvas p-2 text-end">
        <span
          type="button"
          className="text-white me-3"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            CommonTemplate.removeClass();Automation.offcanvasCloseButton();
          }}
        >
          {t("close")}
        </span>
      </div>
    </>
  );
}

export default PlaybookAutomationLayout;
