
function PrimaryButton(props) {
  return (
    <button type="submit" onClick={props.onClick}  className={"btn btn-lg bxBtn-primary " + props.width}>
      {props.text}
    </button>
  );
}

export default PrimaryButton;

