import React from "react";
import NoContent from "../../components/common/NoContent";
import { t } from "i18next";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import { CollectorsScript } from "./CollectorsScript";

function ActiveNode(props) {
  let activenodes = props?.activenodes;

  let content = null;
  function generateRandomColor() {
    const darkColors = [
      "rgb(0 0 0)",
      "rgb(39 25 9)",
      "rgb(15 9 39)",
      "rgb(39 9 9)",
      "rgb(15 39 9)",
      "#201b0b",
      "#0d1d34",
    ];
    const randomColor =
      darkColors[Math.floor(Math.random() * darkColors.length)];
    return randomColor;
  }

  if (activenodes.length > 0) {
    content = (
      <>
        {activenodes?.map((node, index) => (
          <div key={index} className="dataCollectors_Sec">
            <div className="dataCollectors_iconArea">
              <span
                className="dataCollectors_icon me-2"
                style={{
                  backgroundColor: generateRandomColor(),
                  borderColor: "1px solid" + generateRandomColor(),
                }}
              >
                {node?.name.substring(0, 1)}
              </span>
              <div className="dataCollectors_tagsTitle">
                <h3 className="dataCollectors_title" title={node?.name}>
                  {node?.name}
                </h3>
                <ul className="dataCollectors_tags m-0 p-0">
                  <li className="dataCollectors_tagsName">
                    <span>{node?.provider}</span>
                  </li>
                  <li className="dataCollectors_tagsName">
                    <span>{node?.group}</span>
                  </li>
                  <li className="dataCollectors_tagsName">
                    <span>{node.type}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="dataCollectors_Toggle">
              <div
                className="dataCollectors_instanceid"
                title={node?.instanceId}
              >
                {node?.instanceId}
              </div>

              <div className="d-flex attributesconnector ">
                <p className="me-2"   title="Events per Second">
                  <span
                    className={` material-symbols-outlined ms-1 ${CommonTemplate.epscolor(
                      node?.lastmessageat
                    )}`}
                  >
                    vital_signs
                  </span>
                  <span className="ms-1">{node?.currenteps?CollectorsScript.formatNumber(node?.currenteps):'N/A'}</span>
                </p>
                <p className="me-2 lastmessage"  title="Last message received">
                  <span
                    className="material-symbols-outlined text-white"
                    title="Last message recived"
                  >
                    schedule
                  </span>
                  <span className="ms-1">{node?.lastmessageat?CommonTemplate.timeDifference(node?.lastmessageat):'N/A'}</span>
                </p>
                <p className="me-2"  title="Created on">
                  <span
                    className="material-symbols-outlined text-white"
                   
                  >
                    timelapse
                  </span>
                  <span className="ms-1">{CommonTemplate.timeDifference(node?.updatedAt)}</span>
                </p>
              </div>

              <label
                htmlFor="bnfxcheckboxbutton"
                className="toggle-switch d-none"
              >
                <input type="checkbox" id="bnfxcheckboxbutton" />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        ))}{" "}
      </>
    );
  } else {
    content = (
      <NoContent
        id={"noautomationtask"}
        title={t("noactive.node.title")}
        description={t("noactive.node.description")}
      />
    );
  }
  return content;
}

export default ActiveNode;
