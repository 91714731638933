import React, { useState, useRef, useEffect } from "react";
import { OnboardingScript } from "../../pages/customeronboarding/OnboardingScript";
import { BadgePanelScript } from "./Badge/BadgePanelScript";

function MultiInput(props) {
  let entity=props.entity;
  let page=props.page;
  let type=props.type;
  
  const [currentcolor] = useState(["#010F33"]);
  const [content_add, setContentAdd] = useState(page === "signup_Page" ? "Add Tenant +" : "Add Entities +");
  const [width, setWidth] = useState(100);
  const [myItems, setMyItems] = useState([]);
  const lastIdRef = useRef(-1);
  const helperspanRef = useRef(null);
  
  const handleFocus = () => {
    setContentAdd("");
  };
  
  const handleChange = (event) => {
    const usr_input = event.target.value;
    setContentAdd(usr_input);
  };

  useEffect(() => {
    if (entity && typeof entity === 'string') {
      setMyItems([{ content: entity, id: 0 }]);
      lastIdRef.current = 0;
    } else if (Array.isArray(entity) && entity.length > 0) {
      setMyItems(entity.map((name, index) => ({ content: name, id: index })));
      lastIdRef.current = entity.length - 1;
    }
  }, [entity]);

  const handleKeypress = (event) => {
    if (event.key === "Enter") {
      const newArray = [...myItems];

      const tenantList = [...newArray.map((item) => item.content), content_add];
      const capitalizedTenantList = tenantList.map(tenant => tenant.toUpperCase());
      
      if(page==="signup_Page"){
        OnboardingScript.getSignupTenants(capitalizedTenantList);
      }else{
        BadgePanelScript.getEntities(tenantList);
      }
      
      const currentcontent = content_add.trim();
      if (!currentcontent) {
        return;
      }

      const newItem = {
        content: currentcontent,
        id: ++lastIdRef.current,
      };

      newArray.push(newItem);
      setMyItems(newArray);
      setContentAdd("");
    }
  };

  const handleBlur = () => {
    setContentAdd(page === "signup_Page" ? "Add Tenant +" : "Add Entities +");
  };

  

  const handleClick = (event) => {
    if (type === "totalBadge" || type === "signupTenant") {
      const idToRemove = Number(event.target.dataset["item"]);
      const newArray = myItems.filter((listitem) => listitem.id !== idToRemove);
      setMyItems(newArray);
  
      const tenantList = newArray.map((item) => item.content);
      const capitalizedTenantList = tenantList.map(tenant => tenant.toUpperCase());
  
      if (page === "signup_Page") {
        OnboardingScript.getSignupTenants(capitalizedTenantList);
      } else {
        BadgePanelScript.getEntities(tenantList);
      }
    }
  };
  

  useEffect(() => {
    if (helperspanRef.current) {
      const helperWidth = helperspanRef.current.offsetWidth;
      setWidth(Math.max(110, helperWidth + 1));
    }
  }, [content_add]);
  
  const makeAddedList = () => {
    return myItems?.map((listitem, index) => (
        <li
        key={listitem.id}
          style={{
            backgroundColor: currentcolor[index % currentcolor.length],
            // width: listitem.itemWidth
          }}
        >
          {listitem.content}
          <span data-item={listitem.id} onClick={handleClick}>X</span>
        </li>
    ));
  };

  return (
    <div id="multiInput">
        
      {makeAddedList()}
    {(type==="totalBadge"||type==="signupTenant") &&(
      <>
        <input
          id="add"
          type="text"
          name="initvalue"
          className="addtenants"
          autoComplete="off"
          maxLength="70"
          onFocus={handleFocus}
          onChange={(e) => {
            handleChange(e);
            // BadgePanelScript.handleInputChange(e);
          }}
          onKeyDown={handleKeypress}
          onBlur={handleBlur}
          value={content_add}
          style={{ width }}
        />
        <span id="helperspan" ref={helperspanRef}>
          {content_add}
        </span>
      </>)}
    </div>
  );
}

export default MultiInput;
