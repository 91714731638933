import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../SelectDropDown/SelectDropDown.css";

function SelectDropDown({ id, icon, options, defaultText, onChange, value }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClick = () => {
    const container=document.querySelector(".regionselectlist");
    if(container){
      container.classList.remove("show")
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setMenuOpen(false);
    if (onChange) {
      onChange(option);
    }
  };
  useEffect(() => {
    const parsedValue = value ? value : null;
    setSelectedOption(parsedValue);
}, [value]);
  return (
    <div className="mainselect" id={id} onMouseLeave={handleClick}>
      <div className="logoicon" onClick={toggleMenu}>
        <span className="material-symbols-outlined">{icon}</span>
        <small>{selectedOption ? selectedOption.label : defaultText}</small>
      </div>

      <ul className={"regionselectlist" + (menuOpen ? " show" : "")}>
        {options.map((option, index) => (
          <li className="select-list" key={index} onClick={() => handleOptionSelect(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

SelectDropDown.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  icon: PropTypes.node.isRequired,
  defaultText: PropTypes.string.isRequired,
  onChange: PropTypes.func, // onChange prop is a function
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }), // value prop is an object with value and label properties
};

SelectDropDown.defaultProps = {
  defaultText: "Select an option",
};

export default SelectDropDown;
