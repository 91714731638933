import { useTranslation } from "react-i18next";
import InputUser from "../inputField/InputUser";
import Error from "../error/Error";
import Required from "../requiredField/Required";
import PrimaryButton from "../buttons/PrimaryButton";
import { Login } from "../../pages/login/LoginScript";
import { useEffect, useState } from "react";

function LoginForm() {
  /*It is used to access the translation functions provided by the "react-i18next" library. */
  const { t } = useTranslation();
  const [sessionExpired] = useState(
    window.localStorage.getItem("sessionExpired")
  );

  useEffect(() => {
    window.localStorage.removeItem("sessionExpired");
  }, []);

  return (
    <form>
      <div className={sessionExpired ? "" : "d-none"}>
        <span className="text-danger">
          {t("logout.errormessage")}
        </span>
      </div>

      <div className="mb-2">{t("login.header")}</div>
      <div className="form-floating mb-3">
        <InputUser type="text" placeholder="CustomerId" id="accountid" />
        <label htmlFor="accountid">{t("login.accountid")}</label>
        <Error>{t("login.accountid_error")}</Error>
        <Required>{t("login.required")}</Required>
      </div>

      <div className="form-floating mb-3">
        <InputUser type="text" placeholder="Username" id="username" />
        <label htmlFor="username">{t("login.username")}</label>
        <Error>{t("login.username_error")}</Error>
        <Required>{t("login.required")}</Required>
      </div>

      <div className="form-floating">
        <InputUser type="password" placeholder="Password" id="password" />
        <label htmlFor="password">{t("login.password")}</label>
        <Error> {t("login.password_error")}</Error>
        <Required>{t("login.required")}</Required>
      </div>
      <PrimaryButton width="w-100" text="Continue" onClick={Login.signIn} />
      <br/><br/>
    <p className="signupText">{t("dont.have.account")}<a className="ms-2" href="/signup">{t("signup.option")} </a></p>
    </form>
  );
}

export default LoginForm;
