import { Content } from "../ContentScript";
import "../codepanel/codepanel.css";
import CodeMirror from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";
import { EditorView } from "@codemirror/view";
import { javascript } from "@codemirror/lang-javascript";
import { html } from "@codemirror/lang-html";
import { json } from "@codemirror/lang-json";
import {
  ADD_YOUR_CODE,
  CODEMIRROR_FONT_SIZE,
  HIDE_GENERATE_AND_TEST_FOR_TYPE,
} from "../contentConstants";
import NoContent from "../../../components/common/NoContent";
import CustomToolTip from "../../../components/common/customtooltip/CustomToolTip";
import LoadingWave from "../../../components/common/loadingwaveicon/LoadingWave";
import { useEffect } from "react";
import { t } from "i18next";
function CodeLayout({
  nonStreamFields,
  type,
  dispatch,
  tenantsList,
  terminalName,
  isCodeGeneratorPanelOpen,
  codeGeneratorInputs,
  codeGeneratorFormFields,
  isCodeGeneratorLoading,
  testCodeFormValues,
  errors,
  onCloseEditOrCreate,
  isTestCodeLoading,
}) {
  useEffect(() => {
    Content.onCreateOrEditClick(dispatch, true, terminalName);
  }, []);
  return (
    <div className="row bottom-section g-0">
      <div
        className={
          HIDE_GENERATE_AND_TEST_FOR_TYPE.includes(type)
            ? "col-md-12 code-editor pe-3"
            : "col-md-6 code-editor pe-3"
        }
      >
        <div className="editor-header">
          <h3>
            <span className="material-symbols-outlined">data_object</span> Code
          </h3>
          {!HIDE_GENERATE_AND_TEST_FOR_TYPE.includes(type) && (
            <div className="rightBtnGroups">
              <button
                className="content_generation expandFullPage enterfullscreen"
                title="Expand"
                onClick={() => {
                  Content.openFullscreen(".ContentPageLayout");
                }}
              >
                <span class="material-symbols-outlined">expand_content</span>
              </button>
              <button
                className="content_generation expandFullPage exitfullscreen d-none"
                title="Expand"
                onClick={() => {
                  Content.closeFullscreen();
                }}
              >
                <span class="material-symbols-outlined">collapse_content</span>
              </button>
              <button
                className="content_generation "
                onClick={() =>
                  Content.generateCode(
                    dispatch,
                    terminalName,
                    nonStreamFields,
                    codeGeneratorFormFields
                  )
                }
                id="generate"
              >
                <span className="material-symbols-outlined">psychology</span>
                <CustomToolTip
                  content={t("content.generate.tooltip")}
                  customclass="generateTooltip"
                  innerText="Generate"
                />
              </button>

              {nonStreamFields?.content &&
                ADD_YOUR_CODE !== nonStreamFields?.content && (
                  <button
                    className="content_generation"
                    onClick={() =>
                      Content.onTestCodePanelClick(
                        dispatch,
                        true,
                        terminalName,
                        nonStreamFields
                      )
                    }
                    id="run"
                  >
                    <span className="material-symbols-outlined">
                      play_arrow
                    </span>
                    Test
                  </button>
                )}
            </div>
          )}
        </div>
        {nonStreamFields?.content && terminalName !== "report" && (
          <div
            id="contentnonstreamdataeditor"
            spellcheck="false"
            className="content_nonstream_codemirror"
          >
            {isCodeGeneratorLoading && <LoadingWave />}
            {!isCodeGeneratorLoading && (
              <CodeMirror
                className={`${errors?.content ? "border-danger " : ""}`}
                id="bnfx_content_codemirror"
                value={nonStreamFields?.content || ""}
                basicSetup={{
                  lineNumbers: true,
                  highlightActiveLineGutter: false,
                  highlightActiveLine: false,
                  lineWrapping: true,
                }}
                editable="true"
                height="100%"
                style={{ tabSize: 10, fontSize: CODEMIRROR_FONT_SIZE }}
                theme={"dark"}
                extensions={[python({}), EditorView.lineWrapping]}
                name="content"
                onChange={(value) => {
                  Content.onNoStreamItemChange(
                    { target: { name: "content", value: value } },
                    dispatch
                  );
                }}
              />
            )}
          </div>
        )}

        {nonStreamFields?.content && terminalName === "report" && (
          <div
            id="contentnonstreamdataeditor"
            spellcheck="false"
            className="content_nonstream_codemirror"
          >
            {isCodeGeneratorLoading && <LoadingWave />}
            {!isCodeGeneratorLoading && (
              <CodeMirror
                id="bnfx_content_codemirror"
                value={nonStreamFields?.content}
                basicSetup={{
                  lineNumbers: true,
                  highlightActiveLineGutter: false,
                  highlightActiveLine: false,
                  lineWrapping: true,
                }}
                editable="true"
                height="100%"
                style={{ tabSize: 10, fontSize: CODEMIRROR_FONT_SIZE }}
                theme={"dark"}
                extensions={[html({}), EditorView.lineWrapping]}
                name="content"
                onChange={(value) =>
                  Content.onNoStreamItemChange(
                    { target: { name: "content", value: value } },
                    dispatch
                  )
                }
              />
            )}
          </div>
        )}
      </div>

      {!HIDE_GENERATE_AND_TEST_FOR_TYPE.includes(type) && (
        <div className="col-md-6 test-results">
          <div className="tabs ">
            <button
              className={
                "tab-btn " +
                (nonStreamFields.activeTab === "event-tab" ? "active" : "")
              }
              onClick={(event) => Content.openTab(dispatch, "event-tab")}
            >
              Event
            </button>

            {codeGeneratorInputs &&
              codeGeneratorInputs.content &&
              Object.keys(codeGeneratorInputs.content).length > 0 &&
              !(
                Object.keys(codeGeneratorInputs.content).length === 1 &&
                Object.keys(codeGeneratorInputs.content).includes("event")
              ) && (
                <button
                  className={
                    "tab-btn " +
                    (nonStreamFields.activeTab === "configuration-tab"
                      ? "active"
                      : "")
                  }
                  onClick={(event) => {
                    if (
                      !codeGeneratorInputs ||
                      Object.keys(codeGeneratorInputs).length === 0
                    ) {
                      Content.onCodeGeneratorPanelClick(
                        dispatch,
                        true,
                        terminalName
                      );
                    }

                    Content.openTab(dispatch, "configuration-tab");
                  }}
                >
                  Configuration
                </button>
              )}
            <button
              className={
                "tab-btn " +
                (nonStreamFields.activeTab === "instructions-tab"
                  ? "active"
                  : "")
              }
              onClick={(event) => {
                if (
                  !codeGeneratorInputs ||
                  Object.keys(codeGeneratorInputs).length === 0
                ) {
                  Content.onCodeGeneratorPanelClick(
                    dispatch,
                    true,
                    terminalName
                  );
                }

                Content.openTab(dispatch, "instructions-tab");
              }}
            >
              Instructions
            </button>
            <button
              className={
                "tab-btn " +
                (nonStreamFields.activeTab === "testresults-tab"
                  ? "active"
                  : "")
              }
              onClick={(event) => Content.openTab(dispatch, "testresults-tab")}
            >
              Test Results
            </button>
          </div>

          <div className="tab-content contentcodepanels">
            <div
              id="event-tab"
              className={
                "content_code_panel " +
                (nonStreamFields.activeTab === "event-tab" ? "active" : "")
              }
            >
              {codeGeneratorInputs &&
                codeGeneratorInputs?.content &&
                codeGeneratorInputs?.content?.event && (
                  <div className="test-case-view">
                    <div className="test-case">
                      <label className="test-event-label" for="nums-1">
                        Event (json):{" "}
                        {codeGeneratorInputs?.content?.event?.sample && (
                          <span
                            className="test-event-sample"
                            title="Click to add sample event"
                            onClick={() =>
                              Content.addSampleToTestEvent(
                                dispatch,
                                codeGeneratorInputs?.content?.event
                              )
                            }
                          >
                            Add Sample
                          </span>
                        )}
                      </label>

                      <div
                        id="content_testcase_editor"
                        className={errors?.testEvent ? "border-danger " : ""}
                        spellcheck="false"
                      >
                        <CodeMirror
                          id="bnfx_content_testcase_codemirror"
                          value={nonStreamFields?.testEvent || ""}
                          basicSetup={{
                            lineNumbers: true,
                            highlightActiveLineGutter: false,
                            highlightActiveLine: false,
                            lineWrapping: true,
                          }}
                          editable={true} // Use a boolean value instead of a string
                          height="100%"
                          style={{ tabSize: 2, fontSize: CODEMIRROR_FONT_SIZE }} // Adjusted tab size for JSON formatting
                          theme="dark"
                          extensions={[json(), EditorView.lineWrapping]} // Use JSON extension for syntax highlighting
                          name="content"
                          onChange={(value) =>
                            Content.onNoStreamItemChange(
                              { target: { name: "testEvent", value: value } },
                              dispatch
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div
              id="configuration-tab"
              className={
                "content_code_panel " +
                (nonStreamFields.activeTab === "configuration-tab"
                  ? "active"
                  : "")
              }
            >
              <div className="test-case-view">
                <div className="test-case">
                  {(!codeGeneratorInputs || !codeGeneratorInputs?.content) && (
                    <NoContent id={"nodata"} />
                  )}
                  {codeGeneratorInputs &&
                    codeGeneratorInputs?.content &&
                    Object.keys(codeGeneratorInputs?.content).map(
                      (fieldName) =>
                        fieldName !== "event" && (
                          <div className="mb-2">
                            <div className="content_generate_params d-flex">
                              <label
                                className="d-block text-capitalize me-2"
                                htmlFor="spider_content_name"
                              >
                                {fieldName}
                              </label>
                              <CustomToolTip
                                content={
                                  fieldName !== "event"
                                    ? "Example: " +
                                      codeGeneratorInputs?.content[fieldName][
                                        "sample"
                                      ]
                                    : "Sample json event"
                                }
                                customclass="demo"
                              />
                            </div>

                            <InputSelector
                              fieldName={fieldName}
                              fieldData={
                                codeGeneratorInputs?.content[fieldName]
                              }
                              formValues={codeGeneratorFormFields}
                              dispatch={dispatch}
                              errors={errors}
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
            <div
              id="instructions-tab"
              className={
                "content_code_panel " +
                (nonStreamFields.activeTab === "instructions-tab"
                  ? "active"
                  : "")
              }
            >
              <div className="test-instructions-view">
                <textarea
                  className="inputLg content_instruction_area"
                  name="instructions"
                  placeholder={`Enter instructions`}
                  autoComplete="off"
                  value={nonStreamFields?.instructions || ""}
                  onChange={(e) => Content.onNoStreamItemChange(e, dispatch)}
                ></textarea>
              </div>
            </div>
            <div
              id="testresults-tab"
              className={
                "content_code_panel " +
                (nonStreamFields.activeTab === "testresults-tab"
                  ? "active"
                  : "")
              }
            >
              {isTestCodeLoading && <LoadingWave />}
              {!isTestCodeLoading && (
                <div className="test-results-view">
                  {(!testCodeFormValues || !testCodeFormValues.output) && (
                    <div className="result">
                      <p className="noTestHolder">
                        {t("content.noTest.placeholder")}
                      </p>
                    </div>
                  )}
                  {testCodeFormValues &&
                    testCodeFormValues.output &&
                    typeof testCodeFormValues.output === "string" && (
                      <div className="result">
                        <p>{testCodeFormValues.output}</p>
                      </div>
                    )}

                  {testCodeFormValues &&
                    testCodeFormValues.output &&
                    typeof testCodeFormValues.output !== "string" &&
                    Object.keys(testCodeFormValues.output).map((resultKey) => {
                      return (
                        <div className="result">
                          <p>
                            <strong>{resultKey}:</strong>{" "}
                            {testCodeFormValues.output[resultKey].response !==
                              null &&
                              testCodeFormValues.output[resultKey].response !==
                                undefined && (
                                <span className="codetest-success">
                                  {JSON.stringify(
                                    testCodeFormValues.output[resultKey]
                                      ?.response
                                  )}
                                </span>
                              )}
                            {testCodeFormValues.output[resultKey].error !==
                              null &&
                              testCodeFormValues.output[resultKey].error !==
                                undefined && (
                                <span className="codetest-error">
                                  {JSON.stringify(
                                    testCodeFormValues.output[resultKey].error
                                  )}
                                </span>
                              )}
                            {(testCodeFormValues.output[resultKey].response ===
                              null ||
                              testCodeFormValues.output[resultKey].response ===
                                undefined) &&
                              (testCodeFormValues.output[resultKey].error ===
                                null ||
                                testCodeFormValues.output[resultKey].error ===
                                  undefined) && (
                                <span className="codetest-success">
                                  {JSON.stringify(
                                    testCodeFormValues.output[resultKey]
                                  )}
                                </span>
                              )}
                          </p>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const INPUT_TYPES = ["text", "radio", "checkbox"];
const EDITOR_TYPES = ["json"];
const TEXT_AREAR = ["textarea"];
const InputSelector = ({
  dispatch,
  fieldName,
  fieldData,
  formValues,
  errors,
}) => {
  return (
    <>
      {INPUT_TYPES.includes(fieldData.type) && (
        <input
          className={errors[fieldName] ? "border-danger inputLg" : "inputLg"}
          type={fieldData.type}
          id={fieldName}
          name={fieldName}
          placeholder={`Enter ${fieldName}`}
          autoComplete="off"
          value={(formValues && formValues[fieldName]) || ""}
          onChange={(e) =>
            Content.handleCodeGeneratorInputChange(
              dispatch,
              fieldName,
              e.target.value
            )
          }
        />
      )}

      {TEXT_AREAR.includes(fieldData.type) && (
        <textarea
          className={errors[fieldName] ? "border-danger inputLg" : "inputLg"}
          type={fieldData.type}
          id={fieldName}
          name={fieldName}
          placeholder={`Enter ${fieldName}`}
          autoComplete="off"
          value={(formValues && formValues[fieldName]) || ""}
          onChange={(e) =>
            Content.handleCodeGeneratorInputChange(
              dispatch,
              fieldName,
              e.target.value
            )
          }
        ></textarea>
      )}

      {EDITOR_TYPES.includes(fieldData.type) && (
        <div
          className={
            errors[fieldName] ? "border-danger editor-wrap" : "editor-wrap"
          }
        >
          <CodeMirror
            className={"bnfx_spider_codemirror "}
            id="bnfx_spider_codemirror"
            value={(formValues && formValues[fieldName]) || ""}
            basicSetup={{
              lineNumbers: true,
              highlightActiveLineGutter: false,
              highlightActiveLine: false,
              lineWrapping: true,
            }}
            editable="true"
            height="100%"
            style={{ tabSize: 10, fontSize: CODEMIRROR_FONT_SIZE }}
            theme={"dark"}
            extensions={[javascript({}), EditorView.lineWrapping]}
            onChange={(data) =>
              Content.handleCodeGeneratorInputChange(dispatch, fieldName, data)
            }
          />
        </div>
      )}
    </>
  );
};

export default CodeLayout;
