
function CollectorInstructionLayout() {
  
  return (
    <>
      <div
        className="bxStreamLstOffCanvas offcanvas offcanvas-end AddNewInstructionsPanel"
        id="bnfxAddNewInstructions"
        aria-labelledby="bnfxAddNewInstructions"
        data-bs-backdrop={true}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-white" id="offcanvasAddUserLabel">
            Add Instruction
          </h5>
        </div>

        <div className="offcanvas-body p-0">
          <div className="bxAddInstructionsForm p-4" id="bnfxAddInstructions">
            {/* <InstructionForm/> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default CollectorInstructionLayout;
