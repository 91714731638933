import { useGlobalState } from "../../../components/common/GlobalVariables";
import "gridstack/dist/gridstack.min.css";

function DashboardDetailsContainer() {
  const [collapsed] = useGlobalState("global_left_panel_collapsed");

  return (
    <>
      <div
        id="map-switch"
        className=""
        role="tabpanel"
        aria-labelledby="rightSwitch-tab"
      >
        <div
          className={`right-section easy-3s desktop-right float-end ${
            collapsed ? "task-collapsed" : ""
          }`}
        >
          <div
            className="rightDashBoxArea col-12 mb-4 datePickerContainer"
            id="bnfxDashboardLayout"
          >
            {/* here we will replace dashboard meta and widgets */}
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardDetailsContainer;
