import LoadingWave from "../../../components/common/loadingwaveicon/LoadingWave";
import Pagination from "../../../components/common/pagination/Pagination";
import Search from "../../../components/search/Search";
import { Content } from "../ContentScript";
import { RIGHT_PANEL_LABELS } from "../contentConstants";
import CreateEditNonStreamItem from "./CreateEditNonStreamItem";
import CreateStream from "./CreateStream";
import RightPanelContentItemsList from "./RightPanelContentItemsList";

function ContentItemRightPanel({
  isLoading,
  selectedLeftPanelItemName,
  contentItemList,
  contentItemSearchText,
  contentItemOffset,
  contentItemTotal,
  terminalName,
  isCreateOrEdit,
  onCreateOrEditClick,
  onCloseEditOrCreate,
  streamFields,
  nonStreamFields,
  dispatch,
  tenantsList,
  isCodeGeneratorPanelOpen,
  codeGeneratorInputs,
  codeGeneratorFormFields,
  isCodeGeneratorLoading,
  testCodeFormValues,
  errors,
  isTestCodeLoading,
  secretsOrVariablesFields,
}) {
  return (
    <div class="col-md-10">
      <div class="bxContent">
        {!isCreateOrEdit && (
          <>
            <div class="bxContent_header">
              <div className="col-md-12 mb-3">
                <h4 class="m-0 text-white">
                  {RIGHT_PANEL_LABELS[selectedLeftPanelItemName]?.title}
                </h4>
                <p class="m-0 text-truncate">
                  {RIGHT_PANEL_LABELS[selectedLeftPanelItemName]?.description}
                </p>
              </div>
              <div class="btn_Group contentRightPanel col-md-12 d-flex align-items-center justify-content-between">
                <div className="content_rightpanel d-flex ">
                  <div className="sidebar-search me-2">
                    <Search
                      id="contentItemSearch"
                      onKeyDown={(event) =>
                        Content.onSearchPipelineItems(
                          dispatch,
                          event.target.value,
                          selectedLeftPanelItemName
                        )
                      }
                      defaultValue=""
                    ></Search>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary ms-2 contentbutton"
                    id="bxApplyStream"
                    onClick={() => onCreateOrEditClick(null, terminalName)}
                  >
                    Add
                  </button>
                </div>

                <div className="pagination float-end">
                  <Pagination
                    pagination={{
                      total: contentItemTotal,
                      limit: 18,
                      offset: contentItemOffset,
                    }}
                    onClickFn={(offset) =>
                      Content.getPaginatedItems(
                        dispatch,
                        offset,
                        selectedLeftPanelItemName
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div id="bnfxContentStreamList">
              {isLoading && <LoadingWave />}
              {!isLoading && (
                <RightPanelContentItemsList
                  originalData={contentItemList}
                  type={selectedLeftPanelItemName}
                  terminalName={terminalName}
                  onCreateOrEditClick={onCreateOrEditClick}
                />
              )}
            </div>
          </>
        )}

        {isCreateOrEdit && isLoading && <LoadingWave />}
        {isCreateOrEdit &&
          selectedLeftPanelItemName !== "streams" &&
          !isLoading && (
            <CreateEditNonStreamItem
              nonStreamFields={nonStreamFields}
              type={selectedLeftPanelItemName}
              dispatch={dispatch}
              tenantsList={tenantsList}
              terminalName={terminalName}
              isCodeGeneratorPanelOpen={isCodeGeneratorPanelOpen}
              codeGeneratorInputs={codeGeneratorInputs}
              codeGeneratorFormFields={codeGeneratorFormFields}
              isCodeGeneratorLoading={isCodeGeneratorLoading}
              testCodeFormValues={testCodeFormValues}
              errors={errors}
              onCloseEditOrCreate={onCloseEditOrCreate}
              isTestCodeLoading={isTestCodeLoading}
              secretsOrVariablesFields={secretsOrVariablesFields}
            />
          )}

        {/* {isCreateOrEdit &&
          selectedLeftPanelItemName === "streams" &&
          !isLoading &&
          streamFields?.url && (
            <CreateStream
              streamFields={streamFields}
              type={selectedLeftPanelItemName}
              onCloseEditOrCreate={onCloseEditOrCreate}
              dispatch={dispatch}
              errors={errors}
            />
          )} */}

        {isCreateOrEdit &&
          selectedLeftPanelItemName === "streams" &&
          !isLoading && (
            <CreateStream
              streamFields={streamFields}
              type={selectedLeftPanelItemName}
              onCloseEditOrCreate={onCloseEditOrCreate}
              dispatch={dispatch}
              errors={errors}
              tenantsList={tenantsList}
            />
          )}
      </div>
    </div>
  );
}

export default ContentItemRightPanel;
