import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const GraphDataExpandable = ({ rowData, maxToShow }) => {
  const { t } = useTranslation();
  const [showCount, setShowCount] = useState(maxToShow);

  const handleShowMore = () => {
    if (showCount < rowData.length) {
      setShowCount(showCount + maxToShow);
    }
  };

  const handleShowLess = () => {
    if (showCount > maxToShow) {
      setShowCount(showCount - maxToShow);
    }
  };

  const renderEntries = () => {
    return (
      <>
        {rowData.slice(0, showCount).map((entry, index) => (
          <div className="dropdown bxNetWorkArrow" key={index}>
            <p>{entry}</p>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {rowData.length > maxToShow && (
        <>
          {renderEntries()}
          <div className="d-flex align-items-center justify-content-between">
            <button onClick={handleShowMore} className="text-primary">
                {t("organization.show_more")}
                <span className="material-symbols-outlined">
                keyboard_double_arrow_down
                </span>
            </button>
            {showCount > maxToShow && (
                <button onClick={handleShowLess} className="text-primary">
                {t("organization.show_less")}
                <span className="material-symbols-outlined">
                stat_2
                </span>
                </button>
                
            )}
          </div>
        </>
      )}
    </>
  );
};

export default GraphDataExpandable;
