import { t } from "i18next";


function SettingsModal() {
  return (
    <>
      <div
        className="modal bxPopup fade"
        id="bxSettingModal"
        tabIndex="-1"
        aria-labelledby="bxFilterTop"
        aria-hidden="true"
        data-bs-backdrop="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="filterHead modal-header">
              {/* <div id="bnfxtenantdropdown"></div> */}
              <p>{t("setting.modal.header")}</p>
              <button
                type="button"
                className="material-symbols-outlined bxClose"
                data-bs-dismiss="modal"
                aria-label="Close"
              >{t("close")}
              </button>
            </div>
            <div className="filterBody modal-body px-3 p-0">
              <div className="row d-flex align-items-start ">
                <div className="nav flex-column nav-pills col-4 p-3 bx-SettingModal" id="bnfxtenantlist" role="tablist" aria-orientation="vertical">

                </div>
                <div className="tab-content col-8 border-start py-3" id="bnfxrequiredparams">

                </div>

              </div>



            </div>
            <div className="filterFooter modal-footer justify-content-start">
              <label className="form-check-label" >{t("setting.modal.footer")}</label>
              <a target="_blank" rel="noopener noreferrer" className="text-info" href="https://binaryflux.atlassian.net/wiki/spaces/BE/pages/39845889/Zoom+Integration+Complete+end+to+end+documentation">{t("click.here")}</a>
            </div>

          </div>

        </div>
      </div>


    </>
  );
}

export default SettingsModal;
