function ActionLayout() {

  return (
    <>
      <div
        className="bxStreamLstOffCanvas offcanvas offcanvas-start"
        tabIndex="-1"
        id="bxOffcanvasPanel"
        aria-labelledby="bxOffcanvasPanel"
  
      >
        <div id="bnfxActionList">
          {/*  we will replace actionlist */}
        </div>
      </div>

    </>
  );
}

export default ActionLayout;