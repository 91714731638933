import React from "react";
import { t } from "i18next";
import AvailableDashboardLayout from "./AvailableDashboardLayout";

function AddDashboardLayout() {
  return (
    <>
      <div
        className="bxAddDashboardOffCanvas offcanvas offcanvas-start"
        tabIndex="-1"
        id="bnfxAddNewDashboard"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {t("enabled.adddashboard")}
          </h5>
          <button
            type="button"
            className="material-symbols-outlined"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >close</button>
        </div>
        <div id="bnfxAvailableList">
          <AvailableDashboardLayout />
        </div>
      </div>
    </>
  );
}

export default AddDashboardLayout;
