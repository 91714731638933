import { showError } from "../../components/common/DisplayError";
import {
  getGlobalState,
  setGlobalState,
} from "../../components/common/GlobalVariables";
import { requestURI } from "../../components/common/RequestURIs";
import { ajax } from "../../components/common/Service";
import jwt from "jwt-decode";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import SpiderTerminalTenant from "./list/SpiderTerminalTenant";
import SpiderHeader from "./SpiderHeader";
import DragElements from "./details/DragElements";
import ContentOutput from "./details/ContentOutput";
import ToastPopup from "../../components/common/ToastPopup";
import { t } from "i18next";
import Swal from "sweetalert2";
import ShowErrorToastPopup from "../../components/common/ShowErrorToastPopup";
import NoContent from "../../components/common/NoContent";

const token = localStorage.getItem("token");

export const SpiderScript = {
  searchtimeout: [],
  selectedTenant: null,
  streamoffset: null,
  contentoffset: null,
  datasource: null,
  content: "",
  editablecontent: [],
  selectedNodeLabel: null,
  newcomponent: null,
  selectedContent: [],
  setcreatecontent: null,
  contentalreadyexist: false,
  streamselectedurl: "",
  selected_option: null,
  selectedinputname:"",
streamreportglobal:null,

  alreadyaddingcontent: null,
  init: () => {
    setGlobalState("spiderreoladfreeze", true);
    SpiderScript.setStreamOffset(0);
    // document.getElementById('spidernodeloading-icon').style.display = 'block';

    setGlobalState("selectedStreams", []);
    SpiderScript.TenantsList();
    // SpiderScript.loadStreamList(0);
    const StreamsOptions = getGlobalState("StreamsOptions");
    if (StreamsOptions) {
      const container = document.querySelector("#bnfxspiderHeader");

      const data = <SpiderHeader />;
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  TenantsList: () => {
    const userdetails = jwt(token);

    let tenantcount = userdetails?.tenants?.length;
    if (
      tenantcount === null ||
      tenantcount === undefined ||
      tenantcount === 0
    ) {
      SpiderScript.getTenantsList();
    } else {
      let tokentenantlist = userdetails.tenants;
      const tenantList = tokentenantlist.map((item) => ({
        value: item,
        label: item,
      }));
      let singletenant = getGlobalState("singletenant");
      const selectAllOption = {
        value: "default",
        label: "ALL",
      };

      if (singletenant === false) {
        tenantList.unshift(selectAllOption);
      }
      const container = document.querySelector("#terminal_tenants");
      const data = <SpiderTerminalTenant tenants={tenantList} />;
      CommonTemplate.ContainerAvailability(container, data);

      SpiderScript.selectTenantChange(tenantList[0]);
      // SpiderScript.getLastLoggedIn();
    }
  },
  getTenantsList: () => {
    ajax(
      "get",
      requestURI["list.tenant"],
      null,
      null,
      SpiderScript.renderTenantList,
      SpiderScript.renderError
    );
  },

  renderTenantList: (response) => {
    let tenantlist = response.data.response;

    const tenantList = tenantlist.map((item) => ({
      value: item.name,
      label: item.name,
    }));

    const selectAllOption = {
      value: "default",
      label: "ALL",
    };

    tenantList.unshift(selectAllOption);
    const container = document.querySelector("#terminal_tenants");
    const data = <SpiderTerminalTenant tenants={tenantList} />;
    CommonTemplate.ContainerAvailability(container, data);

    SpiderScript.selectTenantChange(tenantList[0]);
  },
  selectTenantChange: (selectedTenant) => {
    // document.querySelector("#bnfx_terminal_tenant_display").innerHTML = selectedTenant.value==="default"?null:selectedTenant.value.toLowerCase();
    SpiderScript.selectedTenant = selectedTenant;
    setGlobalState("StreamsOptions", []);
    SpiderScript.setStreamOffset(0);
    SpiderScript.loadStreamList(0);
  },
  loadMoreStream: () => {
    SpiderScript.setStreamOffset(SpiderScript.streamoffset + 10);
    SpiderScript.loadStreamList(SpiderScript.streamoffset + 10);
  },
  setStreamOffset: (offset) => {
    SpiderScript.streamoffset = offset;
  },

  saveContent: (newcontent, result, resutltype) => {
    const path = result?.path;
    const contenttype = result.contenttype;
    const request = {
      code: newcontent,
      contentid: path,
      contenttype: contenttype,
      tenant: SpiderScript.selectedTenant?.value,
    };

    ajax(
      "post",
      requestURI["terminal.save.command"],
      null,
      request,
      SpiderScript.renderSaveStream,
      showError,
      [result, resutltype]
    );
  },

  renderSaveStream: (response, args) => {
    // const contentname = args[0].contentid;
    let contenttype = args[0].contenttype;
    let resutltype = args[1][0];
    let reuired = args[1][1];
    // let actiontype = args[1][2
    if (resutltype === "new" && reuired === "required") {
      ToastPopup(
        t("spider.create.succesfirstmsg") +
          contenttype +
          t("spider.create.succeslastmsg")
      );
    } else if (resutltype === "old" && reuired === "required") {
      ToastPopup(
        t("spider.update.succesfirstmsg") +
          contenttype +
          t("spider.update.succeslastmsg")
      );
    }
    if (
      contenttype === "stream" &&
      reuired === "required" &&
      resutltype !== "oldadd"
    ) {
      console.log("im in first last if")
      setGlobalState("StreamsOptions", []);
      SpiderScript.setStreamOffset(0);
      setTimeout(() => {
        // let stream = getGlobalState("selectedStreams");
        SpiderScript.loadStreamList(0);
      }, 3000);
    }

    // let isstreamaddpathrequired = getGlobalState("isstreamaddpathrequired");

    if (
    
      contenttype !== "stream" && contenttype !== "fragment"
    ) {
      console.log("im in last if")
      setGlobalState("isEdit", false);
      setGlobalState("spidercontenteditable", true);
      SpiderScript.addnewcomponentpath(response.message);
      SpiderScript.handlestreamaction("addpath");
    }
    else if (
      contenttype !== "stream" && contenttype === "fragment"
    ) {
      setGlobalState("isEdit", false);
      setGlobalState("spidercontenteditable", true);
      // SpiderScript.addnewcomponentpath(response.message);
      SpiderScript.handlereportsaction("addpath");
    }
    else {
      
      setTimeout(() => {
        let stream = getGlobalState("selectedStreams");
        SpiderScript.loadStreamsComponent(stream);
      }, 3000);
    }
    setTimeout(() => {
      setGlobalState("spidersavebutton", false);
    }, 7000);
  },
  handlereportsaction:(action)=>{
    let command =
    "report --mode=edit --location=" +
    SpiderScript.streamreportglobal.output.location +
    " --type=script";
    const request = {
      command: command,
      tenant: SpiderScript.selectedTenant?.value,
    };
    ajax(
      "post",
      requestURI["terminal.command"],
      null,
      request,
      SpiderScript.renderreportaddapth,
      showError,
      [action]
    );
  },
  renderreportaddapth:(response,args)=>{
    let Streamcontentapicall = args[0];
    let newpath = null;
    // Regular expression to match the Python dictionary-like content
    let output = response.data.response;
    let receivedContent = output.content;
    let retrivedpath = SpiderScript.setcreatecontent.path;
    newpath = SpiderScript.extractLocation(retrivedpath);

const modifiedContent = SpiderScript.modifyFragments(receivedContent, Streamcontentapicall, newpath);
    console.log(modifiedContent)
    SpiderScript.saveContent(modifiedContent, output, [
      "old",
      "notrequired",
      "edit",
    ]);
  },

  modifyFragments:(content, action, newPathToRemove)=> {
    const fragmentsFunctionRegex = /def fragments\(\):(?:\s+return \[.*?\])/s;
    const fragmentsFunctionMatch = content.match(fragmentsFunctionRegex);
    
    if (!fragmentsFunctionMatch) {
        console.error("fragments function not found");
        return content;
    }

    let fragmentsFunction = fragmentsFunctionMatch[0];

    // Extract the existing fragments list
    const fragmentsListRegex = /\[.*?\]/s;
    const fragmentsListMatch = fragmentsFunction.match(fragmentsListRegex);

    if (!fragmentsListMatch) {
        console.error("fragments list not found");
        return content;
    }

    let fragmentsList = JSON.parse(fragmentsListMatch[0]);

    if (action === "addpath") {
        // Add the new path to the fragments list
        fragmentsList.push(newPathToRemove);
    } else if (action === "removepath") {
        // Remove the specified path from the fragments list
        fragmentsList = fragmentsList.filter(path => path !== newPathToRemove);
    } else {
        console.error("Invalid action:", action);
        return content;
    }

    // Replace the fragments list in the function
    fragmentsFunction = fragmentsFunction.replace(fragmentsListRegex, JSON.stringify(fragmentsList));

    // Replace the fragments function in the content
    return content.replace(fragmentsFunctionRegex, fragmentsFunction);
},
  clearstreamform: () => {
    setGlobalState("isEdit", false);
    // setGlobalState("selectedStreams", []);
    SpiderScript.clearfield();
  },

  clearfield: () => {
    SpiderScript.content=""
    document.getElementById("name").value = "";
    document.getElementById("description").value = "";
    document.getElementById("spiderstreammodal").innerText =
      " Create New Stream";
    document.getElementById("datasource").value =
      "http://integration-<provider>-<type>/execute?groups=<group-name>&<secret-1>=<$.value_of_secret-1>&<secret-2>=<$.value_of_secret-2>&<frequency=1d>";
  },

  handleClick: () => {
    const clickspider = document.getElementById("spidericon");

    if (clickspider) {
      if (clickspider.classList.contains("active")) {
        clickspider.classList.remove("active");
        const title = document.getElementById("bnfxHeaderTitle").innerText;
        const navlink = document.querySelector(
          "a.bxNavlistLink.row[title = '" + title + "']"
        );
        window.location = navlink.getAttribute("href");
      } else {
        clickspider.classList.add("active");
        // clickspider.style.color = "red";
      }
    }
  },

  loadStreamList: (offset) => {
    let freetext = CommonTemplate.getSearchText("spiderstream_search");
    const request = {
      limit: 10,
      offset: offset ? offset : 0,
      enabled: false,
      sortorder: false,
      sortcol: "lastupdated",
      type: "streams",
      rawContent: true,
      allContent: true,
      query: freetext,
    };
    ajax(
      "post",
      requestURI["content.list"],
      null,
      request,
      SpiderScript.renderStreamList,
      showError,
      [offset]
    );
  },

  renderStreamList: (response, args) => {
    let originalData = response.data.response;
    if (originalData.length > 0) {
      const stream = originalData.map((item) => ({
        value: item,
        label: (
          <div className="bg_stream">
            <span className="streamtitle">{item.title}</span>
            <span className="streamdescription">{item.description}</span>
          </div>
        ),
      }));
      if (args[0] === 0) {
        setGlobalState("StreamsOptions", stream);
      } else {
        setGlobalState("StreamsOptions", (prevUrlOptions) => [
          ...prevUrlOptions,
          ...stream,
        ]);
      }
    } else if (args[0] !== 0 && originalData.length === 0) {
      const selectAllOption = {
        value: "No More Streams",
        label: "No More Streams",
      };
      setGlobalState("StreamsOptions", (prevUrlOptions) => [
        ...prevUrlOptions,
        selectAllOption,
      ]);
    }
    if (args[0] === 0 && originalData.length === 0) {
      const selectAllOption = [
        {
          value: "No Options",
          label: "No Options",
        },
      ];
      setGlobalState("StreamsOptions", selectAllOption);
    }
  },
  loadStreamsComponent: (stream) => {
    const datasourceiconbutton = document.querySelector(".bxIcon_link");
    if (datasourceiconbutton) {
      datasourceiconbutton.className = "bxIcon_link";
    }
    const request = {
      command: "stream --mode=components --location=" + stream?.value?.location,
      tenant: SpiderScript.selectedTenant?.value,
    };

    ajax(
      "post",
      requestURI["terminal.command"],
      null,
      request,
      SpiderScript.loadexistingstreams,
      SpiderScript.nostreamData,
      [stream?.value]
    );
  },
  nostreamData: (error) => {
    setGlobalState("spiderreoladfreeze", false);
    const container = document.querySelector("#spiderdetailsection");
    const data = <DragElements intialnodes={null} initialedges={null} />;
    CommonTemplate.ContainerAvailability(container, data);

    setGlobalState("spiderreoladfreeze", false);
  },
  selectedContentStream: (stream) => {
    SpiderScript.selectedContent = stream;
  },
  setStreamUrl: (url) => {
    SpiderScript.streamselectedurl = url;
  },
  loadexistingstreams: (result, args) => {
    let stream = args[0];
    let content = result.data.response;
    SpiderScript.selectedContentStream(content);
    setGlobalState("spidercontent", content);
    const container1 = document.querySelector("#spiderdetailsection");
    if(container1){
    container1.innerHTML = "";}

    let initialNodes = [];
    let initialEdges = [];
    const filterData = content?.filters?.filters;
    const detectionsData = content?.detections?.detections;
    const transformationData = content?.transformations?.transformations;
    const dictionaryData = content?.dictionary?.dictionary;

    const automationsData = content?.automations?.automations;

    const reportsData = content?.reports?.reports;

    const sequencesData = content?.sequences?.sequences;
    const correlationData = content?.correlations?.correlations;
    const datasourceicon = content?.datasource;
    const streamurl = content?.url;
    SpiderScript.setStreamUrl(streamurl);
    const datasourceiconbutton = document.querySelector(".bxIcon_link");
    if (datasourceiconbutton) {
      datasourceiconbutton.classList.add(datasourceicon.toLowerCase());
    }

    const addNodes = (dataArray, label, source) => {
      if (dataArray?.length > 0 && label!=="report") {
        dataArray?.forEach((streamObj, index) => {
          const newIndex = `${label}-${index}`;
          let node = {
            id: newIndex,
            type: "streamnode",
            data: { label: label, value: newIndex, output: streamObj },
            value: streamObj,
          };

          initialNodes.push(node);
        });
      }
      else if (dataArray?.length > 0 && label==="report") {
      
        dataArray?.forEach((streamObj, index) => {
        
          const newIndex = `${label}-${index}`;
          let node = {
            id: newIndex,
            type: "reportnode",
            data: { label: label, value: newIndex, output: streamObj },
            value: streamObj,
          };

          initialNodes.push(node);


          let circlnode = {
            id: `${newIndex}-fragment`,
            type: "circlenode",
            data: { label: `${label}-${source}` },
          };
          initialNodes.push(circlnode);
          let  fragmentsData = streamObj.fragments.fragments;
          fragmentsData?.forEach((fragment, index) => {
            const newIndex = `fragment-${index}`;
            let fragmentnode = {
              id: newIndex,
              type: "streamnode",
              data: { label: "fragment", value: newIndex, output: fragment },
              value: fragment,
            };
  
            initialNodes.push(fragmentnode);
          });
        });
        
      } else {
        let newlabel = label.charAt(0).toUpperCase() + label.slice(1);
        const datasource = {
          id: `${label}-0`,
          type: "datasource",
          data: {
            label: `No ${newlabel}`,
            value: `No ${newlabel} for this stream`,
            icon: [null, label],
          },
        };
        initialNodes.push(datasource);
      }

      if (source) {
        let circlnode = {
          id: `${source}-${label}`,
          type: "circlenode",
          data: { label: `${label}-${source}` },
        };
        initialNodes.push(circlnode);
      }
    };
    if (dictionaryData) {
      addNodes(dictionaryData, "dictionary", null);
    } else {
      let node = {
        id: "dictionary-0",
        type: "datasource",
        data: {
          label: `No Dictionary`,
          value: `No Dictionary for this stream`,
          icon: [null, "Dictionary"],
        },
      };

      initialNodes.push(node);
    }

    addNodes(filterData, "filter", "dictionary");

    addNodes(transformationData, "transformation", "filter");

    addNodes(detectionsData, "detection", "transformation");

    addNodes(automationsData, "automation", "detection");

    addNodes(sequencesData, "sequence", "automation");

    addNodes(correlationData, "correlation", "sequence");

    addNodes(reportsData, "report", "correlation");

    const addEdges = (dataArray, perviousnode, source, nextnode) => {
      let edge2 = null;
      let edge1 = null;
      if (dataArray?.length > 0 && source!=="report") {
        dataArray?.forEach((streamObj, index) => {
          // const newIndex = `${perviousnode}-${index}`;
          const cicleindex = `${perviousnode}-${source}`;
          const sourceindex = `${source}-${index}`;
          const targetindex = `${source}-${nextnode}`;
          edge1 = {
            id: `e1-${sourceindex}`,
            source: sourceindex,
            target: targetindex,
            targetHandle: "a",
            animated: false,
            style: { stroke: "#fff" },
            type: "default",
          };
          edge2 = {
            id: `e2-${sourceindex}`,
            source: cicleindex,
            target: sourceindex,
            targetHandle: "d",
            animated: false,
            style: { stroke: "#fff" },
            type: "default",
          };
          initialEdges.push(edge2);
          initialEdges.push(edge1);
        });
      } 
      else if(dataArray?.length > 0 && source==="report"){
        dataArray?.forEach((streamObj, index) => {
          // const newIndex = `${perviousnode}-${index}`;
          const cicleindex = `${perviousnode}-${source}`;
          const sourceindex = `${source}-${index}`;
          const targetindex = `${sourceindex}-${nextnode}`;
          edge1 = {
            id: `e1-${sourceindex}`,
            source: sourceindex,
            target: targetindex,
            targetHandle: "a",
            animated: false,
            style: { stroke: "#fff" },
            type: "default",
          };
          edge2 = {
            id: `e2-${sourceindex}`,
            source: cicleindex,
            target: sourceindex,
            targetHandle: "d",
            animated: false,
            style: { stroke: "#fff" },
            type: "default",
          };
          initialEdges.push(edge2);
          initialEdges.push(edge1);

          let  fragmentsData = streamObj.fragments.fragments;
          fragmentsData?.forEach((fragment, index) => {
            const newIndex = `fragment-${index}`;
            let edge3 = {
              id: `e3-${newIndex}`,
              source: targetindex,
              target: newIndex,
              targetHandle: "d",
              animated: false,
              style: { stroke: "#fff" },
              type: "default",
            };
  
            initialEdges.push(edge3);
          });
        });

      }else {
        edge1 = {
          id: `e1-${`${source}-0`}`,
          source: `${source}-0`,
          target: `${source}-${nextnode}`,
          targetHandle: "a",
          animated: false,
          style: { stroke: "#fff" },
          type: "default",
        };
        edge2 = {
          id: `e2-${`${source}-0`}`,
          source: `${perviousnode}-${source}`,
          target: `${source}-0`,
          targetHandle: "d",
          animated: false,
          style: { stroke: "#fff" },
          type: "default",
        };
        initialEdges.push(edge2);
        initialEdges.push(edge1);
      }
    };

    // addEdges(dictionaryData, "datasource",null);
    addEdges(filterData, "dictionary", "filter", "transformation");

    addEdges(transformationData, "filter", "transformation", "detection");

    addEdges(detectionsData, "transformation", "detection", "automation");

    addEdges(automationsData, "detection", "automation", "sequence");

    addEdges(sequencesData, "automation", "sequence", "correlation");
    addEdges(correlationData, "sequence", "correlation", "report");
    addEdges(reportsData, "correlation", "report","fragment");
    const datasource = {
      id: "datasource",
      type: "datasource",
      data: {
        label: stream?.title,
        value: stream?.description,
        icon: [datasourceicon, "stream"],
      },
    };
    const datasourceedge = {
      id: `e1-datasource`,
      source: "datasource",
      target: "dictionary-0",
      targetHandle: "d",
      animated: false,
      style: { stroke: "#fff" },
      type: "default",
    };
    const datasourceedge2 = {
      id: `e1-dictionary`,
      source: "dictionary-0",
      target: "dictionary-filter",
      targetHandle: "d",
      animated: false,
      style: { stroke: "#fff" },
      type: "default",
    };
    initialNodes.push(datasource);
    initialEdges.push(datasourceedge);
    initialEdges.push(datasourceedge2);
    const container = document.querySelector("#spiderdetailsection");
    const data = (
      <DragElements intialnodes={initialNodes} initialedges={initialEdges} />
    );
    CommonTemplate.ContainerAvailability(container, data);
    setGlobalState("spiderreoladfreeze", false);
  },
  setselectedNodeLabel: (node) => {
    SpiderScript.selectedNodeLabel = node;
  },
  setcontentalreadyexist: (type) => {
    SpiderScript.contentalreadyexist = type;
  },
  fillupdetails: (selectedNodeLabel) => {
    SpiderScript.clearfield();
    setGlobalState("spiderExistingContent", []);
    SpiderScript.setcontentalreadyexist(true);
    setGlobalState("isEdit", true);
    // setGlobalState("spidertestClicked", false);
    if (document.querySelector("#spidercontent")) {
      document.querySelector("#spidercontent").innerHTML = "";
    }

    let value = selectedNodeLabel?.value;
    SpiderScript.editablecontent = [];
    if (value) {
      SpiderScript.setselectedNodeLabel([
        selectedNodeLabel.data.label,
        selectedNodeLabel.data.output.location,
      ]);
      const showpythoncode = document.querySelector(
        ".Custom_rightArea_spiderLayout"
      );
      if (showpythoncode) {
        showpythoncode.classList.remove("d-none");
      }
      const showbutton = document.querySelector(".spiderContentButton");
      if (showbutton) {
        showbutton.classList.remove("d-none");
      }

      setGlobalState("spidercontenteditable", true);
      SpiderScript.viewcontent("meta", null);
      if (document.getElementById("loading-icon")) {
        document.getElementById("loading-icon").style.display = "block";
      }
      SpiderScript.viewcontent("script", null);
    } else {
      setGlobalState("spidercontenteditable", false);
      SpiderScript.selectedinputname=""
      document.getElementById("spider_content_name").value = "";
      document.getElementById("spider_content_description").value = "";
      const data = <ContentOutput output={null} />;

      const container = document.querySelector("#spidercontent");
      CommonTemplate.ContainerAvailability(container, data);
    }
  },
  viewcontent: (type, updatestream) => {
    // setGlobalState("isstreamaddpathrequired", false);
    let command = null;
    command =
      SpiderScript.selectedNodeLabel[0] +
      " --mode=edit --location=" +
      SpiderScript.selectedNodeLabel[1] +
      " --type=" +
      type;
    const request = {
      command: command,
      tenant: SpiderScript.selectedTenant?.value,
    };

    ajax(
      "post",
      requestURI["terminal.command"],
      null,
      request,
      SpiderScript.renderView,
      SpiderScript.showErrorMessage,
      [type, updatestream]
    );
  },

  showErrorMessage: (error) => {
    if (error) {
      // ShowErrorToastPopup()
      const container = document.querySelector("#spidercontent");
      const data = (
        <NoContent
          id={"nospiderdetailcode"}
          title={t("No data")}
          description={t("No data found.")}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  renderView: (response, args) => {
    let type = args[0];
    let saveconetnt = args[1];
    if (type === "script") {
      if (document.getElementById("loading-icon")) {
        document.getElementById("loading-icon").style.display = "none";
      }
      const outputmap = response.data.response;
      SpiderScript.editablecontent["script"] = outputmap;
      const data = <ContentOutput output={outputmap} />;

      const container = document.querySelector("#spidercontent");
      CommonTemplate.ContainerAvailability(container, data);
    } else if (type === "meta") {
      const outputmap = response.data.response;
      SpiderScript.editablecontent["meta"] = outputmap;
      const { title, description } = outputmap.content
        .split("\n")
        .map((line) => line.split("="))
        .reduce((acc, [key, value]) => {
          acc[key?.trim()] = value?.trim();
          return acc;
        }, {});
      const nameElement = document.getElementById("spider_content_name");
      if (nameElement) nameElement.value = title;
      const descriptionElement = document.getElementById(
        "spider_content_description"
      );
      if (descriptionElement) descriptionElement.value = description;
    }
    if (saveconetnt === "savepath") {
      SpiderScript.setCreateContent(response.data.response);
    }
  },

  draftedContentPublish: () => {
    // let content = SpiderScript.selectedContent;
    // console.log(content);
    // for (const key in content) {
    //   if (content.hasOwnProperty(key) && Array.isArray(content[key])) {
    //     content[key].forEach((item) => {
    //       // Publish content
    //       if (item.draft === true) {
    //         SpiderScript.publishContent(item.name, item.location);
    //       }
    //     });
    //   }
    // }

    let stream = getGlobalState("selectedStreams");
    let content = SpiderScript.selectedContent;
    const dictionaryCount = content?.dictionary?.count;
    const detectionsCount = content?.detections?.count;
    const correlationsCount = content?.correlation?.count;
    if (dictionaryCount > 0 && (detectionsCount > 0 || correlationsCount > 0)) {
      SpiderScript.publishContent("stream", stream.value.name);
    } else {
      ShowErrorToastPopup(
        "Created Stream should contain atleast one Dictionary/Detection"
      );
    }
  },

  publishContent: (contenttype, contentname) => {
    let publishcommand = contenttype + " --mode=publish --name= " + contentname;

    const request = {
      command: publishcommand,
      tenant: SpiderScript.selectedTenant?.value,
    };

    ajax(
      "post",
      requestURI["terminal.command"],
      null,
      request,
      SpiderScript.renderPublish,
      showError
    );
  },
  renderPublish: (response) => {
    setGlobalState("isEdit", false);
    ToastPopup(t("spider.stream.publish"));
    setTimeout(() => {
      let stream = getGlobalState("selectedStreams");
      SpiderScript.loadStreamsComponent(stream);
    }, 3000);
  },
  handleedit: (stream) => {
    document.getElementById("name").value = stream.title;
    document.getElementById("description").value = stream.description;
    document.getElementById("datasource").value =
      SpiderScript.streamselectedurl;
    document.getElementById("spiderstreammodal").innerText = "Edit Stream";
    setGlobalState("isEdit", true);
    setGlobalState("spidercontenteditable", false);
  },

  addnewcomponentpath: (data) => {
    SpiderScript.newcomponent = data;
  },

  extractLocation: (message) => {
    // Regular expression to match the desired part

    const regex = /packages\/(.*?)\/script\.py/;

    // Extracting the matched part
    let match = message.match(regex);
    let extractedPart = null;

    // Check if there's a match
    if (match) {
      // Extracted part
      extractedPart = match[1] + "/";
    } else {
      console.log("No match found.");
    }
    return extractedPart;
  },

  deleteNode: (params) => {
    setGlobalState("isEdit", false);
    Swal.fire({
      title: t("delete.title.confirmation"),
      text: t("delete.description.confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("stream.delete.yes"),
    }).then((result) => {
      if (result.isConfirmed) {
        SpiderScript.removeContent(params);
      }
    });
  },
  removeContent: (parameters) => {
    setGlobalState("spidersavebutton", true);
    let stream = getGlobalState("selectedStreams");
    let request = {
        command: parameters[0]
          ? parameters[1] + " --mode=remove --location=" + parameters[0]?.location+" --from="+stream.value.location
          : parameters[1] + " --mode=remove --location=" + stream.value.location,
        tenant: SpiderScript.selectedTenant?.value
      };

    ajax(
      "post",
      requestURI["terminal.command"],
      null,
      request,
      SpiderScript.renderremovecontent,
      showError,
      [parameters]
    );
  },
  renderremovecontent: (response, args) => {
    if (args[0][1] !== "stream" && args[0][1] !== "fragment") {
      SpiderScript.setselectedNodeLabel([args[0][1], null]);
      SpiderScript.setCreateContent(args[0]?.name);
      SpiderScript.addnewcomponentpath(args[0]);
      setGlobalState("isEdit", false);
      setGlobalState("spidercontenteditable", true);
      SpiderScript.handlestreamaction("removepath");
    }
    else if(args[0][1] !== "stream" && args[0][1] === "fragment"){
      SpiderScript.setCreateContent(args[0]?.name);

      SpiderScript.handlereportsaction("removepath");
    }

    let str = args[0][1];
    let capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
    ToastPopup(capitalizedStr + " removed successfully .");
    
        if (args[0][1] === "stream") {
      setGlobalState("StreamsOptions", []);
      SpiderScript.setStreamOffset(0);
      setTimeout(() => {
        SpiderScript.loadStreamList(0);
        setGlobalState("spidersavebutton", false);
      }, 7000);
    }
  },
  deleteStreamConfirmation: () => {
    Swal.fire({
      title: t("delete.title.confirmation"),
      text: t("delete.description.confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("stream.delete.yes"),
    }).then((result) => {
      if (result.isConfirmed) {
        SpiderScript.removeContent([null, "stream"]);
      }
    });
  },

  clickTestButton: () => {
    if (SpiderScript.content) {
      const request = { code: SpiderScript.content };

      ajax(
        "post",
        requestURI["compile.command"],
        null,
        request,
        SpiderScript.renderCompileContent,
        SpiderScript.renderCompileContent
      );
    } else {
      ShowErrorToastPopup(
        "No code found, Please insert some code to compile",
        "BOTTOM_CENTER"
      );
    }
  },
  renderCompileContent: (response) => {
    const result = response.data.response.message;
    if (result === t("successfully.compiled")) {
      setGlobalState("spidertestClicked", true);
      ToastPopup(result);
    } else {
      ShowErrorToastPopup(result, "BOTTOM_CENTER");
    }
  },

  handleToggleCollapse: () => {
    const collapsed = getGlobalState(
      "global_spider_detail_right_panel_collapsed"
    );
    setGlobalState("global_spider_detail_right_panel_collapsed", !collapsed);
  },

  handlestreamaction: (actiononstreamscript) => {
    let stream = getGlobalState("selectedStreams");
    const removebutton = document.querySelector(".modal.fade");
    if (removebutton) {
      removebutton.classList.remove("show");
    }
    setGlobalState("spidersavebutton", true);
    let spidercontenteditable = getGlobalState("spidercontenteditable");
    if (spidercontenteditable) {
      let command =
        "stream --mode=edit --location=" +
        stream.value.location +
        " --type=script";
      let parameters = { datasource: null, type: "script" };
      SpiderScript.Streamcontentapicall(
        command,
        parameters,
        actiononstreamscript
      );
    } else {
      let Name = document.getElementById("name").value;
      let Description = document.getElementById("description").value;
      let datasource = document?.getElementById("datasource")?.value;
      let isEdit = getGlobalState("isEdit");
      if (isEdit) {
        let command1 =
          "stream --mode=edit --location=" +
          stream.value.location +
          " --type= meta";
        let command2 =
          "stream --mode=edit --location=" +
          stream.value.location +
          " --type=script";
        let parameters1 = { datasource: datasource, type: "meta" };
        let parameters2 = { datasource: datasource, type: "script" };
        SpiderScript.Streamcontentapicall(
          command1,
          parameters1,
          actiononstreamscript
        );
        SpiderScript.Streamcontentapicall(
          command2,
          parameters2,
          actiononstreamscript
        );
      } else {
        const command =
          "stream --mode=create --name=" +
          Name +
          " --description=" +
          Description;
        let parameters = { datasource: datasource, type: null };
        SpiderScript.Streamcontentapicall(
          command,
          parameters,
          actiononstreamscript
        );
      }
    }
  },
  renderhandlestreamaction: (response, args) => {
    const parameters = args[0];
    let result = response.data.response;
    const content = response.data.response.content;

    let newcontent = null;
    if (parameters?.content) {
      newcontent = parameters?.content;
    }
    const newUrl = parameters?.datasource;

    newcontent = content.replace(
      /def use\(\):\s*return\s*'(.*?)'/,
      `def use():\n   return '${newUrl}'`
    );

    SpiderScript.saveContent(newcontent, result, ["new", "required", "create"]);
  },
  Streamcontentapicall: (command, parameters, Streamcontentapicall) => {
    let isEdit = getGlobalState("isEdit");
    let spidercontenteditable = getGlobalState("spidercontenteditable");

    const request = {
      command: command,
      tenant: SpiderScript.selectedTenant?.value,
    };
    ajax(
      "post",
      requestURI["terminal.command"],
      null,
      request,
      isEdit
        ? SpiderScript.renderhandlestreamedit
        : spidercontenteditable
        ? SpiderScript.renderhandlestreamaddpath
        : SpiderScript.renderhandlestreamaction,
      showError,
      [parameters, Streamcontentapicall]
    );
  },
  renderhandlestreamedit: (response, args) => {
    let output = response.data.response;
    let receivedContent = output.content;
    let reuiredpopup = null;
    let Name = document.getElementById("name").value;
    let Description = document.getElementById("description").value;
    let datasource = document?.getElementById("datasource")?.value;
    let paramters = args[0];

    if (paramters["type"] === "script") {
      receivedContent = receivedContent.replace(
        /def use\(\):\s*return\s*'(.*?)'/,
        `def use():\n   return '${datasource}'`
      );
      reuiredpopup = "notrequired";
    } else if (paramters["type"] === "meta") {
      receivedContent = `title=${Name}\ndescription=${Description}`;
      reuiredpopup = "required";
    }
    SpiderScript.saveContent(receivedContent, output, [
      "old",
      reuiredpopup,
      "edit",
    ]);
  },

  alreadyaddingexsitingcontent: (type) => {
    SpiderScript.alreadyaddingcontent = type;
  },
  handlecontentaction: (contenttype, params, action) => {
    let Name = document.getElementById(params.name).value;
    let Description = document.getElementById(params.description).value;
    let content = params?.content;
    // let isEdit = getGlobalState("isEdit");
    if (action === "editcontent") {
      // let stream = getGlobalState("selectedStreams");
      if (SpiderScript.contentalreadyexist === true) {
        let metacode = `
      title = ${Name}\n
      description = ${Description}\n
      `;
        const metaresult = SpiderScript.editablecontent["meta"];
        SpiderScript.saveContent(metacode, metaresult, [
          "old",
          "required",
          "edit",
        ]);

        const newcode = content
          ? content
          : SpiderScript.editablecontent["script"]["content"];
        const coderesult = SpiderScript.editablecontent["script"];
        SpiderScript.saveContent(newcode, coderesult, [
          "old",
          "notrequired",
          "edit",
        ]);
      } else {
        SpiderScript.alreadyaddingexsitingcontent("alreadyexist");
        let receivedContent = content;
        SpiderScript.saveContent(
          receivedContent,
          SpiderScript.setcreatecontent,
          ["new", "required", "edit"]
        );
      }
    } else {
      SpiderScript.alreadyaddingexsitingcontent("alreadyexist");
      const command =
        contenttype +
        " --mode=create --name=" +
        Name +
        " --description=" +
        Description;
      // let parameters = { datasource: datasource, type: null };
      setGlobalState("isEdit", true);
      SpiderScript.contentapicall(command);
    }
  },
  contentapicall: (command) => {
    const request = {
      command: command,
      tenant: SpiderScript.selectedTenant?.value,
    };
    ajax(
      "post",
      requestURI["terminal.command"],
      null,
      request,
      SpiderScript.renderhandlecontentaction,
      SpiderScript.showErrorMessage
    );
  },
  renderhandlecontentaction: (response) => {
    let result = response.data.response;
    const showpythoncode = document.querySelector(
      ".Custom_rightArea_spiderLayout"
    );
    if (showpythoncode) {
      showpythoncode.classList.remove("d-none");
    }
    const showbutton = document.querySelector(".spiderContentButton");
    if (showbutton) {
      setGlobalState("isEdit", true);
      showbutton.classList.remove("d-none");
    }
    const removebutton = document.querySelector(
      ".SpiderCustomBtn.saveBtn.button"
    );
    if (removebutton) {
      removebutton.classList.add("d-none");
    }

    const removecancelbutton = document.querySelector(
      ".SpiderCustomBtn.cancelBtn.button"
    );
    if (removecancelbutton) {
      removecancelbutton.classList.add("d-none");
    }

    document.getElementById("spiderloading-icon").style.display = "none";
    const container = document.querySelector("#spidercontent");
    const data = <ContentOutput output={result} />;
    CommonTemplate.ContainerAvailability(container, data);
    SpiderScript.setCreateContent(result);
  },

  setCreateContent: (content) => {
    SpiderScript.setcreatecontent = content;
  },
  renderhandlestreamaddpath: (response, args) => {
    let Streamcontentapicall = args[1];
    let key = SpiderScript.selectedNodeLabel[0];
    let newpath = null;
    // Regular expression to match the Python dictionary-like content
    let output = response.data.response;
    let receivedContent = output.content;
    const contentWithoutComments = receivedContent.replace(/#.*/g, "");

    const pythonDictRegex = /\{([^}]*)\}/;

    // Extracting the Python dictionary-like content from the received content
    const match = contentWithoutComments.match(pythonDictRegex);
    const dictionaryContent = match ? match[0] : "";

    // Parsing the dictionary-like content

    let parsedContent;
    try {
      parsedContent = JSON.parse(dictionaryContent.replace(/'/g, '"'));
    } catch (error) {
      console.error("Error parsing Python-like dictionary:", error);
    }
    if (Streamcontentapicall === "addpath") {
      let retrivedpath = SpiderScript.setcreatecontent.path;
      newpath = SpiderScript.extractLocation(retrivedpath);
      if (parsedContent) {
        if (parsedContent.hasOwnProperty(key + "s")) {
          if (!parsedContent[key + "s"].includes(newpath)) {
            parsedContent[key + "s"].push(newpath);
          } else {
            console.log("Path already exists:", newpath);
            ShowErrorToastPopup(
              "This " + key + " already exist. Please add another " + key
            );
          }
        } else {
          // Key doesn't exist, create a new entry
          const newKey = key === "dictionary" ? key : key + "s";
          if (key === "dictionary" && parsedContent[key]) {
            console.log("Dictionary already exists:", parsedContent[key]);
          } else {
            parsedContent[newKey] = key === "dictionary" ? newpath : [newpath];
          }
        }
      } else {
        console.log("parsedContent is not valid.");
      }
    } else if (Streamcontentapicall === "removepath") {
      newpath = SpiderScript.newcomponent[0]?.name;
      if (parsedContent) {
        for (const key in parsedContent) {
          if (Array.isArray(parsedContent[key])) {
            parsedContent[key] = parsedContent[key].filter((path) => {
              const segments = path.split("/");
              const lastSegment = segments[segments.length - 2]; // Get the term before the last '/'
              return lastSegment !== newpath;
            });
          } else if (typeof parsedContent[key] === "string") {
            const segments = parsedContent[key].split("/");
            const lastSegment = segments[segments.length - 2]; // Get the term before the last '/'
            if (lastSegment === newpath) {
              parsedContent[key] = "";
            }
          }

          // Remove the key if its value is empty
          if (!parsedContent[key]) {
            delete parsedContent[key];
          }
        }
      } else {
        console.log("parsedContent is not valid.");
      }
    }

    receivedContent = receivedContent.replace(
      pythonDictRegex,
      JSON.stringify(parsedContent)
    );
    SpiderScript.saveContent(receivedContent, output, [
      "old",
      "notrequired",
      "edit",
    ]);
  },
  listExistingContent: (freetext, name,offset) => {
    let refinedtype = null;
    if (name === "dictionary") {
      refinedtype = "dictionaries";
    } else if (name === "filter") {
      refinedtype = "filters";
    } else if (name === "detection") {
      refinedtype = "detections";
    } else if (name === "transformation") {
      refinedtype = "transformations";
    } else if (name === "correlation") {
      refinedtype = "correlations";
    } else if (name === "sequence") {
      refinedtype = "sequences";
    } else if (name === "automation") {
      refinedtype = "automations";
    } else if (name === "report") {
      refinedtype = "reports";
    }
    else if (name === "fragment") {
      refinedtype = "fragments";
    }
    const request = {
      limit: 10,
      offset: offset?offset:0,
      enabled: false,
      sortorder: true,
      sortcol: "lastupdated",
      type: refinedtype,
      rawContent: true,
      allContent: true,
      query: freetext,
    };
    ajax(
      "post",
      requestURI["content.list"],
      null,
      request,
      SpiderScript.renderExistingContent,
      showError,
      [offset]
    );
  },
  renderExistingContent: (response,args) => {
    let originalData = response.data.response;
    const stream = originalData.map((item) => ({
      value: item,
      label: (
        <div className="bg_stream">
          <span className="streamtitle">{item.title}</span>
          <span className="streamdescription">{item.description}</span>
        </div>
      ),
    }));
    if(args[0]===0){
      setGlobalState("spiderExistingContent", stream);
    }else{
    setGlobalState("spiderExistingContent", (prevUrlOptions) => [
      ...prevUrlOptions,
      ...stream,
    ]);}
  },
  loadselectedcontent: (option, label) => {
    setGlobalState("spiderExistingContent", []);
    SpiderScript.setcontentalreadyexist(true);
    setGlobalState("isEdit", true);
    // setGlobalState("spidertestClicked", false);
    if (document.querySelector("#spidercontent")) {
      document.querySelector("#spidercontent").innerHTML = "";
    }
    SpiderScript.editablecontent = [];
    SpiderScript.setselectedNodeLabel([label, option]);
    const showpythoncode = document.querySelector(
      ".Custom_rightArea_spiderLayout"
    );
    if (showpythoncode) {
      showpythoncode.classList.remove("d-none");
    }
    const showbutton = document.querySelector(".spiderContentButton");
    if (showbutton) {
      showbutton.classList.remove("d-none");
    }

    setGlobalState("spidercontenteditable", true);
    SpiderScript.viewcontent("meta", null);
    if (document.getElementById("loading-icon")) {
      document.getElementById("loading-icon").style.display = "block";
    }
    SpiderScript.viewcontent("script", "savepath");
  },

  closeeditstreammodal: () => {
    const removebutton = document.querySelector(".modal.fade");
    if (removebutton) {
      removebutton.classList.remove("show");
    }
  },
  StreamSearch: (id) => {
    const freetext = document.querySelector("#" + id).value;

    if (freetext.length > 3 || freetext.length === 0) {
      if (SpiderScript.searchtimeout && SpiderScript.searchtimeout.length > 0) {
        for (
          let index = 0;
          index < SpiderScript.searchtimeout.length;
          index++
        ) {
          window.clearTimeout(SpiderScript.searchtimeout[index]);
        }
      }

      SpiderScript.searchtimeout.push(
        setTimeout(() => {
          SpiderScript.loadStreamList(0);
        }, 2000)
      );
    }
  },
  loadmorecontent: (freetext, data) => {
    SpiderScript.setContentOffset(SpiderScript.contentoffset + 10);
    SpiderScript.listExistingContent(
      freetext,
      data,
      SpiderScript.contentoffset + 10
    );
  },
  setContentOffset: (offset) => {
    SpiderScript.contentoffset = offset;
  },
};
