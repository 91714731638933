import React, { useLayoutEffect } from "react";
import Stream from "./pages/stream/Stream";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { requestURI } from "./components/common/RequestURIs";
import { ajax } from "./components/common/Service";
import {
  useGlobalState,
  setGlobalState,
} from "./components/common/GlobalVariables";
import LoginTemplate from "./pages/login/LoginTemplate";
import Header from "./components/common/mainHeader/Header";
import Footer from "./components/common/mainFooter/Footer";
import Dashboard from "./pages/dashboard/Dashboard";
import ErrorDisplay from "./components/error/ErrorDisplay";
import { Initialize } from "./components/common/Initialize";
import Organization from "./pages/organization/Organization";
import { Login } from "./pages/login/LoginScript";
import SearchPage from "./pages/search/SearchPage";
import TerminalPage from "./pages/terminal/TerminalPage";
import SignupTemplate from "./pages/signup/SignupTemplate";
import ConfirmationPage from "./pages/customeronboarding/ConfirmationPage";
import OnboardPage from "./pages/customeronboarding/OnboardPage";
import VerificationLayout from "./pages/customeronboarding/VerificationLayout";
import AlreadyVerifyPage from "./pages/customeronboarding/AlreadyVerifyPage";
import SpiderPage from "./pages/spider/SpiderPage";
import Health from "./pages/health/Health";
import Risk from "./pages/risk/Risk";
import ContentTemplate from "./pages/content/Content";


function App() {




  /*This hook manages a global state value called loggedIn .It return current value of loggedIn & 
  function setLoggedIn to update its value.*/
  const [loggedIn, setLoggedIn] = useGlobalState("loggedIn");


  useLayoutEffect(() => {
    //check local token or something
    return () => tokenvalidation();
  });

  const tokenvalidation = () => {
    validateToken();
    Initialize.onready();
    setGlobalState("bnfx_default_uri", '/' + routes[0].toLowerCase())
  }


  /*That will be executed when the API call to validate the token is successful.It update the loggedIn 
  state using setGlobalState function with the value obtain from API response.*/
  const landingPage = (result) => {
    setGlobalState("loggedIn", result.data.response.remark);
  };

  /*Display error message on console.*/
  const errorLogin = (error) => {
    setGlobalState("loggedIn", false);
    console.log(error);
  };

  const routes = ["Stream","Dashboard", "Organization","SearchPage","TerminalPage","SpiderPage","Health","Risk","Content"];
  const routefunctions = [<Stream/>, <Dashboard/>, <Organization/>,<SearchPage/>, <TerminalPage/>,<SpiderPage/>,<Health/>,<Risk/>,<ContentTemplate/>];

  /*This function which check if their is token store in localStorage.If token exists it creates header
  & make the API call using ajax function.The API endpoints obtains from requestURI["token.verify"].
  If there is no token it set the loggedIn state is false.*/
  const validateToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const headers = {
        Authorization: `${token}`,
      };

      ajax(
        "get",
        requestURI["token.verify"],
        headers,
        null,
        landingPage,
        errorLogin
      );
    } else {
      setLoggedIn(false)
      Login.removeToken();
    }
    
  };

  

  /*Inside return method write code for rendering .If loggedIn is true then render to Stream page otherwise 
  display LoginTemplate*/
  return (
    <>
      <div id="wrapper"  className={loggedIn ? "" : "bxLogin"}>
        <div className="container-fulid" id="body-container">
          <ErrorDisplay />
          <BrowserRouter>
            {loggedIn && <Header />}

            <Routes>
              
              
              {routes.map(function (route, i) {
                return (
                  <Route key={i}
                    path={"/" + route.toLowerCase()}
                    element={loggedIn ? routefunctions[i] : <LoginTemplate />}
                  />
                );
              })}
              <Route
                path="/"
                element={loggedIn ? routefunctions[0] : <LoginTemplate />}
              />
              <Route
                  path="/confirmationpage"
                  element={ <ConfirmationPage />}
              />
              <Route
                  path="/errorpage"
                  element={ <AlreadyVerifyPage />}
              />
              <Route
                  path="/verification"
                  element={ <VerificationLayout />}
              />
              <Route
                  path="/onboardpage"
                  element={ <OnboardPage />}
              />
              <Route
                  path="/signup"
                  element={ <SignupTemplate />}
              />
            </Routes>
            
          </BrowserRouter>
        </div>

      </div>
      {loggedIn && <Footer />}

    </>
  );
}

export default App;
