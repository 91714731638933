function DownloadsLayout() {
  return (
    <>
      {/* Offcanvas for data collectors */}
      <div
        className="offcanvas offcanvas-end dataCollectors_downloadPanel"
        tabIndex="-1"
        id="offcanvas-downloads-content"
        aria-labelledby="offcanvasRightLabel"
        data-bs-backdrop={true}
      >
        <div id="bnfx-download-panel"></div>
      </div>
    </>
  );
}

export default DownloadsLayout;
