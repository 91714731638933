import Highcharts from "highcharts";
import { useEffect, useLayoutEffect, useRef } from "react";
import HighchartsData from "highcharts/modules/data";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsTreeChart from "highcharts/modules/treemap";

HighchartsData(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsTreeChart(Highcharts);
HighchartsExporting(Highcharts);

const idToColorMap = new Map();
const colors = [
  "#00876c",
  "#3d9c73",
  "#63b179",
  "#88c580",
  "#aed987",
  "#d6ec91",
  "#ffff9d",
  "#fee17e",
  "#fcc267",
  "#f7a258",
  "#ef8250",
  "#e4604e",
  "#d43d51",
];

const TreeMap = (props) => {
  const chartContainer = useRef(null);  
  const container = props.container;
  const widgetid=props.id;
  const column=props.column;
  const label=props.label;
  const onclick_param=props.onclick_param;
  const columnmap=props.columnmap;
  
  const handleNameClick = (name,point) => {
    let dataset = { name: name, column: column, label: label ,data:props.uniquekey,point:point,columnmap:columnmap};

    props.onClickFn(dataset, widgetid);
  };

  
  // let data=[
  //   {"name": "10.10.102.67","value": 1.433088E7},
  //   {"name": "10.10.100.121","value": 1.0859364E7},
  //   {"name": "10.10.102.124","value": 7262509.0},
  //   {"name": "10.10.102.173","value": 4677487.0},
  //   {"name": "10.10.102.99","value": 2402142.0},
  //   {"name": "10.10.5.115","value": 2116659.0},
  //   {"name": "10.10.100.107","value": 1761790.0},]

  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  props.series?.forEach((item) => {
    if (!idToColorMap.has(item.name)) {
      idToColorMap.set(item.name, colors[idToColorMap.size]);
    }
    item.color = idToColorMap.get(item.name);
  });

  useLayoutEffect(() => {
    let height = null;
    if (chartContainer.current) {
      const parent = container.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );
      if (parent) {
        height = parseInt(document.getElementById(parent)?.clientHeight) - 120;
      }

      Highcharts.chart(chartContainer.current, {
        chart: {
          height: height + "px",
          events: {
            load: function () {
              if (onclick_param === 'open_offcanvaspanel') {
              this.series[0].points.forEach((point) => {
                if (point.graphic && point.graphic.element) {
                  point.graphic.element.addEventListener("mousedown", () => {
                    point.graphic.element.setAttribute(
                      "data-bs-toggle",
                      "offcanvas"
                    );
                    point.graphic.element.setAttribute(
                      "data-bs-target",
                      "#bxOffcanvasPanel"
                    );
                    handleNameClick(point.name,point);
                  });
                }
              })};
            },
          },
        },
        credits: {
          enabled: false,
        },
        accessibility: {
          screenReaderSection: {
            beforeChartFormat:
              '<{headingTagName}>{"chartTitle"}</{headingTagName}><div>{typeDescription}</div><div>{chartSubtitle}</div><div>{chartLongdesc}</div>',
          },
        },
        series: [
          {
            type: "treemap",
            layoutAlgorithm: "squarified",
            allowDrillToNode: true,
            dataLabels: {
              enabled: false,
            },
            levels: [
              {
                borderWidth: 0,
                level: 1,
                dataLabels: {
                  enabled: true,
                  style: {
                    textOutline: false,
                  },
                },
              },
            ],
            data: props.series,
          },
        ],
        title: {
          text: "",
        },
        tooltip: {
          useHTML: true,
          pointFormat:
            " {point.name} : <b>{point.value}</b>",
        },
      });

    }
  }, [props.series, container]);

 
  return <div ref={chartContainer} id={container} />;
};

export default TreeMap;
