import { useEffect, useRef } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import IncidenceActivitesChat from "./IncidenceActivitiesChat";
import { Incidence } from "./IncidenceScript";




function IncidenceActivites(props) {

  let result = props.data
  let newoffset = null
  const editorRef = useRef(null);

  useEffect(() => {
    let chatconatinerheight = document.querySelector(".bxTabHeight.bxChat .tl");

    if (chatconatinerheight) {
    }
  });
  const setcommentoffset = (offset) => {
    newoffset = offset
  };
  const handleFormSubmit = (e) => {

    const editor = editorRef.current.getEditor();
    Incidence.loadcommentsubmit(Incidence.selectedCommentHtm, props.id)
    editor.setText('');
    e.preventDefault();

  };

  const handleChange = (html) => {
    Incidence.selectedCommentHtm = html
  }

  const paginationscroll = event => {
    let chatconatinerheight = document.querySelector(".bxTabHeight.bxChat .tl");
    if (chatconatinerheight.scrollTop === 0) {

      if (props.total) {
        setcommentoffset(newoffset + 5);

      }
      Incidence.loadactivites(props.id, newoffset)
    }
  };
  return (
    <div
      className="tab-pane fade show"
      id="activities-tab-pane"
      role="tabpanel"
      aria-labelledby="activities-tab"
      tabIndex="0"
    >
      <div className="bxTabHeight bxChat" id="activitescommentsection">
        <div className="tl" onScroll={paginationscroll}>
          <div className="bnfx_incident_activities_pagination"></div>
          <div className="bnfx_incident_activities_pagination">
            <IncidenceActivitesChat data={result} />
          </div>
        </div>
      </div>
      <div className="comment-area-box-sec">
        <div className="col-12">
          <form action="#" className="comment-area-box" id="comment_name" onSubmit={handleFormSubmit}>
            <ReactQuill onChange={handleChange}
              ref={editorRef}
            />
            <button type="submit" className="btn btn-sm btn-primary">
              <span className="material-symbols-outlined">send</span>
            </button>
          </form>
        </div>

      </div>
    </div >
  );
}

export default IncidenceActivites;