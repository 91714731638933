import { useEffect } from "react";
import DashboardListItem from "./DashboardListItem";

function DashboardListData(props) {
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <>
      {props.dashboards.map((dashboardObj, i) => (
        <DashboardListItem
          key={dashboardObj._id}
          dashboard={dashboardObj}
          enabled={props.enabled}
        />
      ))}
    </>
  );
}

export default DashboardListData;
