import Select from "react-select";
import { Organization } from "./OrganizationScript";
import {
  getGlobalState,
  setGlobalState,
  useGlobalState,
} from "../../components/common/GlobalVariables";
import classNames from "classnames";
import { useState } from "react";
import { t } from "i18next";

function AddRole() {
  const [TenantOptions] = useGlobalState("TenantOptions");
  const [urlOptions] = useGlobalState("urlOptions");
  const [offset, setOffset] = useState(0);
  const [isEdit] = useGlobalState("isEdit");
  const [_id] = useGlobalState("_id");

  const [selectedTenants, setSelectedTenants] =
    useGlobalState("selectedTenants");
  
  const [selectedUrls, setSelectedUrls] = useGlobalState("selectedUrls");
  const handleUrlSelectChange = (selectedOptions) => {
    const isSelectAllSelected = selectedOptions.some(
      (option) => option.value === "SELECT ALL"
    );

    // If "Select All" is selected, set it as the only selected option
    if (isSelectAllSelected) {
      setSelectedUrls([{ value: "SELECT ALL", label: "SELECT ALL" }]);
    } else {
      // Remove "Select All" from the selected options
      const filteredOptions = selectedOptions.filter(
        (option) => option.value !== "SELECT ALL"
      );
      setSelectedUrls(filteredOptions);
    }
  
  };
  const handleTenantSelectChange = (selectedOptions) => {
    setSelectedTenants(selectedOptions);
  };

  // Get the validation handler
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const handleInputChange = () => {
    let areAllFieldsFilled = true;
    const field = document.getElementById("role_name");
    if (!field || field.value.trim() === "") {
      areAllFieldsFilled = false;
      field.classList.add("border-danger"); // Add the class if the field is empty
    } else {
      areAllFieldsFilled = true;
      field.classList.remove("border-danger"); // Remove the class if the field is filled
    }
    setIsSaveEnabled(areAllFieldsFilled);
  };
  const clearfrom = () => {
    setGlobalState("urlOptions",[])
    document.getElementById("role_name").value = "";
    setSelectedUrls([]);
    setSelectedTenants("");
    setGlobalState("isEdit", false);

    const field = document.getElementById("role_name");

    field.classList.remove("border-danger");
  };

  const handleFormSubmit = (e) => {
    setGlobalState("urlOptions",[])
    e?.preventDefault();
    // Prepare the data to send to the backend (if needed)
    const formData = {
      Rolename: document.getElementById("role_name").value,
      Tenants: selectedTenants.value,
      urls: selectedUrls,
    };

    if (isEdit) {
      Organization.update_role(formData, "securityrole", _id);
    } else {
      Organization.addRole(formData);
    }
    clearfrom();
  };

  const loadMoreUrls = () => {
    setOffset(offset + 10); // Adjust the increment according to your needs
    Organization.loadUrlList(offset + 10);
  };



  let singletenant = getGlobalState("singletenant");
  let tenantoption = null;
  if (singletenant) {
    tenantoption = null;
  } else {
    tenantoption = (
      <div className="col-sm-6">
        <div className="col-sm-12">
          <label htmlFor="tenants" className="form-label">
            {t("organization.tenants")}
          </label>
          <Select
            placeholder="Please select tenant"
            id="tenants"
            className={classNames("form-control", {
              "low-opacity": isEdit,
            })}
            options={TenantOptions}
            value={selectedTenants}
            onChange={handleTenantSelectChange}
            isDisabled={isEdit}
          ></Select>
          <small className="d-block text-end bxFontSize">{t("required")}</small>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className="bxOffcanvasAddOrg AddRole offcanvas bg-dark offcanvas-end"
        tabIndex="-1"
        id="offcanvasAddRole"
        aria-labelledby="offcanvasAddRoleLabel"
        data-bs-backdrop="false"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-white" id="offcanvasAddRoleLabel">
            {isEdit?t("organization.update.role.header.name"):t("organization.add.role.header.name")}
          </h5>
        </div>
        <div className="offcanvas-body p-0">
          <div className="bxAddRoleForm p-4 pb-1">
            <form action="">
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-12">
                    <label htmlFor="role_name" className="form-label">
                      {t("organization.add.role.name")}
                    </label>
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "low-opacity": isEdit,
                      })}
                      id="role_name"
                      placeholder="Please enter role name"
                      autoComplete="off"
                      name="Rolename"
                      required
                      readOnly={isEdit}
                      onChange={!isEdit && handleInputChange}
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="col-sm-12">
                    <label className="form-label">
                      {t("organization.role.urls")}
                    </label>
                    <Select
                      placeholder="Please select urls"
                      id="urls"
                      className="form-control"
                      isMulti
                      options={urlOptions}
                      value={selectedUrls}
                      onChange={handleUrlSelectChange}
                      onMenuScrollToBottom={loadMoreUrls}
                    ></Select>
                  </div>
                </div>
                {tenantoption}
              </div>
            </form>
          </div>
        </div>
        <div className="offcanvas-footer py-2 px-3">
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="text-white me-3"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                clearfrom(); // Assuming clearfrom is a function
                Organization.removeClass();
              }}
            >
              {t("cancel")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                handleFormSubmit();
                Organization.removeClass();
              }}
              disabled={!isEdit && !isSaveEnabled}
            >
              {" "}
              {t("name.save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddRole;
