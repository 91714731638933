import React from 'react';
import '../loadingwaveicon/loadingwave.css';

const LoadingWave = () => {
  return (
    <div className="loading-container">
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default LoadingWave;