import { showError } from "../../components/common/DisplayError";
import { requestURI } from "../../components/common/RequestURIs";
import Verification from "./Verification";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import ShowErrorToastPopup from "../../components/common/ShowErrorToastPopup";
import { t } from "i18next";
import { ajax } from "../../components/common/Service";

export const OnboardingScript = {
  selected_tenant: [],

  getSignup: (formData) => {
    const request = {
      customer: formData.Customer?.trim(),
      username: formData.Username?.trim(),
      password: formData.Password?.trim(),
      firstname: formData.Firstname?.trim(),
      lastname: formData.Lastname?.trim(),
      email: formData.Email?.trim(),
      tenant: OnboardingScript.selected_tenant,
    };

    ajax(
      "post",
      requestURI["onboard.signup"],
      null,
      request,
      OnboardingScript.renderSignup,
      showError
    );
  },

  renderSignup: (response) => {
    let status = response.data.response.status;
    let error = response.data.response.error;
    if (status === "COMPLETED") {
      let url = "https://ironman.binaryflux.xyz/confirmationpage";
      window.location.href = url;
    } else {
      ShowErrorToastPopup(error);
    }
  },

  getSignupTenants: (tenantList) => {
    OnboardingScript.selected_tenant = tenantList;
  },

  getVerification: (token) => {
    const request = {
      token: token,
    };

    ajax(
      "post",
      requestURI["onboard.verify"],
      null,
      request,
      OnboardingScript.renderVerification,
      showError
    );
  },

  renderVerification: (response) => {
    let status = response.data.response.status;
    let error = response.data.response.error;

    if (status === "COMPLETED") {
      let url = "https://ironman.binaryflux.xyz/onboardpage";
      window.location.href = url;
    } else {
      ShowErrorToastPopup(error);
    }
  },

  tokenValidity: (token) => {
    const request = {
      token: token,
    };

    ajax(
      "post",
      requestURI["onboard.checktoken"],
      null,
      request,
      OnboardingScript.renderTokenValidity,
      showError,
      [token]
    );
  },

  renderTokenValidity: (response, args) => {
    let token = args[0];
    const result = response.data.response;
    if (result) {
      const container = document.getElementById("bnfxverification");
      let data = <Verification token={token} />;
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      let url = "https://ironman.binaryflux.xyz/errorpage";
      window.location.href = url;
    }
  },

  clearfrom: () => {
    document.getElementById("customer").value = "";
    document.getElementById("username").value = "";
    document.getElementById("email").value = "";
    document.getElementById("firstname").value = "";
    document.getElementById("lastname").value = "";
    document.getElementById("password").value = "";
    OnboardingScript.selected_tenant = null;
  },

  handleFormSubmit: (e) => {
    const addNewFieldsDiv = document.getElementById("bnfxsignuptenants");
    const tenantNames = [];
    if (addNewFieldsDiv) {
      const inputElements = addNewFieldsDiv.querySelectorAll(".form-control");

      inputElements.forEach((inputElement) => {
        const tenantName = inputElement.value;
        tenantNames.push(tenantName);
      });
    }

    const requiredFields = ["customer","username","email","firstname","lastname","password"];
    requiredFields.forEach((fieldId) => {
      const field = document.getElementById(fieldId);
      if (!field || field.value.trim() === "") {
        field.classList.add("border-danger");
      } else {
        field.classList.remove("border-danger");
      }
    });
    const allFieldsFilled = requiredFields.every((field) => {
      const value = document.getElementById(field).value;
      return value.trim() !== "";
    });

    if (allFieldsFilled) {
      const formData = {
        Customer: document.getElementById("customer").value,
        Username: document.getElementById("username").value,
        Email: document.getElementById("email").value,
        Firstname: document.getElementById("firstname").value,
        Lastname: document.getElementById("lastname").value,
        Password: document.getElementById("password").value,
        Tenantname: tenantNames,
      };

      const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
      const emailInput = document.getElementById("email");
      const email = emailInput.value;
      const isEmailValid = emailRegex.test(email);

      if (!isEmailValid) {
        ShowErrorToastPopup(t("email.error.message"));
        document.getElementById("email").value = "";
        return;
      } else {
        OnboardingScript.getSignup(formData);
      }
      OnboardingScript.clearfrom();
    }
  },

  handleInputChange: () => {
    const requiredFields = ["customer","username","email","firstname","lastname","password"];

    requiredFields.forEach((fieldId) => {
      const field = document.getElementById(fieldId);
      if (!field || field.value.trim() === "") {
        field.classList.add("border-danger");
      } else {
        field.classList.remove("border-danger");
      }
    });
  },
};
