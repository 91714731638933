import React from "react";
import { Stream } from "../../StreamScript";

const SettingTenantOption = (props) => {
  let data = props.tenantdatacount;

  const matchingRecord = data?.find(
    (record) => record.tenant === props.tenant.value
  );
  let tenanthasdataicon = null;
  if (matchingRecord) {
    tenanthasdataicon = (
      <span className="material-symbols-outlined text-success BXf-18">
       task_alt
      </span>
    );
  } else {
    tenanthasdataicon = (
      <span className="material-symbols-outlined text-warning BXf-18">
       warning
      </span>
    );
  }
  return (
    <div
      className="nav-link "
      id="65084efdfcfe5c39a7741272cisco"
      data-bs-toggle="pill"
      data-bs-target="#v-pills-1"
      type="button"
      role="tab"
      aria-controls="v-pills-1"
      aria-selected="true"
      onClick={() => {
        Stream.selectTenantChange(props.tenant.value);
      }}
    >
      <div className="d-flex align-item-center justify-content-between settings-link">
        <label className="form-check-label" htmlFor="onOff1">
          {props.tenant.value}
        </label>
        {tenanthasdataicon}
      </div>
    </div>
  );
};

export default SettingTenantOption;
