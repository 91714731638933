export const calculatePagesCount = (pagination) => {
  const pages = [];
  var numberofpages =
    pagination?.total < pagination?.limit
      ? 1
      : Math.ceil(pagination?.total / pagination?.limit);

  var currentpage = 1 + pagination?.offset / pagination?.limit;

  if (numberofpages <= 1) {
    return [];
  }

  if (numberofpages > 10) {
    numberofpages = 10
  }

  for (let index = 1; index <= numberofpages; index++) {
    pages.push({
      page: index,
      offset: (index - 1) * pagination?.limit,
      limit: pagination?.limit,
      active: currentpage === index ? true : false,
    });
  }

  return pages;
};
