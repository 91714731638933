import { OnboardingScript } from "./OnboardingScript";
import { useLayoutEffect } from "react";

function VerificationLayout() {
  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    OnboardingScript.tokenValidity(token);
  });

  return (
    <div className="row align-items-center bxLoginArea" id="bnfxverification">
      {/* we will be render verification page */}
    </div>
  );
}

export default VerificationLayout;
