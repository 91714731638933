import { t } from "i18next";
import ConnectionDropDown from "./ConnectionDropDown";
import { CollectorsScript } from "./CollectorsScript";


function CollectorInstructionForm(props) {
 let result=props.result;
  
  return (
    <>
      <form action="">
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="provider" className="form-label">
                      Provider
                    </label>
                    <input
                      type={result.provider.type}
                      className="form-control"
                      id="provider"
                      placeholder="Please enter provider name"
                      autoComplete="off"
                      name="providername"
                      required
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="group" className="form-label">
                      Group
                    </label>
                    <input
                      type={result.group.type}
                      className="form-control"
                      id="group"
                      placeholder="Please enter group name"
                      autoComplete="off"
                      name="groupname"
                      required
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="type" className="form-label">
                      Type
                    </label>
                    <input
                      type={result.type.type}
                      className="form-control"
                      id="type"
                      placeholder="Please enter type name"
                      autoComplete="off"
                      name="typename"
                      required
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="format" className="form-label">
                      Format
                    </label>
                    <input
                      type={result.format.type}
                      className="form-control"
                      id="format"
                      placeholder="Please enter format"
                      autoComplete="off"
                      name="formatname"
                      required
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="retention" className="form-label">
                      Retention
                    </label>
                    <input
                      type={result.retention.type}
                      className="form-control"
                      id="retention"
                      placeholder="Please enter retention"
                      autoComplete="off"
                      name="retentionname"
                      required
                    />
                  </div>
                </div>
                  <ConnectionDropDown connectionoptions={result.connection}/>
              </div>
            </form>

        <div className="offcanvas-footer bg_canvas p-2 text-end">
          <button
            type="button"
            className="me-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              CollectorsScript.showInstructionPanel();
            }}
          >
            {t("close")}
          </button>
          <div className="collector-savebutton active me-4">
          <button
              type="button"
              className="btn ms-2 btn-primary CustomSaveBtn saveBtn button"
              // data-bs-dismiss="offcanvas"
              onClick={() => {
                CollectorsScript.getCollectorSuggest();
              }}
            >
              {t("name.save")}
            </button>
          </div>
        </div>
    </>
  );
}

export default CollectorInstructionForm;
