import SearchCardLayout from "./SearchCardLayout";
import React, { useEffect } from "react";

function SearchDropdownLayout(props) {
  let data = null;

  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  if (props.searchdata) {
    data = props.searchdata.map((dataObj, index) => (
      <React.Fragment key={index}>
        <SearchCardLayout searchData={dataObj} />
      </React.Fragment>
    ));
  }

  return (
    <>
      <div className="card card-body savedSearchResult">{data}</div>
    </>
  );
}

export default SearchDropdownLayout;
