import { getGlobalState, setGlobalState } from "./GlobalVariables";

export const Initialize = {
  initfunctions: [],
  inittimeout: null,
  loaded: null,

  onready: () => {


    if (getGlobalState("bnfx_app_loaded")) {
      return
    }

    if (document.querySelector("#bxFooter")) {
      for (let index = 0; index < Initialize.initfunctions.length; index++) {
        const fn = Initialize.initfunctions[index];
        fn();
      }
      setGlobalState("bnfx_app_loaded", true)
    }

  },

  register: (fn) => {
    Initialize.initfunctions.push(fn);
  }
};
