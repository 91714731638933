import React, { memo } from "react";
import { Handle, Position } from "reactflow";
const CircleNode = memo(({ data, isConnectable }) => {
  const circleSize = 20; 
  const color = "red"; 

  return (
    <div className="SpiderCircle" style={{ position: "relative" }}>
      <svg height={circleSize} width={circleSize}>
        <circle
          cx={circleSize / 5}
          cy={circleSize / 5}
          r={circleSize / 5}
          fill={color}
        />
      </svg>
      {/* Adding the Handle component */}
      <Handle
        type="target"
        position={Position.Top}
        id="a"
        style={{
          position: "absolute",
          top: -10,
          left: "50%",
          background: "#fff",
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        style={{ bottom: -10, right: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
    </div>
  );
});

export default CircleNode;
