import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";
import moment from "moment";
import { t } from "i18next";

function IncidentDetailWidget(props) {
  let data = props.incidentdata.result;
  let total = props.incidentdata.total;

  function getBadgeStatus(status) {
    switch (status) {
      case 'assigned':
        return 'badge-assign status icon-assign';
      case 'open':
        return 'badge-success status icon-success';
      case 'in-progress':
        return 'badge-info status icon-progress';
      case 'ongoing':
        return 'badge-ongoing status icon-ongoing';
      case 'closed':
        return 'badge-closed status icon-closed';
      default:
        return '';
    }
  }

  function getBadgeClass(criticality) {
    switch (criticality) {
      case 'HIGH':
        return 'badge-danger icon-danger';
      case 'LOW':
        return 'badge-success icon-success';
      case 'MEDIUM':
        return 'badge-info icon-progress';
      default:
        return '';
    }
  }

  return (
    <>
      <div className="card_layout">
        <div className="card-body">
          <div className="row">
            <div className="col-md-3">
              <div className="d-flex align-iten-center roiDash_Title_box">
                <h5 className=""> {t("incident")}</h5>
                <span className="material-symbols-outlined ms-2 title_icon">cases</span>
              </div>
              <p className="total_detectioncount">{CommonTemplate.formatNumber(total)}</p>
            </div>
            <div className="col-md-9 critical_detection">
              {data.map((item, index) => (
                <div key={index} className="border-bottom mb-2">
                  <div className="d-flex align-item-baseline justify-content-between mb-2">
                    <p className="roi detectionname">
                      <span className={`badge px-2 py-2 text-uppercase ${getBadgeClass(item.criticality)}`}>
                        {item.criticality}
                      </span>
                    </p>
                    <span className={`badge text-uppercase ${getBadgeStatus(item.status)}`}>
                    {item.status}
                      </span>
                  </div>
                  <div className="d-flex align-item-center justify-content-between mb-2">
                    <span className="incident_name">{item.name}</span>
                    <span className="incident_assignee_name">{item.assignee}</span>
                  </div>
                  <div className="d-flex align-item-center justify-content-between mb-2">
                    <p className="roi detectionname">
                      <b className="incident_createdby text-white">{t("createdby")} {item.createdby}</b>
                    </p>
                    <span className="incident_date">{moment(new Date(item.createdon)).format("DD MMM YYYY hh:mm")}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



export default IncidentDetailWidget;
