import { useEffect, useReducer, useRef } from "react";
import Select from "react-select";
import {
  contentReducer,
  initialState,
  CONTENT_CONSTANTS,
} from "../contentReducer";
import { Content } from "../ContentScript";

function ContentSelectDropDown(props) {
  const [state, dispatch] = useReducer(contentReducer, initialState);
  const pageloaded = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({
          type: CONTENT_CONSTANTS.LOADING_MORE_CONTENT,
          data: 0,
        });
        dispatch({
          type: CONTENT_CONSTANTS.POPULATE_AUTO_COMPLETE_FIELDS,
          data: {
            contentType: props.contenttype,
            items: [],
          },
        });
        await loadcontent(0);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    pageloaded.current = true;
    return () => {};
  }, [props.tenant]);
  const loadMore = async () => {
    try {
      let offset = state.offset + 18;
      dispatch({
        type: CONTENT_CONSTANTS.LOADING_MORE_CONTENT,
        data: offset,
      });

      await loadcontent(offset);
    } catch (error) {
      console.error("Error loading more data:", error);
    }
  };
  const loadcontent = async (offset) => {
    let payload = {
      offset: offset,
      type: props.contenttype,
      terminalName: state.terminalName,
      tenant: props.tenant,
    };
    const apiResponse = await Content.loadContentItemsList(payload, null);

    dispatch({
      type: CONTENT_CONSTANTS.POPULATE_AUTO_COMPLETE_FIELDS,
      data: {
        contentType: props.contenttype,
        items: apiResponse?.response || [],
      },
    });
  };

  const { autocomplete } = state;

  const optionList = autocomplete[props.contenttype]?.map((item) => ({
    value: item.location,
    label: item.title,
  }));

  let deafultoption = [];
  if (Array.isArray(props.streamFields)) {
    deafultoption = props.streamFields?.map((item) => ({
      value: item.location || item.value,
      label: item.title || item.label,
    }));
  } else {
    const item = props.streamFields;
    if (item) {
      deafultoption = [
        { value: item.location || item.value, label: item.title || item.label },
      ];
    }
  }
  return (
    <Select
      id={props.contenttype + "list"}
      className={"form-control " + props.className}
      options={optionList}
      onChange={props.onChange}
      onMenuScrollToBottom={loadMore}
      value={deafultoption}
      isMulti={props.multi}
    ></Select>
  );
}
export default ContentSelectDropDown;
