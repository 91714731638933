import { t } from 'i18next';
import React from 'react';

function NoStatCard(props) {

  return (
    <>
      <div className="ph-item statCard top-card" id={props.id}>
        <div className="ph-col-12 p-0">
            <div className="placeholder-glow p-0 py-2">
              <div className="ph-col-4 placeholder big"></div>
              <div className="ph-col-8 empty big"></div>
              <div className="ph-col-12 streamStatCards placeholder big">{t("stat_error_msg")}</div>
              <div className="ph-col-4 empty big"></div>
            </div>
            </div>
        </div>
    
    </>
  );

};

export default NoStatCard;
