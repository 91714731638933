import React, { useRef, useLayoutEffect, useState } from 'react';
import './funnelchart.css';
import { CommonTemplate } from '../containerAvailability/CommonScript';

const FunnelCustomize = ({ series, container, layout ,hidex}) => {
  const chartContainer = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
  useLayoutEffect(() => {
    const handleResize = () => {
      let height =null;
      if (chartContainer.current) {
        const parent = container.replace("bnfxWidgetContainer", "grid-stack-item");
        let width = null;
        if(layout === "conciselayout"){
          height = parseInt(document.getElementById(parent)?.clientHeight) - 80;
          let widthheight = hidex ? parseInt(document.getElementById(parent)?.clientHeight) - 50 : null;
          width = widthheight;
        }else{
          height = parseInt(document.getElementById(parent)?.clientHeight) - 120;
          let widthheight = hidex ? document.getElementById(container)?.closest(".bxDashboardGraph")?.clientWidth - 200 : null;
          width = widthheight;
        }
        setDimensions({ width, height });
      }
    };

    handleResize(); // Set dimensions on mount
    window.addEventListener('resize', handleResize); // Update dimensions on window resize

    return () => window.removeEventListener('resize', handleResize);
  }, [container, layout ,hidex]);

  // const data = [
  //   ['Published', 45000],
  //   ['Filters', 650],
  //   ['Detections', 150],
  // ];

  return (
    <div
      className="funnel-container"
      ref={chartContainer}
      id={container}
      style={{ width: dimensions.width, height: dimensions.height }}
    >
      <div className="funnel">
      {series?.map((item, index) => (
          <div
            key={index}
            className={`funnel-section ${index === 0 ? 'top' : index === 1 ? 'middle' : 'bottom'}`}
            title={`${item[0]}: ${CommonTemplate.formatNumber(item[1])}`}
          >
            <div className="funnel-text">{CommonTemplate.formatNumber(item[1])}<br />{item[0]}</div>
            {index !== series.length - 1 && <div className="funnel-circle"></div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FunnelCustomize;
