import axios from "axios";
import { Login } from "../../pages/login/LoginScript";
import jwt from 'jwt-decode'
import { Mutex } from 'async-mutex';


const tokenmutex = new Mutex();

export const ajax = async (method, url, headers, parameters, callback, error_callback, args, sync) => {

  const token = localStorage.getItem("token");
  const selectedregion = localStorage.getItem(
    "bnfxSelectedRegion"
  );
  if (token && !tokenmutex.isLocked()) {
    const jwtobj = jwt(token);
    const expiry = (jwtobj.exp * 1000 - (1 * 60 * 1000));
    const currenttime = (new Date()).valueOf();
    if (expiry <= currenttime) {
      tokenmutex.acquire()
      await Login.refreshtoken(token);
      tokenmutex.release()
    }
  }

  if (headers == null) {
    const bearerToken = "Bearer " + token;
    headers = {
      Authorization: `${bearerToken}`,
      region: selectedregion
    };
  }


  let baseurl = process.env.REACT_APP_API_URL;

  if (!baseurl) {
    baseurl = "https://backend.binaryflux.xyz/";
  }


  let ajaxconfig = {
    method: method,
    url: (baseurl + url),
    data: parameters,
    headers: headers
  }

  if (sync && sync === true) {
    return syncAjax(ajaxconfig)
  } else {
    return axios(ajaxconfig)
      .then((result) => {
        callback(result, args);
      })
      .catch((error) => {
        sessionexpired(error, error_callback, args)
      });
  }

}



export const ajax2 = async (method, url, headers, parameters, callback, error_callback, args, sync) => {


  let ajaxconfig = {
    method: method,
    url: url,
    data: parameters,
    headers: headers
  }

  if (sync && sync === true) {
    return syncAjax(ajaxconfig)
  } else {
    return axios(ajaxconfig)
      .then((result) => {
        callback(result, args);
      })
      .catch((error) => {
        sessionexpired(error, error_callback, args)
      });
  }

}

export const syncAjax = async (ajaxconfig) => {
  return await axios(ajaxconfig);
}

export const sessionexpired = (error, error_callback, args) => {
  if (error.response && error.response.data && error.response.data.errorcode) {
    const errorcode = error.response.data.errorcode;
    if (errorcode === "AUTH001" || errorcode === "AUTH004") {
      Login.removeToken();
      window.localStorage.setItem("sessionExpired", true);
      window.location = '/';
    } else {

      error_callback(error, args);
    }
  }
  else if (error.response && error.response.data && error.response.data.error) {
    error_callback(error.response.data.error, args);
  }
  else {

    console.error("Error response or data is missing:", error);
  }
}

export const fetchData = async (method, url, headers, parameters, signal) => {
  const token = localStorage.getItem("token");
  const selectedregion = localStorage.getItem("bnfxSelectedRegion");
  if (token && !tokenmutex.isLocked()) {
    const jwtobj = jwt(token);
    const expiry = jwtobj.exp * 1000 - 1 * 60 * 1000;
    const currenttime = new Date().valueOf();
    if (expiry <= currenttime) {
      tokenmutex.acquire();
      await Login.refreshtoken(token);
      tokenmutex.release();
    }
  }

  if (headers == null) {
    const bearerToken = "Bearer " + token;
    headers = {
      Authorization: `${bearerToken}`,
      region: selectedregion
    };
  }

  let baseurl = process.env.REACT_APP_API_URL;

  if (!baseurl) {
    baseurl = "https://backend.binaryflux.xyz/";
  }

  parameters = parameters || {};
  let response = null;

  if (method && method.toLowerCase() === "post") {
    let reqHeaders = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify(parameters)
    };
    if (signal) {
      reqHeaders.signal = signal;
    }
    try {
      response = await fetch(baseurl + url, reqHeaders);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch request was aborted");
      } else {
        console.error("Fetch error:", error);
      }
    }
  } else if (method && method.toLowerCase() === "get") {
    response = await fetch(baseurl + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...headers
      }
    });
  }

  return response ? response.json() : {};
};
