
function IncidenceLayout() {

  return (
    <>
      <div
        className="bxStreamLstOffCanvas offcanvas offcanvas-end incidencePanel"
        id="bxOffcanvasIncidence"
        aria-labelledby="bxOffcanvasIncidence"
        data-bs-backdrop={false}
      >
        <div id="bnfxIncidencemeta">
          {/*  we will replace actionlist */}
        </div>
      </div>
    </>
  );
}

export default IncidenceLayout;