import React, { useLayoutEffect, useRef } from "react";
import Highcharts from "highcharts";

const SingleColumnChart = (props) => {
  const series = props.series;
  const colors = props.colors;
  const container = props.container;
  const categories = props.categories;
  const height = props.height;
  const onclick_param = props.onclick_param;
  const chartContainer = useRef(null);

  const handleRowClick = (data) => {
    props.applyfilteronClick(props.filterid, data);
  };

  useLayoutEffect(() => {
    let widgetheight = null;
    if (chartContainer.current) {
      const parent = container?.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );
      if (props.layout === "conciselayout") {
        widgetheight =
          parseInt(document.getElementById(parent)?.clientHeight) - 80;
      } else {
        widgetheight =
          parseInt(document.getElementById(parent)?.clientHeight) - 120;
      }

      const options = {
        chart: {
          type: "column",
          renderTo: "container",
          height: height ? height + "px" : widgetheight + "px",
        },
        title: null,
        xAxis: {
          categories: categories,
          title: {
            text: null,
          },
          tickInterval: categories.length > 5 ? 3 : undefined,
          labels: {
            rotation: 0,
            formatter: function () {
              return this.value.split(" ")[0];
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Count",
            align: "middle",
          },
          labels: {
            overflow: "justify",
          },
          gridLineWidth: 0,
        },
        tooltip: {
          valueSuffix: "%",
        },
        plotOptions: {
          column: {
            colorByPoint: true,
            colors: colors,
            pointWidth: 30,
            dataLabels: {
              enabled: false,
              style: {
                fontFamily: "Arial",
                fontSize: "12px",
                fontWeight: "normal",
                color: "#FFF",
              },
            },
            events: {
              click: function (event) {
                if (onclick_param === 'filter_apply') {
                handleRowClick(event.point.category);
                }
              },
            },
          },
          series: {
            borderWidth: 0,
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "",
            data: series,
          },
        ],
      };
      Highcharts.chart(chartContainer.current, options);
    }
  }, [series, colors, container, categories, height, props.layout]);

  return (
    <>
      {" "}
      <style>
        {`
    .highcharts-no-tooltip {
      display: none !important;
    }
  `}
      </style>
      <div ref={chartContainer} id={container} />
    </>
  );
};

export default SingleColumnChart;
