import { t } from "i18next";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import { Automation } from "./AutomationScript";

function AutomationLayout() {
  return (
    <>
      <div
        className="offcanvas Custom_offcanvas offcanvas-end"
        id="bnfxAutomationLayout"
        aria-labelledby="bnfxAutomationLayout"
        data-bs-backdrop={false}
      >
        <div id="bnfxAutomationLayoutHeader">
          {/* we will repace AutomationLayoutHeader */}
        </div>

        <div id="bnfxAutomationTask">
          {/* we will repace AutomationTaskDetails */}
        </div>
        <div className="offcanvas-footer bg_canvas p-2 text-end">
          <button
            type="button"
            className="me-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              CommonTemplate.removeClass();Automation.offcanvasCloseButton();
            }}
          >
            {t("close")}
          </button>
        </div>
      </div>
    </>
  );
}

export default AutomationLayout;
