import "../signup/signup.css";
import { OnboardingScript } from "../customeronboarding/OnboardingScript";
import { useState } from "react";
import MultiInput from "../../components/common/MultiInput";
import { t } from "i18next";
import SignupInputField from "../../components/inputField/SignupInputField";

function SignupTemplate() {
  const [showContent, setShowContent] = useState(false);

  return (
    <>
      <div className="row align-items-center bxLoginArea">
        <div className="col-lg-8 mx-auto">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img className="w-100" src="images/illustration.png" alt="" />
            </div>
            <div className="col-md-6">
              <div className="signup_form text-white">
                <h4>{t("signup.title")}</h4>
                <p>{t("signup.description")}</p>
                <form className="pt-2">
                  <div className="form-floating mb-3">
                    <SignupInputField type="text" id="customer" placeholder="Customer" autoComplete="off"/>
                    <label htmlFor="customer">{t("signup.customer")}</label>
                  </div>
                  <div className="form-floating mb-3">
                    <SignupInputField type="text" id="username" placeholder="Username" autoComplete="off"/>
                    <label htmlFor="username">{t("signup.username")}</label>
                  </div>
                  <div className="form-floating mb-3">
                    <SignupInputField type="text" id="email" placeholder="Email" autoComplete="off"/>
                    <label htmlFor="email">{t("signup.email")}</label>
                  </div>
                  <div className="form-floating mb-3">
                    <SignupInputField type="text" id="firstname" placeholder="Firstname" autoComplete="off"/>
                    <label htmlFor="firstname">{t("signup.firstname")}</label>
                  </div>
                  <div className="form-floating mb-3">
                    <SignupInputField type="text" id="lastname" placeholder="Lastname" autoComplete="off"/>
                    <label htmlFor="lastname">{t("signup.lastname")}</label>
                  </div>
                  <div className=" form-floating mb-3">
                    <SignupInputField type="password" id="password" placeholder="Password" autoComplete="off"/>
                    <label htmlFor="password">{t("signup.password")}</label>
                  </div>
                  <div className="signUpPageFoot">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value=""
                        id="flexCheckChecked"
                        onChange={() => setShowContent(!showContent)}
                      />
                      &nbsp;
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        {t("signup.checkbox.title")}
                      </label>
                    </div>

                    {showContent && (
                      <div className="panel-body" id="bnfxsignuptenants">
                        <MultiInput entity={null} page="signup_Page" type="signupTenant"/>
                      </div>
                    )}
                  </div>
                  <div className="submit_button">
                    <button
                      type="button"
                      className="btn btn-lg bxBtn-primary w-100"
                      onClick={() => {
                        OnboardingScript.handleFormSubmit();
                      }}
                    >
                      {t("signup.title")}
                    </button>
                    <p className="text-center mt-2">
                      {t("signup.message")}
                      <a href="/" className="signup_account">
                        {t("login.name")}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupTemplate;
