import { t } from "i18next";
function InvalidWidget(){
    return(
        <>
             <div className="col-12 rightSideCardLgBox bxAddEventText mt-2">
              <div className="card mb-0">
                <div className="card-body p-0">
                  <div className="p-5">
                    <img
                      className="mb-3"
                      src="images/no_events_yet.svg"
                      alt="no_events_yet"
                    />
                    <h5 className="text-white">{t("no.widget.header")}</h5>
                    <p className="p-0">
                    {t( "no.widget.description")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </>
    );
}

export default InvalidWidget;