import { t } from "i18next";
import Search from "../../../search/Search";
import { useLayoutEffect } from "react";
import { Automation } from "../AutomationScript";

function PlaybookExecutionLayout() {

  useLayoutEffect(() => {

    const searchbar = document.getElementById("playbook_search")
    if (searchbar) {
      searchbar.addEventListener("keydown", function () {
        Automation.search("playbook_search");
      });
      searchbar.addEventListener("input", function () {
        if (searchbar.value === "") {
          document.getElementById("playbook_search").value = null;
          Automation.loadPlaybookLayout(0);
        }
      });
    }

  });

  return (
    <>
      <div
        className="bxStreamLstOffCanvas offcanvas playbook_automation offcanvas-end"
        id="bnfxPlaybookExecution"
        aria-labelledby="bnfxPlaybookExecution"
        data-bs-backdrop={false}
      >
        <div className="offcanvas-header p-0" id="bnfxPlayookexecutionheader">
          <div className="bxSEDHeadDashboard py-2 border-0">
            <div className="bxOutliersCard">
              <h4 className="BXf-18 text-white text-uppercase">
                <span className="material-symbols-outlined me-2 text-capitalize">
                order_play
                </span>
                {t("title.playbooklist")}
              </h4>
            </div>
          </div>
        </div>

        <div className="bxStreamLeftSideBar_searchArea p-2 pb-0 pt-2 border-0" id="bnfxplaybooksearch">
          <form className="sidebar-search">
            <Search
              id="playbook_search"
              defaultValue=""
            />
          </form>
        </div>
        <div id="bnfxPlaybookExecutionList">
          {/*  we will replace automationlist */}
        </div>
      </div>
    </>
  );
}

export default PlaybookExecutionLayout;
