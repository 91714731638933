import { useTranslation } from "react-i18next";
import { Login } from "../../../../pages/login/LoginScript";
import jwt from 'jwt-decode'
import { setGlobalState } from "../../GlobalVariables";
import { useEffect } from "react";

function MainProfile() {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const loggedintime=localStorage.getItem("lastloggingtime")
  const detectionDate = new Date(parseInt(loggedintime));


    const userdetails = jwt(token);
   
    useEffect(() => {
      const tenantcount = userdetails.tenants?.length;
      if (tenantcount === 1) {
        setGlobalState("singletenant", true);
      }
    }, []); 
    function generateRandomColor() {
      const darkColors = [
        "rgb(0 0 0)" , "rgb(39 25 9)" ,"rgb(15 9 39)", "rgb(39 9 9)" , "rgb(15 39 9)" , "#201b0b" , "#0d1d34",
      ]
      const randomColor = darkColors[Math.floor(Math.random() * darkColors.length)];
      return randomColor;
    }
      const randomColor = generateRandomColor();
      const buttonStyle = {
      backgroundColor: randomColor,
      };
  return (
    <>
      <span
        className="nav-link "
        data-bs-toggle="dropdown"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <span className="material-symbols-outlined navsearchicon" >person_pin</span>
        <small>{userdetails.firstname}</small>
      </span>
     
      
      <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
        <div className="dropdown-content custom_profile" >
          <div className="bg_user"  style={buttonStyle}></div>
          <span className="account-user-name d-none d-md-block" style={buttonStyle}>
          {userdetails?.username?.charAt(0)}
          </span>
        <span className="dropdown-option" > {userdetails.username}</span>
        <span className="dropdown-option" > {userdetails.firstname}{" "}{userdetails.lastname}</span>
        <span className="dropdown-option" > {userdetails.email}</span>
        <span className="dropdown-option LastLoginDate" > {detectionDate.toLocaleString()}</span>
        <button
          type="button"
          className="dropdown-item notify-item"
          onClick={() => {
            Login.logout();
          }}
          
        >
          <i className="material-symbols-outlined me-1">logout</i>
          <span>{t("logout")}</span>
        </button>
    </div>
        
    
      </div>
    </>
  );
}

export default MainProfile;
