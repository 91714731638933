import React from "react";
import DashboardCardLayout from "./DashboardCardLayout";

function DashboardCard(props) {
  let data=props.data
  let chartData=data.data.response.result
  
 
  return (
    <>
        <DashboardCardLayout data={chartData} />
    </>
  );
}

export default DashboardCard;
