import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import drilldown from "highcharts/modules/drilldown";
drilldown(Highcharts);

const DonutChart = ({ data, height,layout,container,onclick_param ,applyfilteronClick,filterid}) => {
  const chartRef = useRef(null);
  const total = Object.values(data).reduce((acc, value) => acc + value, 0);

  const defaultColors = ["#4432a0","#3a2790eb","#43338bc9","#423871d1"];
  let defaultColorIndex = 0;

  const handleRowClick = (data) => {

    applyfilteronClick(filterid,data);
  };

  const getDefaultColor = () => {
    const color = defaultColors[defaultColorIndex % defaultColors.length];
    defaultColorIndex++;
    return color;
  };

  const formattedData = Object.entries(data).map(([key, value]) => {
    let color;
    switch (key) {
      case "CRITICAL":
        color = "rgb(245, 5, 5)"; // dark red
        break;
      case "HIGH":
        color = "#FF3E3E"; // red
        break;
      case "MEDIUM":
        color = "#F5C304"; // yellow
        break;
      case "LOW":
        color = "#66BEA9"; // green
        break;
      default:
        color = getDefaultColor(); 
        break;
    }
    return {
      name: key,
      y: (value / total) * 100,
      color: color,
    };
  });

  useEffect(() => {
    if (chartRef.current) {
      let widgetheight=null
      const parent = container?.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );

      if(layout==='conciselayout'){
        widgetheight =
        parseInt(document.getElementById(parent)?.clientHeight) - 100;
      }else{
        widgetheight =
        parseInt(document.getElementById(parent)?.clientHeight) - 120;
      } 

      Highcharts.chart(chartRef.current, {
        chart: {
          type: "pie",
          height:height ? height +"px": widgetheight+ "px",
        },
        title: null,
        plotOptions: {
          pie: {
            shadow: false,
            innerSize: "95%",
            borderWidth: 0,
            events: {
              click: function (event) {
                if (onclick_param === 'filter_apply') {
                handleRowClick(event.point.name); // Pass the category or other relevant data
                }
              }
          }
          },
          
        },
        
        tooltip: {
          valueSuffix: "%",
        },
        credits: {
          enabled: false,
        },
        accessibility: {
          enabled: false
        },
        series: [
          {
            name: "Severity",
            data: formattedData,
            size: "100%",
            dataLabels: {
              formatter: function () {
                return this.y > 5 ? this.point.name : null;
              },
              color: "#ffffff",
              distance: -30,
              style: {
                fontSize: "8px",
              },
            },
          },
        ],
        legend: {
          enabled: true,
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical'
        },
      });
    }
  }, [data, height,container,formattedData,layout]);

  return <div ref={chartRef}></div>;
};

export default DonutChart;
