import { Navigation } from "./Navigation";
import MainNavScript from "./MainNavScript";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function MainNavBar() {
  const { t } = useTranslation();

  useEffect(() => {
    Navigation.register(Navigation.alterHeader);
  }, []);

  return (
    <>
      <div className="float-star order-md-2">
        <div className=" navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown dropdown-fullwidth">
              <p
                className="nav-link dropdown-toggle m-0"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span id="bnfxHeaderTitle">
                  {Navigation.navLinkData(t)[0].title}
                </span>
                <small id="bnfxHeaderDescription">
                  {Navigation.navLinkData(t)[0].description}
                </small>
              </p>
              <ul className="dropdown-menu  p-2">
                <MainNavScript></MainNavScript>
              </ul>
            </li>
          </ul>
        </div>
        {/* <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="material-symbols-outlined text-white">subject</span>
        </button> */}
      </div>{" "}
    </>
  );
}

export default MainNavBar;
