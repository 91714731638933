import StreamListContainer from "./list/StreamListContainer";
import StreamDetailsContainer from "./details/StreamDetailsContainer";

function StreamsWrapperContainer() {

    return (
        <>
            <StreamListContainer />
            <div id="bnfxDetailsContainer">
                <StreamDetailsContainer /></div>
        </>
    );

}


export default StreamsWrapperContainer;
