import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsWordcloud from "highcharts/modules/wordcloud";

HighchartsWordcloud(Highcharts);

// const sampleText = [
//   { name: 'Alice', weight: 5 },
//   { name: 'Rabbit', weight: 3 },
//   { name: 'Hole', weight: 2 },
//   { name: 'Bank', weight: 1 },
//   { name: 'Sister', weight: 1 },
//   // Add more words and their frequencies here
// ];

const WordCloud = (props) => {
  let data = props.data?.result;
  let container = props.container;
  let widgetid = props.id;
  let column = props.data?.column;
  let height=props.height;
  let layout=props.layout;
  let onclick_param=props.onclick_param;
  let label = props.label;
  let columnmap=props.columnmap;

  const handleNameClick = (name,point) => {
    let dataset = { name: name, column: column, label: label ,data:props.data,point:point,columnmap:columnmap};
    
    props.onClickFn(dataset, widgetid);
  };

  const chartRef = useRef(null);
  const colors = [
    "#ffff9d",
    "#fee17e",
    "#00876c",
    "#3d9c73",
    "#63b179",
    "#fcc267",
    "#f7a258",
    "#ef8250",
    "#003F5C",
    "#004c6d",
    "#00b8d3",
    "#e4604e",
    "#d43d51",
    "#006083",
    "#007599",
    "#88c580",
    "#aed987",
    "#d6ec91",
    "#008bad",
    "#00a1c1",
  ];

  const assignColors = (data, colors) => {
    return data?.map((item, index) => ({
      ...item,
      color: colors[index % colors.length], // Cycle through colors if not enough colors for all words
    }));
  };

  useEffect(() => {
    let widgetheight = null;
    const coloredData = assignColors(data, colors);
    if (chartRef.current) {
      const parent = container?.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );

      if (layout === "conciselayout") {
        widgetheight = parseInt(document.getElementById(parent)?.clientHeight) - 70;
      } else {
        widgetheight = parseInt(document.getElementById(parent)?.clientHeight) - 120;
      }
      const options = {
        chart: {
          height: height ? height + "px" : widgetheight + "px",
          events: {
            load: function () {
              if (onclick_param !== 'not_open_offcanvaspanel') {
              this.series[0].points.forEach((point) => {
                if (point.graphic && point.graphic.element) {
                  point.graphic.element.addEventListener("mousedown", () => {
                    point.graphic.element.setAttribute(
                      "data-bs-toggle",
                      "offcanvas"
                    );
                    point.graphic.element.setAttribute(
                      "data-bs-target",
                      "#bxOffcanvasPanel"
                    );
                    handleNameClick(point.name,point);
                  });
                }
              });
            }
            },
          },
        },
        accessibility: {
          screenReaderSection: {
            beforeChartFormat: null,
          },
        },
        series: [
          {
            type: "wordcloud",
            data: coloredData,
            name: "Occurrences",
          },
        ],
        title: null,
        subtitle: null,
        tooltip: {
          headerFormat:
            '<span style="font-size: 16px"><b>{point.key}</b>' + "</span><br>",
        },
        credits: {
          enabled: false, // Disable the credits
        },
        plotOptions: {
          wordcloud: {
            maxFontSize: 4, // Maximum font size for the largest words
            minFontSize: 1, // Minimum font size for the smallest words
            style: {
              fontFamily: "Arial",
            }
          },
        },
      };
      

      Highcharts.chart(chartRef.current, options);
    }
  }, [data, container]);

  return <div ref={chartRef} id={container}></div>;
};

export default WordCloud;
