import { t } from "i18next";
function StreamSelection({
  faq,
  index,
  toggleFAQ,
  toggleCheckbox,
  toggleQuestionCheckbox,
  toggleSelectAll,
}) {
  const handleToggleFAQ = () => {
    toggleFAQ(index);
  };
  const allAnswersChecked = faq?.answers?.every(answer => answer?.checked);
  return (
    <div className={"faq border-top " + (faq?.open ? "open" : "")} key={index}>
      <div className="faq-question" onClick={handleToggleFAQ}>
        <div className="d-flex align-items-center faq-Stream_list">
          <div
            className="custom-checkbox"
            onClick={(event) => event.stopPropagation()}
          >
            <input
              type="checkbox"
              id={"questionCheckbox" + index}
              checked={faq?.checked}
              onChange={() => toggleQuestionCheckbox(index)}
            />
            <label htmlFor={"questionCheckbox" + index}></label>
          </div>
          <span className={"bxIcon_link " + faq?.source?.toLowerCase()}></span>
          <p className="badgestreamname">{faq?.question}</p>
        </div>
      </div>

      <div className="faq-answers badgedetection_list">
        {faq?.open && (
          <>
            <div className="faq-answer mt-2 badgestreamdetection">
              <div className="custom-checkbox me-3">
                <input
                  type="checkbox"
                  id={"selectAllCheckbox" + index}
                  checked={allAnswersChecked}
                  onChange={() => toggleSelectAll(index)}
                />
                <label htmlFor={"selectAllCheckbox" + index}></label>
              </div>
              {t("selectall")}
            </div>

            {faq?.answers?.map((answer, ansIndex) => (
              <div
                className="faq-answer mt-2 badgestreamdetection"
                key={ansIndex}
              >
                <div className="custom-checkbox me-3">
                  <input
                    type="checkbox"
                    id={"answerCheckbox" + index + "-" + ansIndex}
                    checked={answer?.checked}
                    onChange={() => toggleCheckbox(index, ansIndex)}
                    onClick={(event) => event.stopPropagation()}
                  />
                  <label
                    htmlFor={"answerCheckbox" + index + "-" + ansIndex}
                  ></label>
                </div>
                {answer?.text}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
export default StreamSelection;
