import React from "react";
import { Stream } from "../../../pages/stream/StreamScript";

function BadgesIcon(props) {
  let existingbadge = props.existingbadge
  let badgeslist=props.output
  return (
    <>
   {badgeslist?.map((entry, index) => {
    const keys = Object.keys(entry);
    return (
        <span key={index} className={`new_TagsStyle TagsFloating ${keys.map(key => Stream.badgeriskcolour(entry[key])).join(' ')}`}>
            {keys.map((key, index) => (
                <React.Fragment key={index}>
                    {key === existingbadge && (
                        <span className="material-symbols-outlined">local_police</span>
                    )}
                    {entry[key]}
                </React.Fragment>
            ))}
        </span>
    );
})}
   
    </>
  );
}

export default BadgesIcon;
