import React from "react";
import DatePickerComponent from "../../../components/common/calender/DatePickerComponent";
import { Dashboard } from "../DashboardScript";
import { t } from "i18next";
import ToastPopup from "../../../components/common/ToastPopup";

function DashboardMetadata({ dashboard }) {
  const dashboardDate = new Date(dashboard.lastupdated);
  const selecteddate = localStorage.getItem(`date_${dashboard._id}${"dashboardDatePicker"}`)


  

  return (
    <>
      <div className="col-6">
        <div className="d-flex align-items-center">
          <span className="bxIcon_link ">{dashboard?.title.charAt(0)}</span>
          <div className="dashBText">
            <label className="dashboard_title">
              <span className="text-white"> {dashboard?.title}</span>
            </label>
            <p className="m-0" title={dashboard?.description}>
              {dashboard?.description}
            </p>
            <ul className="rightSearchStatus list-inline-item mb-0 p-0">
              <li>
                {dashboardDate?.toDateString()}, {dashboardDate?.toTimeString()}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="rightResultActionBtns">
          <ul className="d-flex mb-0 p-0 align-item-center">
            <DatePickerComponent
              id="dashboardDatePicker"
              dashboardid={dashboard?._id}
              onClickFn={Dashboard.renderDatePicker}
              singleDate={false}
            />

            <li className="ms-1 bxDelete" id="bxDeleteStream">
              <button
                className="bxDeletebtn"
                type="button"
                data-bs-toggle="popover"
                title="Delete"
                onClick={() => {
                  Dashboard.deleteConfirmation(dashboard.title);
                }}
              >
                <span className="material-symbols-outlined">delete</span>
              </button>
            </li>

            <div className="dropdown mt-2">
              <button
                className="dropdown-toggle arrow-none"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              >
                <i className="material-symbols-outlined text-white">
                  more_vert
                </i>
              </button>
              <ul className="dropdown-menu dashboardDots">
                <h6 className="ps-2 border-bottom pb-2">Actions</h6>
                <li
                  className="d-flex align-items-center bxReloadBtn"
                  title="Refresh"
                  type="button"
                  data-bs-toggle="popover"
                  onClick={() => {
                    Dashboard.reRenderWidgets(selecteddate);
                    ToastPopup(t("refresh.toast"));
                  }}
                >
                  <span className="material-symbols-outlined">refresh</span>
                  Refresh
                </li>
                <li
                  className="d-flex align-items-center bxDownloadBtn"
                  type="button"
                  data-bs-toggle="popover"
                  title="Download"
                  onClick={() => {
                    Dashboard.downloadPDF(dashboard?.title);
                  }}
                >
                  <span className="material-symbols-outlined">download</span>
                  Download
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
}

export default DashboardMetadata;
