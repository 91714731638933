import DataExpandable from "./DataExpandable";
import { Organization } from "../OrganizationScript";

function OrganizationRow(props) {
  const { columns, data, id } = props;
  let openform = {};
  if (id === "securityuser") {
    openform["data-bs-target"] = "#offcanvasAddUser";
    openform["aria-controls"] = "offcanvasAddUser";
  } else if (id === "securitygroup") {
    openform["data-bs-target"] = "#offcanvasAddGroup";
    openform["aria-controls"] = "offcanvasAddGroup";
  } else if (id === "securityrole") {
    openform["data-bs-target"] = "#offcanvasAddRole";
    openform["aria-controls"] = "offcanvasAddRole";
  } else {
    openform["data-bs-target"] = "#offcanvasAddTenant";
    openform["aria-controls"] = "offcanvasAddTenant";
  }
  
  return (
    <>
      {data.map((rowData, rowIndex) => (
        <tr key={rowIndex}>
          {columns.map((column) =>
            column === "_id" ? null : (
              <td
                key={column}
                className={
                  column === "status"
                    ? "bxOpacity"
                    : "" || column === "updatedon"
                    ? "classupdatedon"
                    : ""
                }
              >
                {column === "status" ? (
                  <>
                    {rowData[column] === "ACTIVE" ? (
                      <span className="badge badge-success-lighten">
                        {rowData[column]}{" "}
                      </span>
                    ) : (
                      <span className="badge badge-danger-lighten">
                        {rowData[column]}
                      </span>
                    )}
                  </>
                ) : id === "tenant" && column === "name" ? (
                  <>
                    <span
                      className={"badge tenantname-badge"}
                      style={{ backgroundColor: rowData.colorcode }}
                    >
                      {rowData.name}
                    </span>
                  </>
                ) : column === "updatedon" ? (
                  // Display updated time in date-time format
                  <>{new Date(parseInt(rowData[column])).toLocaleString()}</>
                ) : column === "roles" ? (
                  <DataExpandable
                    column="roles"
                    rowData={rowData}
                    maxToShow={2}
                  />
                ) : column === "groups" ? (
                  <DataExpandable
                    column="groups"
                    rowData={rowData}
                    maxToShow={2}
                  />
                ) : column === "urls" ? (
                  <DataExpandable
                    column="urls"
                    rowData={rowData}
                    maxToShow={2}
                  />
                ) : column === "tenants" ? (
                  <DataExpandable
                    column="tenants"
                    rowData={rowData}
                    maxToShow={2}
                  />
                ) : column === "streams" ? (
                  <DataExpandable
                    column="streams"
                    rowData={rowData}
                    maxToShow={2}
                  />
                ) : Array.isArray(rowData[column]) ? (
                  rowData[column].join(", ")
                ) : (
                  rowData[column]
                )}
              </td>
            )
          )}
          <td className="bxDelete classupdatedon">
          {rowData.name!== "ROLE_CUSTOMER_ADMIN" && rowData.name!== "GROUP_ADMIN" && rowData.name!== "ROLE_ADMIN" && !(id === "securityuser"&&(rowData?.roles[0]?.name === "ROLE_CUSTOMER_ADMIN" || rowData?.groups[0]?.name === "GROUP_ADMIN"))?(<>
            <span
              className="me-3"
              onClick={() => {
                Organization.handleedit(rowData, props.id); Organization.showClass();
              }}
              data-bs-toggle="offcanvas"
              data-bs-target={openform["data-bs-target"]}
              aria-controls={openform["aria-controls"]}
            >
              <span className="material-symbols-outlined">
                {" "}
                edit
              </span>
            </span>

            <button
              className="bxDeletebtn"
              onClick={() => {
                Organization.deleteConfirmation(
                  rowData.Name?rowData.Name:rowData.name,
                  props.id,
                  rowData._id
                );
              }}
            >
              <span className="material-symbols-outlined">
              delete
              </span>
            </button></>):null}
          </td>
        </tr>
      ))}
    </>
  );
}

export default OrganizationRow;
