import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n/config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("body-wrapper"));
root.render(
  <React.StrictMode>
    <App />
    <ToastContainer />
  </React.StrictMode>
);

// import App from './App'; // Replace with the correct path to your main component

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//     <ToastContainer />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
