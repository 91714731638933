import Highcharts from "highcharts";
import React, { useLayoutEffect, useRef } from "react";

const LineChart = (props) => {
  const chartRef = useRef(null);

  const series = props.series.series;
  const categories = props.categories;
  const container = props.container;
  const layout=props.layout;
  const height=props.height;
//   let series=[{
//         "data": [9,5,3,1,7,2,        ],
//         "color": "#ff7300",
//         "name": "detections"
//     }
// ]

// let categories=["Executable file download","Executable file download","Node CPU usage","System settings changed",
//   "Email Suspicious Attachment","Login from multiple locations"]

  useLayoutEffect(() => {
    let widgetheight=null
    if (chartRef.current) {
      const parent = container.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );
      if(layout==='conciselayout'){
        widgetheight =
        parseInt(document.getElementById(parent)?.clientHeight) - 70;
      }else{
        widgetheight =
        parseInt(document.getElementById(parent)?.clientHeight) - 120;
      } 

      Highcharts.chart(chartRef.current, {
        chart: {
          type: "spline",
          height:height ? height +"px": widgetheight+ "px",
        },
        title: {
          text: "",
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: categories,
          tickInterval: layout==='conciselayout' ? 1: 4,
          labels: {
            useHTML: true,
            formatter: function () {
              const label = this.value;
              const maxLength = 5; // Set the maximum length for the label
              const truncatedLabel = label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
              return `<span title="${label}">${truncatedLabel}</span>`;
            },
            style: {
              color: '#b3771a',
              textOverflow: 'ellipsis',
            },
          },
        },
        legend: {
          enabled:false
        },
        plotOptions: {
         
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: 0,
            point: {
              events: {},
            }
          },
        },
        series: series,
      });
    }
  }, [series, categories, container]);

  return <div ref={chartRef} id={container} />;
};

export default LineChart;
