function ErrorDisplay(props) {
  return (
    <>
      <div className="bxMessageApplyOuter">
        <div className="bxMessageApply d-none">
          <div className="alert bxBgColorDangerOuter">
            <div className="bxAleartTextMessage">
              <span className="bxBgColorDanger">
                <i className="material-symbols-outlined">exclamation</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorDisplay;
