import React from "react";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";
import TrendChart from "../../../components/highChart/TrendChart";
import BarChart from "../../../components/highChart/BarChart";

const DashboardTrendLayout = (props) => {
  const series = props.series?.[0]?.data;
  const categories = props.categories;
  const statname = props.series?.[0]?.name;
  const total = props.total;
  const graphtype=props.graphtype;
  const name=props.name;
  
  return (
    <>
      <div className="col equal-height-column">
        <div className="card widget-flat topcard">
          <div className="card-body p-2">
            <div className="d-flex align-items-center">
              <div className="widgetText">
                <h3 className="text-white fw-bold mb-1 ms-2">
                  {CommonTemplate.formatNumber(total)}
                </h3>
                <h5
                  className="text-uppercase fw-normal mt-0 mb-0 ms-2"
                >
                  {statname?statname:name}
                </h5>
                
              </div>
              <div className="graph" id={"bnfxWidgetStatChart-" + props.id}>
              {graphtype === 'trend' && (
                  <TrendChart
                    series={series}
                    categories={categories}
                    container={"bnfxWidgetStatChart-" + props.id}
                    height={60}
                    hidex={true}
                    color={'#23483b'}
                  />
                )}
                {graphtype === 'bar' && (
                  <BarChart
                    series={props.series}
                    categories={categories}
                    container={"bnfxWidgetStatChart-" + props.id}
                    layout="statcard"
                    height={85}
                    hidex={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardTrendLayout;
