import React, { useEffect, useReducer, useRef } from "react";
import "../../../pages/stream/allicons.css";
import { Content } from "../ContentScript";
import {
  FORM_FOR_NON_PIPELINE_TERMINAL_NAME,
  RIGHT_PANEL_LABELS,
} from "../contentConstants";
import {
  CONTENT_CONSTANTS,
  contentReducer,
  initialState,
} from "../contentReducer";
import ContentItemRightPanel from "./ContentItemRightPanel";
import ContentListLeftPanel from "./ContentListLeftPanel";

function LayoutPanel() {
  const [state, dispatch] = useReducer(contentReducer, initialState);

  const pageloaded = useRef(false);

  useEffect(() => {
    if (pageloaded.current) {
      return;
    }

    const fetchData = async () => {
      try {
        let payload = {
          offset: state.offset,
          type: state.selectedLeftPanelItemName,
          terminalName: state.terminalName,
          count: true,
        };
        const apiResponse = await Content.loadContentItemsList(payload, null);
        let data = {};
        if (apiResponse?.response?.total) {
          data = {
            contentItemList: apiResponse?.response?.result,
            contentItemTotal: apiResponse?.response?.total,
          };
        } else {
          data = {
            contentItemList: apiResponse?.response,
          };
        }
        dispatch({
          type: CONTENT_CONSTANTS.SET_CONTENT_ITEM_LIST,
          data: { ...data, contentItemOffset: state.offset },
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    pageloaded.current = true;
    return () => {};
  }, []);

  const onLeftPanelItemClick = async (itemName) => {
    if (document.querySelector(".contentEditPage")) {
      document
        .querySelector(".contentEditPage")
        .classList.remove("contentNonStreamfromOpen");
    }

    // get terminal name as this is singular and is needed to call the terminal api's
    const terminalName = RIGHT_PANEL_LABELS[itemName].terminal;

    if (document.getElementById("contentItemSearch")) {
      document.getElementById("contentItemSearch").value = "";
    }
    dispatch({
      type: CONTENT_CONSTANTS.ON_PANEL_CLICK,
      data: { terminalName: terminalName, selectedLeftPanelItemName: itemName },
    });
    let payload = {
      offset: 0,
      type: itemName,
      terminalName: terminalName,
      count: true,
    };
    Content.getItemsListData(dispatch, payload);
  };

  const onCreateOrEditClick = async (data, terminalName) => {
    // adding class to add scroll

    if (
      document.querySelector(".contentEditPage")
    ) {
      document
        .querySelector(".contentEditPage")
        .classList.add("contentNonStreamfromOpen");
    }
    if (FORM_FOR_NON_PIPELINE_TERMINAL_NAME.includes(terminalName)) {
      Content.createOrEditOfSecretAndVariables(dispatch, data, terminalName);
    } else {
      Content.createOrEditOfPipelineAndDashboard(dispatch, data, terminalName);
    }
  };

  const onCloseEditOrCreate = async (data, terminalName) => {
    if (document.querySelector(".contentEditPage")) {
      document
        .querySelector(".contentEditPage")
        ?.classList?.remove("contentNonStreamfromOpen");
    }
    dispatch({
      type: CONTENT_CONSTANTS.ON_CLOSE_CREATE_OR_EDIT,
    });
  };

  const {
    isLoading,
    selectedLeftPanelItemName,
    terminalName,
    contentItemList,
    contentItemSearchText,
    contentItemOffset,
    contentItemTotal,
    isCreateOrEdit,
    streamFields,
    nonStreamFields,
    tenantsList,
    isCodeGeneratorPanelOpen,
    codeGeneratorInputs,
    codeGeneratorFormFields,
    isCodeGeneratorLoading,
    testCodeFormValues,
    errors,
    isTestCodeLoading,
    secretsOrVariablesFields,
  } = state;
  return (
    <div class="container-fluid py-5 mt-4">
      <div class="col-xxl-11 col-xl-12 m-auto">
        <div class="row">
          <ContentListLeftPanel
            selectedLeftPanelItemName={selectedLeftPanelItemName}
            onLeftPanelItemClick={onLeftPanelItemClick}
          />
          <ContentItemRightPanel
            isLoading={isLoading}
            selectedLeftPanelItemName={selectedLeftPanelItemName}
            terminalName={terminalName}
            contentItemList={contentItemList}
            contentItemSearchText={contentItemSearchText}
            contentItemOffset={contentItemOffset}
            contentItemTotal={contentItemTotal}
            isCreateOrEdit={isCreateOrEdit}
            onCreateOrEditClick={onCreateOrEditClick}
            onCloseEditOrCreate={onCloseEditOrCreate}
            streamFields={streamFields}
            nonStreamFields={nonStreamFields}
            dispatch={dispatch}
            tenantsList={tenantsList}
            isCodeGeneratorPanelOpen={isCodeGeneratorPanelOpen}
            codeGeneratorInputs={codeGeneratorInputs}
            codeGeneratorFormFields={codeGeneratorFormFields}
            isCodeGeneratorLoading={isCodeGeneratorLoading}
            testCodeFormValues={testCodeFormValues}
            errors={errors}
            isTestCodeLoading={isTestCodeLoading}
            secretsOrVariablesFields={secretsOrVariablesFields}
          />
        </div>
      </div>
    </div>
  );
}

export default LayoutPanel;
