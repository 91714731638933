import $ from "jquery";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import { t } from "i18next";
import { ajax } from "../Service";
import { showError } from "../DisplayError";
import { requestURI } from "../RequestURIs";
import NoContent from "../NoContent";
import Pagination from "../pagination/Pagination";
import CompleteBadgeListLayout from "./CompleteBadgeListLayout";
import Swal from "sweetalert2";
import ToastPopup from "../ToastPopup";
import { getGlobalState, setGlobalState } from "../GlobalVariables";
import BadgeTab from "./BadgeTab";
import jwt from 'jwt-decode'
import ShowErrorToastPopup from "../ShowErrorToastPopup";

const token = localStorage.getItem("token");
export const BadgePanelScript = {
  endDate: null,
  defaultEndDate: null,
  badgelisttotal: null,
  searchtimeout: [],
  total_entity: [],
  badgeSelectedRisk: null,
  newbadgename: null,
  newbadgedescription: null,
  entitytype: null,
  detectionData: null,
  streamdetails: null,
  type: "",
  tenant: null,
  streamid: null,
  entity: null,
  streamoffset: null,
  total_entitytype: null,
  badgetenantoptions: [],
  showtype: null,
  radio_btn_type:null,


  badgecriticalityoptions: [
    { value: "CRITICAL", label: "CRITICAL" },
    { value: "HIGH", label: "HIGH" },
    { value: "MEDIUM", label: "MEDIUM" },
    { value: "LOW", label: "LOW" },
    { value: "donothing", label: "SUPPRESS" },
  ],

  TenantsList: () => {
    const userdetails = jwt(token);

    let tenantcount = userdetails?.tenants?.length;
    if (tenantcount == null || tenantcount === 0) {
      BadgePanelScript.getTenantsList();
    } else {
      let tokentenantlist = userdetails.tenants;
      const tenantList = tokentenantlist?.map((item) => ({
        value: item,
        label: item,
      }));
      BadgePanelScript.badgetenantoptions = tenantList;

      BadgePanelScript.selectTenantChange(tenantList[0]);
    }
  },
  selectTenantChange: (selectedTenant) => {
    BadgePanelScript.tenant = selectedTenant;
  },

  getTenantsList: () => {
    ajax(
      "get",
      requestURI["list.tenant"],
      null,
      null,
      BadgePanelScript.renderTenantList,
      showError
    );
  },

  renderTenantList: (response) => {
    let tenantlist = response.data.response;

    const tenantList = tenantlist.map((item) => ({
      value: item.name,
      label: item.name,
    }));
    BadgePanelScript.badgetenantoptions = tenantList;

    BadgePanelScript.selectTenantChange(tenantList[0]);
  },
  getEntities: (entityList) => {
    BadgePanelScript.total_entity = entityList;
  },

  handleRiskChange: (option) => {
    BadgePanelScript.badgeSelectedRisk = option?.value;
  },

  createBadge: (selectedValues) => {
    let selectedgrouptype = getGlobalState("selectedBadgeGroupsType");
    let selectedgroupvalue = getGlobalState("selectedBadgeGroupValue");
    let request=null;
    if(selectedValues && Object.keys(selectedValues).length > 0 ){
    
      request = {
        badge: {
          name: BadgePanelScript.newbadgename,
          description: BadgePanelScript.newbadgedescription,
          enddatetime: BadgePanelScript.endDate
            ? BadgePanelScript.endDate
            : BadgePanelScript.defaultEndDate,
          criticality: BadgePanelScript.badgeSelectedRisk
            ? BadgePanelScript.badgeSelectedRisk
            : BadgePanelScript.badgecriticalityoptions[0].label,
          entitytype: BadgePanelScript.radio_btn_type === 'group' ? null : (BadgePanelScript.total_entitytype
            ? BadgePanelScript.total_entitytype
            : BadgePanelScript.entitytype),
          entities: BadgePanelScript.radio_btn_type === 'group' ? null : (BadgePanelScript.entity
            ? BadgePanelScript.entity
            : BadgePanelScript.total_entity),
          grouptype: BadgePanelScript.radio_btn_type === 'entity' ? undefined : selectedgrouptype.label,
          groupvalue: BadgePanelScript.radio_btn_type === 'entity' ? undefined : selectedgroupvalue.label,
          tenant: BadgePanelScript.tenant.value,
          streams: selectedValues,
        },
      timezone:CommonTemplate.Timezone

      };
    }else{
      request = {
        badge: {
          name: BadgePanelScript.newbadgename,
          description: BadgePanelScript.newbadgedescription,
          enddatetime: BadgePanelScript.endDate
            ? BadgePanelScript.endDate
            : BadgePanelScript.defaultEndDate,
          criticality: BadgePanelScript.badgeSelectedRisk
            ? BadgePanelScript.badgeSelectedRisk
            : BadgePanelScript.badgecriticalityoptions[0].label,
          entitytype: BadgePanelScript.radio_btn_type === 'group' ? null : (BadgePanelScript.total_entitytype
            ? BadgePanelScript.total_entitytype
            : BadgePanelScript.entitytype),
          entities: BadgePanelScript.radio_btn_type === 'group' ? null : (BadgePanelScript.entity
            ? BadgePanelScript.entity
            : BadgePanelScript.total_entity),
          grouptype: BadgePanelScript.radio_btn_type === 'entity' ? undefined : selectedgrouptype.label,
          groupvalue: BadgePanelScript.radio_btn_type === 'entity' ? undefined : selectedgroupvalue.label,
          tenant: BadgePanelScript.tenant.value,
        },
      timezone:CommonTemplate.Timezone
      };
    }
     
    ajax(
      "post",
      requestURI["badge.create"],
      null,
      request,
      BadgePanelScript.renderBadgeData,
      showError,
      [selectedValues]
    );
  },

  renderBadgeData: (response,args) => {
    let selectedValues=args[0];
    if(selectedValues && Object.keys(selectedValues).length > 0 ){
      ToastPopup(t("badge_create_sucessfully_for_selected_stream"));
    }
    else{
      ToastPopup(t("badge_create_sucessfully_for_all_stream"));
    }
    
  
    BadgePanelScript.showBadgeListPanel();
    BadgePanelScript.badgeList(0);
  },

  // getbadgeslist: (stream, type) => {
  //   let request = {
  //     streamid: stream.streamid,
  //     detectionid: stream.detectionid,
  //     entityid: stream.entity,
  //     entitytype: stream.entitytype,
  //   };

  //   ajax(
  //     "post",
  //     requestURI["badge.getBadges"],
  //     null,
  //     request,
  //     BadgePanelScript.renderBadgesList,
  //     showError,
  //     [stream]
  //   );
  // },
  // renderBadgesList: (response, args) => {
  //   let result = response.data.response;
  //   let stream = args[0];
  //   if (result) {
  //     let data = (
  //       <BadgesIcon output={result} existingbadge={stream.detectedbadge} />
  //     );
  //     const container = document.querySelector(
  //       "#badges" + stream.detectiontime + stream.streamid + stream.detectionid
  //     );
  //     CommonTemplate.ContainerAvailability(container, data);
  //   }
  // },

  updateBadge: (selectedValues, badgedata) => {
    let selectedgrouptype = getGlobalState("selectedBadgeGroupsType");
    let selectedgroupvalue = getGlobalState("selectedBadgeGroupValue");
    let request=null;
    if(selectedValues && Object.keys(selectedValues).length > 0){
      request = {
        badge: {
          id: badgedata.id,
          description: BadgePanelScript.newbadgedescription,
          criticality: BadgePanelScript.badgeSelectedRisk,
          enddatetime: BadgePanelScript.endDate
            ? BadgePanelScript.endDate
            : BadgePanelScript.defaultEndDate,
            entitytype: BadgePanelScript.radio_btn_type === 'group' ? null : (BadgePanelScript.total_entitytype
              ? BadgePanelScript.total_entitytype
              : BadgePanelScript.entitytype),
            entities: BadgePanelScript.radio_btn_type === 'group' ? null : (BadgePanelScript.entity
              ? BadgePanelScript.entity
              : BadgePanelScript.total_entity),
            grouptype: BadgePanelScript.radio_btn_type === 'entity' ? undefined : selectedgrouptype.label,
            groupvalue: BadgePanelScript.radio_btn_type === 'entity' ? undefined : selectedgroupvalue.label,
            streams: selectedValues,
        },
      timezone:CommonTemplate.Timezone

      };
    }else{
      request = {
        badge: {
          id: badgedata.id,
          description: BadgePanelScript.newbadgedescription,
          criticality: BadgePanelScript.badgeSelectedRisk,
          enddatetime: BadgePanelScript.endDate
            ? BadgePanelScript.endDate
            : BadgePanelScript.defaultEndDate,
            entitytype: BadgePanelScript.radio_btn_type === 'group' ? null : (BadgePanelScript.total_entitytype
              ? BadgePanelScript.total_entitytype
              : BadgePanelScript.entitytype),
            entities: BadgePanelScript.radio_btn_type === 'group' ? null : (BadgePanelScript.entity
              ? BadgePanelScript.entity
              : BadgePanelScript.total_entity),
            grouptype: BadgePanelScript.radio_btn_type === 'entity' ? undefined : selectedgrouptype.label,
            groupvalue: BadgePanelScript.radio_btn_type === 'entity' ? undefined : selectedgroupvalue.label,
        },
      timezone:CommonTemplate.Timezone
      };
    }
     

    ajax(
      "post",
      requestURI["badge.update"],
      null,
      request,
      BadgePanelScript.renderUpdateBadgeData,
      showError
    );
  },
  renderUpdateBadgeData: (response) => {
    ToastPopup(t("badge_update_msg"));
    setGlobalState("selectedBadgeGroupsType","")
    setGlobalState("selectedBadgeGroupValue","")
    BadgePanelScript.showBadgeListPanel();
    BadgePanelScript.badgeList(0);
  },

  completeBadgeList: (type) => {
    BadgePanelScript.showtype = type;
    BadgePanelScript.streamid = null;
    BadgePanelScript.entity = null;
    BadgePanelScript.entitytype = null;
    BadgePanelScript.total_entity = null;
    BadgePanelScript.total_entitytype = null;
    BadgePanelScript.type=null;
    BadgePanelScript.badgeList(0);
  },

  badgeList: (offset) => {
    let freetext = CommonTemplate.getSearchText("badgelist_search");

    const request = {
      limit: Math.floor(
        BadgePanelScript.bxOffcanvasHeights() /
          (window.innerWidth > 1500 ? 82 : 75)
      ),
      offset: offset ? offset : 0,
      freetext: freetext ? freetext : undefined,
      filter: {
        streamid: BadgePanelScript.streamid,
        entity: BadgePanelScript.entity ? BadgePanelScript.entity[0] : null,
        entitytype: BadgePanelScript.entitytype,
      },
    };

    ajax(
      "post",
      requestURI["badge.list"],
      null,
      request,
      BadgePanelScript.renderBadgeList,
      showError,
      [offset]
    );
  },

  renderBadgeList: (response, args) => {
    let status = response.data.response.status;

    if (status === "FAILED" || status === "failed") {
      const container = document.querySelector("#bnfxbadgeList");
      const data = (
        <NoContent
          id={"nobadgelist"}
          title={t("no_badge_title")}
          description={t("no_badge_description")}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      const result = response.data.response.result;
      const total = response.data.response.total;
      if (total !== undefined) {
        BadgePanelScript.badgelisttotal = total;
      }
      let offset = args[0];
      const container = document.querySelector("#bnfxbadgeList");

      if (container) {
        const data = (
          <CompleteBadgeListLayout
            result={result}
            callback={() =>
              BadgePanelScript.renderPagination(
                BadgePanelScript.badgelisttotal,
                offset
              )
            }
          />
        );
        CommonTemplate.ContainerAvailability(container, data);
      }
    }
  },

  renderPagination: (total, offset) => {
    if (total > offset) {
      const paginationData = {
        total: total,
        limit: Math.floor(
          BadgePanelScript.bxOffcanvasHeights() /
            (window.innerWidth > 1500 ? 82 : 75)
        ),
        offset: offset ? offset : 0,
      };

      const container = document.getElementById("bnfxbadgeListPagination");

      const data = (
        <Pagination
          pagination={paginationData}
          onClickFn={BadgePanelScript.getBadgeListPagination}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  getBadgeListPagination: (offset) => {
    BadgePanelScript.badgeList(offset);
  },

  badgeAllDetails: (detectionData, streamdetails, type) => {
    BadgePanelScript.showtype=null;
    BadgePanelScript.detectionData = detectionData;
    BadgePanelScript.streamdetails = streamdetails;
    BadgePanelScript.type = type;
    BadgePanelScript.tenant = detectionData.tenant;
    BadgePanelScript.showtype = null;

    let entity = [];
    if (type === "hr_employee_data") {
      BadgePanelScript.streamid = null;
      entity.push(`${detectionData?.firstname} ${detectionData?.lastname}`);
      BadgePanelScript.entity = entity;
      BadgePanelScript.entitytype = "employee";
    } else {
      BadgePanelScript.streamid = detectionData.streamid;
      entity.push(detectionData.entity);
      BadgePanelScript.entity = entity;
      BadgePanelScript.entitytype = detectionData.entitytype;
    }
    BadgePanelScript.badgeList(0);
    //then call loadCreateBadgepanel();
  },

  getGroups: () => {
    const request = {
      limit: 100,
      offset: 0,
    };

    ajax(
      "post",
      requestURI["entity.groups"],
      null,
      request,
      BadgePanelScript.renderGroups,
      showError
    );
  },
  renderGroups: (response) => {
    let groupTypes = response.data.response.result;

    const groups = groupTypes.map((item) => ({
      value: item,
      label: item,
    }));
    setGlobalState("GroupTypeOption", groups);
  },

  getGroupValues: (groupvalue) => {
    
    const request = {
      limit: 100,
      offset: 0,
      label: groupvalue,
    };

    ajax(
      "post",
      requestURI["entity.group.values"],
      null,
      request,
      BadgePanelScript.renderGroupValues,
      showError
    );
  },
  renderGroupValues: (response) => {
    let groupValue = response.data.response.result;

    const groupvalue = groupValue.map((item) => ({
      value: item,
      label: item,
    }));
    setGlobalState("GroupValueOption", groupvalue);
  },

  deleteConfirmation: (badgeid, badgename) => {
    Swal.fire({
      title: t("delete.title.confirmation"),
      text: t("delete.description.confirmation") + badgename,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("stream.delete.yes"),
    }).then((result) => {
      if (result.isConfirmed) {
        BadgePanelScript.removeBadge(badgeid);
      }
    });
  },

  removeBadge: (badgeid) => {
    const request = {
      identifier: badgeid,
    };

    ajax(
      "post",
      requestURI["badge.remove"],
      null,
      request,
      BadgePanelScript.renderDeleteBadge,
      showError,
      [badgeid]
    );
  },

  renderDeleteBadge: (response, args) => {
    ToastPopup(t("badge_deleted_msg"));
    BadgePanelScript.badgeList(0);
  },

  bxOffcanvasHeights: () => {
    let panelheight = document.querySelector(".Badge_offcanvas")?.clientHeight;
    let headerheight = document.querySelector(
      ".BadgeRightSec_header"
    )?.clientHeight;

    let finalheight = panelheight - headerheight - 35 -42 -75;
    return finalheight;
  },

  search: (id) => {
    const freetext = document.querySelector("#" + id).value;

    if (freetext.length > 3 || freetext.length === 0) {
      if (
        BadgePanelScript.searchtimeout &&
        BadgePanelScript.searchtimeout.length > 0
      ) {
        for (
          let index = 0;
          index < BadgePanelScript.searchtimeout.length;
          index++
        ) {
          window.clearTimeout(BadgePanelScript.searchtimeout[index]);
        }
      }

      BadgePanelScript.searchtimeout.push(
        setTimeout(() => {
          BadgePanelScript.badgeList(0);
        }, 2000)
      );
    }
  },

  setEndDate: (endDate) => {
    BadgePanelScript.endDate = endDate;
  },

  setDefaultEndDate: (defaultEndDate) => {
    BadgePanelScript.defaultEndDate = defaultEndDate;
  },

  renderDatePicker: (current) => {
    const datepicker = $(current).data("daterangepicker");
    if (datepicker) {
      let defaultEndDate = datepicker.endDate.format("YYYY-MM-DD hh:mm A");
      BadgePanelScript.setDefaultEndDate(defaultEndDate);
      $(current).on("apply.daterangepicker", function (ev, picker) {
        const endDate = picker.endDate.format("YYYY-MM-DD hh:mm A");
        BadgePanelScript.setEndDate(endDate);
      });
    }
  },

  handleFormSubmit: (isedit, result) => {
    BadgePanelScript.newbadgename = document.getElementById("Badgename").value;
    BadgePanelScript.newbadgedescription =
      document.getElementById("Badgedescription").value;

    if (isedit) {
      BadgePanelScript.updateBadge(null, result);
    } else {
      if (
        BadgePanelScript.newbadgename &&
        BadgePanelScript.newbadgedescription
      ) {
        BadgePanelScript.clickTabSwitch("entitytab", "tabEntities");
      } else {
        ShowErrorToastPopup(t("fill_the_field_msg"));
        BadgePanelScript.handleChange();
      }
    }
  },

  badgeEntityFormSubmit: (isedit, result) => {
    BadgePanelScript.total_entitytype =
      document.getElementById("badgeentitytype").value;
    let selectedgrouptype = getGlobalState("selectedBadgeGroupsType");
    let selectedgroupvalue = getGlobalState("selectedBadgeGroupValue");

    if (isedit) {
      BadgePanelScript.updateBadge(null, result);
    } else {
      if (
        (BadgePanelScript.total_entitytype && BadgePanelScript.total_entity) ||
        (selectedgrouptype.value && selectedgroupvalue.value) ||
        (BadgePanelScript.entity && BadgePanelScript.entitytype)
      ) {
        BadgePanelScript.clickTabSwitch("streamtab", "tabStreams");
      } else {
        ShowErrorToastPopup(t("fill_the_field_msg"));
      }
    }
  },

  getSelectedStreams: (data) => {
    const selectedValues = {};
      data.forEach((faq) => {
      const selectedAnswers = faq?.answers?.filter((answer) => answer?.checked);
      if ( selectedAnswers?.length > 0) {
      const questionId = faq?.questionid;
      const answerIds = selectedAnswers.map((answer) => answer?.id);
      if (!selectedValues.hasOwnProperty(questionId)) {
      selectedValues[questionId] = [];
      }
      selectedValues[questionId] =
      selectedValues[questionId].concat(answerIds);
      } else if (selectedAnswers?.length === 0 && faq?.checked) {
      const questionId = faq?.questionid;
      let answerlist = faq?.answers.map((answer) => answer?.id);
      if (!selectedValues.hasOwnProperty(questionId)) {
      selectedValues[questionId] = [];
      }
      selectedValues[questionId] =
      selectedValues[questionId].concat(answerlist);
      }
      });
    return selectedValues;
    },

  savebadge: (data, result, isedit) => {
    const selectedValues = BadgePanelScript.getSelectedStreams(data);

    if (result !== null) {
      BadgePanelScript.updateBadge(selectedValues, result);
    } else {
        BadgePanelScript.clickTabSwitch("streamtab", "tabStreams");
        BadgePanelScript.createBadge(selectedValues);
        BadgePanelScript.showBadgeListPanel();
    }
  },

  showBadgeListPanel: () => {
    BadgePanelScript.badgeList(0);
    let showBadgeListPanel = document.querySelector(
      ".offcanvas.Badge_offcanvas.offcanvas-end"
    );
    if (showBadgeListPanel) {
      showBadgeListPanel.classList.add("show");
    }
    let removeCreateBadgePanel = document.querySelector(
      ".bxStreamLstOffCanvas.offcanvas.offcanvas-end.CrateBadgePage"
    );
    if (removeCreateBadgePanel) {
      removeCreateBadgePanel.classList.remove("show");
    }
  },

  removeBadgeListPanel: () => {
    let removeBadgeListPanel = document.querySelector(
      ".offcanvas.Badge_offcanvas.offcanvas-end"
    );
    if (removeBadgeListPanel) {
      removeBadgeListPanel.classList.remove("show");
    }
    let showCreateBadgePanel = document.querySelector(
      ".bxStreamLstOffCanvas.offcanvas.offcanvas-end.CrateBadgePage"
    );
    if (showCreateBadgePanel) {
      showCreateBadgePanel.classList.add("show");
    }
  },

  removeBadgePanel: () => {
    let removeBadgeListPanel = document.querySelector(
      ".offcanvas.Badge_offcanvas.offcanvas-end"
    );
    if (removeBadgeListPanel) {
      removeBadgeListPanel.classList.remove("show");
    }
  },
  handleInputChange: (e) => {
    let value = e.target.value;
    const badge_entities = document.querySelector("#badge_entities");

    if (value) {
      badge_entities.classList.add("active");
    } else {
      badge_entities.classList.remove("active");
    }
  },

  handleGroupChange: () => {
    const badge_group_option = document.querySelector(".badgeGroupOption");
    if (badge_group_option) {
      badge_group_option.classList.add("active");
    }

  },

  clickEntityRadioBtn: () => {
    BadgePanelScript.radio_btn_type="entity";
   
    const badge_entities = document.querySelector("#badge_entities");
    const badge_group_option = document.querySelector(".badgeGroupOption");
    if (badge_entities && badge_group_option) {
      badge_entities.classList.add("active");
      badge_group_option.classList.remove("active");
      // Manually uncheck the group radio button
      const groupRadioInput = document.querySelector("#bnfxgroupButton .radio-input");
      if (groupRadioInput) {
        groupRadioInput.checked = false;
      }

      const entityRadioInput = document.querySelector("#bnfxentityButton .radio-input");
      if (entityRadioInput) {
        entityRadioInput.checked = true;
      }
    }
  },
  
  clickGroupRadioBtn: () => {
    BadgePanelScript.radio_btn_type="group";
   
    const badge_group_option = document.querySelector(".badgeGroupOption");
    const badge_entities = document.querySelector("#badge_entities");
    if (badge_group_option && badge_entities) {
      badge_group_option.classList.add("active");
      badge_entities.classList.remove("active");
      // Manually uncheck the entity radio button
      const entityRadioInput = document.querySelector("#bnfxentityButton .radio-input");
      if (entityRadioInput) {
        entityRadioInput.checked = false;
      }
      const groupRadioInput = document.querySelector("#bnfxgroupButton .radio-input");
      if (groupRadioInput) {
        groupRadioInput.checked = true;
      }
    }

    
  },

  loadCreateBadgepanel: () => {
    document.getElementById("badgelist_search").value=""
    
    setGlobalState("StreamsOptions",[])
    setGlobalState("badgedit", false);

    let streamdetails = BadgePanelScript.streamdetails;
    let type = BadgePanelScript.type;
    BadgePanelScript.setStreamOffset(0)

    if (type === "stream_data") {
      let data = { value: streamdetails, label: streamdetails };
      setGlobalState("StreamsOptions", [data]);
    } else {
      BadgePanelScript.loadStreamList(0);
    }

    const container = document.getElementById("addtabslist");
    let data = (
      <BadgeTab data={null} type={BadgePanelScript.showtype} edit={false} />
    );
    CommonTemplate.ContainerAvailability(container, data);
    BadgePanelScript.getGroups();
  },

  editbadgeform: (data) => {
    let type = BadgePanelScript.type;
    
    if(type==='stream_data'){
      let stream = { value: BadgePanelScript.streamdetails, label: BadgePanelScript.streamdetails };
      setGlobalState("StreamsOptions", [stream]);
    }
    
    const container = document.getElementById("addtabslist");
    let layout = (
      <BadgeTab data={data} type={BadgePanelScript.showtype} edit={true} />
    );
    CommonTemplate.ContainerAvailability(container, layout);

    let grouptype = {
      label: data?.grouptype,
      value: data?.grouptype,
    };

    let groupvalue = {
      label: data?.groupvalue,
      value: data?.groupvalue,
    };
    setGlobalState("selectedBadgeGroupsType", grouptype);
    setGlobalState("selectedBadgeGroupValue", groupvalue);
    if (data.entities) {
      BadgePanelScript.getEntities(data.entities);
    }

    BadgePanelScript.removeBadgeListPanel();
  },
  loadStreamList: (offset) => {
    const request = {
      limit: 15,
      offset: offset ? offset : 0,
      sortcol: "name",
      sortorder: false,
      enabled: false,
      allContent: true,
      type: "packages.streams",
    };

    ajax(
      "post",
      requestURI["list.stream"],
      null,
      request,
      BadgePanelScript.renderStreamList,
      showError,
      [offset]
    );
  },

  renderStreamList: (response, args) => {
    let originalData = response.data.response;

    const stream = originalData
      .map((item) => {
        let correlationcount = item?.correlation?.count || 0;
        let detectionscount = item?.detections?.count || 0;

        if (!(correlationcount > 0 && detectionscount === 0)) {
          return {
            value: item,
            label: item.title,
          };
        }

        // Return null for items that don't meet the condition
        return null;
      })
      .filter(Boolean); // Filter out null values

    setGlobalState("StreamsOptions", (prevUrlOptions) => [
      ...prevUrlOptions,
      ...stream,
    ]);
  },

  loadmore: () => {
    BadgePanelScript.setStreamOffset(BadgePanelScript.streamoffset + 15);
    BadgePanelScript.loadStreamList(BadgePanelScript.streamoffset);
  },
  setStreamOffset: (offset) => {
    BadgePanelScript.streamoffset = offset;
  },
  isBadgeEdit: (edit) => {
    setGlobalState("badgedit", edit);
  },

  openTab: (evt, tabName) => {
    let selectedgrouptype = getGlobalState("selectedBadgeGroupsType");
    let selectedgroupvalue = getGlobalState("selectedBadgeGroupValue");

    BadgePanelScript.newbadgename = document.getElementById("Badgename").value;
    BadgePanelScript.newbadgedescription =
      document.getElementById("Badgedescription").value;
    BadgePanelScript.total_entitytype =
      document.getElementById("badgeentitytype").value;

    if (tabName === "tabEntities") {
      if (
        BadgePanelScript.newbadgename &&
        BadgePanelScript.newbadgedescription
      ) {
        BadgePanelScript.clickTabSwitch("entitytab", "tabEntities");
        BadgePanelScript.showActiveOnTabClick(evt, tabName);
      } else {
        ShowErrorToastPopup(t("fill_the_field_msg"));
        BadgePanelScript.handleChange();
      }
    } else if (tabName === "tabStreams") {
      if (
        BadgePanelScript.total_entitytype ||
        (selectedgrouptype.value && selectedgroupvalue.value) ||
        (BadgePanelScript.entity && BadgePanelScript.entitytype)
      ) {
        BadgePanelScript.clickTabSwitch("streamtab", "tabStreams");
        BadgePanelScript.showActiveOnTabClick(evt, tabName);
      } else {
        ShowErrorToastPopup(t("fill_the_field_msg"));
      }
    } else {
      BadgePanelScript.showActiveOnTabClick(evt, tabName);
    }
  },

  showActiveOnTabClick: (evt, tabName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("badge-content");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("active");
    }
    tablinks = document.getElementsByClassName("BadgeTab");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].classList.remove("active");
    }
    document.getElementById(tabName).classList.add("active");
    evt.currentTarget.classList.add("active");
  },

  clickTabSwitch: (tab, next) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("badge-content");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("active");
    }
    tablinks = document.getElementsByClassName("BadgeTab");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].classList.remove("active");
    }
    document.getElementById(next).classList.add("active");
    document.getElementById(tab).classList.add("active");
  },
  
  handleChange: () => {
    const requiredFields = ["Badgename", "Badgedescription"];

    requiredFields.forEach((fieldId) => {
      const field = document.getElementById(fieldId);
      if (!field || field.value.trim() === "") {
        field.classList.add("border-danger");
      } else {
        field.classList.remove("border-danger");
      }
    });
  },
  handleToggleChange: () => {
    let streamlist = document.querySelector(".badgestreams");
    if (streamlist?.classList?.contains("active")) {
      streamlist?.classList?.remove("active");
    } else {
      streamlist?.classList?.add("active");
    }
  },
};
