import React from "react";
import { Stream } from "../StreamScript";
import "jquery-toast-plugin"; // Import the jQuery Toast plugin CSS
import "react-toastify/dist/ReactToastify.css";
import { flatten } from "flat";
import moment from "moment";
import { BadgePanelScript } from "../../../components/common/Badge/BadgePanelScript";

const UserStreamLayout = (props) => {
  let data = props.detectionData;
  let detectionData = data;
  const detectionDate = moment(new Date(detectionData.lastupdate)).format(
    "DD  MMM hh:mm"
  );

  const { lastupdate, employmentstatus, email, ...requireDetectionData } =
    detectionData;
  const processedDetectionData = flatten(requireDetectionData);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="bxStreamEventDetection userHrData">
            <div className="bxSEDHead p-2  ">
              <div className="d-flex align-items-center justify-content-between">
                <div className="leftUserHrData">
                  <div className="d-flex align-items-center ">
                    {detectionData.firstname ? (
                      <h6 className="text-white m-0">
                        {detectionData.firstname} {detectionData.lastname}{" "}
                      </h6>
                    ) : null}
                    <p className="StatusBtn ms-3">
                      {detectionData.employmentstatus}
                    </p>
                  </div>
                  <ul className="hrUserEmailDate p-1 m-0">
                    <li>#{detectionData.employeeid}</li>
                    <li className="timer_box">{detectionData.email}</li>
                    <li className="timer_box">{detectionDate}</li>
                  </ul>
                </div>

                <div className="dropdown">
                  <button
                    className="dropdown-toggle arrow-none"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <i className="material-symbols-outlined text-white">
                      more_vert
                    </i>
                  </button>
                  <ul className="dropdown-menu streamDots">
                    <h6 className="ps-2 border-bottom pb-2">Explore</h6>
                    <li
                      className="bageEntity d-flex align-items-center"
                      id="createbadgetab"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBadge"
                      aria-controls="offcanvasBadge"
                      data-bs-dismiss="offcanvas"
                      onClick={() =>{
                        BadgePanelScript.badgeAllDetails(
                          detectionData,
                          props.streamdetails,"hr_employee_data")
                          BadgePanelScript.TenantsList();}
                      }
                    >
                      <span className="material-symbols-outlined">badge</span>
                      Manage Badge
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bxSEDBody">
              <div className="p-2 bxSEDBodyBox mx-2">
                <div className="bxSEDBodyBox_top">
                  <p>
                    {Object?.keys(processedDetectionData)?.map((key) => (
                      <React.Fragment key={key}>
                        <>
                          <span
                            className="bxEventKey"
                            onDoubleClick={() =>
                              Stream.handleDoubleClick(
                                key,
                                processedDetectionData[key]
                              )
                            }
                          >
                            {key}
                          </span>
                          <span
                            className="bxEventValue"
                            onDoubleClick={() =>
                              Stream.handleDoubleClick(
                                key,
                                processedDetectionData[key]
                              )
                            }
                          >{`${processedDetectionData[key]}`}</span>
                        </>
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </div>
              <div
                className="tags_foot mb-3 mt-2 px-2 d-block"
                id={
                  "badges" +
                  detectionData.detectiontime +
                  detectionData.streamid +
                  detectionData.detectionid
                }
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserStreamLayout;
