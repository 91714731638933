import React, { useEffect } from "react";

import { CommonTemplate } from "../containerAvailability/CommonScript";
import moment from "moment";
import { Stream } from "../../../pages/stream/StreamScript";
import { t } from "i18next";
import BadgesIcon from "../Badge/BadgeIcon";
import { Incidence } from "../IncidencePanel/IncidenceScript";
import { Automation } from "../Automations/AutomationScript";
import { PanelScript } from "./PanelScript";


function DetectionTimelineEvents(props) {
  let output = props.output
  
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });
 
  return (
    <div
      className="tab-pane fade active show text-white timeline_EventTab"
      id="events-timeline-tab-pane"
      role="tabpanel"
      aria-labelledby="events-tab"
      tabIndex="0"
    >
      <div className="bxDashboardAction_inner">
        <div className="form-group mb-3">
          {output?.map((entry, index) => (
            <div className="bxStreamEventDetection ">
              <div className=" bxSEDHead entityBox d-flex align-items-center justify-content-between">
                <div className="divScore d-flex align-items-center">
                  <p className="timer_box">
                    {moment(new Date(entry["detectiontime"])).format(
                      `DD  MMM ${
                        moment(new Date(entry["detectiontime"])).hour() >= 12
                          ? "HH"
                          : "hh"
                      }:mm`
                    )}
                  </p>
                  <h2 className="text m-0 mx-2">{entry.score} </h2>
                  <span
                    className={CommonTemplate.criticalityColor(
                      entry?.criticality
                    )}
                  >
                    {entry?.criticality}
                  </span>
                </div>
                <div className="timeline_dropdown">
                  <button
                    className="dropdown-toggle arrow-none"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                    onClick={() => PanelScript.handleClick(output)}
                  >
                    <i className="material-symbols-outlined text-white">
                      more_vert
                    </i>
                  </button>

                  <ul className="dropdown-menu streamDots">
                    <h6 className="ps-2 border-bottom pb-2">Explore</h6>
                    <li
                      className="bageEntity d-flex align-items-center"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      data-bs-dismiss="offcanvas"
                      title="Create Incident"
                      onClick={() => {
                        Incidence.loadformselectionoptions(
                          entry,
                          entry.detectionid,
                          entry.streamid,
                          entry.entity,
                          entry.entitytype,
                          entry.name,
                          "entitylist"
                        );
                        Automation.offcanvasCloseButton();
                      }}
                    >
                      <span className="material-symbols-outlined">
                        destruction
                      </span>
                      Create Incident
                    </li>
                    <li
                      className={`bageDetection d-flex align-items-center resloveButton ${
                        entry?.criticality === "NONE" ? "d-none" : ""
                      }`}
                      data-bs-target="#bxOffcanvasPanel"
                      title="Ressolve"
                      data-bs-toggle="offcanvas"
                      onClick={() => PanelScript.resolveDetection(output)}
                    >
                      <span className="material-symbols-outlined">link</span>
                      Ressolve
                    </li>

                    <li
                      className="bageDetection d-flex align-items-center"
                      data-bs-target="#bnfxdetctiongraph"
                      title="Attack Path"
                      data-bs-toggle="offcanvas"
                      onClick={(e) => {
                        PanelScript.graphrender(
                          entry.name,
                          entry.detectionid,
                          entry.entity,
                          entry.entitytype,
                          entry.tenant,
                          entry.score,
                          entry.streamid
                        );
                        Automation.offcanvasCloseButton();
                      }}
                    >
                      <span className="material-symbols-outlined">tactic</span>
                      Attack Path
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bxSEDBody">
                <div className="bxSEDBody_title">
                  <p className="m-0 double-space text-white">
                    {entry?.context}
                  </p>
                </div>
                <div className="p-2 bxSEDBodyBox mt-2">
                  <div className="bxSEDBodyBox_top">
                    <div className="bxSEDBodyBox_inner">
                      <p className="m-0">
                        <span className="bxEventKey">{t("title.entity")}</span>
                        <span className="bxEventValue">
                          {entry.entity} ({entry.entitytype})
                        </span>
                        <span className="bxEventKey">{t("title.tactic")}</span>
                        <span className="bxEventValue">{entry.tactic}</span>
                        <span className="bxEventKey">
                          {t("title.technique")}
                        </span>
                        <span className="bxEventValue">{entry.technique}</span>

                        <span className="bxEventKey">{t("title.name")}</span>
                        <span className="bxEventValue">{entry.name}</span>
                      </p>
                      <div
                        className="ExpandCollapseBtn"
                        onClick={(e) => Stream.loadmoredatacoallapse(e.target)}
                        data-bs-toggle="collapse"
                        data-bs-target={
                          "#collapsebxJsonText" + entry.detectionid + index
                        }
                        aria-expanded="false"
                        aria-controls="collapsebxJsonText"
                      >
                        <span className="material-symbols-outlined text-success">
                          expand_more
                        </span>
                      </div>
                    </div>
                    <div
                      className="bxJsonText collapse"
                      id={"collapsebxJsonText" + entry.detectionid + index}
                    >
                      {Object.keys(entry)
                        .filter(
                          (key) =>
                            key !== "context" &&
                            key !== "score" &&
                            key !== "criticality" &&
                            key !== "streamid" &&
                            key !== "detectionid" &&
                            key !== "detectiontime" &&
                            key !== "derived" &&
                            key !== "technique" &&
                            key !== "tactic" &&
                            key !== "entity" &&
                            key !== "entitytype" &&
                            key !== "streamname" &&
                            key !== "attachedbadges" &&
                            key !== "name" &&
                            key !== "tags" &&
                            key !== "messageid" &&
                            key !== "messageid.set" &&
                            key !== "features" &&
                            key !== "details"
                        )
                        .map((key) => (
                          <React.Fragment key={key}>
                            <span className="bxEventKey">{key}</span>
                            <span className="bxEventValue">{entry[key]}</span>
                          </React.Fragment>
                        ))}

                      {/* <button
                        className="text_RawData_icon float-end"
                        type="button"
                        title="Raw Data"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#bnfxRawDataLayout"
                        data-bs-backdrop={false}
                        onClick={() => {
                          CommonTemplate.renderrawdata(entry['messageid.set']?entry['messageid.set']:[entry?.messageid],entry.name);
                          Incidence.showClass();
                        }}
                      >
                        <span className="material-symbols-outlined">
                          home_storage
                        </span>
                        Raw Data
                      </button> */}
                    </div>
                  </div>
                </div>

                <div className="tags_foot mb-3 mt-2 px-2 d-block">
                  <BadgesIcon
                    output={entry.attachedbadges}
                    existingbadge={entry.detectedbadge}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetectionTimelineEvents;