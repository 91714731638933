// ActionData.js
import React, { useState } from "react";
import { Stream } from "../StreamScript";

function ActionData(props) {
  const [showAll, setShowAll] = useState(false);

  const limitedData = showAll ? props.data : props.data?.slice(0, 3);
 
  return (
    <div className="col-12">
      <span className="badge rounded-pill bg-secondary p-1 mb-2">
        {props.count} {props?.dataType}
      </span>
      {limitedData?.map((item, index) => (
        <>
        <div className={props?.dataType==="Sequences"?"d-flex align-items-center justify-content-between border-bottom  mb-4":"border-bottom mb-4"}>
          <div key={index} className="bxPopupLowerText pb-2  ">
            <h3 className="bxStreamTitle_link text-capitalize" title={item?.title}>
              {item?.title}
            </h3>
            <p className="bxStreamDiscription_link text-capitalize" title={item?.description}>
              {item?.description}
            </p>
          </div>
        
          {props?.dataType==="Sequences"?<div
              className="ExpandCollapseBtn"
              onClick={e => Stream.loadmoredatacoallapse(e.target)}
              data-bs-toggle="collapse"
              data-bs-target={"#collapsebxJsonText" + index}
              aria-expanded={limitedData.length === 1 ? "true" : "false"}
              aria-controls="collapsebxJsonText"
            >
              <span className="material-symbols-outlined text-success">
              expand_more
              </span>
            </div>:null}
            </div>
         {props?.dataType==="Sequences"? <div
            className={`bxJsonText collapse ${limitedData.length === 1 ? "show" : ""}`}
            id={"collapsebxJsonText" + index}
          >
             {<span dangerouslySetInnerHTML={{ __html: item?.details }} />}
          </div>:null}
         
        </>
      ))}
      {props.data && props.data.length > 3 && (
        <div className="text-end">
          <button type="button" className="actionDataCollapse_btn float-end" onClick={() => setShowAll(!showAll)}>
            {showAll ? "SHOW LESS" : "SHOW MORE"}
            <span className="material-symbols-outlined actionDataCollapse_Btn">
                {showAll ? "keyboard_double_arrow_up" : "keyboard_double_arrow_down"}
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

export default ActionData;
