import { Link } from "react-router-dom";
import { SearchTemplate } from "../../../../pages/search/SearchScript";
import { t } from "i18next";

function MainSearch() {

    return (
      <>
        <Link to="/searchpage">
          <div className="mainHunter">
            <span className="nav-link"  id="searchicon" onClick={SearchTemplate.handleClick}>
              <span className="material-symbols-outlined navsearchicon" >location_searching</span>
            </span>
            <small>{t("title.search")}</small>
          </div>
        </Link>
      </>
    );
  }
  
  export default MainSearch;
  