import { Organization } from "./OrganizationScript";
import {
  setGlobalState,
  useGlobalState,
} from "../../components/common/GlobalVariables";
import classNames from "classnames";
import { useState } from "react";
import Select from "react-select";
import { t } from "i18next";

function AddTenant() {
  const [color, setColor] = useState("#000000"); // Initial color state; // Initial color value

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };
  const [isEdit] = useGlobalState("isEdit");
  const [_id] = useGlobalState("_id");

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  let statusoptions = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "DEPRECATED", label: "DEPRECATED" },
  ];

  const [selectedStatus, setSelectedStatus] = useGlobalState("selectedStatus");
  const handlestatusChange = (selectedOptions) => {
    setSelectedStatus(selectedOptions);
  };
  const handleInputChange = () => {
    const requiredFields = [
      "tenant_name",
      "tenant_colorcode",
    ];
    let areAllFieldsFilled = true;

    requiredFields.forEach((fieldId) => {
      const field = document.getElementById(fieldId);
      if (!field || field.value.trim() === "") {
        areAllFieldsFilled = false;
        field.classList.add("border-danger"); // Add the class if the field is empty
      } else {
        field.classList.remove("border-danger"); // Remove the class if the field is filled
      }
    });
    setIsSaveEnabled(areAllFieldsFilled);
    
  };
  const clearfrom = () => {
    document.getElementById("tenant_name").value = "";
    document.getElementById("tenant_description").value = "";
    document.getElementById("tenant_colorcode").value = "";
    setGlobalState("isEdit", false);
    const requiredFields = [
      "tenant_name",
      "tenant_colorcode",
    ];
    requiredFields.forEach((fieldId) => {
      const field = document.getElementById(fieldId);

      field.classList.remove("border-danger");
    });
  };

  const handleFormSubmit = (e) => {
    e?.preventDefault();

    const formData = {
      Name: document.getElementById("tenant_name").value,
      Description: document.getElementById("tenant_description").value,
      Colorcode: document.getElementById("tenant_colorcode").value,
      tenantstatus: selectedStatus,
    };

    if (isEdit) {
      Organization.update_tenant(formData, "tenant", _id);
    } else {
      Organization.addTenant(formData);
    }
    clearfrom();
  };

  return (
    <>
      <div
        className="bxOffcanvasAddOrg AddTenant offcanvas bg-dark offcanvas-end"
        tabIndex="-1"
        id="offcanvasAddTenant"
        aria-labelledby="offcanvasAddTenantLabel"
        data-bs-backdrop="false"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title text-white"
            id="offcanvasAddTenantLabel"
          >
            {isEdit?t( "organization.update.tenant.header.name"):t("organization.add.tenant.header.name")}
          </h5>
        </div>
        <div className="offcanvas-body p-0">
          <div className="bxAddTenantForm p-4">
            <form action="" id="tenantform">
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="tenant_name" className="form-label">
                      {t("name")}
                    </label>
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "low-opacity": isEdit,
                      })}
                      id="tenant_name"
                      placeholder="Enter"
                      autoComplete="off"
                      name="Name"
                      required
                      readOnly={isEdit}
                      onChange={!isEdit ? handleInputChange : undefined}
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="tenant_description" className="form-label">
                      {t("descripition")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tenant_description"
                      placeholder="Enter"
                      autoComplete="off"
                      name="Description"
                      required
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="mb-2">
                    <label className="form-label">
                      {t("status")}
                    </label>
                    <Select
                      id="criticality"
                      aria-label="Default select example"
                      name="criticality"
                      options={statusoptions}
                      value={selectedStatus}
                      onChange={handlestatusChange}
                    ></Select>
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="tenant_colorcode" className="form-label">
                      {t("organization.tenant.colorcode")}
                    </label>
                    <input
                      type="color"
                      className="form-control"
                      id="tenant_colorcode"
                      placeholder="Enter"
                      autoComplete="off"
                      name="Colorcode"
                      // value={color}
                      onChange={(event) => {
                        handleColorChange(event);
                        handleInputChange();
                      }}
                    />
                    <small className="d-block text-start bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="offcanvas-footer py-2 px-3">
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="text-white me-3"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                clearfrom(); // Assuming clearfrom is a function
                Organization.removeClass();
              }}
            >
              {t("cancel")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="offcanvas"
              onClick={()=>{handleFormSubmit();Organization.removeClass();}}
              disabled={!isEdit &&!isSaveEnabled}
            >
              {" "}
              {t("name.save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTenant;
