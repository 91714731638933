
import { t } from "i18next";
import jwt from 'jwt-decode'
import moment from "moment";

function IncidenceActivitesChat(props) {
  let result = props.data
  let paginationscroll = null
  const token = localStorage.getItem("token");
  const userdetails = jwt(token);
  if (result !== undefined) {
    paginationscroll = <>

      {result && result.reverse().map((entry, index) => (
        <div className={entry.actiontakenby === userdetails.username ? "tl-container tl-right same-sender" : "tl-container tl-right"}  key={index} >
          <small className="date ">{moment(new Date(entry.actiontime)).format('DD MMM hh:mm')}</small>

          <b className="text-white d-block">{entry.actiontakenby===userdetails.username?"YOU":entry.actiontakenby}</b>
          <div className="tl-content mt-2">
            <div className="bxBgChat mb-0">
              <span dangerouslySetInnerHTML={{ __html: entry.actioncomment }} />
            </div>
          </div>
        </div>
      ))}
    </>
  } else {
    paginationscroll =
      <div className="bnfx_paginationscroll_nodata">
        <b >{t("incident.activities.scroll")}</b>
      </div>
  }
  return (
    paginationscroll
  );
}

export default IncidenceActivitesChat;