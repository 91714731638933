import { t } from "i18next";
import DatePickerComponent from "../calender/DatePickerComponent";
import { Incidence } from "./IncidenceScript";

function IncidenceReopen(props) {
  // const [status, setStatus] = useState("");
  // const [incidencestatusoptions] = useGlobalState("incidencestatusoptions");

  // const handleStatusChange = (selectedOptions) => {
  //     setStatus(selectedOptions);

  // };
  const handleFormSubmit = () => {
    Incidence.loadReopenIncident(props.data);
  };

  return (
    <>
      <div className="reopen-box p-3">
        <p className="d-block m-0">
          <button
            type="button"
            className="back-btn"
            onClick={(editorclass) => Incidence.displayback("reopen-box")}
          >
            <span className="material-symbols-outlined">arrow_back</span>
          </button>
        </p>
        <label htmlFor="IncidenceDatePicker" className="mt-3">
          {t("probe.time")} :
        </label>
        <span className=" form-control mb-3">
          <DatePickerComponent
            id="IncidenceDatePicker"
            onClickFn={Incidence.renderIncidenceDatePicker}
            singleDate={true}
          />
        </span>

        <button
          type="submit"
          value="Save"
          className="btn btn-primary mt-3"
          onClick={(e) => handleFormSubmit()}
        >
          {t("name.save")}
        </button>
      </div>
    </>
  );
}

export default IncidenceReopen;
