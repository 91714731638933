import React, { useLayoutEffect } from "react";
import Search from "../../../components/search/Search";
import { Stream } from "../StreamScript";

import { t } from "i18next";

function AvailableStreamLayout() {

  useLayoutEffect(() => {

    const searchbar = document.getElementById("available_stream_search")
    if (searchbar) {
      searchbar.addEventListener("keydown", function () {
        Stream.search("available_stream_search");
      });
      searchbar.addEventListener("input", function () {
        if (searchbar.value === "") {
          document.getElementById("available_stream_search").value = null;
          Stream.loadAvailableLayout(0);
        }
      });
    }

  });
  return (
    <>
      <div className="offcanvas-body p-0">
        <div className="bxSuggestionTopBar px-3 py-2">
          <div className="sidebar-search">
            <Search
              id="available_stream_search"
               defaultValue=""
            />
          </div>
        </div>

        <div className="bxSuggestionArea px-3 ">
          <span id="bnfxAvailableStreamTotal"></span>
          <span> {t("available.stream.total")}</span>
        </div>
        <div id="bnfxAvailableStreamList">
          {/* we will replace available streamlist */}
        </div>
      </div>
      <div className="offcanvas-footer ">
        <div className="d-flex align-items-center justify-content-between px-3 py-2">
          <div id="bnfxAvailablePagination">
            {/* here we will replace pagination */}
          </div>
          <div>
            <span
              type="button"
              className="text-white me-3"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                const searchInput = document.getElementById(
                  "available_stream_search"
                ); // Replace "searchBarInput" with the actual ID of your search bar input element
                if (searchInput) {
                  searchInput.value = ""; // Clear the search bar input text
                }
              }}
            >
              {t("close")}
            </span>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="bxApplyStream"
              onClick={() => {
                Stream.addStream();
              }}
            >
              {t("apply.add")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AvailableStreamLayout;
