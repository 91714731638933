import { useEffect } from "react";
import SettingTenantOption from "./SettingTenantOption";

function SettingTenantLayout(props) {
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <>
      {props.data.map((streamObj, i) => (
        <SettingTenantOption
          tenant={streamObj}
          tenantdatacount={props.tenantdatacount}

        />
      ))}
    </>
  );
}

export default SettingTenantLayout;
