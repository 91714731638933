import React from "react";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";

const DashboardCardLayout = ({ data }) => {
  let title = data.stattype;
  let total = data.total_count;

  return (
    <>
      <div className="col equal-height-column">
        <div className="card widget-flat topcard">
          <div className="card-body p-3 px-3">
            <h5
              className="text-uppercase text-muted fw-normal mt-0 mb-0"
              title="Users"
            >
              {title}
              <h3 className="text-white fw-bold float-end">
              {CommonTemplate.formatNumber(total)}
            </h3>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCardLayout;
