import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import { Incidence } from "../IncidencePanel/IncidenceScript";

function DisplayPanel(props) {
  const [activeTab, setActiveTab] = useState(props.tab1);

  useEffect(() => {
    props.onClickFn(activeTab, props.row);
    if (props.callback) {
      props.callback();
    }
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const addclossbutton = () => {
    let addshowclass = document.getElementById("bxOffcanvasPanel");
    addshowclass.classList.remove("show");
  };

  return (
    <>
      <div className="offcanvas-header p-0 text-white " id="panelheader">
        <div className="bxSEDHeadDashboard">
          <div className="text-end float-end">
            <h4 className="m-0">
              {props.total}
              {props.tab1 === "entity" ? "X" : null}
            </h4>

            <small
              className={CommonTemplate.criticalityColor(props.criticality)}
            >
              {props.criticality}
            </small>
          </div>
          <div className="bxOutliersCard">
            <h4 className="BXf-18 text-white">
              <span className="material-symbols-outlined me-2">
                {props.logo}
              </span>{" "}
              {props.name?.charAt(0).toUpperCase() + props.name?.slice(1)}
            </h4>
            <ul
              className="bxOC_list d-flex align-items-center mb-0"
              id="bnfxhrdetails"
            ></ul>
            {props.tab1 !== "detections" ? (
              <ul className="bxOC_list d-flex align-items-center mb-0">
                {props.details === undefined ? (
                  <li className="BXf-14">{t("title.nodescription")}</li>
                ) : (
                  <li className="BXf-14" key="values">
                    {Object.values(props.details).join("   |   ")}
                  </li>
                )}
              </ul>
            ) : null}

            <ul className="bxOC_list d-flex align-items-center mb-0">
              <li className=" d-flex align-items-center BXf-12">
                <span
                  style={{ fontSize: "16px" }}
                  className="material-symbols-outlined me-2"
                >
                  pace
                </span>
                <i title="Last Detection Time">{props.timestamp} </i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="offcanvas-body p-1">
        <div className="bfxPanelGraph" id="detectiontrendgraph"></div>

        <div className=" text-end float-end custom-mts">
          {activeTab === props.tab1 ? (
            <>
              <div id={props.tab1 + "Pagination"}></div>
            </>
          ) : (
            <>
              <div id={props.tab2 + "Pagination"}></div>
            </>
          )}
        </div>
        <ul className="nav nav-tabs p-0" id="myTab" role="tablist">
          <li className="nav-item " role="presentation">
            <button
              className="nav-link active"
              id="detections-tab"
              data-bs-toggle="tab"
              data-bs-target="#detections-tab-pane"
              type="button"
              role="tab"
              aria-controls="detections-tab-pane"
              aria-selected="false"
              onClick={() => {
                handleTabClick(props.tab1);
              }}
            >
              {props.tab1}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="events-tab"
              data-bs-toggle="tab"
              data-bs-target="#events-tab-pane"
              type="button"
              role="tab"
              aria-controls="events-tab-pane"
              aria-selected="false"
              onClick={() => {
                handleTabClick(props.tab2);
              }}
            >
              {props.tab2}
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          {activeTab === props.tab1 ? (
            <>
              <div id={props.tab1 + "list"}></div>
            </>
          ) : (
            <>
              <div id={props.tab2 + "list"}></div>
            </>
          )}
        </div>
      </div>
      <div className="offcanvas-footer bg_canvas p-2 text-end">
        <span
          type="button"
          className="text-white me-3"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            addclossbutton();
            Incidence.removeClass();
          }}
        >
          {t("close")}{" "}
        </span>
      </div>
    </>
  );
}

export default DisplayPanel;
