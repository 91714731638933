import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import React, { useLayoutEffect, useRef } from "react";

HighchartsMore(Highcharts);

function BubbleChart(props) {
  const series = props.series;
  const colors = props.colors;
  const container = props.container;
  const onclick_param=props.onclick_param;

  const chartContainer = useRef(null);

  const detectionNames = Array.from(
    new Set(series.map((point) => point.detection))
  );

  // Preprocess the data to ensure unique x values
  const uniqueXValues = Array.from(new Set(series.map((point) => point.x)));

  const aggregatedData = uniqueXValues.map((xValue) => {
    // Find all points with the same x value
    const pointsWithSameX = series.filter((point) => point.x === xValue);

    // Aggregate z values and use the first detection for y value
    const totalZ = pointsWithSameX.reduce((sum, point) => sum + point.z, 0);
    const firstPoint = pointsWithSameX[0];

    return {
      x: firstPoint.x,
      y: detectionNames.indexOf(firstPoint.detection),
      z: totalZ,
      detection: firstPoint.detection,
      details: firstPoint.details,
    };
  });

  const handleRowClick = (data) => {
    props.applyfilteronClick(props.filterid,data.detectionname);
  };

  useLayoutEffect(() => {
    if (chartContainer.current) {
      const parent = container.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );

      const height =
        parseInt(document.getElementById(parent)?.clientHeight) - 70;

      const options = {
        chart: {
          type: "bubble",
          plotBorderWidth: 0,
          zooming: {
            type: "xy",
          },
          height: height + "px",
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        accessibility: {
          point: {
            valueDescriptionFormat:
              "{index}. {point.name}, x: {point.x}, y: {point.y}, z: {point.z}.",
          },
        },
        xAxis: {
          type: "daydatetime", // Assuming x-axis is datetime
          tickLength: 0, // Remove major tick marks
          minorTickLength: 0, // Remove minor tick marks
          gridLineWidth: 0,
          title: {
            text: "",
          },
          labels: {
            format: "{value:%m-%d %H}", // Adjust datetime format as needed
          },

          accessibility: {
            rangeDescription: "",
          },
          tickInterval: uniqueXValues.length > 20 ? 3600000 * 24 * 5 : null,
          // Tick interval in milliseconds (1 hour in this example)
        },
        yAxis: {
          startOnTick: false,
          endOnTick: false,
          categories: detectionNames, // Use detectionNames for y-axis categories
          title: {
            text: "",
          },
          labels: {
            useHTML: true,
            formatter: function () {
              const label = this.value;
              const maxLength = 10; // Set the maximum length for the label
              const truncatedLabel =
                label.length > maxLength
                  ? label.substring(0, maxLength) + "..."
                  : label;
              return `<span title="${label}">${truncatedLabel}</span>`;
            },
            style: {
              color: "#b3771a",
              textOverflow: "ellipsis",
            },
          },
          maxPadding: 0.2,
          accessibility: {
            rangeDescription: "",
          },
        },
        tooltip: {
          useHTML: true,
          backgroundColor: "#FFF", // Set background color
          borderWidth: 0.5,
          style: {
            fontSize: "10px", // Set smaller font size
          },
          headerFormat: "<table>",
          pointFormat:
            '<tr><th style="color: black;">DetectionTime:</th><td style="color: black;">{point.x: %Y-%m-%d %H}</td></tr>' +
            '<tr><th style="color: black;">Occurence:</th><td style="color: black;">{point.z}</td></tr>',
          footerFormat: "</table>",
          followPointer: true,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              format: "",
            },
            events: {
              click: function (event) {
                if (onclick_param === 'filter_apply') {
                const point = event.point;
                handleRowClick(point.details);
                } 
              },
            },
          },
        },
        series: [
          {
            data: aggregatedData,
            colorByPoint: true,
          },
        ],
      };

      Highcharts.chart(chartContainer.current, options);
    }
  }, [
    series,
    colors,
    container,
    aggregatedData,
    detectionNames,
    uniqueXValues,
  ]);

  return <div ref={chartContainer} />;
}

export default BubbleChart;
