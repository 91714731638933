import React from "react";
import CumulativeStatsLayout from "./CumulativeStatsLayout";

function CumulativeStats(props) {
  return (
    <>
      <div className={"row mt-1 mb-3 bxRightCardTop g-2 " + props.id}>
        {props.chartData.map((link, index) => (
          <CumulativeStatsLayout key={index} {...link} />
        ))}
      </div>
    </>
  );
}

export default CumulativeStats;
