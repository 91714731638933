import ActiveNode from "./ActiveNode";
import { CollectorsScript } from "./CollectorsScript";
import ConnectionNode from "./ConnectionNode";
import { t } from "i18next";

// Component for the Collectors tab
function CollectorsTab(props) {
  let config = props.config;
  let activenodes = props.activenodes;
  let tenant = props.tenant;

  return (
    <>
      <button
        className="tab active"
        onClick={(event) =>
          CollectorsScript.openTab(event, "collectors-active-nodes")
        }
      >
        {t("collectors.active.node")}
      </button>
      <button
        className="tab connectionNode"
        onClick={(event) =>
          CollectorsScript.openTab(event, "collectors-ini-editor")
        }
      >
        {t("collectors.connections")}
      </button>

      <div
        id="collectors-active-nodes"
        className="tab-content collector active "
      >
        <ActiveNode activenodes={activenodes} />
      </div>

      <div
        id="collectors-ini-editor"
        className="tab-content collector connectionNode h-100"
        style={{ display: "flex" }}
      >
        <ConnectionNode config={config} />
      </div>
      <div className="offcanvas-footer bg_canvas p-2 text-end">
        <div className="bxFloatingAddBtn">
          <button
            title="Add Instructions"
            data-bs-toggle="offcanvas"
            data-bs-target="#bnfxAddNewInstructions"
            aria-controls="offcanvasRight"
            className="action-icon"
            href="#addInstruction"
            onClick={() => {
              CollectorsScript.getInstruction();
            }}
          >
            <span className="material-symbols-outlined">add</span>
          </button>
        </div>
        <button
          type="button"
          className="me-2"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            CollectorsScript.removePanel();
          }}
        >
          {t("close")}
        </button>
        <div className="collector-savebutton me-2">
          <button
            type="button"
            className=" btn ms-2 btn-primary SpiderCustomBtn saveBtn button"
            data-bs-dismiss="offcanvas"
            onClick={() => {
              CollectorsScript.saveconfig(tenant, config);
            }}
          >
            {t("name.save")}
          </button>
        </div>
      </div>
    </>
  );
}

export default CollectorsTab;
