import SaveSearchLayout from "./SaveSearchLayout";

function NewSearchLayout() {
  return (
    <>
      <div
        className="modal bxPopup fade"
        id="newSearch"
        tabIndex="-1"
        aria-labelledby="newSearchLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <SaveSearchLayout />
        </div>
      </div>
    </>
  );
}

export default NewSearchLayout;
