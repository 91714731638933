import React, { useEffect } from "react";
import Select from "react-select";
import { useGlobalState } from "../GlobalVariables";
import { BadgePanelScript } from "./BadgePanelScript";
import { t } from "i18next";

function GroupSelectDropdown(props) {
  let result = props?.result;

  const [GroupTypeOption] = useGlobalState("GroupTypeOption");
  const [selectedBadgeGroupsType, setSelectedBadgeGroupsType] = useGlobalState(
    "selectedBadgeGroupsType"
  );

  const [GroupValueOption] = useGlobalState("GroupValueOption");
  const [selectedBadgeGroupValue, setSelectedBadgeGroupValue] = useGlobalState(
    "selectedBadgeGroupValue"
  );

  const handleSelectedBadgeGroupsType = (selectedOptions) => {
    setSelectedBadgeGroupsType(selectedOptions);
    setSelectedBadgeGroupValue("");
  };

  const handleSelectedBadgeGroupsValue = (selectedOptions) => {
    setSelectedBadgeGroupValue(selectedOptions);
  };

  useEffect(() => {
    if (result?.grouptype) {
      BadgePanelScript.clickGroupRadioBtn();
    }
    if (selectedBadgeGroupsType.label) {
      BadgePanelScript.getGroupValues(selectedBadgeGroupsType.label);
    }
  }, [result, selectedBadgeGroupsType.label]);

  return (
    <>
      <div className="col-6">
        <div className="mb-2">
          <label htmlFor="badge_group_type" className="form-label">
            {t("group_type_name")}
          </label>
          <Select
            placeholder="Please select group type"
            id="badge_group_type"
            className="form-control GroupTypeList"
            options={GroupTypeOption}
            value={selectedBadgeGroupsType}
            onChange={(value) => {
              handleSelectedBadgeGroupsType(value);
              BadgePanelScript.handleGroupChange();
            }}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="mb-2">
          <label htmlFor="badge_group_value" className="form-label">
            {t("groups")}
          </label>
          <Select
            placeholder="Please select group value"
            id="badge_group_value"
            className="form-control GroupValueList"
            options={GroupValueOption}
            value={selectedBadgeGroupValue}
            onChange={handleSelectedBadgeGroupsValue}
          />
        </div>
      </div>
    </>
  );
}

export default GroupSelectDropdown;
