import Highcharts from "highcharts";
import { useLayoutEffect, useRef } from "react";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsSankey from "highcharts/modules/sankey";
import HighchartsDependencyWheel from "highcharts/modules/dependency-wheel";
import HighchartsAccessibility from "highcharts/modules/accessibility";

HighchartsExporting(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsSankey(Highcharts);
HighchartsDependencyWheel(Highcharts);
HighchartsAccessibility(Highcharts);

function Scatterplot(props) {
  const handleRowClick = (data) => {
    props.onClickFn(data, props.id);
  };

  const containerRef = useRef(null);

  const series = [
    {
      showInLegend: false,
      data: props.data?.map((item, index) => ({
        x: item.detection_count,
        y: item.total_score,
        entity: item.entity,
        color:
          item.detectioncriticality === "HIGH"
            ? "rgba(233, 21, 21, 0.5)"
            : item.detectioncriticality === "MEDIUM"
            ? "rgba(0, 128, 0, 0.4)"
            : item.detectioncriticality === "LOW"
            ? "rgba(255, 165, 0, 0.4)"
            : "rgba(233, 83, 21, 0.5)",
        data: item,
      })),
    },
  ];

  const minY = props.data
    ? Math.min(...props.data.map((item) => item.total_score))
    : 0;

  const maxY = props.data
    ? Math.max(...props.data.map((item) => item.total_score))
    : 0;
  const middleYValue = (minY + maxY) / 2;

  const minX = props.data
    ? Math.min(...props.data.map((item) => item.detection_count))
    : 0;
  const maxX = props.data
    ? Math.max(...props.data.map((item) => item.detection_count))
    : 0;
  const middleXValue = (minX + maxX) / 2;

  useLayoutEffect(() => {
    let elementWidth = null;

    if (containerRef.current) {
      elementWidth = containerRef.current.getBoundingClientRect().width;
    }

    const chart = Highcharts.chart(containerRef.current, {
      chart: {
        type: "scatter",
        zoomType: "xy",
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 11,
            symbol: "circle",
            states: {
              hover: {
                enabled: true,
                lineColor: "rgb(100,100,100)",
              },
            },
          },
          states: {
            hover: {
              marker: {
                enabled: false,
              },
            },
          },
          jitter: {
            x: 0.005,
          },
        },
      },
      tooltip: {
        formatter: function () {
          return (
            "Detection Count: <b>" +
            this.point.x +
            "</b><br/>Total Score: <b>" +
            this.point.y +
            "</b><br/>Entity: <b>" +
            this.point.entity +
            "</b>"
          );
        },
      },
      series: series,
      xAxis: {
        gridLineWidth: 0,
        title: {
          text: "Number of detections",
        },
        lineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        minorTickLength: 0,
        tickLength: 0,
        plotLines: [
          {
            color: "rgba(255,255,255,.1)", // Line color
            dashStyle: "solid", // Style of the line (solid, shortdash, shortdot, etc.)
            width: 1, // Line width
            value: middleXValue, // X-axis value where the line will appear
            zIndex: 2, // Z-index of the line
            label: {
              text: "Elevated", // Label for the section
              align: "right",
              x: -elementWidth / 4,
              y: 84,
              style: {
                color: "#fff",
                transform: "rotate(0deg)",
                // width:"100%"
              },
            },
          },
          {
            color: "rgba(255,255,255,.1)", // Line color
            dashStyle: "solid", // Style of the line (solid, shortdash, shortdot, etc.)
            width: 2, // Line width
            value: middleXValue, // X-axis value where the line will appear
            zIndex: 2, // Z-index of the line
            label: {
              text: "Intermediate", // Label for the section
              align: "right",
              x: elementWidth / 4 - 10,
              y: 263,
              style: {
                color: "#fff",
                transform: "rotate(0deg)",
              },
            },
          },
        ],
      },

      yAxis: {
        gridLineWidth: 0,
        title: {
          text: "Increasing Score",
        },
        lineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        minorTickLength: 0,
        tickLength: 0,
        plotLines: [
          {
            color: "rgba(255,255,255,.1)",
            dashStyle: "solid",
            width: 2,
            value: middleYValue,
            zIndex: 2,
            label: {
              text: "Urgent",
              align: "right",
              x: -elementWidth / 4,
              y: -100,
              style: {
                color: "#fff",
              },
            },
          },
          {
            color: "rgba(255,255,255,.1)",
            dashStyle: "solid",
            width: 2,
            value: middleYValue,
            zIndex: 2,
            label: {
              text: "Minimal",
              align: "left",
              x: elementWidth / 4 - 100,
              y: 80,
              style: {
                color: "#fff",
              },
            },
          },
        ],
      },
    });

    chart.series[0].points.forEach((point) => {
      point.graphic.element.addEventListener("mousedown", () => {
        point.graphic.element.setAttribute("data-bs-toggle", "offcanvas");
        point.graphic.element.setAttribute(
          "data-bs-target",
          "#bxOffcanvasPanel"
        );
        handleRowClick(point.data);
      });
    });
  }, []);

  return <div ref={containerRef}></div>;
}

export default Scatterplot;
