import React, { useEffect, useRef, useState } from "react";
import StreamSelection from "./StreamSelection";
import { BadgePanelScript } from "./BadgePanelScript";
import { useGlobalState } from "../GlobalVariables";
import { t } from "i18next";

function BadgeStreams(props) {
  let result = props?.data;
  let isedit = props.isedit;

  let dataList = null;
  if (result && result.streams) {
    dataList = Object.entries(result?.streams).flatMap(([key, values]) => [
      key,
      ...values,
    ]);
  }

  const [selectedContent] = useGlobalState("StreamsOptions");
  const selectedValuesLength = Object.keys(selectedContent).length;

  useEffect(() => {
    let transformedData = selectedContent?.map((item) => {
      let source = item?.value?.datasource;
      let detections = item?.value?.detections?.detections?.filter(
        (detection) => detection !== null
      );
      let question = item?.value?.name;
      let answers = null;
      let checked = null;

      if (dataList) {
        let streamlist = document.querySelector(".badgestreams");
        if (streamlist.classList.contains("active")) {
          streamlist.classList.remove("active");
        }
        answers = detections?.map((detection) => ({
          text: detection?.title,
          checked: dataList.includes(detection?._id), // Using includes() for arrays
          id: detection?._id,
        }));
        checked = dataList.includes(item?.value?._id);
      } else {

        answers = detections?.map((detection) => ({
          text: detection?.title,
          checked: false,
          id: detection?._id,
        }));
        checked = false;
      }
      return {
        source: source,
        question: question,
        answers: answers,
        checked: checked,
        questionid: item?.value?._id,
      };
    });

    setFaqs(transformedData);
  }, [selectedContent]);

  useEffect(() => {
    if (!dataList && selectedValuesLength>1) {
    let streamlist = document.querySelector(".badgestreams");
    streamlist.classList.add("active");}
    else if(!dataList && selectedValuesLength===1){
      let streamlist = document.querySelector(".badgestreams");
      streamlist.classList.remove("active");}
    }, [selectedContent[0]]);

  const [faqs, setFaqs] = useState([]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };

  const toggleCheckbox = (faqIndex, answerIndex) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === faqIndex) {
          faq.answers[answerIndex].checked = !faq.answers[answerIndex].checked;
        }
        return faq;
      })
    );
  };

  const toggleQuestionCheckbox = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.checked = !faq.checked;
        }
        return faq;
      })
    );
  };

  const containerRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      const container = containerRef.current;
      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight
      ) {
        // User has scrolled to the bottom
        BadgePanelScript.loadmore(); // Assuming this function is defined elsewhere
      }
    }

    containerRef?.current?.addEventListener("scroll", handleScroll);
    return () => {
      containerRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const toggleSelectAll = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.selectAllChecked = !faq.selectAllChecked;
          faq.answers.forEach((answer) => {
            answer.checked = faq.selectAllChecked;
          });
        }
        return faq;
      })
    );
  };

  return (
    <>
      <div className="BadgeLeftSec_inner h-100">
      {selectedValuesLength>1 && (<div
          className="ToggleButton d-flex justify-content-between align-item-center mb-2 mt-2"
          id="bnfxbadgeToggle"
        >
          <p className="badgestreamnote">
            {" "}
            {t("badge_streamnote")}
          </p>
          <label htmlFor="togBtn" className="switch streamswitch">
            <input
              type="checkbox"
              id="togBtn"
              onChange={BadgePanelScript.handleToggleChange}
            />
            <div className="slider round">
              <span className="on">{t("name_across")}</span>
              <span className="off">{t("name_specific")}</span>
            </div>
          </label>
        </div>)}

        <div className="col-12 faqs badgestreams active" ref={containerRef}>
          {faqs.map((faq, index) => (
            <StreamSelection
              faq={faq}
              index={index}
              key={index}
              toggleFAQ={toggleFAQ}
              toggleCheckbox={toggleCheckbox}
              toggleQuestionCheckbox={toggleQuestionCheckbox}
              toggleSelectAll={toggleSelectAll}
            />
          ))}
        </div>
        <div className="col-12  position-absolute bottom-0 end-0  text-end ">
          <button
            onClick={() =>
              BadgePanelScript.clickTabSwitch("entitytab", "tabEntities")
            }
            className="text-white CustomBtn border text-uppercase"
          >
            {t("back")}
          </button>

          <button
            type="button"
            className="btn ms-2 btn-primary CustomBtn saveBtn button text-uppercase"
            data-bs-dismiss="offcanvas"
            onClick={() => BadgePanelScript.savebadge(faqs, result, isedit)}
          >
            {t("name.save")}
          </button>
        </div>
      </div>
    </>
  );
}

export default BadgeStreams;
