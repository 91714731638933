import React from "react";
import AvailableStreamLayout from "./AvailableStreamLayout";
import { t } from "i18next";

function AddStreamLayout() {
  return (
    <>
      <div
        className="bxAddStreamOffCanvas offcanvas offcanvas-start"
        tabIndex="-1"
        id="bnfxAddNewStreams"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {t("enabled.addstream")}
          </h5>
          <button
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              const searchInput = document.getElementById(
                "available_stream_search"
              ); // Replace "searchBarInput" with the actual ID of your search bar input element
              if (searchInput) {
                searchInput.value = ""; // Clear the search bar input text
              }
            }}
          >
            <span className="material-symbols-outlined">arrow_back</span>
          </button>
        </div>
        <div id="bnfxAvailableList">
          <AvailableStreamLayout />
        </div>
      </div>
    </>
  );
}

export default AddStreamLayout;
