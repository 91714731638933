import React from "react";
import { BadgePanelScript } from "./BadgePanelScript";
import EntityConponent from "./EntityConponent";
import GroupSelectDropdown from "./GroupSelectDropdown";
import { t } from "i18next";


function BadgeEntity(props) {
  let result = props?.data;
  let type = props?.type;
  let isedit = props.isedit;

  return (
    <>
      <div className=" mt-3">
        <h6 className="sub_title">{t("select_one")}</h6>
        <div className="radio-container">
          <label htmlFor="bnfx_entityButton" className="radio-label" id="bnfxentityButton">
            <input
              type="radio"
              name="custom-radio"
              id="bnfx_entityButton"
              className="radio-input"
              onChange={() => {
                BadgePanelScript.clickEntityRadioBtn();
              }}
            />
            <span className="radio-custom"></span>
            {t("entity")}
          </label>

          {type === "totalBadge" ? (
            <label htmlFor="bnfx_groupButton" className="radio-label" id="bnfxgroupButton">
              <input
                type="radio"
                name="custom-radio"
                id="bnfx_groupButton"
                className="radio-input"
                checked={type !== "totalBadge" ? false : null}
                onChange={() => {
                  BadgePanelScript.clickGroupRadioBtn();
                }}
              />
              <span className="radio-custom"></span>
              {t("groups")}
            </label>
          ) : null}
        </div>
        <div className="mt-2">
        <div id="badgeentitylsits">
          <div
            className={`row badgeentities ${
              result?.entities?.length > 0 ? "active" : ""
            }`}
            id="badge_entities"
          >
            <EntityConponent result={result} type={type} isedit={isedit} />
          </div>
        </div>
        <div
          className={` badgeGroupOption ${
            result?.grouptype ? "active" : ""
          } `}
          id="badge_group_option"
        >
          <div className="row g-2"><GroupSelectDropdown result={result} type={type} isedit={isedit} /></div>
        </div>
      </div>
      <div className="col-12  position-absolute bottom-0 end-0  text-end py-2">
        <button
          onClick={() =>
            BadgePanelScript.clickTabSwitch("detailstab", "tabDetails")
          }
          className="text-white CustomBtn border text-uppercase"
        >
          {t("back")}
        </button>

        <button
          type="button"
          className="btn ms-2 btn-primary CustomBtn saveBtn button text-uppercase"
          onClick={() => {
            BadgePanelScript.badgeEntityFormSubmit(isedit, result);
          }}
        >
          {t("next")}
        </button>
      </div>
      </div>

      

      
    </>
  );
}

export default BadgeEntity;
