import React from "react";
import { Artifact } from "./ArtifactScript";

const ArtifactListLayout = (props) => {
  const data = props.data;
  const streamid = props.streamid;

  const getArtifactCountCallback = (value) => {
    return Artifact.getArtifactCount(
      "bnfxartifactcount",
      value,
      streamid,
      true
    );
  };

  return (
    <div id="container" className="style-scope yt-chip-cloud-renderer">
      <div id={streamid} className="style-scope yt-chip-cloud-renderer">
        <div className="mt-3 mb-0 d-flex align-items-center text-white bnfxartifactslist">
          {data?.map((value, index) => (
            <div key={index}>
              {getArtifactCountCallback(value)}
              <div id={"bnfxartifactcount" + streamid + value}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArtifactListLayout;
