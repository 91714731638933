
function Search(props) {
  let searchtextplacholder = null;

  if (
    props.id === "enabled_stream_search" ||
    props.id === "available_stream_search"
  ) {
    searchtextplacholder = "Type more than 3 letters to search streams..";
  } else {
    searchtextplacholder = "Type more than 3 letters to search..";
  }


  let onKeyDown = props.onKeyDown;
  if (props.id==="tenant_search"||props.id==="group_search"||props.id==="user_search"||props.id==="role_search") {
    onKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        // Handle Enter key press as needed
      } else {
        // Handle other key presses
        props.onKeyDown && props.onKeyDown(e);
      }
    };
  }
  return (
    <div className="input-group searchBx">
      <span
        className="material-symbols-outlined search-icon text-white "
      >search</span>
      <input
        type="search"
        className="form-control streamtogglesearch"
        placeholder={searchtextplacholder}
        id={props.id}
        autoComplete="off"
        onKeyDown={onKeyDown}
        defaultValue={props.defaultValue}
      />
    </div>
  );
}

export default Search;
