import React from "react";
import "../search/searchdropdown.css";
import { t } from "i18next";
import { SearchTemplate } from "./SearchScript";


function SavedQuery() {
  return (
    <>
      <div className="collapse cSSearch_collapse streamsavedsearch" id="collapseSaveSearchStream">
        <div className="row g-0">
          <div className="col-md-7 pageheight">
            <div id="bnfxSearchListPagination"></div>
            <div className="how_search_text d-flex align-items-center justify-content-between p-3">
              <h4 className="savedSearch_title m-0">{t("name.savedsearch")}</h4>
             
                <button
                  type="button"
                  className="btn btn-primary ms-3"
                  data-bs-toggle="modal"
                  data-bs-target="#newSearch"
                  onClick={()=> SearchTemplate.getTenantlist()}
                >
                  {t("name.newsearch")}
                </button>
            </div>
            <div id="bnfxstreamsavedsearchlayout">
              {/* we render SearchDropdownLayout */}
            </div>
          </div>
          <div className="col-md-5 Saved_bgSecondary">
            <div className="how_search_text p-2 px-3 ">
              <h4 className="savedSearch_title m-0 mt-3">
                {t("name.searchmessage")}
              </h4>
            </div>
            <div className="px-3">
              <label htmlFor="Step1">{t("stream.search.name.usersuggest.messsageone")}</label>
              <p className="">{t("stream.search.name.usersuggest.descriptionone")}</p>

              <label htmlFor="Step1">{t("stream.search.name.usersuggest.messsagetwo")}</label>
              <p className="">{t("stream.search.name.usersuggest.descriptiontwo")}</p>

              <label htmlFor="Step1">
                {t("stream.search.name.usersuggest.messsagethree")}
              </label>
              <p className="">{t("stream.search.name.usersuggest.descriptionthree")}</p>

              <label htmlFor="Step1">
                {t("stream.search.name.usersuggest.messsagefour")}
              </label>
              <p className="">{t("stream.search.name.usersuggest.descriptionfour")}</p>
              <br></br>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default SavedQuery;
