import React, { memo } from "react";
import { Handle, Position } from "reactflow";

export default memo(({ data, isConnectable }) => {
  let sourcename = data?.label
  return (
    <>
      <div className="BFNX_Bg_color">
        <div className="dataSource ">
          <span className={"bxIcon_link " + sourcename?.toLowerCase()}></span>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ bottom: -10, right: '50%', background: "#fff" }}
        isConnectable={isConnectable}
      />
    </>
  );
});
