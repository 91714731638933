import OrganizationLayout from "./OrganizationLayout";

function OrganizationContainer() {
  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row d-flex justify-content-center">
          <div className=" col-lg-12">
            <OrganizationLayout />
          </div>
        </div>
      </div>
    </>
  );
}

export default OrganizationContainer;
