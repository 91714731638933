import React, { useEffect } from 'react';
import RawEventLayout from './RawEventLayout';
import { Stream } from '../StreamScript';


function RawEvent(props) {

  let data = null;

  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  if (props.output) {
    data =
    props.output.map((streamObj, index) => (
      <React.Fragment key={index}>
        <RawEventLayout data={streamObj}/>
      </React.Fragment>
    ))
  }


  

  return (
    <>
      
        <div className="bnfxDectionsEvents mt-0 bnfxrawevents">
        <div className="bnfxDESDetails pb-0 rawEvent">
        <div className="raweventstoggles mb-2 d-flex justify-content-end">
          <ul class="d-flex bxSEActionBtn m-0 p-0 rawEventIcons">
            <li class="mx-0">
              <button type="button" class="tab_parsed_events active parseDataIcon" onClick={(event) => Stream.openRawEventsTab(event, "bnfxparsedevents")}>
                  <span
                    class="material-symbols-outlined"
                    title="Please Configure Settings"
                  >
                    repartition
                  </span>
              </button>
            </li>
            <li class="border-start"></li>
            <li class="ms-0">
              <button
              type="button"
              class="tab_raw_events rawDataIcon"
                onClick={(event) => Stream.openRawEventsTab(event, "bnfxrawevents")}
                title="Raw Events"
              >
                <span class="material-symbols-outlined">key_visualizer</span>
              </button>
            </li>
          </ul>
        </div>
        </div>{data}</div>
      
    </>
  );
}

export default RawEvent;
