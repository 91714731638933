import React, { memo } from "react";
import { Handle, Position } from "reactflow";

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        id="c"
        isConnectable={isConnectable}
        style={{  left: "10%" , height: "10px", width: "10px", background: "#fff"}}
      />

      <div className="bnfx_automationtask_node aT_node">
        <div className="row aT_node_box">
          <div className="col-auto">
          {data?.label.description.includes("Checks") ? (
            <span className="material-symbols-outlined aT_icon">
             family_history
            </span>
          ) : (
            <span className="material-symbols-outlined aT_icon">
             podium
            </span>
          )}
          </div>
          <div className="col-auto">
            <span className="aT_title" title={data.label.title}>{data.label.title}</span>
            <p className="aT_des" title={data.label.description}>{data.label.description} </p>
          </div>
        </div>
        
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        id="d"
        style={{ bottom: -10, right: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
    </>
  );
});
