
import React from "react";
import { requestURI } from "../RequestURIs";
import { ajax } from "../Service";
import Pagination from "../pagination/Pagination";
import { showError } from "../DisplayError";
import { getGlobalState, setGlobalState } from "../GlobalVariables";
import CreateIncident from "./CreateIncident";
import IncidencePanel from "./IncidencePanel";
import { Dashboard } from "../../../pages/dashboard/DashboardScript";
import IncidenceOverview from "./IncidenceOverview";
import IncidenceActivites from "./IncidenceActivities";
import IncidenceActivitesChat from "./IncidenceActivitiesChat";
import NoContent from "../NoContent";
import IncidenceEvents from "./IncidenceEvents";
import $ from "jquery";
import ToastPopup from "../ToastPopup";
import { DisplayErrorToast } from "../../error/DisplayErrorToast";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import { t } from "i18next";
import { PanelScript } from "../GridPanel.js/PanelScript";
import Swal from "sweetalert2";


export const Incidence = {
  incidentid: null,
  probefrom: null,
  selectedCommentHtm: null,
  accrossstream: false,

  setselectedrequest: (data) => {
    Incidence.requestdata = data;
  },
  setincidentid: (data) => {
    Incidence.incidentid = data;
  },
  getincidentid: (
    data,
    detectionid,
    id,
    entity,
    entitytype,
    placholderid,
    across
  ) => {
    const request = {
      tenant: data.tenant,
      entity: entity ? entity : data.entity,
      entitytype: entitytype ? entitytype : data.entitytype,
      detectionid: detectionid,
      streamid: id,
      across: across,
    };
    ajax(
      "post",
      requestURI["get.incidentid"],
      null,
      request,
      Incidence.renderincidentid,
      showError,
      [data, id, entity, entitytype, placholderid, across, detectionid]
    );
  },
  renderincidentid: (result, args) => {
    let across = args[5];
    let entitytype = args[3];
    let entity = args[2];
    let placholderid = args[4];
    let data = args[0];
    let detectionid = args[6];

    let incidentid = result.data.response;
    let createincidentoption = null;
    if (across) {
      const container1 = document.getElementById(
        placholderid +
          "1" +
          (entitytype ? entitytype : data.entitytype) +
          (entity ? entity : data.entity) +
          detectionid
      );
      const container2 = document.getElementById(
        placholderid +
          "2" +
          (entitytype ? entitytype : data.entitytype) +
          (entity ? entity : data.entity) +
          detectionid
      );
      if (container1) {
        if (incidentid[0] !== null) {
          container1.innerHTML =
            "<span class='incidenthash'>#</span>" + incidentid[0];
          container1.setAttribute("data-incidentid", incidentid[0]);

          if (placholderid === "bnfxincidentid") {
            createincidentoption = document.getElementById(
              "creatincident" +
                (entitytype ? entitytype : data.entitytype) +
                (entity ? entity : data.entity) +
                detectionid
            );
          } else if (placholderid === "bnfxgraphincident") {
            createincidentoption = document.getElementById(
              "bnfxdetectiongraphcreateincident" +
                (entitytype ? entitytype : data.entitytype) +
                (entity ? entity : data.entity) +
                detectionid
            );
          }
          createincidentoption.classList.add("d-none");
        } else {
          container1.closest(".incidentclass").classList.add("d-none");
        }
      }

      if (container2) {
        if (incidentid[1] !== null) {
          container2.innerHTML =
            "<span class='incidenthash'>#</span>" + incidentid[1];
          container2.setAttribute("data-incidentid", incidentid[1]);
        } else {
          container2.closest(".incidentclass").classList.add("d-none");
        }
      }
    } else {
      const container = document.getElementById(
        placholderid +
          (entitytype ? entitytype : data.entitytype) +
          (entity ? entity : data.entity) +
          detectionid
      );
      if (incidentid[0] !== null) {
        container.innerHTML =
          "<span class='incidenthash'>#</span>" + incidentid;
        container.setAttribute("data-incidentid", incidentid);

        if (placholderid === "bnfxincidentid") {
          createincidentoption = document.getElementById(
            "creatincident" +
              (entitytype ? entitytype : data.entitytype) +
              (entity ? entity : data.entity) +
              detectionid
          );
        } else if (placholderid === "bnfxgraphincident") {
          createincidentoption = document.getElementById(
            "bnfxdetectiongraphcreateincident" +
              (entitytype ? entitytype : data.entitytype) +
              (entity ? entity : data.entity) +
              detectionid
          );
        }
        createincidentoption.classList.add("d-none");
      } else {
        container.closest(".incidentclass").classList.add("d-none");
      }
    }
  },
  createincident: (data,incidentfrom) => {
    let metadata = Incidence.requestdata;

    const request = {
      name: data.name,
      description: data.description,
      criticality: data.criticality.value,
      asigneetype: data.asigneetype.value,
      assignee: data.assignee.value,
      workflowid: data.workflowid.value,
      tenant: metadata.tenant,

      entity: metadata.entity,
      entitytype: metadata.entitytype,
      detectionid: metadata.detectionid,
      streamid: metadata.streamid,
      across: Incidence.accrossstream,
    };
    ajax(
      "post",
      requestURI["create.incident"],
      null,
      request,
      Incidence.renderincidentdata,
      DisplayErrorToast,
      [metadata,incidentfrom]
    );
  },
  renderincidentdata: (result, args) => {
    let incidentfrom =args[1]
   
    if (incidentfrom === "attackpath") {
      let inputdata = args[0];
      const globaldetectiongraphid = getGlobalState("globaldetectiongraphid");
      PanelScript.graphrender(
        globaldetectiongraphid ? globaldetectiongraphid[1] : "no title",
        inputdata.detectionid,
        inputdata.entity,
        inputdata.entitytype,
        inputdata.tenant,
        globaldetectiongraphid[2],
        inputdata.streamid
      );
    } else {
      PanelScript.loadlist(args[0]);
    }
    ToastPopup("Incident created successfully");
  },
  loadformdata: (data, id, entity, entitytype, globaldetectiongraphid) => {
    let addshowclass = document.getElementById("bnfxdetctiongraph");
    addshowclass.classList.add("show");
    Incidence.loadformselectionoptions(
      data,
      data.detectionid,
      id,
      entity,
      entitytype,
      globaldetectiongraphid[1],
      "attackpath"
    );
  },

  loadformselectionoptions: (
    data,
    detectionid,
    id,
    entity,
    entitytype,
    title,
    incidentfrom

  ) => {
    
    const request = {
      tenant: data.tenant,
      entity: entity ? entity : data.entity,
      entitytype: entitytype ? entitytype : data.entitytype,
      detectionid: detectionid,
      streamid: id,
    };
    Incidence.setselectedrequest(request);
    Incidence.loadListOfUsers(0);
    Incidence.loadWorkflowID();
    Incidence.loadListOfGroup();
    const container = document.getElementById("createtincidentform");

    let createincident = (
      <CreateIncident
        entity={entity}
        title={title}
        incidentfrom={incidentfrom}
      />
    );
    CommonTemplate.ContainerAvailability(container, createincident);
  },

  loadWorkflowID: () => {
    const request = {
      limit: 100,
      offset: 0,
      sortcol: "name",
      sortorder: false,

      type: "packages.workflows",
    };

    ajax(
      "post",
      requestURI["list.stream"],
      null,
      request,
      Incidence.renderworkflowidList,
      showError
    );
  },

  renderworkflowidList: (response) => {
    let originalData = response.data.response;

    const worflowlist = originalData.map((item) => ({
      value: item._id,
      label: item.title,
    }));
    setGlobalState("WorkFlowOptions", worflowlist);
  },

  loadListOfUsers: (offset) => {
    let endpoint = "?offset=" + offset;

    ajax(
      "get",
      requestURI["list.organization"] + "/securityuser" + endpoint,
      null,
      null,
      Incidence.renderuserlist,
      showError
    );
  },
  renderuserlist: (response) => {
    let userlist = response.data.response;
    const useroptions = userlist.map((item) => ({
      value: item.username,
      label: item.username,
    }));

    setGlobalState("dashboarduseroptions", useroptions);
  },

  loadListOfGroup: () => {
    ajax(
      "get",
      requestURI["list.group"],
      null,
      null,
      Incidence.renderListOfGroups,
      showError
    );
  },

  renderListOfGroups: (response, args) => {
    let originalData = response.data.response;
    const groupData = originalData.map((item) => ({
      value: item.name,
      label: item.name,
    }));
    setGlobalState("dashboardgroupoptions", groupData);
  },
  loadmetadata: (data,from,refreshdata) => {
    // Incidence.loadListOfUsers(0);
    // Incidence.loadListOfGroup();
    let addshowclass = document.getElementById("bxOffcanvasPanel");
    addshowclass.classList.add("show");
    const id = "bnfxincidentid" + data;
    const targetElement = document.getElementById(id);
    if (targetElement) {
      let incidentId = targetElement.textContent.trim();
      incidentId = incidentId.replace("#", "");
      Incidence.loadincidentdetails(incidentId,from,refreshdata);
    } else {
      console.error("Target element not found.");
    }

    //Incidence.setselectedrequest(metadata)
  },
  loaddetectiongraphincidencemetadata: (e,requestdata) => {
    let incidenceclass = document.getElementById("bxOffcanvasIncidence");
    incidenceclass.classList.remove("graphopen");

    // Incidence.loadListOfUsers(0);
    // Incidence.loadListOfGroup();

    let addshowclass = document.getElementById("bnfxdetctiongraph");
    addshowclass.classList.add("show");

    let incidentid = e.target.getAttribute("data-incidentid");
    Incidence.loadincidentdetails(incidentid,"fromattackpath",requestdata);

    //Incidence.setselectedrequest(metadata)
  },
  loadincidentdetails: (id,from,refreshdata) => {
    CommonTemplate.renderLoadingIcon("#bnfxIncidencemeta")
    Incidence.loadListOfUsers(0);
    Incidence.loadListOfGroup();
    const request = {
      identifier: id,
    };

    ajax(
      "post",
      requestURI["incident.overview"],
      null,
      request,
      Incidence.rendermetaData,
      showError,
      [from,refreshdata]
    );
  },

  rendermetaData: (result,args) => {
    let from =args[0]
    let refreshdata=args[1];
    let output = result.data.response;

    const container = document.querySelector("#bnfxIncidencemeta");
    const data = (
      <IncidencePanel
        output={output}
        onClickFn={Incidence.OutlierIncidenceRender}
        from={from}
        refreshdata={refreshdata}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);
  },
  OutlierIncidenceRender: (tabtype, id) => {
    if (tabtype === "Overview") {
      Incidence.loadoverview(id);
    } else if (tabtype === "Activities") {
      Incidence.loadactivites(id);
    } else {
      Incidence.loadIncidenceevents(id);
    }
  },
  loadoverview: (id) => {
    CommonTemplate.renderLoadingIcon("#bnfxIncidenceOverview")
    Incidence.setincidentid(id);
    const request = {
      identifier: id,
    };

    ajax(
      "post",
      requestURI["incident.overview"],
      null,
      request,
      Incidence.renderoverview,
      showError,
      [id]
    );
  },

  renderoverview: (result, args) => {
    const paginationconatiner = document.querySelector(
      "#bnfxIncidenceEventsPagination"
    );
    let pagainationdata = null;
    CommonTemplate.ContainerAvailability(paginationconatiner, pagainationdata);
    let output = result.data.response;

    const container = document.querySelector("#bnfxIncidenceOverview");
    const data = <IncidenceOverview data={output} id={args[0]} />;

    CommonTemplate.ContainerAvailability(container, data);
  },
  loadactivites: (id, offset) => {
    const request = {
      identifier: id,
      offset: offset ? offset : 0,
      limit: 5,
    };

    ajax(
      "post",
      requestURI["incident.activites"],
      null,
      request,
      Incidence.renderActivitesData,
      showError,
      [id]
    );
  },

  renderActivitesData: (result, args) => {
    const paginationconatiner = document.querySelector(
      "#bnfxIncidenceEventsPagination"
    );
    let pagainationdata = null;

    CommonTemplate.ContainerAvailability(paginationconatiner, pagainationdata);
    let activitestotal = result.data.response.total;

    const id = args[0];
    let output = result.data.response.result;

    const paginationcontainer = document.querySelector(
      ".bnfx_incident_activities_pagination"
    );
    const nodatapaginationconatiner = document.querySelector(
      ".bnfx_paginationscroll_nodata"
    );
    let container = null;
    let data = null;
    if (!nodatapaginationconatiner) {
      if (!paginationcontainer) {
        container = document.querySelector("#bnfxIncidenceActivities");
        data = (
          <IncidenceActivites data={output} id={id} total={activitestotal} />
        );
      } else {
        container = paginationcontainer;
        data = <IncidenceActivitesChat data={output} />;
        const emptycontainer = document.createElement("div");
        emptycontainer.classList.add("bnfx_incident_activities_pagination");
        container.parentNode.insertBefore(emptycontainer, container);
      }
    }
    CommonTemplate.ContainerAvailability(container, data);
  },
  loadIncidenceevents: (id, offset) => {
    CommonTemplate.renderLoadingIcon("#bnfxIncidenceEvents")
    let limit = 10;
    const request = {
      identifier: id,
      limit: limit,
      offset: offset ? offset : 0,
    };

    ajax(
      "post",
      requestURI["incident.events"],
      null,
      request,
      Incidence.renderEventsData,
      showError,
      [limit, offset]
    );
  },

  renderEventsData: (result, args) => {
    let eventstotal = result.data.response.total;

    const offset = args[1];
    const limit = args[0];
    if (result.data.response.status === "failed") {
      // If result is not present, render the NoEvent component
      const container = document.querySelector("#bnfxIncidenceEvents");
      const data = (
        <NoContent
          id={"noincidenceevents"}
          title={t("noevents.for.incidenttitle")}
          description={t("noevents.for.incidentdescription")}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      let output = result.data.response;

      const container = document.querySelector("#bnfxIncidenceEvents");
      const data = <IncidenceEvents data={output} />;

      CommonTemplate.ContainerAvailability(container, data);
      Incidence.renderIncidencePanelPagination(
        eventstotal,
        limit,
        offset,
        "bnfxIncidenceEventsPagination"
      );
    }
  },
  loadcommentsubmit: (text, id) => {
    const request = {
      identifier: id,
      comments: text,
    };

    ajax(
      "post",
      requestURI["incident.comment"],
      null,
      request,
      Incidence.renderCommentsData,
      showError,
      [id]
    );
  },
  renderCommentsData: (result, args) => {
    let commentsection = document.getElementById("activitescommentsection");
    commentsection.innerHTML = "";
    Incidence.loadactivites(args[0]);
  },
  renderIncidencePanelPagination: (total, limit, offset, id) => {
    if (offset > 0) {
      return;
    }
    const paginationData = {
      total: total,
      limit: limit,
      offset: offset ? offset : 0,
    };

    const container = document.querySelector("#" + id);
    let data = (
      <Pagination
        pagination={paginationData}
        onClickFn={Incidence.getIncidencePaginationEvents}
      />
    );

    if (total > limit) {
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      data = null;
      CommonTemplate.ContainerAvailability(container, data);
    }
  },
  getIncidencePaginationEvents: (offset) => {
    Incidence.loadIncidenceevents(Incidence.incidentid, offset);
  },

  loadupdate: (id, data) => {
    const request = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const type = key;
        const value = data[key];
        request[type] = value;
      }
    }
    request["identifier"] = id;

    ajax(
      "post",
      requestURI["incident.update"],
      null,
      request,
      Incidence.renderupdateData,
      showError,
      [id]
    );
  },
  renderupdateData: (result, args) => {
    ToastPopup("Incident updated sucessfully");
    Incidence.loadincidentdetails(args[0]);
    Dashboard.renderWidgetData(Dashboard.widget_param, 0, false);
    // Incidence.loadoverview(args[0]);
    // Incidence.loadactivites(args[0]);
    // Incidence.loadIncidenceevents(args[0]);
  },
  loadStatusList: (id, currentstatus) => {
    const request = {
      workflowid: id,
      currentstatus: currentstatus,
    };
    ajax(
      "post",
      requestURI["incident.statuslist"],
      null,
      request,
      Incidence.renderliststatus,
      showError
    );
  },
  renderliststatus: (result) => {
    let originalData = result.data.response;
    const statusData = originalData.map((item) => ({
      value: item,
      label: item.toUpperCase(),
    }));
    setGlobalState("incidencestatusoptions", statusData);
  },

  display: (editorclass) => {
    $(
      ".description-box, .assignee-box, .entity-box, .criticality-box, .status-box, .reopen-box, .worflow-box"
    ).hide();
    $(".overview_sec").hide();
    $("." + editorclass).show();
  },
  displayback: (editorclass) => {
    $("." + editorclass).hide();
    $(".overview_sec").show();
  },

  loadworflow: () => {
    Incidence.display("worflow-box");
    Incidence.loadWorkflowID();
  },
  loadstatus: (worflowid, status) => {
    Incidence.display("status-box");
    Incidence.loadStatusList(worflowid, status);
  },

  loadReopenIncident: (data) => {
    const request = {
      identifier: data.id,
      probefrom: Incidence.probefrom,
      startstatus: data.status,
      timezone: CommonTemplate.Timezone,
    };
    ajax(
      "post",
      requestURI["incident.reopen"],
      null,
      request,
      Incidence.renderReopenIncidence,
      showError,
      [data.id]
    );
  },
  renderReopenIncidence: (result, args) => {
    Incidence.loadoverview(args[0]);
    Incidence.loadactivites(args[0]);
    Incidence.loadIncidenceevents(args[0]);
    Dashboard.renderWidgetData(Dashboard.widget_param, 0, false);
  },
  renderIncidenceDatePicker: (current) => {
    $(current).on("apply.daterangepicker", function (ev, picker) {
      const startDate = picker.startDate.format("MM/DD/YYYY HH:mm");
      Incidence.setProbeFrom(startDate);
    });
  },
  setProbeFrom: (probefrom) => {
    Incidence.probefrom = probefrom;
  },
  badgecriticalityColor: (criticality) => {
    return criticality === "HIGH"
      ? "badge badge-danger-lighten p-1 px-3 "
      : criticality === "MEDIUM"
      ? "badge badge-warning-lighten p-1 px-3"
      : "badge badge-success-lighten p-1 px-3";
  },
  showClass: () => {
    const container = document.querySelector("#modalname");
    const addDiv = document.createElement("div");
    addDiv.className = "customeModal-backgrop";
    container?.replaceWith(addDiv);
  },
  removeClass: () => {
    const offcanvasstart = document.querySelectorAll(
      ".offcanvas.offcanvas-start.show"
    );
    const offcanvasend = document.querySelectorAll(
      ".offcanvas.offcanvas-end.show"
    );

    if (!(offcanvasstart.length > 0) && !(offcanvasend.length > 0)) {
      const container = document.querySelector(".customeModal-backgrop");
      const removeDiv = document.createElement("div");
      removeDiv.id = "modalname";
      container?.replaceWith(removeDiv);
    }
  },
  removeOffcanvas: () => {
    const incidence_panel = document.querySelector(
      ".bxStreamLstOffCanvas.offcanvas.offcanvas-end.incidencePanel"
    );
    if (incidence_panel) {
      incidence_panel.classList.remove("show");
    }
  },
  // paneladdclossbutton:() => {
  //   let addshowclass = document.querySelector("panelheader");
  //   addshowclass.classList.add("show");
  // },
  panelloadetails: () => {
    CommonTemplate.showClass();
    let addshowclass = document.getElementById("bxOffcanvasPanel");
    addshowclass?.classList?.add("show");
  },
  handleToggleChange: (event) => {
    let type = event.target.checked;
    Incidence.accrossstream = type;
  },
  
  // {Incidence.getincidentid(
  //   entry,
  //   entry.streamid,
  //   entity,
  //   entitytype,
  //   "bnfxincidentid"
  // )}
  getentityincidentid: (row, placholderid) => {
    const request = {
      tenant: row.tenant,
      entity: "" + row.entity,
      entitytype: row.entitytype,
    };
    ajax(
      "post",
      requestURI["get.entity.incidentid"],
      null,
      request,
      Incidence.renderentityincidentid,
      showError,
      [row, placholderid]
    );
  },
  renderentityincidentid: (result, args) => {
    let placholderid = args[1];
    let data = args[0];
    const container = document.getElementById(
      placholderid + data.entitytype + data.entity
    );
    let incidentid = result.data.response;

    let createincidentoption = null;
    if (incidentid !== undefined) {
      container.innerHTML = "<span class='incidenthash'>#</span>" + incidentid;
      container.setAttribute("data-incidentid", incidentid);
      if (placholderid === "bnfxincidentid") {
        createincidentoption = document.getElementById(
          "creatincident" + data.entitytype + data.entity
        );
      } else if (placholderid === "bnfxgraphincident") {
        createincidentoption = document.getElementById(
          "bnfxdetectiongraphcreateincident" + data?.entitytype + data?.entity
        );
      }
      createincidentoption?.classList?.add("d-none");
    } else {
      container.closest(".incidentclass").classList.add("d-none");
    }
  },

  deleteIncidenceConfirmation: (id, from, refreshdata) => {
    Swal.fire({
      title: t("delete.title.confirmation"),
      text: t("delete.description.confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("stream.delete.yes"),
    }).then((result) => {
      if (result.isConfirmed) {
        Incidence.deleteIncident(id, from, refreshdata);
      }
    });
  },

  deleteIncident: (id, from, refreshdata) => {
    let request = {
      identifier: id,
    };
    ajax(
      "post",
      requestURI["incident.delete"],
      null,
      request,
      Incidence.renderDeletedIncident,
      showError,
      [from, refreshdata]
    );
  },
  renderDeletedIncident: (response, args) => {
    let from = args[0];
    let refreshdata = args[1];
    const deletetrue = t("Deleted");
    const streamdelete = "Incident Deleted Successfully";
    let success = t("success");
    Swal.fire(deletetrue, streamdelete, success);
    Incidence.removeClass();
    Incidence.removeOffcanvas();
    console.log(from,refreshdata)
    if (from === "fromdashboard") {
      Dashboard.renderWidgetData(Dashboard.widget_param, 0, false);
    } else if (from === "fromdetectionlist") {
      PanelScript.loaddetectionlist(refreshdata, 0);
    } else if (from === "fromentitylist") {
      PanelScript.loadenitylist(refreshdata, 0);
    }else if (from === "fromentitypanel") {
      PanelScript.loadEntityMeta(refreshdata);
    }else if(from==="fromattackpath"){
      PanelScript.loaddetectiongraph(
        refreshdata.id,
        refreshdata.entity,
        refreshdata.entitytype,
        refreshdata.title,
        refreshdata.tenant,
        refreshdata.score,
        refreshdata.streamid
      );
    }
  },
};