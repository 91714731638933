import { useLayoutEffect } from "react";
import Search from "../../search/Search";
import { Artifact } from "./ArtifactScript";
function ArtifactDetailsLayout() {
  useLayoutEffect(() => {
    const searchbar = document.getElementById("bnfxartifactdata_search");
    if (searchbar) {
      searchbar.addEventListener("keydown", function () {
        Artifact.search("bnfxartifactdata_search");
      });
      searchbar.addEventListener("input", function () {
        if (searchbar.value === "") {
          document.getElementById("bnfxartifactdata_search").value = null;
          Artifact.getArtifactDetailslist(0);
        }
      });
    }
  }, []);
  return (
    <>
      <div
        className="modal bxPopup fade"
        id="bnfxArtifactModal"
        tabIndex="-1"
        aria-labelledby="newSearchLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="tab-pane fade show custom-min-height-modal widgetHead-flat"
              id="artifact-data"
              role="tabpanel"
              aria-labelledby="entity-tab"
              tabIndex="0"
            >
              <div className="modal-header">
                <div id="bnfxartifactdetailcount"></div>{" "}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <Search id="bnfxartifactdata_search" defaultValue="" />
              <div className="custom_box" id="bnfxArtifactData"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArtifactDetailsLayout;
