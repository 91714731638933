import moment from "moment";
import React, { useEffect } from "react";
import { useGlobalState } from "../GlobalVariables";
import Select from "react-select";
import { Automation } from "./AutomationScript";
import { t } from "i18next";

function AutomationLayoutHeader(props) {
  const details = props.details;

  const [automationOptions] = useGlobalState("automationOptions");
  const [automationTimeOptions] = useGlobalState("automationTimeOptions");

  useEffect(() => {
    Automation.getAutomationOptions();
  }, []);
  return (
    <div className="offcanvas-header ">
      <div className="bxOutliersCard">
        <h4 className="BXf-18 text-white text-uppercase">
          <span className="material-symbols-outlined me-2 text-capitalize">
          order_play
          </span>
          {details.entity}&nbsp;&nbsp;&nbsp;(&nbsp;{details.entitytype}&nbsp;)
        </h4>
        <ul className="bxOC_list d-flex align-items-center" id="">
          <li className="BXf-12">
            {t("name.detection")}
            <span>{details.detection_name}</span>
          </li>
          <li className="BXf-12">
            {t("name.date")}
            <span>
              {moment(new Date(details.time)).format("DD MMM YYYY hh:mm A")}
            </span>
          </li>
        </ul>
        <ul
          className="bxOC_list d-flex align-items-center mb-0"
        >
          <li className="BXf-12">
            <span
              className="badge  px-3 py-2 text-uppercase"
              id="bnfxAutomationStatus"
            ></span>
          </li>
          {automationOptions.length > 0 && (
            <li className="BXf-12 d-flex align-items-center">
              <span className="mb-2 playbookdropdown_title">
                {t("title.playbooks")}
              </span>
              <Select
                id="bnfxAutomationList"
                className="form-control automationList ms-1"
                options={automationOptions}
                onChange={Automation.handleExecutionList}
                defaultValue={automationOptions[0]}
                onMenuScrollToBottom={Automation.loadMoreAutomation}
              ></Select>
            </li>
          )}
          {automationTimeOptions.length > 0 && (
            <li className="BXf-12 d-flex align-items-center">
              <span className="mb-2 playbookdropdown_title">
                {t("title.executions")}
              </span>
              <Select
                id="bnfxAutomationTime"
                className="form-control ms-1"
                options={automationTimeOptions}
                onChange={Automation.loadAutomationTaskLayout}
                defaultValue={automationTimeOptions[0]}
                onMenuScrollToBottom={Automation.loadMoreTimeAutomation}
              ></Select>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default AutomationLayoutHeader;
