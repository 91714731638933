import DashboardDetailsContainer from "./details/DashboardDetailsContainer";
import DashboardListContainer from "./list/DashboardListContainer";

function DashboardWrapperContainer() {

    return (
        <>
            <DashboardListContainer />
            <DashboardDetailsContainer />
        </>
    );

}


export default DashboardWrapperContainer;
