import React from "react";
import { requestURI } from "../RequestURIs";
import { ajax } from "../Service";
import { showError } from "../DisplayError";
import { getGlobalState, setGlobalState } from "../GlobalVariables";
import ToastPopup from "../ToastPopup";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import moment from "moment";
import AutomationLayoutHeader from "./AutomationLayoutHeader";
import AutomationListData from "./AutomationListData";
import AutomationTaskDetails from "./AutomationTaskDetails";
import NoContent from "../../../components/common/NoContent";
import { t } from "i18next";
import PlaybookAutomationLayout from "./PlaybookExecution/PlaybookAutomationLayout";
import Pagination from "../pagination/Pagination";
import AutomationDetailItem from "./AutomationDetailItem";
import TaskFlowMap from "./TaskFlowMap";

export const Automation = {
  searchtimeout: [],
  automation_offset: null,
  selected_Automation: null,
  executiontime_offset: null,
  selected_params: [],
  selected_execution_total: null,
  automation_total: null,
  initial_automation_parmas: [],

  setRequiredParamter: (data) => {
    Automation.selected_params = data;
  },

  getAutomationOptions: () => {
    let aumationoptions = getGlobalState("automationOptions");
    if (aumationoptions.length > 0 && aumationoptions[0] !== undefined) {
      Automation.handleExecutionList(aumationoptions[0]);
    }
  },

  getAutomationTimeOptions: () => {
    let aumationTimeoptions = getGlobalState("automationTimeOptions");
    if (
      aumationTimeoptions.length > 0 &&
      aumationTimeoptions[0] !== undefined
    ) {
      Automation.loadAutomationTaskLayout(aumationTimeoptions[0]);
    }
  },
  setInitialAutomationparmas: (data) => {
    Automation.initial_automation_parmas = data;
  },
  loadAutomationList: (offset,time,detection_name,entity,entitytype,streamid) => {
    Automation.clearAutomationList();
    let addshowclass = document.getElementById("bxOffcanvasPanel");
    addshowclass.classList.add("show");

    let data = { entity: entity, streamid: streamid, entitytype: entitytype };

    let emptyconatiner = document.querySelector("#bnfxAutomationTask");
    const emptydata = null;
    CommonTemplate.ContainerAvailability(emptyconatiner, emptydata);
    Automation.loadAutomation(offset,time,detection_name,entity,entitytype,data);
  },
  loadAutomation: (offset, time, detection_name, entity, entitytype, data) => {
    Automation.setRequiredParamter(data);

    const request = {
      limit: 5,
      offset: offset ? offset : 0,
      entity: Automation.selected_params.entity,
      streamid: Automation.selected_params.streamid,
      entitytype: Automation.selected_params.entitytype,
    };

    ajax(
      "post",
      requestURI["playbook.automation"],
      null,
      request,
      Automation.renderAutomation,
      showError,
      [time, detection_name, entity, entitytype, offset]
    );
  },

  renderAutomation: (response, args) => {
    let automations = response.data.response.result;

    let time = args[0];
    let detection_name = args[1];
    let entity = args[2];
    let entitytype = args[3];
    let total = response.data.response.total;
    const offset = args[4];
    let automationheaderdetails = { time, detection_name, entity, entitytype };
    const automationlist = automations?.map((item) => ({
      value: item.automation_id,
      label: item.name.toUpperCase(),
    }));

    if (total !== undefined) {
      Automation.setAutomationListTotal(total);
    }
    if (automationlist !== undefined) {
      setGlobalState("automationOptions", (prevAutomationOptions) => [
        ...prevAutomationOptions,
        ...automationlist,
      ]);
    } else if (Automation.automation_total === null) {
      const container = document.querySelector("#bnfxAutomationTask");
      const nodata = (
        <NoContent
          id={"noautomationtask"}
          title={t("no_automation_title")}
          description={t("no_automation_description")}
        />
      );
      CommonTemplate.ContainerAvailability(container, nodata);
    }

    if (offset === 0) {
      const container = document.querySelector("#bnfxAutomationLayoutHeader");
      const data = <AutomationLayoutHeader details={automationheaderdetails} />;

      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  clearAutomationList: () => {
    setGlobalState("automationOptions", []);
    setGlobalState("automationTimeOptions", []);
    Automation.setAutomationOffset(null);
    Automation.setExecutionTimeOffset(null);
    Automation.setAutomationListTotal(null);
    Automation.setExecutionTimeTotal(null);
  },

  // statusColor: (statusname) => {
  //   return statusname === "COMPLETED"
  //     ? "text-success ms-2"
  //     : statusname === "IN-PROGRESS"
  //     ? "text-warning ms-2"
  //     : "text-danger ms-2";
  // },
  setSelectedAutomation: (selectedAutomation) => {
    Automation.selected_Automation = selectedAutomation;
  },
  handleExecutionList: (selectedAutomation) => {
    Automation.setSelectedAutomation(selectedAutomation);
    setGlobalState("automationTimeOptions", []);
    Automation.getExecutionTime(selectedAutomation, 0);
  },
  getExecutionTime: (selectedAutomation, offset) => {
    const selectedautomationid = selectedAutomation.value;
    let request = {
      automation_id: selectedautomationid,
      limit: 5,
      offset: offset ? offset : 0,
      entity: Automation.selected_params.entity,
      streamid: Automation.selected_params.streamid,
      entitytype: Automation.selected_params.entitytype,
    };
    ajax(
      "post",
      requestURI["playbook.automation.time"],
      null,
      request,
      Automation.renderAutomationExecution,
      showError,
      [offset]
    );
  },

  setAutomationListTotal: (total) => {
    Automation.automation_total = total;
  },

  setExecutionTimeTotal: (total) => {
    Automation.selected_execution_total = total;
  },

  renderAutomationExecution: (response, args) => {
    let offset = args[0];
    let automations = response.data.response.result;
    let status = response.data.response.status;
    let total = response.data.response.total;
    if (total) {
      Automation.setExecutionTimeTotal(total);
    }
    if (offset === 0) {
      const container = document.querySelector("#bnfxAutomationStatus");
      let statusname = "Status : " + status;
      if (status === "completed") {
        container.style.backgroundColor = "rgb(4 108 4)";
      } else if (status === "completedwitherrors") {
        container.style.backgroundColor = "rgb(167 129 14)";
      } else if (status === "failed") {
        container.style.backgroundColor = "#9c1d0d";
      }

      CommonTemplate.ContainerAvailability(container, statusname);
    }
    if (automations === undefined && offset === 0) {
      const container = document.querySelector("#bnfxAutomationTask");
      const nodata = (
        <NoContent
          id={"noautomationtask"}
          title={t("noplaybook_found_title")}
          description={t("noplaybook_found_description")}
        />
      );
      CommonTemplate.ContainerAvailability(container, nodata);
    } else {
      const executiontimelist = automations?.map((item) => ({
        value: item.execution_id,
        label: moment(new Date(item.executiontime)).format("DD MMM hh:mm:ss"),
        timestatus: item.status,
      }));

      setGlobalState("automationTimeOptions", (prevAutomationTimeOptions) => [
        ...prevAutomationTimeOptions,
        ...executiontimelist,
      ]);
      if (offset === 0) {
        Automation.getAutomationTimeOptions();
      }
    }
  },

  loadAutomationTaskLayout: (executionid) => {
    const container = document.querySelector("#bnfxAutomationTask");

    const data = (
      <AutomationTaskDetails
        callback={() => Automation.loadAutomationTask(executionid.value)}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);
  },
  loadAutomationTask: (executionid) => {
    let request = {
      execution_id: executionid,
    };

    ajax(
      "post",
      requestURI["playbook.tasks"],
      null,
      request,
      Automation.renderAutomationTaskList,
      showError,
      [executionid]
    );
  },

  renderAutomationTaskList: (response, args) => {
    let result = response.data.response.result;
    let executionid = args[0];

    const container = document.querySelector("#bnfxAutomationData");
    const data = (
      <AutomationListData
        data={result}
        executionid={executionid}
        callback={() => {
          const firsttask = document.querySelector(".task_default_click");
          if (firsttask) {
            firsttask.click();
          }
        }}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);
  },
  getTaskDetails: (data, executionid) => {
    let request = {
      execution_id: executionid,
      taskname: data.taskname,
    };

    ajax(
      "post",
      requestURI["playbook.taskdetails"],
      null,
      request,
      Automation.renderAutomationTaskDetails,
      showError
    );
  },

  renderAutomationTaskDetails: (result) => {
    const DetailedContainer = document.querySelector("#bnfxAutomationTaskDetail");
    const Detaileddata = <AutomationDetailItem taskdata={result} />;
    CommonTemplate.ContainerAvailability(DetailedContainer, Detaileddata);
  },

  copyTaskData: (event, classname, notparent, json) => {
    const data = json
      ? JSON.stringify(json, null, 2)
      : notparent
      ? document.querySelector("." + classname).innerText
      : document.querySelector("." + classname).innerText;

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(data)
        .then(() => {
          //alert(data);
        })
        .catch((err) => {
          ToastPopup(err);
        });
    } else {
      // console.error('');
    }
  },
  automationStatusIcon: (status) => {
    let icon = null;
    if (status === "completed") {
      icon = <span className="al_dots icon-success"></span>;
    } else if (status === "failed") {
      icon = <span className="al_dots icon-danger"></span>;
    } else if (status === "in-progress") {
      icon = <span className="al_dots icon-progress"></span>;
    }

    return icon;
  },
  automationTaskStatusIcon: (status) => {
    let statusname = null;
    if (status === "completed") {
      statusname = (
        <span className="badge  px-2 py-2 icon-success text-uppercase">
          {t("title.status")} {status}
        </span>
      );
    } else if (status === "failed") {
      statusname = (
        <span className="badge  px-2 py-2 icon-danger text-uppercase">
          {t("title.status")} {status}
        </span>
      );
    } else if (status === "completedwitherrors") {
      statusname = (
        <span className="badge  px-2 py-2 icon-progress text-uppercase">
          {t("title.status")} {status}
        </span>
      );
    }
    return statusname;
  },
  setAutomationOffset: (AutomationOffset) => {
    Automation.automation_offset = AutomationOffset;
  },
  setExecutionTimeOffset: (ExecutionTimeOffset) => {
    Automation.executiontime_offset = ExecutionTimeOffset;
  },
  loadMoreAutomation: () => {
    if (Automation.automation_total > Automation.automation_offset) {
      Automation.setAutomationOffset(Automation.automation_offset + 5);
      Automation.loadAutomation(Automation.automation_offset,null,null,null,null,Automation.selected_params);
    }
  },
  loadMoreTimeAutomation: () => {
    if (Automation.selected_execution_total > Automation.executiontime_offset) {
      Automation.setExecutionTimeOffset(Automation.executiontime_offset + 5);
      Automation.getExecutionTime(
        Automation.selected_Automation,
        Automation.executiontime_offset
      );
    }
  },

  loadPlaybookAutomationList: (offset,entity,entitytype,streamid,detectionid,score,tenant,time,detectionname) => {
    let initialparmas = {
      offset: 0,
      time: time,
      detectionname: detectionname,
      entity: entity,
      entitytype: entitytype,
      streamid: streamid,
    };
    Automation.setInitialAutomationparmas(initialparmas);
    let data = {
      entity: entity,
      entitytype: entitytype,
      streamid: streamid,
      detectionid: detectionid,
      score: score,
      tenant: tenant,
    };
    Automation.setRequiredParamter(data);

    let addshowclass = document.getElementById("bxOffcanvasPanel");
    addshowclass.classList.add("show");
    let freetext = CommonTemplate.getSearchText("playbook_search");

    let request = {
      limit: Math.floor(
        Automation.bxOffcanvasHeights() / (window.innerWidth > 1500 ? 91 : 71)
      ),
      offset: offset ? offset : 0,
      query: freetext ? freetext : undefined,
      enabled: false,
      sortorder: false,
      sortcol: "lastupdated",
      type: "packages.automations",
    };

    ajax(
      "post",
      requestURI["list.stream"],
      null,
      request,
      Automation.renderExecuteList,
      showError,
      [offset]
    );
  },

  renderExecuteList: (response, args) => {
    let automations = response.data.response;
    const offset = args[0];

    Automation.getAutomationsCount(automations, offset);
  },

  renderPagination: (total, offset) => {
    if (total > offset) {
      const paginationData = {
        total: total,
        limit: Math.floor(
          Automation.bxOffcanvasHeights() / (window.innerWidth > 1500 ? 91 : 71)
        ),
        offset: offset ? offset : 0,
      };

      const container = document.getElementById(
        "bnfxPlaybookExecutionPagination"
      );

      const data = (
        <Pagination
          pagination={paginationData}
          onClickFn={Automation.getPlaybookExecutionPagination}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  getPlaybookExecutionPagination: (offset) => {
    Automation.loadPlaybookAutomationList(offset,Automation.selected_params.entity,Automation.selected_params.entitytype,
              Automation.selected_params.streamid,Automation.selected_params.detectionid,
              Automation.selected_params.score,Automation.selected_params.tenant);
  },

  getPlaybookExecute: (data) => {
    const request = {
      automationid: data._id,
      threshold: Automation.selected_params.score,
      entity: Automation.selected_params.entity,
      streamid: Automation.selected_params.streamid,
      entitytype: Automation.selected_params.entitytype,
      detectionid: Automation.selected_params.detectionid,
      tenant: Automation.selected_params.tenant,
    };

    ajax(
      "post",
      requestURI["playbook.execute"],
      null,
      request,
      Automation.renderAutomationExecute,
      showError
    );
  },

  renderAutomationExecute: (response) => {
    let data = Automation.initial_automation_parmas;
    Automation.loadAutomationList(data.offset,data.time,data.detection_name,data.entity,data.entitytype,data.streamid);

    let container = document.querySelector(
      ".bxStreamLstOffCanvas.offcanvas.playbook_automation.offcanvas-end"
    );
    container.classList.remove("show");
  },

  getAutomationsCount: (automations, offset) => {
    let freetext = CommonTemplate.getSearchText("playbook_search");
    const parameters = {
      enabled: false,
      query: freetext ? freetext : undefined,
      type: "packages.automations",
    };

    ajax(
      "post",
      requestURI["stream.count"],
      null,
      parameters,
      Automation.createAutomationPagination,
      showError,
      [automations, offset]
    );
  },
  createAutomationPagination: (result, args) => {
    const total = result.data.response;

    let automations = args[0];
    let offset = args[1];
    const container = document.querySelector("#bnfxPlaybookExecutionList");
    const data = (
      <PlaybookAutomationLayout
        data={automations}
        callback={() => Automation.renderPagination(total, offset)}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);

    if (total === undefined || automations.length === 0) {
      const container = document.querySelector("#bnfxPlaybookExecutionList");
      const nodata = (
        <NoContent
          id={"noautomationtask"}
          title={t("no_automation_title")}
          description={t("no_automation_description")}
        />
      );
      CommonTemplate.ContainerAvailability(container, nodata);
    }
  },

  search: (id) => {
    const freetext = document.querySelector("#" + id).value;

    if (freetext.length > 3 || freetext.length === 0) {
      if (Automation.searchtimeout && Automation.searchtimeout.length > 0) {
        for (let index = 0; index < Automation.searchtimeout.length; index++) {
          window.clearTimeout(Automation.searchtimeout[index]);
        }
      }

      Automation.searchtimeout.push(
        setTimeout(() => {
          if (id === "playbook_search") {
            Automation.loadPlaybookLayout(0);
          }
        }, 2000)
      );
    }
  },

  loadPlaybookLayout: (offset) => {
    Automation.loadPlaybookAutomationList(offset,Automation.selected_params.entity,Automation.selected_params.entitytype,
              Automation.selected_params.streamid,Automation.selected_params.detectionid,
              Automation.selected_params.score,Automation.selected_params.tenant);
  },
  bxOffcanvasHeights: () => {
    let panelheight = document.querySelector(
      "#bnfxPlaybookExecution"
    ).clientHeight;
    let headerheight = document.querySelector(
      "#bnfxPlayookexecutionheader"
    ).clientHeight;

    let finalheight = panelheight - headerheight - 35 - 70-60;
    return finalheight;
  },

  taskmapworkflow: (data) => {
    setGlobalState("globalautomationworkflowid",data)
    const offcanvasElement = document.getElementById("bnfxautomationtaskmap");

    if (offcanvasElement) {
      offcanvasElement.classList.add("show"); // Add 'show' class to display the offcanvas
    }
    let request = {
      _id: data._id,
    };

    ajax(
      "post",
      requestURI["automation.taskmap"],
      null,
      request,
      Automation.renderAutomationTaskmap,
      showError,
      [data]
    );
  },

  renderAutomationTaskmap: (result,args) => {
    let newdata = result.data.response;
    const container = document.querySelector("#bnfxtaskmap"+args[0]._id);
  
    let derviednodes = Automation.derivedNodes(newdata);
    let derviededges = Automation.derivedEdges(newdata);
    let data = <TaskFlowMap node={derviednodes} edge={derviededges} />;
    CommonTemplate.ContainerAvailability(container, data);
  },
  derivedNodes: (data) => {
    const nodes = [];

    // Add "begin" node
    nodes.push({
      id: "begin",
      type: "begin",
      data: { label: "begin" },
      style: { border: "1px solid #777", padding: 1 },
    });

    data.tasks.forEach((task, index) => {
      nodes.push({
        id: `${task.name.trim()}`,
        type: "automationnode",
        data: { label: {"title":task.name,"description":task.description}},
        style: { border: "1px solid #777", padding: 1 },
       
      });
    });

    // Add "end" node
    nodes.push({
      id: "end",
      type: "end",
      data: { label: "end" },
      style: { border: "1px solid #777", padding: 1 },

    });

    return nodes;
  },

  derivedEdges: (data) => {
    const edges = [];

    // Add edge from "begin" to the first node
    const firstTask = data.tasks[0];
    if (firstTask) {
      edges.push({
        id: `begin-${firstTask.name}`,
        source: "begin",
        target: firstTask.name,
        animated: true,
      });
    }

    data.tasks.forEach((task, index) => {
      const id = task.name.trim();

      if (index < data.tasks.length - 1) {
        // Add edges for on_success and on_failure, excluding the last node
        if (task.on_success && task.on_success.length > 0) {
          task.on_success.forEach((successTask) => {
            edges.push({
              id: `${id}-${successTask}`,
              source: id,
              target: successTask,
              animated: true,
            });
          });
        }

        // if (task.on_failure && task.on_failure.length > 0) {
        //     task.on_failure.forEach((failureTask) => {
        //         edges.push({ id: `${id}-${failureTask}`, source: id, target: failureTask, animated: true });
        //     });
        // }
      }
    });
    // Add edge from the last node to "end"
    const lastTask = data.tasks[data.tasks.length - 1];
    if (lastTask) {
      edges.push({
        id: `${lastTask.name}-end`,
        source: lastTask.name,
        target: "end",
        animated: true,
      });
    }

    return edges;
  },
  removeClass: () => {
    const offcanvasElement = document.getElementById("bnfxautomationtaskmap");

    if (offcanvasElement) {
      offcanvasElement.classList.remove("show"); // Add 'show' class to display the offcanvas
    }
  },

  removeAutomationPanel:()=>{
    const automation_model=document.querySelector(".offcanvas.Custom_offcanvas.offcanvas-end")
    if (automation_model) {
      automation_model.classList.remove("show"); 
    }

    const execution_model=document.querySelector(".bxStreamLstOffCanvas.offcanvas.playbook_automation.offcanvas-end")
    if (execution_model) {
      execution_model.classList.add("show"); 
    }
  },

  offcanvasCloseButton:()=>{
    const automation_model=document.querySelector(".offcanvas.Custom_offcanvas.offcanvas-end")
    const execution_model=document.querySelector(".bxStreamLstOffCanvas.offcanvas.playbook_automation.offcanvas-end")

    if (automation_model) {
      automation_model.classList.remove("show"); 
    }

    if (execution_model) {
        execution_model.classList.remove("show");
    }
  },

  removeExecutionPanel:()=>{
    const execution_model=document.querySelector(".bxStreamLstOffCanvas.offcanvas.playbook_automation.offcanvas-end")
    if (execution_model) {
      execution_model.classList.remove("show"); 
    }

    const automation_model=document.querySelector(".offcanvas.Custom_offcanvas.offcanvas-end")
    if (automation_model) {
      automation_model.classList.add("show"); 
    }
  }
};
