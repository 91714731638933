import { useState } from "react";
import { Dashboard } from "../DashboardScript";
import { setGlobalState } from "../../../components/common/GlobalVariables";

const DashboardListItem = (props) => {
  let dashboard_component = null;

  const [activeDashboardId, setActiveDashboardId] = useState(null);

  const handleDashboardClick = (dashboardId) => {
    const dashboardElements = document.querySelectorAll(".row .card");
    dashboardElements.forEach((element) => {
      element.classList.remove("active");
    });

    const clickedEnabledDashboardElement = document.getElementById(
      `enabled-dashboard-form${dashboardId}`
    );
    if (clickedEnabledDashboardElement) {
      clickedEnabledDashboardElement.classList.add("active");
    }

     
    const closePanel=document.querySelector(".tab-pane.show.active")
    if(closePanel){
      closePanel.classList.add("collapsed")
    }

    const showPanel=document.querySelector(".right-section.easy-3s.desktop-right.float-end")
    if(showPanel){
      showPanel.classList.add("task-collapsed")
    }
    setGlobalState("global_left_panel_collapsed", true);
    const clickedAvailableDashboardElement = document.getElementById(
      `available-dashboard-form${dashboardId}`
    );
    if (clickedAvailableDashboardElement) {
      clickedAvailableDashboardElement.classList.add("active");
    }

    setActiveDashboardId(dashboardId);
  };

  if (props.enabled) {
    dashboard_component = (
      <div className="row m-0">
        <div
          className="bxStreamList_sec p-0"
          onClick={() => Dashboard.loadDashboard(props.dashboard)}
        >
          <span
            className={`card ${
              props.dashboard._id === activeDashboardId ? "active" : ""
            }`}
            id={`enabled-dashboard-form${props.dashboard._id}`}
            key={props.dashboard._id}
            onClick={() => handleDashboardClick(props.dashboard._id)}
          >
            <div className="bxStream_link">
              <span className="bxIcon_link ">
                {props.dashboard.title.charAt(0)}
              </span>
              <div
                className="bxStream_linkContent dashboard-link"
                identifier={props.dashboard?._id}
              >
                <h3 className="bxStreamTitle_link">{props.dashboard.title}</h3>

                <p className="bxStreamDiscription_link">
                  {props.dashboard.description}
                </p>
              </div>
            </div>
          </span>
        </div>
      </div>
    );
  } else {
    dashboard_component = (
      <div className="row m-0">
        <div
          className="bxStreamList_sec p-0"
          onClick={() => Dashboard.selectAvailableDashboard(props.dashboard)}
        >
          <span
            className={`card ${
              props.dashboard._id === activeDashboardId ? "active" : ""
            }`}
            id={`available-dashboard-form${props.dashboard._id}`}
            key={props.dashboard._id}
            onClick={() => handleDashboardClick(props.dashboard._id)}
          >
            <div className="bxStream_link">
              <span className="bxIcon_link ">
                {props.dashboard.title.charAt(0)}
              </span>
              <div
                className="bxStream_linkContent dashboard-link"
                identifier={props.dashboard?._id}
              >
                <h3 className="bxStreamTitle_link">{props.dashboard.title}</h3>

                <p className="bxStreamDiscription_link">
                  {props.dashboard.description}
                </p>
              </div>
            </div>
          </span>
        </div>
      </div>
    );
  }

  return <>{dashboard_component}</>;
};
export default DashboardListItem;
