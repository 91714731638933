import dagre from "dagre";
import React, { useCallback, useEffect } from "react";
import ReactFlow, {
  Controls,
  addEdge,
  useEdgesState,
  useNodesState
} from "reactflow";
import 'reactflow/dist/style.css';
import "../../../../src/components/flow/flow.css";
import AutomationTaskEnd from "../../flow/AutomationTaskEnd";
import AutmationTaskBegin from "../../flow/AutmationTaskBegin";
import AutomationTaskNode from "../../flow/AutomationTaskNode";
const nodeTypes = {
  end: AutomationTaskEnd,
  begin: AutmationTaskBegin,
  automationnode: AutomationTaskNode
 
};
function TaskFlowMap(props) {

  const connectionLineStyle = { stroke: "#fff" ,strokeWidth: 2, animated: true};
  const snapGrid = [100, 100];
  const defaultViewport = { x: 0, y: 0, zoom: 1.2 };

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback((params) => setEdges((els) => addEdge(params, els)), [setEdges]);


  const dagreGraph = new dagre.graphlib.Graph();

  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 420;
  const nodeHeight = 120;

  const getLayoutedElements = (nodes, edges, direction = "TB") => {
    const isHorizontal = direction === "TB";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node, i) => {
      dagreGraph.setNode(node.id, { width: i === 0 ? 0 : nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    let timestampposition={x:-300,y:200}
    let negative =false
    nodes.forEach((node, i) => {
 
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "top" : "left";
      node.sourcePosition = isHorizontal ? "bottom" : "right";
     
      node.position = {
        x: nodeWithPosition.x - nodeWidth ,
        y: nodeWithPosition.y - nodeHeight
      };

      if (i === 0) {
        node.position.y =0;
        node.position.x =0;
      } else if (i === 1) {
        node.position.y =200;
        node.position.x = -300;
      } else if (node.type==='automationnode') {
      if(!negative){
        timestampposition.x = timestampposition.x -200;
        timestampposition.y =  timestampposition.y + 300;}
      else{
        timestampposition.x = timestampposition.x +300;
        timestampposition.y =  timestampposition.y + 300;
      }
      negative=!negative
        node.position.x = timestampposition.x;
        node.position.y = timestampposition.y;
    
    }
      else if (node.id ==='end') {
        node.position.y =timestampposition.y+300;
  
        node.position.x = 0;
      }

      return node;
    });

    return { nodes, edges };
  };
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    props.node,
    props.edge
  );
  useEffect(() => {
    setEdges(layoutedEdges);
    setNodes(layoutedNodes);
  }, [setNodes,layoutedNodes,setEdges,layoutedEdges]);
  

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      connectionLineStyle={connectionLineStyle}
      snapToGrid={true}
      snapGrid={snapGrid}
      defaultViewport={defaultViewport}
      fitView
      attributionPosition="bottom-left"
    >
      <Controls />
    </ReactFlow>
  );
};

export default TaskFlowMap;
