import { t } from "i18next";
function AlreadyVerifyPage() {
  return (
    <div className="row align-items-center bxLoginArea confirmation_page">
      <div className="col-lg-5 col-xxl-4 mx-auto">
        <div className="bxLeftBox text-white p-lg-0 p-3 text-center">
          <img src="../images/close.png" alt="Binaryflux" height="150" />
          <br />
          <br />
          <h3 className="logDis">
            {t("verify.page.description")}{" "}
          </h3>
        </div>
      </div>
    </div>
  );
}

export default AlreadyVerifyPage;
