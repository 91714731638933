
import { getGlobalState } from "../common/GlobalVariables";
import { CommonTemplate } from "../common/containerAvailability/CommonScript";
import GraphNodeLayout from "./GraphNodeLayout";

export const Flow = {
  provider: null,
  detectiondatagraph: null,
  graphentity :null,
  graphentitytype :null,

  setdeetctiondata: (detectionid) => {
    Flow.detectiondatagraph = detectionid;
  },

  setprovider: (provider) => {
    Flow.provider = provider;
  },

  setentity: (entity) => {
    Flow.graphentity = entity;
  },

  setentitytype: (entitytype) => {
    Flow.graphentitytype = entitytype;
  },

  detectiongraphnodesedges: (cleanedResponse, incidentrequest,requestdata) => {
    let nodes = [
      {
        id: "1",
        type: "datasource",
        data: { label: Flow.provider },
        style: { border: "1px solid #777", padding: 1 },
      },
      {
        id: "2",
        type: "datadetectionname",
        data: {
          label: Flow.detectiondatagraph[1],
          score: Flow.detectiondatagraph[2],
        },
        style: { border: "1px solid #777", padding: 1 },
      },
      {
        id: `timestamp`,
        type: "datadetectiontime",
        data: { label: cleanedResponse, incidentrequest: { incidentrequest,requestdata } },
        style: { border: "1px solid #777", padding: 1 },
      },
      {
        id: `entity`,
        type: "dataentityname",
        data: {
          label: Flow.graphentity,
          type: Flow.graphentitytype,
          incidentrequest: { incidentrequest,requestdata },
        },
        style: { border: "1px solid #777", padding: 1 },
      },
    ];
    let edges = [
      {
        id: "e1-1",
        source: "1",
        target: "2",
        targetHandle: "a",
        animated: true,
        style: { stroke: "#fff" },
        type: "smoothstep",
      },
      {
        id: `e1-2`,
        source: "2",
        target: `timestamp`,
        animated: true,
        style: { stroke: "#fff" },
        type: "smoothstep",
      },
      {
        id: `e1-3`,
        source: "timestamp",
        target: `entity`,
        sourceHandle: "top",
        animated: true,
        style: { stroke: "#fff" },
        type: "smoothstep",
      },
    ];

    const container = document.querySelector(
      "#detectiongraph" + Flow.detectiondatagraph[0]
    );
    let derviednodes = Flow.derivednodes(cleanedResponse, nodes);
    let derviededges = Flow.derviededges(cleanedResponse, edges);
    let data = <GraphNodeLayout node={derviednodes} edge={derviededges} />;
    CommonTemplate.ContainerAvailability(container, data);
  },

  derivednodes: (cleanedResponse, nodes) => {
    const combinedNodes = nodes.concat([]);
    let timestamp = getGlobalState("selectedTimestamp");

    const attributenode = {
      id: `${timestamp}.attribute`,
      type: "dataentitytype",
      data: { label: "Artifacts" },
      style: { border: "1px solid #777", padding: 1 },
    };

    combinedNodes.push(attributenode);

    let nodeValue = null;
    for (const key in cleanedResponse[timestamp]) {
      if (key === "entity") {
        continue;
      }

      let value = cleanedResponse[timestamp][key];

      nodeValue = {
        id: `${timestamp}.${key}.value`,
        type: "dataentityresult",
        data: { label: value, type: key },
        style: { border: "1px solid #777", padding: 1 },
      };

      combinedNodes.push(nodeValue);
    }

    return combinedNodes;
  },
  derviededges: (cleanedResponse, edges) => {
    const combinedEdges = edges.concat([]);
    let timestamp = getGlobalState("selectedTimestamp");

    let edge5 = null;
    let edgeattribute = null;
    let edge6 = null;
    edge6 = {
      id: `e1-4${timestamp}.attribute`,
      source: `timestamp`,
      target: `${timestamp}.attribute`,
      animated: true,
      style: { stroke: "#fff" },
      sourceHandle: "right",
      type: "smoothstep",
    };
    combinedEdges.push(edge6);
    for (const key in cleanedResponse[timestamp]) {
      if (key === "context") {
        edge5 = {
          id: `e1-5${timestamp}.${key}.value`,
          source: `timestamp`,
          target: `${timestamp}.${key}.value`,
          animated: true,
          style: { stroke: "#fff" },
          sourceHandle: "bottom",
          type: "smoothstep",
        };
        combinedEdges.push(edge5);
      } else if (key !== "entity") {
        edgeattribute = {
          id: `e1-6${timestamp}.${key}.value`,
          source: `${timestamp}.attribute`,
          target: `${timestamp}.${key}.value`,
          animated: true,
          style: { stroke: "#fff" },
          type: "smoothstep",
        };
        combinedEdges.push(edgeattribute);
      }
    }

    return combinedEdges;
  },
};
