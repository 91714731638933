import { t } from "i18next";
import { getGlobalState, useGlobalState } from "../../../components/common/GlobalVariables";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";

function NetworkGraphModel() {
  const [globaldetectiongraphid] = useGlobalState("globaldetectiongraphid");
const closebutton=()=>{
  let incidenceclass=document.getElementById("bxOffcanvasIncidence")
 incidenceclass.classList.remove("graphopen");
  let removecalss=document.querySelector(".networkOffcanvas")
  removecalss?.classList.remove("show")
  CommonTemplate.removeCustomeModelClass();
}
let ispanelrequired=getGlobalState("ispanelrequired")
let panelrequired=null
if(ispanelrequired){
  panelrequired=<button
  type="button"
  className="btn-close bg-white"
  aria-label="Close"
 data-bs-toggle="offcanvas"
  data-bs-target="#bxOffcanvasPanel"
  data-bs-dismiss="offcanvas"
  aria-controls="offcanvasRight"
  
  onClick={closebutton}
></button>
}else{
  panelrequired=<button
  type="button"
  className="btn-close bg-white"
  aria-label="Close"
  data-bs-dismiss="offcanvas"
  aria-controls="offcanvasRight"  onClick={closebutton}
  ></button>
}
  return (
    <>
      <div
        className="networkOffcanvas offcanvas offcanvas-start"
        id="bnfxdetctiongraph"
        aria-labelledby="staticBackdropLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="staticBackdropLabel">
            {t("detection.graph.header.text")}{" "}
            {globaldetectiongraphid[1]?.toUpperCase()}
          </h5>
          {panelrequired}
        </div>
        <div className="offcanvas-body">
          <div
            className="m-0 h-100"
            id={"detectiongraph" + globaldetectiongraphid[0]}
          ></div>
        </div>
      </div>
    </>
  );
}

export default NetworkGraphModel;
