import React, { useLayoutEffect, useRef } from 'react';
import Highcharts from 'highcharts';


const StackedBar = (props) => {
  const chartContainer = useRef(null);
  const container=props.container;
  const series=props.series;
  const categories=props.categories;
  let height=props.height;
  let layout=props.layout;

  let colors = ["#fee17e", "#3d9c73", "#63b179", "#88c580","#d6ec91","#ffff9d","#fee17e"];

  useLayoutEffect(() => {
    let widgetheight = null;
    if (chartContainer.current) {
      const parent = container.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );
      if (layout === "conciselayout") {
        widgetheight = parseInt(document.getElementById(parent)?.clientHeight) - 70;
      } else {
        widgetheight = parseInt(document.getElementById(parent)?.clientHeight) - 120;
      }
      Highcharts.chart(chartContainer.current, {
        chart: {
          type: 'bar',
          height: height ? height + "px" : widgetheight + "px",
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: categories,
          labels: {
            useHTML: true,
            formatter: function () {
              const label = this.value;
              const maxLength = 10; // Set the maximum length for the label
              const truncatedLabel =
                label.length > maxLength
                  ? label.substring(0, maxLength) + "..."
                  : label;
              return `<span title="${label}">${truncatedLabel}</span>`;
            },
            style: {
              color: "#b3771a",
              textOverflow: "ellipsis",
            },
          },
        },
        yAxis: {
            type: 'logarithmic',
            min: 1, // Set the minimum value to avoid log(0)
            title: {
              text: ''
            },
            gridLineWidth: 0,
            labels: {
              formatter: function() {
                  if (this.value >= 1000000) {
                      return (this.value / 1000000) + 'M'; // Format for millions
                  } else if (this.value >= 1000) {
                      return (this.value / 1000) + 'k'; // Format for thousands
                  } else {
                      return this.value; // Use the value as is for smaller numbers
                  }
              }
          }
          },
        credits: {
          enabled: false,
        },
        colors: colors,
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderWidth: 0, // Remove border width
            dataLabels: {
              enabled: false
            }
          }
        },
        series: series
      });
    }
  }, [series, categories, container, height, layout, colors]);
  

  return <div ref={chartContainer} id={container} />;
};

export default StackedBar;
