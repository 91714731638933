import React, { useLayoutEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsSankey from 'highcharts/modules/sankey';

HighchartsSankey(Highcharts);

const SankeyDiagram = (props) => {
  const chartContainer = useRef(null);
  const series=props.series;
  const container=props.container;
  const widgetid=props.id;
  const column=props.column;
  const label=props.label;
  const onclick_param=props.onclick_param;
  const columnmap=props.columnmap;
  
  const handleNameClick = (name,point) => {
    let dataset = { name: name, column: column, label: label ,data:props.uniquekey,point:point,columnmap:columnmap};
    
    props.onClickFn(dataset, widgetid);
  };


  // let seriesData = [{
  //   keys: ['from', 'to', 'weight'],
  //   data: [
  //     ['10.10.102.99', '10.10.2.150', 2.29],
  //     ['10.10.102.112', '13.107.136.10', 1.95],
  //     ['10.10.102.170', '52.98.87.242', 833.33],
  //     ['10.10.102.168', '157.240.15.60', 696.89],
  //     ['10.10.102.108', '52.98.86.162', 443.16],
  //     ['10.10.102.112', '13.107.138.10', 373.78]
  //   ]
  // }];

  useLayoutEffect(() => {
    let height = null;
    if (chartContainer.current) {
      const parent = container.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );
      if (parent) {
        height = parseInt(document.getElementById(parent)?.clientHeight) - 90;
      }
      Highcharts.chart(chartContainer.current, {
        chart: {
          type: 'sankey',
          height: height + 'px',
          events: {
            load: function () {
              if (onclick_param === 'open_offcanvaspanel') {
              this.series[0]?.points.forEach((point) => {
                if (point.graphic && point.graphic.element) {
                  point.graphic.element.addEventListener("mousedown", () => {
                    point.graphic.element.setAttribute(
                      "data-bs-toggle",
                      "offcanvas"
                    );
                    point.graphic.element.setAttribute(
                      "data-bs-target",
                      "#bxOffcanvasPanel"
                    );
                    handleNameClick(point.options.from,point);
                  });
                }
              })};
            },
          },
        },
        title: {
          text: '',
        },
        subtitle: {
          text: '',
        },
        accessibility: {
          point: {
            valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
          }
        },
        tooltip: {
          headerFormat: null,
          pointFormat: '{point.fromNode.name} \u2192 {point.toNode.name}: {point.weight:.2f} gb',
          nodeFormat: '{point.name}: {point.sum:.2f} gb'
        },
        plotOptions: {
          sankey: {
            dataLabels: {
              enabled: true,
              style: {
                fontFamily: 'Arial',
                fontSize: '12px',
                fontWeight: 'normal',
                color: '#FFF'
              }
            },
            nodePadding: 8,
            curveFactor: 0.5,
            colorByPoint: true,
          }
        },
        colors: [
          "#003F5C",
          "#004c6d",
          "#006083",
          "#007599",
          "#88c580",
          "#aed987",
          "#d6ec91",
          "#ffff9d",
          "#fee17e",
          "#00e7f2",
          "#00ffff",
          "#00876c",
          "#3d9c73",
          "#63b179",
          "#fcc267",
          "#f7a258",
          "#ef8250",
          "#008bad",
          "#00a1c1",
          "#00b8d3",
          "#e4604e",
          "#d43d51"
        ],
        series: series,
        credits: {
          enabled: false
        }
      });
    }
  }, [container]);

  return <div ref={chartContainer} id={container} />;
};

export default SankeyDiagram;
