import { useLayoutEffect } from "react";
import { Stream } from "../StreamScript";
import StreamDetailTab from "./StreamDetailTab";
import { useGlobalState } from "../../../components/common/GlobalVariables";
import NewRiskPage from "../../risk/NewRiskPage";

function StreamDetailsLayout({ stream }) {
  const [showToggle] = useGlobalState("showToggle");

 
  useLayoutEffect(() => {
    if(!showToggle){
    Stream.loadDetailsLayout(stream);}
  }, [stream,showToggle]);
  

  return (
    <>
      <div className="bxStreamRightSide">
        <div id="bxSREDetails" className="bxStreamRightEventDetails">
          <div id="bnfxStreamMetaData">{/* here we replace metadata */}</div>
          
          {!showToggle &&<div id="bnfxCumulativeStatsStreamDetails">
            {/* here we replace CumulativeStats */}
          </div>}
        </div>

       {!showToggle &&<StreamDetailTab/>}
       {showToggle && <NewRiskPage />}
      </div>
    </>
  );
}

export default StreamDetailsLayout;
