import React, { memo, useCallback } from "react";
import { useReactFlow } from "reactflow";
import { Handle, Position } from "reactflow";
import { SpiderScript } from "../SpiderScript";
import { getGlobalState } from "../../../components/common/GlobalVariables";
import ShowErrorToastPopup from "../../../components/common/ShowErrorToastPopup";

const StreamNode = memo(({ data, isConnectable }) => {
  let id = data.value;

  let draft = data.output?.draft;

  const { deleteElements } = useReactFlow();

  const removeNode = useCallback((e) => {  
    let nodes = getGlobalState("spidernodeslist");
    if (
      id === "detection-0" &&
      nodes.filter(
        (node) => node.id.startsWith("detection-") && node.type === "streamnode"
      ).length === 1
    ) {
      ShowErrorToastPopup("There should be atleast one detection node in the Stream");
      console.log("Cannot delete the only detection node");
      return;
    } else {
     
      e.stopPropagation();
      if(data.output!== undefined){
      SpiderScript.deleteNode([data.output, data.label]);
    }
      deleteElements({ nodes: [{id }] });
    }
  }, [id, deleteElements]);

  let logo = null;
  if (data.label === "dictionary") {
    logo = "menu_book";
  } else if (data.label === "filter") {
    logo = "filter_alt";
  } else if (data.label === "transformation") {
    logo = "settings_timelapse";
  } else if (data.label === "detection") {
    logo = "coronavirus";
  } else if (data.label === "automation") {
    logo = "macro_auto";
  } else if (data.label === "sequence") {
    logo = "crossword";
  } else if (data.label === "report") {
    logo = "summarize";
  } else if (data.label === "fragment") {
    logo = "data_table";
  } else {
    logo = "circles_ext";
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        id="d"
        style={{ top: -10, left: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
      <div className="Hover_delete_button">
        {data.label !== "dictionary" ? (
          <b
            className="material-symbols-outlined Spider_Delete_items"
            onClick={(e) => removeNode(e)}
          >
            delete
          </b>
        ) : null}
        <div
          className={`card spiderBox_area ${draft ? "draft" : "nodraft"} ${
            data.label
          }`}
        >
          <div
            className="card-body d-flex align-items-center p-0 justify-content-between"
            onClick={() => {
              SpiderScript.handleToggleCollapse();
            }}
          >
            <span className="material-symbols-outlined me-2">{logo}</span>
            <div>
              <p
                className="m-0 Spider_font16 text-white"
                title={data.output?.title}
              >
                {data.output?.title
                  ? data.output?.title.charAt(0).toUpperCase() +
                    data.output?.title.slice(1)
                  : data.label.charAt(0).toUpperCase() + data.label.slice(1)}
              </p>
              {data.output?.description ? (
                <p
                  className="m-0 Spider_font14"
                  title={data.output?.description}
                >
                  {data.output?.description}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="e"
        style={{ bottom: -10, right: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
    </>
  );
});

export default StreamNode;
