import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ShowErrorToastPopup(message,position) {
    toast.error(message, {
        position: position?toast.POSITION.BOTTOM_CENTER:toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        pauseOnHover: false,
        closeButton: false, // Optionally, you can hide the close button
        style: {
            background: '#061731',
            color: '#FFFF', 
        },
        });
}

export default ShowErrorToastPopup;