import LogoImage from "../../logo/LogoImage";
import MainNavBar from "./leftHeader/MainNavBar";
import TopRightHeader from "./rightHeader/TopRightHeader";
import "../mainHeader/header.css";
function Header() {
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary" id="bxNavBar">
                <div className="container-fluid p-0">
                    <div className="bxHeaderLeftNav d-flex align-items-center">
                        <MainNavBar />
                        <LogoImage height="35" />
                    </div>
                </div>
            </nav>
            <TopRightHeader />
        </>
    )
}

export default Header;