import { t } from "i18next";
import React from "react";

function NoDetections() {
  return (
    <>
      <div className="DopplerInputFiled mb-2">
        <p className="form-contoral detectionName mb-2">
          {t("no_detection_msg")}
        </p>
      </div>
    </>
  );
}

export default NoDetections;
