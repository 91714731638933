import { createGlobalState } from "react-hooks-global-state";
let LLMSTABLE = process.env.REACT_APP_LLM_STABLE;

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState({
  loggedIn: localStorage.getItem("token") != null,
  global_stream_left_panel_collapsed: false,
  global_stream_right_panel_collapsed: false,
  global_left_panel_collapsed: true,
  global_right_panel_collapsed: false,
  doppler_user_profile: false,
  // stats_collapsed: true,
  search_toggle: false,
  expand_data: false,
  stream_no_data_present: true,
  dashboard_no_data_present: true,
  bnfx_app_loaded: false,
  useropen: false,
  bnfx_default_uri: "/",
  TenantOptions: [],
  StreamsOptions: [],
  urlOptions: [],
  RoleOptions: [],
  groupOptions: [],
  AllRoleOptions: [],
  AllgroupOptions: [],
  groupName: "",
  emailAddress: "",
  userstatus: "",
  selectedStreams: [],
  selectedTenants: [],
  selectedUrls: [],
  selectedGroups: [],
  selectedRoles: [],
  selectedUserTenants: [],
  FilterOptions: [],
  GlobalWidgetId: {},
  startDate: "",
  endDate: "",
  globalwidget: "",
  isEdit: false,
  _id: "",
  stream_selected_days: "",
  globaldetectiongraphid: [],
  globalautomationworkflowid:[],
  global_stream_rendered: false,
  login_session_expired: false,
  assigntype: [],
  WorkFlowOptions: [],
  dashboarduseroptions: [],
  dashboardgroupoptions: [],
  assigneeoptions: [],
  assignee: [],
  WorkFlowId: [],
  incidencestatusoptions: [],
  incidenceassigneeoptions: [],
  selectedStatus: [],
  isSearchEdit: false,
  global_search_value: LLMSTABLE==="true"? "give me all detection":"select * from detection ",
  bnfx_global_stream_detction_serachbar:"",
  search_collapsed: false,
  global_edit_query: "",
  selectedTimestamp: "",
  singletenant: false,
  ispanelrequired: true,
  global_selectedtenant: {},
  automationOptions:[],
  automationTimeOptions:[],
  region_list:[],
  investigation_heatmap:true,
  super_admin:false,
  spidercontenteditable:false,
  spidertestClicked:true,
  spidercontent:null,
  // isstreamaddpathrequired:true,
  spidersavebutton:false,
  global_spider_detail_right_panel_collapsed:true,
  spidernodeslist:[],
  spiderExistingContent:[],
  spiderreoladfreeze:false,
  menuopenforstreamlist:false,
  isSelected:true,
  GroupTypeOption:[],
  selectedBadgeGroupsType:[],
  GroupValueOption:[],
  selectedBadgeGroupValue:[],
  badgedit:false,
  showToggle:false,
  encryptValue:"",
  getconnectiondata:{},
  rawEventsTabIsActive:false
});

export { setGlobalState, useGlobalState, getGlobalState };

/*Create and manage global state variables in your React application.*/
