import { showError } from "../../components/common/DisplayError";
import { requestURI } from "../../components/common/RequestURIs";
import { ajax } from "../../components/common/Service";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import PostgressTemplate from "./PostgressTemplate";
import RedisTemplate from "./RedisTemplate";

export const Health={

    getRedisData:()=>{
        let request={}

        ajax(
            "post",
            requestURI["health.redis"],
            null,
            request,
            Health.renderRediData,
            showError
        );
    },

    renderRediData:(response)=>{
        const output=response.data.response;
        
        const conatiner=document.querySelector("#bnfxRedisData");
        const data=<RedisTemplate output={output} 
        callback={() => Health.getPostgressData()}/>

        CommonTemplate.ContainerAvailability(conatiner,data)
    },

    getPostgressData:()=>{
        let request={}

        ajax(
            "post",
            requestURI["health.postgress"],
            null,
            request,
            Health.renderPostgressData,
            showError
        );
    },

    renderPostgressData:(response)=>{
        const result=response.data.response.result;
        
        const conatiner=document.querySelector("#bnfxPostgressData");
        const data=<PostgressTemplate data={result}/>

        CommonTemplate.ContainerAvailability(conatiner,data)

    },

    applyCountColor:(index)=>{
        const colors = ['#AF64FA', '#65CC49', '#FAA465', '#FF3E3E', '#DCFD12',
                        '#F5C304', '#d35a14', '#b556ae', '#FF5733','#3C92FF'];

          return colors[index % colors.length];
    }
};