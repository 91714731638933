function PanelLayout() {
  return (
    <>
      <div className="bxpanel d-none" id="bxOffcanvasPanelLayout">
        <div id="bnfxList">{/*  we will replace actionlist */}</div>
      </div>
    </>
  );
}

export default PanelLayout;
