import React, { useEffect } from "react";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import { BadgePanelScript } from "./BadgePanelScript";
import { t } from "i18next";

function CompleteBadgeListLayout(props) {
  const data = props.result;

  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <>
      <div className="BadgeRightSec_body">
        <div className="d-flex align-items-center justify-content-between p-2 pb-0">
          <p className="m-0" id="bnfxbadgeListPagination"></p>
        </div>
        <table className="table BadgeTableData">
          <thead>
            <tr>
              <th className="text-uppercase">{t("badge")}</th>
            </tr>
          </thead>
          <tbody className="ListBody">
            {data?.map((item, index) => (
              <tr key={index} className="">
                <td className="">
                  <div className="BadgeResultBox">
                    <div className="BadgeResult">
                      <div
                        className="dataBadges_icon"
                        style={
                          item.criticality
                            ? {
                                backgroundColor:
                                  CommonTemplate.badgecriticalityColor(
                                    item.criticality
                                  ),
                              }
                            : null
                        }
                      >
                        {item.name?.substring(0, 1)}
                      </div>
                      <div className="BadgeResult_inner     ms-3">
                        <div className="badgeTitle">
                          <p className="text-white" title={item.name}>
                            {item.name}
                          </p>
                          <span
                            className="BadgeResultDes badgeDescription"
                            title={item.description}
                          >
                            {item.description}
                          </span>
                          <small className="m-0 LastUpdate d-flex align-items-center">
                            <span className="material-symbols-outlined me-2">
                              nest_clock_farsight_analog
                            </span>
                            {item.enddatetime}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="BadgeResultBtn">
                      <button
                        className="BadgeEdit"
                        title="Add Badge"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#bnfxAddNewBadge"
                        data-bs-dismiss="offcanvas"
                        aria-controls="offcanvasRight"
                        href="#badgeList"
                        onClick={() => {
                          BadgePanelScript.editbadgeform(item);
                          BadgePanelScript.isBadgeEdit(true);
                        }}
                      >
                        <span className="material-symbols-outlined">
                          border_color
                        </span>
                       {t("edit")}
                      </button>
                      <button
                        className="BadgeDelete"
                        onClick={() => {
                          BadgePanelScript.deleteConfirmation(
                            item.id,
                            item.name
                          );
                        }}
                      >
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                        {t("button.delete")}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CompleteBadgeListLayout;
