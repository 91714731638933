import React from "react";
import MainNavLink from "./MainNavLink";
import { useTranslation } from "react-i18next";
import { Navigation } from "./Navigation";

function MainNavScript() {
  const { t } = useTranslation();

  return (
    <div className="row g-2">
      {Navigation.navLinkData(t).map((link, index) => (
        <MainNavLink key={index} {...link} />
      ))}
    </div>
  );
}

export default MainNavScript;
