import React, { useEffect, useRef } from "react";
import DatePickerComponent from "../calender/DatePickerComponent";
import Select from "react-select";
import { t } from "i18next";
import { BadgePanelScript } from "./BadgePanelScript";
import { getGlobalState } from "../GlobalVariables";


function BadgeTabDetail(props) {
  let result=props?.data
  let enddatetime=props.data?.enddatetime;
  let isedit=props.isedit
  let risk={
    label:result?.criticality,value:result?.criticality
  }
  let tenant={
    label:result?.tenant,value:result?.tenant
  }
  let singletenant = getGlobalState("singletenant");
  let tenantoption = null;
  if (singletenant) {
    tenantoption = null;
  } else {
    tenantoption = (
      <>
        <div className="col-6">
          <div className=" mb-2">
          <label htmlFor="badgeTenant" className="form-label">
                  {t("name.tenant")}
                  </label>
            <Select
              placeholder="Please select tenant"
              id="badgeTenant"
              className="BadgeTeantDropdown"
              options={BadgePanelScript.badgetenantoptions}
              // value={BadgePanelScript.tenant}
              onChange={BadgePanelScript.selectTenantChange}
              defaultValue={result?tenant:BadgePanelScript.tenant}
              isDisabled={isedit} 

            ></Select>
          </div>
        </div>
      </>
    );
  }

  const nameInputRef = useRef(null);
  const descriptionInputRef = useRef(null);

  useEffect(() => {
    if (result) {
      nameInputRef.current.value = result.name || '';
      descriptionInputRef.current.value = result.description || '';
    }
  }, [result]);
  return (
    <>
      <form>
        <div className="mb-2 mt-2">
          <label htmlFor="Badgename" className="form-label">
            {t("input.name")}
          </label>
          <input
            type="text"
            className="form-control"
            id="Badgename"
            placeholder="Please enter title"
            autoComplete="off"
            ref={nameInputRef}
            onChange={BadgePanelScript.handleChange}
            readOnly={result ? result.name : null}
          />
        </div>

        <div className="mb-2">
          <label htmlFor="Badgedescription" className="form-label">
            {t("reason")}
          </label>
          <textarea
            type="text"
            className="form-control"
            id="Badgedescription"
            placeholder="Please enter description"
            autoComplete="off"
            onChange={BadgePanelScript.handleChange}
            ref={descriptionInputRef}
            maxLength="150"
            rows="2"
          />
        </div>

        <div className="row g-2">
          <div className="col-6">
            <div className="mb-2">
              <label htmlFor="createBadgeDatePicker" className="form-label">
                {t("enddate")}
              </label>
              <DatePickerComponent
                noMax
                id="createBadgeDatePicker"
                badgeid="bnfxcreatebadge"
                onClickFn={() =>
                  BadgePanelScript.renderDatePicker("#createBadgeDatePicker")
                }
                singleDate={true}
                data={
                  enddatetime
                    ? { customdate: { startdate: enddatetime } }
                    : { range: "Today" }
                }
                beforeTodayDateHide
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-2">
              <label htmlFor="criticality" className="form-label">
                Adjust Risk To
              </label>
              <Select
                id="criticality"
                className="CriticalityList"
                aria-label="Default select example"
                name="criticality"
                options={BadgePanelScript.badgecriticalityoptions}
                onChange={BadgePanelScript.handleRiskChange}
                defaultValue={result ? risk : BadgePanelScript.badgecriticalityoptions[0]}
              ></Select>
            </div>
          </div>
        </div>
        {tenantoption}
      </form>

      <div className="col-12  position-absolute bottom-0 end-0  text-end ">
        <button
          type="button"
          className="text-white CustomBtn border text-uppercase"
          data-bs-dismiss="offcanvas"
          onClick={() => {
            BadgePanelScript.showBadgeListPanel();
          }}
        >
          {t("cancel")}
        </button>
        <button
          type="button"
          className="btn ms-2 btn-primary CustomBtn saveBtn button text-uppercase"
          onClick={() => {
            BadgePanelScript.handleFormSubmit(isedit,result);
            }}
        >
          {t("next")}
        </button>
      </div>
    </>
  );
}

export default BadgeTabDetail;
