import { t } from "i18next";
import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { setGlobalState, useGlobalState } from "../common/GlobalVariables";
import { Flow } from "./FlowScript";

export default memo(({ data, isConnectable }) => {
  let completedata = data?.label;
  let incidentrequest=data?.incidentrequest?.incidentrequest
  let incidentdata = data.incidentrequest.incidentrequest;
  const timestamps = Object.keys(completedata).map((timestamp) =>
    parseInt(timestamp)
  );
  const sortedData = timestamps.slice().sort((a, b) => a - b);


  const [selectedTimestamp] = useGlobalState("selectedTimestamp");

  const handleTimestampSelect = (timestamp) => {
    setGlobalState("selectedTimestamp", timestamp);
  
    Flow.detectiongraphnodesedges(completedata,incidentrequest,incidentdata);
  };
  const timeconversion = (time) => {
    const date = new Date(parseInt(time));
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      " " +
      date.getFullYear()
    );
  };
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="d"
        style={{ top: "50%", left: -10, background: "#fff" }}
        isConnectable={isConnectable}
      />
      <div className="BFNX_Bg_color">
        <div className="dataSource cardBox cardTime">
          <span className="material-symbols-outlined iconImg">
          schedule
          </span>
          <p>{t("timestamp.icon")}</p>
        </div>
      </div>
      <div className="BFNX_data_attack timestamp">
        <div className="dropdown bxNetWorkArrow">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedTimestamp
              ? timeconversion(selectedTimestamp)
              : timeconversion(timestamps[0])}
          </button>
          <ul className="dropdown-menu">
            {sortedData.map((timestamp, index) => (
              <li key={index}>
                <button
                  type="button"
                  className={`dropdown-item ${
                    selectedTimestamp === timestamp ? "active" : ""
                  }`}
                  onClick={() => handleTimestampSelect(timestamp)}
                >
                  {timeconversion(timestamp)}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="bottom"
        style={{ bottom: -10, right: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Top}
        id="top"
        style={{ top: -10, right: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="right"
        style={{ right: -10, top: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
    </>
  );
});
