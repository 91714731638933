import dagre from "dagre";
import React, { useCallback, useEffect } from "react";
import ReactFlow, {
  Controls,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import "../flow/flow.css";
import DataDetectionName from "./DataDetectionName";
import DataDetectionTime from "./DataDetectionTime";
import DataEntityName from "./DataEntityName";
import DataEntityResult from "./DataEntityResult";
import DataEntityType from "./DataEntityType";
import DataSource from "./DataSource";
const nodeTypes = {
  datasource: DataSource,
  datadetectionname: DataDetectionName,
  datadetectiontime: DataDetectionTime,
  dataentityname: DataEntityName,
  dataentityresult: DataEntityResult,
  dataentitytype: DataEntityType,
};
function GraphNodeLayout(props) {
  const connectionLineStyle = { stroke: "#fff" };
  const snapGrid = [100, 100];
  const defaultViewport = { x: 0, y: 0, zoom: 1.2 };

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback(
    (params) => setEdges((els) => addEdge(params, els)),
    [setEdges]
  );
  const dagreGraph = new dagre.graphlib.Graph();

  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 420;
  const nodeHeight = 120;

  const getLayoutedElements = (nodes, edges, direction = "LR") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node, i) => {
      dagreGraph.setNode(node.id, {
        width: i === 0 ? 0 : nodeWidth,
        height: nodeHeight,
      });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    let timestampposition;
    nodes.forEach((node, i) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";

      node.position = {
        x: nodeWithPosition.x - nodeWidth,
        y: nodeWithPosition.y - nodeHeight,
      };

      if (node.id === "timestamp") {
        timestampposition = node.position;
        node.position.x = 100;
        node.position.y = window.innerHeight - 680;
      }

      if (i === 0) {
        node.position.y = window.innerHeight / 10;
        node.position.x = -400;
      } else if (i === 1) {
        node.position.y = window.innerHeight - 550;
        node.position.x = -400;
      } else if (node.id === "entity") {
        node.position.x = timestampposition.x + 300;
        node.position.y = timestampposition.y - 300;
      } else if (node.id.indexOf(".attribute") !== -1) {
        node.position.x = timestampposition.x + 300;
        node.position.y = timestampposition.y + 25;
      } else if (node.id.indexOf(".context") !== -1) {
        node.position.x = timestampposition.x + 300;
        node.position.y = timestampposition.y + 200;
      }

      return node;
    });

    return { nodes, edges };
  };
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    props.node,
    props.edge
  );
  useEffect(() => {
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [setNodes, layoutedNodes, setEdges, layoutedEdges]);
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      //style={{ background: bgColor }}
      nodeTypes={nodeTypes}
      connectionLineStyle={connectionLineStyle}
      snapToGrid={true}
      snapGrid={snapGrid}
      defaultViewport={defaultViewport}
      fitView
      attributionPosition="bottom-left"
      // onNodeClick={nodeClick}
    >
      <Controls />
    </ReactFlow>
  );
}

export default GraphNodeLayout;
