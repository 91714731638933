import { useEffect } from "react";
import { Navigation } from "../../components/common/mainHeader/leftHeader/Navigation";
import { useLocation } from "react-router";

import OrganizationContainer from "./OrganizationContainer";
import "../organization/organization.css";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";

function OrganizationTemplate() {
  const location = useLocation();
  useEffect(() => {
    Navigation.onnavigate(location.pathname);
    CommonTemplate.removeSaveSearchModal();
  }, [location.pathname]);

  return (
    <div>
      <div id="wrapper" className="bxContent OrganisationPage">
        <div className="fullwidth main-inner" id="bxFullwidthContent">
          <OrganizationContainer />
        </div>
      </div>
    </div>
  );
}

export default OrganizationTemplate;
