import { useEffect, useState } from "react";
import { Organization } from "../OrganizationScript";
import OrganizationRow from "./OrgainzationRow";
import { t } from "i18next";

function OrgainzationTable(props) {
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  const dataArray = Object.values(props.data);
  const filteredData = dataArray.map((rowData) => {
    const {
      _id, // Exclude _id from filteredRowData
      customer,
      createdon,
      createdby,
      password,
      firstname,
      lastname,
      name,
      colorcode,
      ...filteredRowData
    } = rowData;
    const Name = `${firstname} ${lastname}`;
    const resultData = name
      ? { name, ...filteredRowData, _id, colorcode }
      : { Name, ...filteredRowData, _id };

    return resultData;
  });

  // Get the columns dynamically from the first row (assuming all rows have the same keys)
  let columns = null;
  if (props.id === "securitygroup") {
    columns = [
      "name",
      "email",
      "status",
      "tenant",
      "streams",
      "updatedon",
      "updatedby",
    ];
  } else if (props.id === "securityuser") {
    columns = [
      "Name",
      "username",
      "email",
      "status",
      "tenants",
      "roles",
      "groups",
      "updatedon",
      "updatedby",
    ];
  } else if (props.id === "securityrole") {
    columns = ["name", "tenant", "urls", "updatedon", "updatedby"];
  } else {
    columns = [
      "name",
      "description",
      "shortcode",
      "status",
      "updatedon",
      "updatedby",
    ];
  }
  const [sortOrders, setSortOrders] = useState({}); // State to track sorting orders for each column

  const handleSortClick = (column) => {
    setSortOrders((prevSortOrders) => ({
      ...prevSortOrders,
      [column]: !prevSortOrders[column], // Toggle the sorting order
    }));
    // Determine the sorting order (true for up arrow, false for down arrow)
    const sortingOrder = sortOrders[column];

    // Call the appropriate sorting function based on sortingOrder
    if (sortingOrder) {
      // Call sorting function for up arrow
      Organization.handlesorting(column, true, props.id);
    } else {
      // Call sorting function for down arrow
      Organization.handlesorting(column, false, props.id);
    }
  };

  return (
    <>
      <div className="bxScrollTable">
        <table className="table table-dark">
          <thead>
            <tr>
              {columns.map((column) =>
                column === "_id" ? null : column === "username"  || column==="updatedby"? (
                  <th className="organizationcolumnname" key={column}>
                    {column.charAt(0).toUpperCase() + column.slice(1)}

                    <span
                      className="material-symbols-outlined"
                      onClick={() => handleSortClick(column)}
                    >
                      {sortOrders[column] ? "arrow_drop_up" : "arrow_drop_down"}
                    </span>
                  </th>
                ) : (
                  <th key={column}>
                    {column.charAt(0).toUpperCase() + column.slice(1)}
                    {column !== "urls" &&
                    column !== "groups" &&
                    column !== "tenants" &&
                    column !== "roles" &&
                    column !== "description" && column !== "shortcode" &&
                    column !== "streams" ? (
                      <span
                        className="material-symbols-outlined"
                        onClick={() => handleSortClick(column)}
                      >
                        {sortOrders[column]
                          ? "arrow_drop_up"
                          : "arrow_drop_down"}
                      </span>
                    ) : null}
                  </th>
                )
              )}
              <th>{t("action.title")}</th>
            </tr>
          </thead>
          <tbody id="addrow">
            <OrganizationRow
              columns={columns}
              data={filteredData}
              id={props.id}
            />
          </tbody>
        </table>
      </div>
    </>
  );
}

export default OrgainzationTable;
