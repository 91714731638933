import { createRoot } from "react-dom/client";
import jwt from "jwt-decode";
import { getGlobalState, setGlobalState } from "../GlobalVariables";
import TenantLists from "./TenantLists";
import { ajax, fetchData } from "../../../components/common/Service";
import { requestURI } from "../RequestURIs";
import CodeMirror from "@uiw/react-codemirror";
import { sql, PostgreSQL } from "@codemirror/lang-sql";
import { EditorView } from "@codemirror/view";
import { SearchTemplate } from "../../../pages/search/SearchScript";
import { showError } from "../DisplayError";
import RawEvent from "../../../pages/stream/details/RawEvent";
import RawDataLayout from "../../../pages/stream/details/RawDataLayout";
import Pagination from "../pagination/Pagination";
import { Stream } from "../../../pages/stream/StreamScript";
import NoContent from "../NoContent";
import LoadingDataEffect from "../LoadingWidget/LoadingDataEffect";
import LoadingWave from "../loadingwaveicon/LoadingWave";
import { t } from "i18next";

const token = localStorage.getItem("token");
export const CommonTemplate = {
  selectedTenant: null,
  messageid: [],
  Timezone: null,
  selectTableName: null,

  setTableName: (selectTableName) => {
    CommonTemplate.selectTableName = selectTableName;
  },

  getUserTenants: () => {
    const token = localStorage.getItem("token");
    const userdetails = jwt(token);
    return userdetails?.tenants || [];
  },

  getTenants: () => {
    const userdetails = jwt(token);

    let tenantcount = userdetails?.tenants?.length;

    if (tenantcount == null || tenantcount === 0 || tenantcount === undefined) {
      CommonTemplate.getTenantsList();
    } else {
      let tokentenantlist = userdetails.tenants;

      const tenantList = tokentenantlist.map((item) => ({
        value: item,
        label: item
      }));

      let singletenant = getGlobalState("singletenant");
      const selectAllOption = {
        value: "default",
        label: "ALL"
      };

      if (singletenant === false) {
        tenantList.unshift(selectAllOption);
      }
      const container = document.querySelector("#bnfx_tenantlists");
      const data = <TenantLists tenants={tenantList} />;
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  getTenantsList: () => {
    ajax(
      "get",
      requestURI["list.tenant"],
      null,
      null,
      CommonTemplate.renderTenantList,
      showError
    );
  },

  renderTenantList: (response) => {
    let tenantlist = response.data.response;
    let tenantcount = tenantlist.length;
    if (tenantcount === 1) {
      setGlobalState("singletenant", true);
      const tenantList = {
        value: tenantlist[0]["name"],
        label: tenantlist[0]["name"]
      };
      CommonTemplate.selectTenantChange(tenantList);
    } else {
      const tenantList = tenantlist.map((item) => ({
        value: item.name,
        label: item.name
      }));

      const selectAllOption = {
        value: "default",
        label: "ALL"
      };

      tenantList.unshift(selectAllOption);
      const container = document.querySelector("#bnfx_tenantlists");
      const data = <TenantLists tenants={tenantList} />;
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  selectTenantChange: (selectedTenant) => {
    setGlobalState("global_selectedtenant", selectedTenant);
    CommonTemplate.selectedTenant = selectedTenant;
  },

  ContainerAvailability: (container, data) => {
    if (container) {
      // Check if the root is already attached to the container
      let root = createRoot(container);
      root.render(data);
    } else {
      console.log("Container not found");
    }
  },

  timeconversion: (time) => {
    const localTime = time.toDate();
    const year = localTime.getFullYear();
    const month = (localTime.getMonth() + 1).toString().padStart(2, "0");
    const day = localTime.getDate().toString().padStart(2, "0");
    const hours = localTime.getHours();
    const minutes = localTime.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    const finalTime = `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
    return finalTime;
  },

  criticalityColor: (criticality) => {
    return criticality === "HIGH"
      ? "text-danger ms-2"
      : criticality === "MEDIUM"
      ? "text-warning ms-2"
      : criticality === "LOW"
      ? "text-success ms-2"
      : "text-criticalcolor me-2";
  },

  badgecriticalityColor: (criticality) => {
    return criticality === "HIGH"
      ? "rgba(255, 0, 0, 0.2)"
      : criticality === "MEDIUM"
      ? "rgba(255, 193, 7,0.5)"
      : criticality === "LOW"
      ? "rgba(64, 197, 60, 0.4)"
      : "rgba(185, 8, 8, 0.4)";
  },

  // criticalityColorstyle: (criticality) => {
  //   return criticality === "HIGH"
  //     ? "#ffc107"
  //     : criticality === "MEDIUM"
  //       ? "#FFA500"
  //       : "#008000";
  // },
  getRegionList: () => {
    ajax(
      "get",
      requestURI["list.region"],
      null,
      null,
      CommonTemplate.renderRegionList,
      showError
    );
  },

  renderRegionList: (result) => {
    // temprory comment need to uncoment
    // let regions = result.data.response;

    let regions = ["Mumbai", "N. Virgina"];
    const regionList = regions.map((item) => ({
      value: "DEFAULT",
      label: item
    }));

    setGlobalState("region_list", regionList);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    CommonTemplate.Timezone = timezone;
  },

  handleRegionChange: (selectedRegion) => {
    localStorage.setItem("bnfxSelectedRegion", selectedRegion.label);
    // setGlobalState("bnfxSelectedRegion",selectedregion.value)
    window.location.reload();
  },

  showClass: () => {
    // Automation.clearAutomationList()
    const container = document.querySelector("#modalname");
    const addDiv = document.createElement("div");
    addDiv.className = "customeModal-backgrop";
    container?.replaceWith(addDiv);
  },
  removeClass: () => {
    const offcanvasstart = document.querySelectorAll(
      ".offcanvas.offcanvas-start.show"
    );
    const offcanvasend = document.querySelectorAll(
      ".offcanvas.offcanvas-end.show"
    );

    if (!(offcanvasstart.length > 0) && !(offcanvasend.length > 0)) {
      const container = document.querySelector(".customeModal-backgrop");
      const removeDiv = document.createElement("div");
      removeDiv.id = "modalname";
      container?.replaceWith(removeDiv);
    }
  },
  removeCustomeModelClass: () => {
    const container = document.querySelector(".customeModal-backgrop");
    const removeDiv = document.createElement("div");
    removeDiv.id = "modalname";
    container?.replaceWith(removeDiv);
  },
  clickOnDropdown: (data) => {
    CommonTemplate.setTableName(data.value);
  },
  handlequerytranslator: () => {
    document.querySelector("#bnfxFreetextOutput").innerHTML = null;
    const freetext = document.getElementById("bnfxFreetextInput").value;
    const tablename = CommonTemplate.selectTableName
      ? CommonTemplate.selectTableName
      : "detection";
    CommonTemplate.getTableSchema(freetext, tablename);
  },

  getTableSchema: (freetext, tablename) => {
    CommonTemplate.renderLoadingWaveIcon("#bnfxFreetextOutput");
    let request = { table_name: tablename };
    ajax(
      "post",
      requestURI["get.table.schema"],
      null,
      request,
      CommonTemplate.renderTableSchema,
      showError,
      [freetext, tablename]
    );
  },
  renderTableSchema: (response, args) => {
    const data = args[0];
    const tablename = args[1];
    let output = null;
    if (tablename === "detection") {
      output = response.data.response;
    } else {
      output = response.data.response.result;
    }

    const schema = {
      [tablename]: output
    };
    let request = { question: data, tblschema: schema };
    CommonTemplate.getSqlQuery(request);
  },
  getSqlQuery: (request) => {
    ajax(
      "post",
      requestURI["get.freetext.query"],
      null,
      request,
      CommonTemplate.renderRequiredQuery,
      showError
    );
  },
  renderRequiredQuery: (result) => {
    let output = result.data.response;
    let conatiner = document.querySelector("#bnfxFreetextOutput");

    let data = (
      <>
        <CodeMirror
          id="bnfx_query_convertor"
          value={output}
          basicSetup={{
            lineNumbers: true,
            highlightActiveLineGutter: false,
            highlightActiveLine: false,
            lineWrapping: true
          }}
          editable="false"
          height="100%"
          style={{ tabSize: 10 }}
          theme={"dark"}
          extensions={[
            sql({
              defaultTable: "detections",
              dialect: PostgreSQL
            }),
            EditorView.lineWrapping
          ]}
        />
        <button
          id="publishBtn"
          className="btn btn-success"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#bnfxSqlQueryConvertor"
          aria-expanded="false"
          aria-controls="collapseSaveSearch"
          onClick={() => {
            CommonTemplate.OnClickPublishQuery(output);
          }}
        >
          Publish
        </button>
      </>
    );
    CommonTemplate.ContainerAvailability(conatiner, data);
  },

  clearQeryBox: () => {
    document.querySelector("#bnfxFreetextInput").value = null;
    document.querySelector("#bnfxFreetextOutput").innerHTML = null;
    CommonTemplate.selectTableName = null;
  },

  OnClickPublishQuery: (data) => {
    setGlobalState("global_search_value", data);
    SearchTemplate.getSearchPage(null, data);
  },
  formatNumber: (value) => {
    if (value >= 1e12) {
      return (value / 1e12).toFixed(2) + "T";
    } else if (value >= 1e9) {
      return (value / 1e9).toFixed(2) + "B";
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + "K";
    } else {
      return value?.toString();
    }
  },
  notIncludesAny: (mainString, substrings) => {
    for (const substring of substrings) {
      if (mainString.includes(substring)) {
        return false; // Return false if any substring is present
      }
    }
    return true; // Return true if none of the substrings are present
  },

  getRawData: (messageid, offset) => {
    CommonTemplate.messageid = messageid;
    const total = messageid.length;

    const request = {
      messageid: messageid,
      limit: Math.floor(
        CommonTemplate.bxRawPanelOffcanvasHeights() /
          (window.innerWidth > 1500 ? 97 : 90)
      ),
      offset: offset ? offset : 0,
      sortcol: "insert_date",
      sortorder: "asc",
      rawSearch: true
    };

    ajax(
      "post",
      requestURI["rawdata"],
      null,
      request,
      CommonTemplate.renderRawData,
      showError,
      [total, offset]
    );
  },

  renderRawData: (response, args) => {
    const total = args[0];
    const offset = args[1];
    const output = response.data.response.result;
    const status = response.data.response.status;

    const container = document.querySelector("#bnfxRawdata");
    let data = null;
    if (status === "failed") {
      data = (
        <NoContent
          id={"noenablestream"}
          title={t("norawdata.found.title")}
          description={t("norawdata.found.description")}
        />
      );
    } else {
      data = (
        <RawEvent
          output={output}
          callback={() => CommonTemplate.renderPagination(total, offset)}
        />
      );
    }
    CommonTemplate.ContainerAvailability(container, data);
  },
  renderrawdata: (messageid, title) => {
    const container = document.querySelector("#bnfx_rawdatalayout");

    const data = (
      <RawDataLayout
        title={title}
        callback={() => CommonTemplate.getRawData(messageid, 0)}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);

    const remove_panel = document.querySelector(
      ".bxStreamLstOffCanvas.offcanvas.offcanvas-end"
    );
    if (remove_panel) {
      remove_panel.classList.remove("show");
    }
  },

  renderPagination: (total, offset) => {
    if (total > offset) {
      const paginationData = {
        total: total,
        limit: Math.floor(
          CommonTemplate.bxRawPanelOffcanvasHeights() /
            (window.innerWidth > 1500 ? 97 : 90)
        ),
        offset: offset ? offset : 0
      };

      const container = document.getElementById("bnfxRawDataPagination");

      const data = (
        <Pagination
          pagination={paginationData}
          onClickFn={CommonTemplate.getRawDataPagination}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  getRawDataPagination: (offset) => {
    CommonTemplate.getRawData(CommonTemplate.messageid, offset);
  },

  bxRawPanelOffcanvasHeights: () => {
    let panelheight = document.querySelector("#bnfxRawDataLayout")?.clientHeight;
    let headerheight = document.querySelector(".rawLayoutHeader")?.clientHeight;

    let finalheight = panelheight - headerheight - 35 - 97;
    return finalheight;
  },

  removePanel: () => {
    const incidence_panel = document.querySelector(
      ".bxStreamLstOffCanvas.offcanvas.offcanvas-end"
    );
    const rawdata_panel = document.querySelector(
      ".bxStreamLstOffCanvas.offcanvas.offcanvas-end.rawDataPage"
    );

    if (rawdata_panel) {
      rawdata_panel.classList.remove("show");
    }

    if (incidence_panel) {
      incidence_panel.classList.add("show");
    }
  },
  getSearchText: (id) => {
    let freetext = null;
    //check of search box is loaded into dom, if yes get the free text value
    if (document?.querySelector("#" + id)) {
      freetext = document.querySelector("#" + id).value;

      if (freetext.trim() === "" || freetext.length < 3) {
        //ignore free text if less then three characters
        freetext = null;
      }
    }

    return freetext;
  },

  getListTenant: () => {
    return ajax(
      "get",
      requestURI["list.tenant"],
      null,
      null,
      null,
      null,
      null,
      true
    );
  },
  downloadcontent: () => {
    let pathname = window.location.pathname;
    if (pathname === "/searchpage") {
      SearchTemplate.searchresultdownload();
    } else {
      Stream.getDownloadDetections();
    }
  },
  removedownloadnone: () => {
    let closeconnection = document.querySelector("#bnfxprefixform");
    closeconnection?.classList?.remove("d-none");
  },

  clearfillupdetails: () => {
    document.querySelector("#prefixInput").value = "";
    let conatiner = document.querySelector("#bnfxdownloadedpath");
    CommonTemplate.ContainerAvailability(conatiner, null);
  },
  removeSaveSearchModal: () => {
    let removemodal = document.querySelector(
      ".cSSearch_collapse.streamsavedsearch.collapse"
    );
    removemodal?.classList?.remove("show");
  },
  removeCursorPointer: () => {
    const container = document.querySelector(
      ".dropdown-menu.dropdown-menu-end.dropdown-menu-animated.topbar-dropdown-menu.profile-dropdown"
    );
    if (container) {
      container.classList.remove("show");
    }
  },
  handleClick: () => {
    const container = document.querySelector(
      ".dropdown-menu.dropdown-menu-end.dropdown-menu-animated.topbar-dropdown-menu.profile-dropdown"
    );
    if (container) {
      container.classList.add("show");
    }
  },
  dashboardcriticalityColor: (criticality) => {
    return criticality === "HIGH"
      ? "text-danger high ms-2"
      : criticality === "MEDIUM"
      ? "text-warning medium ms-2"
      : criticality === "LOW"
      ? "text-success low ms-2"
      : criticality === "CRITICAL"
      ? "text-criticalcolor critical me-2"
      : "text-default";
  },

  renderLoadingIcon: (containerclass) => {
    let container = document.querySelector(containerclass);
    let data = <LoadingDataEffect />;
    CommonTemplate.ContainerAvailability(container, data);
  },
  renderLoadingWaveIcon: (containerclass) => {
    let container = document.querySelector(containerclass);
    let data = <LoadingWave />;
    CommonTemplate.ContainerAvailability(container, data);
  },

  handleToggleCollapse: () => {
    const add_collapsed = document.querySelector(".tab-pane.show.active");
    const container = document.querySelector(".collapse-sidebar");
    const task_collapsed = document.querySelector(
      ".right-section.easy-3s.desktop-right.float-end"
    );

    if (add_collapsed && add_collapsed.classList.contains("collapsed")) {
      add_collapsed.classList.remove("collapsed");
    } else {
      add_collapsed.classList.add("collapsed");
    }

    if (container && container.classList.contains("right")) {
      container.classList.remove("right");
    } else {
      container.classList.add("right");
    }

    if (task_collapsed && task_collapsed.classList.contains("task-collapsed")) {
      task_collapsed.classList.remove("task-collapsed");
    } else {
      task_collapsed.classList.add("task-collapsed");
    }
  },
  timeDifference: (updatedTime) => {
    const now = Date.now();
    const diffMs = now - updatedTime;

    const seconds = Math.floor(diffMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30); // Rough estimate
    const years = Math.floor(days / 365); // Rough estimate

    if (seconds < 60) {
      return `${seconds} sec ago`;
    } else if (minutes < 60) {
      return `${minutes} min ago`;
    } else if (hours < 24) {
      return `${hours} hr ago`;
    } else if (days < 7) {
      return `${days} days ago`;
    } else if (weeks < 4) {
      return `${weeks} weeks ago`;
    } else if (months < 12) {
      return `${months} months ago`;
    } else {
      return `${years} years ago`;
    }
  },
  epscolor: (update) => {
    const currentTime = Date.now();

    const timeDifference = currentTime - update;
    // Time differences in milliseconds
    const halfHour = 30 * 60 * 1000; // 30 minutes
    const oneHour = 60 * 60 * 1000; // 60 minutes

    if (timeDifference <= halfHour) {
      return "text-success";
    } else if (timeDifference <= oneHour) {
      return "text-warning";
    } else {
      return "text-danger";
    }
  },
  getPyFunctionData: (content, functionName) => {
    const fnRegexStr =
      "def " +
      functionName +
      "\\s?\\(\\)\\s?:(?:\\s+return\\s+([\"'](.*?)[\"']|\\[.*?\\]|\\{([\\s\\S]*?)\\}\\s)|\\[(.*?)\\])";
    const fnRegex = new RegExp(fnRegexStr, "s");
    const fnMatch = content.match(fnRegex);

    if (!fnMatch) {
      console.error("function not found");
      return content;
    }

    let dataFn = fnMatch[0];

    // Extract the existing fragments list
    const valuesRegex = /["'](.*?)["']|\[.*?\]|\{([\s\S]*?)\}\s|\[(.*?)\]/s;
    const valuesMatch = dataFn.match(valuesRegex);

    if (!valuesMatch) {
      console.error("list not found");
      return content;
    }

    let values;
    try {
      values = JSON.parse(valuesMatch[0]);
    } catch (e) {
      values = eval(valuesMatch[0]);
    }

    return values;
  },
  // not tested yet
  modifyPyFunctions: (
    content,
    functionName,
    actionToPerform,
    itemToAddOrRemove
  ) => {
    const fnRegexStr =
      "def " +
      functionName +
      "\\s?\\(\\)\\s?:(?:\\s+return\\s+([\"'](.*?)[\"']|\\[.*?\\]|\\{([\\s\\S]*?)\\}\\s)|\\[(.*?)\\])";
    const fnRegex = new RegExp(fnRegexStr, "s");
    const fnMatch = content.match(fnRegex);

    if (!fnMatch) {
      console.error("function not found");
      return content;
    }

    let dataFn = fnMatch[0];

    // Extract the existing fragments list
    const valuesRegex = /["'](.*?)["']|\[.*?\]|\{([\s\S]*?)\}\s|\[(.*?)\]/s;
    const valuesMatch = dataFn.match(valuesRegex);

    if (!valuesMatch) {
      console.error("list not found");
      return content;
    }
    debugger;
    let values;
    try {
      values = JSON.parse(valuesMatch[0]);
    } catch (e) {
      values = eval(valuesMatch[0]);
    }

    if (actionToPerform === "add") {
      // Add the new path to the fragments list
      values.push(itemToAddOrRemove);
    } else if (actionToPerform === "remove") {
      // Remove the specified path from the fragments list
      values = values.filter((path) => path !== itemToAddOrRemove);
    } else if (actionToPerform === "replace") {
      // Replace it with incoming item
      values = values.filter((path) => path !== itemToAddOrRemove);
    } else {
      console.error("Invalid action:", actionToPerform);
      return content;
    }

    // Replace the fragments list in the function
    dataFn = dataFn.replace(valuesRegex, JSON.stringify(values));

    // Replace the fragments function in the content
    return content.replace(valuesRegex, dataFn);
  },
  loadComponents: async (componentName, location, tenant) => {
    //console.log(location);
    const request = {
      command: `${componentName} --mode=components --location=${location}`,
      tenant: tenant
    };

    const response = await fetchData(
      "POST",
      requestURI["terminal.command"],
      null,
      request
    );
    return response;
  }
};
