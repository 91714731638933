import { t } from "i18next";
import { useEffect } from "react";
import { CommonTemplate } from "./containerAvailability/CommonScript";

function TableList(props) {
  let data = props.data.data?.response?.result;
  let column = data?.result?.columns;
  let rows = data?.result?.rows;
  const handleRowClick = (row) => {
    props.onClickFn(row,props.id,data,null);
  };
  
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <>
      <div className="bxTabelRelations">
        <table className="table table-centered mb-0">
          <thead className="table-dark">
            <tr>
              {column?.map((colname, index) => (
                <th className="text-capitalize" key={index}>{colname}</th>
              ))}
            </tr>
          </thead>
          <tbody id="bnfxTableBody">
            {rows?.map((row, rowIndex) => (
              <tr
                className={row["icon"] !== undefined ? "bnfxStreamTable" : null}
                key={rowIndex}
                onClick={() => handleRowClick(row)}
                data-bs-toggle={
                  row["type"] === "entity" ||
                  row["type"] === "detection" ||
                  row["type"] === "stream" ||
                  row["type"] === "incident"||
                  row["type"] === "timelinepanel"
                    ? "offcanvas"
                  
                    : ""
                }
                data-bs-target={
                  row["type"] === "entity" ||
                  row["type"] === "detection" ||
                  row["type"] === "stream"||
                  row["type"] === "timelinepanel"
                    ? "#bxOffcanvasPanel"
                    : row["type"] === "incident"
                    ? "#bxOffcanvasIncidence"
                    :""
                }
              >
                {column?.map((colname, columnIndex) => (
                  <td className="bnfxSourceIcon" key={columnIndex}>
                    {columnIndex === 0 && row["icon"] === undefined && (
                      <span
                        className="bxbg-danger ms-1"
                        style={
                          row["color"]
                            ? { backgroundColor: row["color"] }
                            : null
                        }
                      >
                        
                      </span>
                    )}

                    {row["icon"] !== undefined &&
                      (colname === "name" || colname === "provider") && (
                        <span
                          className={
                            "bxIcon_link " + row["icon"]?.toLowerCase()
                          }
                        ></span>
                      )}

                    <p className={colname !== "entity" ? "m-0 text-white text-capitalize" : "m-0 text-white"} title={colname !== "entity" ? row[colname] : `${row[colname]} ${row["entitytype"]}`}>
                      {" "}
                      {colname !== "entity"
                        ? CommonTemplate.formatNumber(row[colname])
                        : row[colname]}
                    </p>

                    {columnIndex === 0 && (
                      <i
                        className="d-block text-mute"
                        title={
                          row["description"]
                            ? row["description"]
                            : t("title.nodescription")
                        }
                      >
                        {row["description"]
                          ? row["description"]
                          : t("title.nodescription")}
                      </i>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TableList;
