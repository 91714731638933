
import Select from 'react-select'
import { CollectorsScript } from './CollectorsScript';

function CollectorsTenants(props) {
    return (
        <Select
        id="collectors-tenant-select"
        className=""
        name="collectorTenant"
        options={props.tenants}
        onChange={CollectorsScript.loadnodesandconfig}
        defaultValue={props.tenants[0]}
      ></Select>

    );
}
export default CollectorsTenants;