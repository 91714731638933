import React, { memo } from "react";
import { Handle, Position } from "reactflow";

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="f"
        style={{ left: -10, top: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
      <div className="BFNX_Bg_color">
        <div className="dataSource cardBox cardType">
          <span className="material-symbols-outlined iconImg">
          family_history
          </span>
          <p> {data?.label} </p>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="g"
        style={{ right: -10, top: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
    </>
  );
});
