import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { t } from "i18next";

export default memo(({isConnectable }) => {
  
  return (
    <>
      <div className="bnfx_automationtask_begin_node">
        <div className="automation_Taskflow aT_start">
          <p>{t("start")}</p>
          <span class="material-symbols-outlined">arrow_and_edge</span>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ bottom: -10, right: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
    </>
  );
});
