import React, { useState, useRef } from "react";
import Search from "../search/Search";

function CustomDropDown({
  id,
  options,
  onChange,
  value,
  onMenuScrollToBottom,
  searchrequired,
}) {
  const [, setSelectedOption] = useState(value);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleOptionSelect = (option) => {
    setMenuOpen(false);
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };

  const handleScroll = () => {
    if (menuRef.current) {
      let bottom =
        menuRef.current.scrollHeight - menuRef.current.scrollTop <=
        menuRef.current.clientHeight +
          document.querySelector(".bnfxcustomdropdown").clientHeight;
      if (bottom && onMenuScrollToBottom) {
        onMenuScrollToBottom();
      }
    }
  };

  return (
    <>
      <div className="spiderdropdown" id={id}>
       {value? <div
          className={"selectclick "+value?.value?._id}
          onClick={searchrequired ? toggleMenu : null}
        >
          {value?.value?.title}
        </div>:null}
        <div
          className={`spiderselectlist ${
            searchrequired ? (menuOpen ? "show" : "") : "show"
          }`}
        >
          <div className="bnfxcustomdropdown">
            {searchrequired ? <Search /> : null}
          </div>
          {options?
          <ul
            ref={menuRef}
            className="customselectoptions p-1"
            onScroll={handleScroll}
          >
            {options?.map((option, index) => (
              <li
                className="listName"
                key={index}
                onClick={() => handleOptionSelect(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>:null}
        </div>
      </div>
    </>
  );
}
export default CustomDropDown;