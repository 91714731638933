
function StreamDetailsContainer() {


  return (
    <>
      <div
        id="map-switch"
        className="tab-pane show active"
        role="tabpanel"
        aria-labelledby="rightSwitch-tab"
      >
        <div className="right-section easy-3s desktop-right float-end">
          <div id="bnfxStreamDetailsLayout">
            {/* <StreamDetailsLayout /> */}
          </div>

        </div>
      </div>
    </>
  );
}

export default StreamDetailsContainer;
