import React, { useState } from "react";
import Select from "react-select";
import { t } from "i18next";
import { setGlobalState } from "../../components/common/GlobalVariables";

function ConnectionDropDown(props) {
  const [selectedConnectionType, setSelectedConnectionType] = useState(null);
  const [formData, setFormData] = useState({ connection: {} });
  setGlobalState("getconnectiondata",formData)
 
  const options = Object.keys(props.connectionoptions).map(key => ({
    label: key,
    value: key
  }));

  const handleConnectionChange = (selectedOption) => {
    setSelectedConnectionType(selectedOption.value);
    setFormData({
      connection: {
        [selectedOption.value]: {}
      }
    });
  };

  const handleInputChange = (key, value) => {
    setFormData(prevData => ({
      connection: {
        ...prevData.connection,
        [selectedConnectionType]: {
          ...prevData.connection[selectedConnectionType],
          [key]: value
        }
      }
    }));
   
  };

  const renderInputField = (key, field) => {
    const isMandatory = field.mandatory;
    
    if (field.type === "select" || field.type === "text") {
      return (
        <div className="col-sm-6" key={key}>
          <div className="mb-2">
            <label className="form-label">{key}</label>
            <Select
              placeholder={`Please select ${key}`}
              className="form-control"
              options={field.enum.map((value) => ({ label: value, value }))}
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
            />
            {isMandatory && (
              <small className="d-block text-end bxFontSize">
                {t("required")}
              </small>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-sm-6" key={key}>
          <div className="mb-2">
            <label htmlFor={key} className="form-label">
              {key}
            </label>
            <input
              type={field.type}
              className="form-control"
              id={key}
              placeholder={`Please enter ${key}`}
              autoComplete="off"
              required={isMandatory}
              onChange={(e) => handleInputChange(key, e.target.value)}
            />
            {isMandatory && (
              <small className="d-block text-end bxFontSize">
                {t("required")}
              </small>
            )}
          </div>
        </div>
      );
    }
  };
  

  return (
    <>
      <div className="col-sm-6" id="bnfxConnectionType">
        <div className="mb-2">
          <label className="form-label">Type of connection</label>
          <Select
            placeholder="Please select type"
            id="connectiontype"
            className="form-control"
            options={options}
            onChange={handleConnectionChange}
          />
          <small className="d-block text-end bxFontSize">{t("required")}</small>
        </div>
      </div>

      {/* Render fields outside the initial div */}
      {selectedConnectionType &&
        Object.keys(props.connectionoptions[selectedConnectionType]).map(
          (key) =>
            renderInputField(
              key,
              props.connectionoptions[selectedConnectionType][key]
            )
        )}
    </>
  );
}

export default ConnectionDropDown;
