import { t } from "i18next";
import { useEffect } from "react";

function AutomationTaskDetails(props) {
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <>
      <div className="offcanvas-body p-0 cm_overflow">
        <div className="Custom_leftArea">
          <div className="Custom_leftArea_title cm_border p-3">
            <div className="d-flex align-items-center justify-content-between ">
              <h6 className="m-0 tasktitle">{t("title.playbooktask")}</h6>
            </div>
          </div>
          <div className="Custom_leftArea_box" id="bnfxAutomationData">
            {/* we will render automation tasklist */}
          </div>
        </div>
        <div className="Custom_rightArea">
          <div className="Custom_leftArea_box" id="bnfxAutomationTaskDetail">
            {/* here we will replace details of automations */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AutomationTaskDetails;
