import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';

const colors = ['#6CDDCA', '#C771F3', '#4D90DB', '#FAB776'];

const StellarChart = ({container,series}) => {
    const [chartHeight, setChartHeight] = useState(window.innerHeight * 0.6);
    const chartRef = useRef(null);
   
    useEffect(() => {
        const handleResize = () => {
            setChartHeight(window.innerHeight * 0.6);
        };

        window.addEventListener('resize', handleResize);

        const exoplanetData = [
            {
                name: 'Proxima Centauri b',
                x: 0,
                y: 1,
                z: 3.40076102736182,
                custom: {
                    lightYears: 4,
                    planetMass: '1.07 Earths',
                    discoveryDate: '2016'
                }
            },
            {
                name: 'Epsilon Eridani b',
                x: 1,
                y: 1,
                z: 2.09766568042879,
                custom: {
                    lightYears: 10,
                    planetMass: '0.66 Jupiters',
                    discoveryDate: '2000'
                }
            },
            {
                name: 'GJ 887 b',
                x: 2,
                y: 1,
                z: 13.3487816027286,
                custom: {
                    lightYears: 11,
                    planetMass: '4.2 Earths',
                    discoveryDate: '2020'
                }
            },
            {
                name: 'GJ 887 c',
                x: 3,
                y: 1,
                z: 24.1549381382709,
                custom: {
                    lightYears: 11,
                    planetMass: '7.6 Earths',
                    discoveryDate: '2020'
                }
            },
            {
                name: 'Ross 128 b',
                x: 4,
                y: 1,
                z: 4.44959386757621,
                custom: {
                    lightYears: 11,
                    planetMass: '1.4 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'Epsilon Indi A b',
                x: 5,
                y: 1,
                z: 10.3294143354448,
                custom: {
                    lightYears: 12,
                    planetMass: '3.25 Jupiters',
                    discoveryDate: '2019'
                }
            },
            {
                name: 'GJ 1061 b',
                x: 6,
                y: 1,
                z: 4.35424542755672,
                custom: {
                    lightYears: 12,
                    planetMass: '1.37 Earths',
                    discoveryDate: '2020'
                }
            },
            {
                name: 'GJ 1061 c',
                x: 7,
                y: 1,
                z: 5.53020952113044,
                custom: {
                    lightYears: 12,
                    planetMass: '1.74 Earths',
                    discoveryDate: '2020'
                }
            },
            {
                name: 'GJ 1061 d',
                x: 8,
                y: 1,
                z: 5.21238138773214,
                custom: {
                    lightYears: 12,
                    planetMass: '1.64 Earths',
                    discoveryDate: '2020'
                }
            },
            {
                name: 'GJ 15 A b',
                x: 9,
                y: 1,
                z: 9.63019244196852,
                custom: {
                    lightYears: 12,
                    planetMass: '3.03 Earths',
                    discoveryDate: '2014'
                }
            },
            {
                name: 'GJ 15 A c',
                x: 10,
                y: 1,
                z: 114.418128023388,
                custom: {
                    lightYears: 12,
                    planetMass: '36 Earths',
                    discoveryDate: '2018'
                }
            },
            {
                name: 'Tau Ceti e',
                x: 11,
                y: 1,
                z: 12.4906456425532,
                custom: {
                    lightYears: 12,
                    planetMass: '3.93 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'Tau Ceti f',
                x: 12,
                y: 1,
                z: 12.4906456425532,
                custom: {
                    lightYears: 12,
                    planetMass: '3.93 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'Tau Ceti g',
                x: 13,
                y: 1,
                z: 5.56199233447027,
                custom: {
                    lightYears: 12,
                    planetMass: '1.75 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'Tau Ceti h',
                x: 14,
                y: 1,
                z: 5.81625484118891,
                custom: {
                    lightYears: 12,
                    planetMass: '1.83 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: "Teegarden's Star b",
                x: 15,
                y: 1,
                z: 3.33719540068216,
                custom: {
                    lightYears: 12,
                    planetMass: '1.05 Earths',
                    discoveryDate: '2019'
                }
            },
            {
                name: "Teegarden's Star c",
                x: 16,
                y: 1,
                z: 3.52789228072114,
                custom: {
                    lightYears: 12,
                    planetMass: '1.11 Earths',
                    discoveryDate: '2019'
                }
            },
            {
                name: 'YZ Ceti b',
                x: 17,
                y: 1,
                z: 2.22479693378811,
                custom: {
                    lightYears: 12,
                    planetMass: '0.7 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'YZ Ceti c',
                x: 18,
                y: 1,
                z: 3.62324072074063,
                custom: {
                    lightYears: 12,
                    planetMass: '1.14 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'YZ Ceti d',
                x: 19,
                y: 1,
                z: 3.46432665404148,
                custom: {
                    lightYears: 12,
                    planetMass: '1.09 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'Kapteyn c',
                x: 20,
                y: 1,
                z: 22.2479693378811,
                custom: {
                    lightYears: 13,
                    planetMass: '7 Earths',
                    discoveryDate: '2014'
                }
            },
            {
                name: 'Wolf 1061 b',
                x: 21,
                y: 1,
                z: 6.07051734790755,
                custom: {
                    lightYears: 14,
                    planetMass: '1.91 Earths',
                    discoveryDate: '2015'
                }
            },
            {
                name: 'Wolf 1061 c',
                x: 22,
                y: 1,
                z: 10.8379393488821,
                custom: {
                    lightYears: 14,
                    planetMass: '3.41 Earths',
                    discoveryDate: '2015'
                }
            },
            {
                name: 'Wolf 1061 d',
                x: 23,
                y: 1,
                z: 24.4727662716692,
                custom: {
                    lightYears: 14,
                    planetMass: '7.7 Earths',
                    discoveryDate: '2015'
                }
            },
            {
                name: 'GJ 674 b',
                x: 24,
                y: 1,
                z: 35.2471399938716,
                custom: {
                    lightYears: 15,
                    planetMass: '11.09 Earths',
                    discoveryDate: '2007'
                }
            },
            {
                name: 'GJ 687 b',
                x: 25,
                y: 1,
                z: 54.6664389445078,
                custom: {
                    lightYears: 15,
                    planetMass: '17.2 Earths',
                    discoveryDate: '2014'
                }
            },
            {
                name: 'GJ 687 c',
                x: 26,
                y: 1,
                z: 50.8525013437282,
                custom: {
                    lightYears: 15,
                    planetMass: '16 Earths',
                    discoveryDate: '2020'
                }
            },
            {
                name: 'GJ 876 b',
                x: 27,
                y: 1,
                z: 7.23249700361174,
                custom: {
                    lightYears: 15,
                    planetMass: '2.2756 Jupiters',
                    discoveryDate: '1998'
                }
            },
            {
                name: 'GJ 876 c',
                x: 28,
                y: 1,
                z: 2.26992852873067,
                custom: {
                    lightYears: 15,
                    planetMass: '0.7142 Jupiters',
                    discoveryDate: '2000'
                }
            },
            {
                name: 'GJ 876 d',
                x: 29,
                y: 1,
                z: 21.707661511104,
                custom: {
                    lightYears: 15,
                    planetMass: '6.83 Earths',
                    discoveryDate: '2005'
                }
            },
            {
                name: 'GJ 876 e',
                x: 0,
                y: 2,
                z: 46.4029074761519,
                custom: {
                    lightYears: 15,
                    planetMass: '14.6 Earths',
                    discoveryDate: '2010'
                }
            },
            {
                name: 'GJ 9066 c',
                x: 1,
                y: 2,
                z: 0.667439080136432,
                custom: {
                    lightYears: 15,
                    planetMass: '0.21 Jupiters',
                    discoveryDate: '2020'
                }
            },
            {
                name: 'GJ 1002 b',
                x: 2,
                y: 2,
                z: 3.43254384070165,
                custom: {
                    lightYears: 16,
                    planetMass: '1.08 Earths',
                    discoveryDate: '2022'
                }
            },
            {
                name: 'GJ 1002 c',
                x: 3,
                y: 2,
                z: 4.32246261421689,
                custom: {
                    lightYears: 16,
                    planetMass: '1.36 Earths',
                    discoveryDate: '2022'
                }
            },
            {
                name: 'GJ 682 b',
                x: 4,
                y: 2,
                z: 13.9844378695252,
                custom: {
                    lightYears: 16,
                    planetMass: '4.4 Earths',
                    discoveryDate: '2014'
                }
            },
            {
                name: 'GJ 682 c',
                x: 5,
                y: 2,
                z: 27.6510476056522,
                custom: {
                    lightYears: 16,
                    planetMass: '8.7 Earths',
                    discoveryDate: '2014'
                }
            },
            {
                name: 'GJ 832 b',
                x: 6,
                y: 2,
                z: 2.54262506718641,
                custom: {
                    lightYears: 16,
                    planetMass: '0.8 Jupiters',
                    discoveryDate: '2008'
                }
            },
            {
                name: 'HD 26965 b',
                x: 7,
                y: 2,
                z: 26.9200428988361,
                custom: {
                    lightYears: 16,
                    planetMass: '8.47 Earths',
                    discoveryDate: '2018'
                }
            },
            {
                name: 'GJ 251 b',
                x: 8,
                y: 2,
                z: 12.713125335932,
                custom: {
                    lightYears: 18,
                    planetMass: '4 Earths',
                    discoveryDate: '2020'
                }
            },
            {
                name: 'GJ 3323 b',
                x: 9,
                y: 2,
                z: 6.42012829464568,
                custom: {
                    lightYears: 18,
                    planetMass: '2.02 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'GJ 3323 c',
                x: 10,
                y: 2,
                z: 7.34182988150075,
                custom: {
                    lightYears: 18,
                    planetMass: '2.31 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'GJ 229 A c',
                x: 11,
                y: 2,
                z: 27.2740080910018,
                custom: {
                    lightYears: 19,
                    planetMass: '8.58137 Earths',
                    discoveryDate: '2020'
                }
            },
            {
                name: 'GJ 229 b',
                x: 12,
                y: 2,
                z: 47.4769758701582,
                custom: {
                    lightYears: 19,
                    planetMass: '14.93794 Earths',
                    discoveryDate: '2014'
                }
            },
            {
                name: 'GJ 273 b',
                x: 13,
                y: 2,
                z: 9.1852330552109,
                custom: {
                    lightYears: 19,
                    planetMass: '2.89 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'GJ 273 c',
                x: 14,
                y: 2,
                z: 3.75037197409995,
                custom: {
                    lightYears: 19,
                    planetMass: '1.18 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'GJ 411 b',
                x: 15,
                y: 2,
                z: 8.5495767884143,
                custom: {
                    lightYears: 19,
                    planetMass: '2.69 Earths',
                    discoveryDate: '2019'
                }
            },
            {
                name: 'HD 180617 b',
                x: 16,
                y: 2,
                z: 38.8195282132685,
                custom: {
                    lightYears: 19,
                    planetMass: '12.214 Earths',
                    discoveryDate: '2018'
                }
            },
            {
                name: 'HD 95735 c',
                x: 17,
                y: 2,
                z: 43.2246261421689,
                custom: {
                    lightYears: 19,
                    planetMass: '13.6 Earths',
                    discoveryDate: '2021'
                }
            },
            {
                name: 'GJ 896 A b',
                x: 18,
                y: 2,
                z: 7.1829158148016,
                custom: {
                    lightYears: 20,
                    planetMass: '2.26 Jupiters',
                    discoveryDate: '2022'
                }
            },
            {
                name: 'HD 20794 b',
                x: 19,
                y: 2,
                z: 8.58135960175413,
                custom: {
                    lightYears: 20,
                    planetMass: '2.7 Earths',
                    discoveryDate: '2011'
                }
            },
            {
                name: 'HD 20794 c',
                x: 20,
                y: 2,
                z: 7.62787520155922,
                custom: {
                    lightYears: 20,
                    planetMass: '2.4 Earths',
                    discoveryDate: '2011'
                }
            },
            {
                name: 'HD 20794 d',
                x: 21,
                y: 2,
                z: 15.2557504031184,
                custom: {
                    lightYears: 20,
                    planetMass: '4.8 Earths',
                    discoveryDate: '2011'
                }
            },
            {
                name: 'HD 20794 e',
                x: 22,
                y: 2,
                z: 15.160401963099,
                custom: {
                    lightYears: 20,
                    planetMass: '4.77 Earths',
                    discoveryDate: '2017'
                }
            },
            {
                name: 'GJ 338 B b',
                x: 23,
                y: 2,
                z: 33.6897821402199,
                custom: {
                    lightYears: 21,
                    planetMass: '10.6 Earths',
                    discoveryDate: '2020'
                }
            },
            {
                name: 'GJ 581 b',
                x: 24,
                y: 2,
                z: 50.2168450769316,
                custom: {
                    lightYears: 21,
                    planetMass: '15.8 Earths',
                    discoveryDate: '2005'
                }
            },
            {
                name: 'GJ 581 c',
                x: 25,
                y: 2,
                z: 17.4805473369066,
                custom: {
                    lightYears: 21,
                    planetMass: '5.5 Earths',
                    discoveryDate: '2007'
                }
            },
            {
                name: 'GJ 581 e',
                x: 26,
                y: 2,
                z: 5.40307826777112,
                custom: {
                    lightYears: 21,
                    planetMass: '1.7 Earths',
                    discoveryDate: '2009'
                }
            },  { name: 'Planet C', x: 3, y: 4, z: 12, custom: { lightYears: 20, planetMass: 9, stellarMagnitude: 1.6, discoveryDate: '2021' } },
        ];

        const chartOptions = {
            chart: {
                type: 'bubble',
                polar: true,
                height: chartHeight,
                events: {
                    render() {
                        const chart = this;
                        const pieSeries = chart.series[1];
                        pieSeries.customLabel = fillCenter(
                            100,
                            '1990-2020',
                            chart,
                            pieSeries.customLabel
                        );
                    }
                }
            },
            colorAxis: {
                min: 0,
                max: 12,
                stops: [
                    [0, colors[0]],
                    [1 / 3, colors[1]],
                    [2 / 3, colors[2]],
                    [1, colors[3]]
                ]
            },
            legend: {
                enabled: false
            },
            pane: {
                innerSize: '42%',
                size: '95%',
                background: [{
                    backgroundColor: '#000',
                    borderWidth: 0
                }, {
                    backgroundColor: '#090b19',
                    borderWidth: 0,
                    outerRadius: '42%'
                }]
            },
            xAxis: {
                tickInterval: 1,
                min: 0,
                max: 30,
                gridLineWidth: 0,
                labels: {
                    enabled: false
                },
                lineWidth: 0
            },
            yAxis: {
                tickInterval: 1,
                labels: {
                    enabled: false
                },
                gridLineWidth: 0.7,
                gridLineColor: 'black',
                gridLineDashStyle: 'longdash',
                endOnTick: false
            },
            plotOptions: {
                series: {
                    states: {
                        inactive: {
                            enabled: false
                        }
                    }
                },
                pie: {
                    states: {
                        hover: {
                            halo: 0
                        }
                    }
                }
            },
            series: [{
                type: 'bubble',
                colorKey: 'custom.stellarMagnitude',
                maxSize: 14,
                minSize: 3,
                data: exoplanetData,
                tooltip: {
                    pointFormat: `<table>
                        <tr>
                            <td style='padding:0'>
                                <span class="smallerLabel">Name:</span>
                                {point.name}
                            </td>
                        </tr>
                        <tr>
                            <td style='padding:0'>
                                <span class="smallerLabel">Mass:</span>
                                {point.custom.planetMass}
                            </td>
                        </tr>
                        <tr>
                            <td style='padding:0'>
                                <span class="smallerLabel">Distance:</span>
                                {point.custom.lightYears} Light Years
                            </td>
                        </tr>
                        <tr>
                            <td style='padding:0'>
                                <span class="smallerLabel">Stellar Magnitude:</span>
                                {point.custom.stellarMagnitude}
                            </td>
                        </tr>
                    </table>`
                }
            }, {
                type: 'pie',
                dataLabels: {
                    enabled: false
                },
                size: '40%',
                innerSize: '85%',
                zIndex: -1,
                point: {
                    events: {
                        mouseOver() {
                            const { minDate, maxDate } = this.options.custom;
                            const point = this;
                            const series = this.series;
                            const chart = series.chart;
                            const bubbleSeries = chart.series[0];

                            bubbleSeries.points.forEach(point => {
                                if (
                                    point.options.custom.discoveryDate < minDate ||
                                    point.options.custom.discoveryDate >= maxDate
                                ) {
                                    point.graphic.attr({
                                        opacity: 0.2
                                    });
                                }
                            });

                            series.customLabel = fillCenter(
                                point.percentage,
                                point.options.custom.minDate,
                                chart,
                                series.customLabel
                            );
                        },
                        mouseOut() {
                            const chart = this.series.chart;
                            const series = this.series;
                            const bubbleSeries = chart.series[0];

                            bubbleSeries.points.forEach(point => {
                                point.graphic.attr({
                                    opacity: 1
                                });
                            });
                            series.customLabel = fillCenter(
                                100,
                                '1990-2020',
                                chart,
                                series.customLabel
                            );
                        }
                    }
                },
                data: [{
                    y: 12,
                    color: 'red',
                    custom: {
                        minDate: 1990,
                        maxDate: 2000
                    }
                }, {
                    y: 47,
                    color: 'blue',
                    custom: {
                        minDate: 2000,
                        maxDate: 2010
                    }
                }, {
                    y: 117,
                    color: '#4D90DB',
                    custom: {
                        minDate: 2010,
                        maxDate: 2020
                    }
                }, {
                    y: 64,
                    color: '#FAB776',
                    custom: {
                        minDate: 2020,
                        maxDate: 2030
                    }
                }]
            }],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        pane: {
                            innerSize: '10%'
                        },
                        series: [{
                            maxSize: 8
                        }, {
                            size: '45%'
                        }]
                    }
                }]
            }
        };

        // Function to fill center with label
        function fillCenter(percentage, decade, chart, customLabel) {
            const labelText = `<table class='innerTable'>
                <tr>
                    <td class='symbol'>☉</td>
                </tr>
                <tr>
                    <td class='uppercase'>${decade}<small>s</small></td>
                </tr>
                <tr>
                    <td class='label'>Planets discovered</td>
                </tr>
                <tr>
                    <td class='percentage'>
                        ${percentage.toFixed(2)}
                        <sup style='font-size: 0.5em;'>%</sup>
                    </td>
                </tr>
            </table>`;

            if (!customLabel) {
                customLabel = chart.renderer.label(
                    labelText,
                    0,
                    0,
                    void 0,
                    void 0,
                    void 0,
                    true
                ).css({
                    color: '#000',
                    textAlign: 'center',
                    pointerEvents: 'none'
                }).add();
            } else {
                customLabel.attr({
                    text: labelText
                });
            }
            customLabel.attr({
                x: (chart.pane[0].center[0] + chart.plotLeft) - customLabel.attr('width') / 2,
                y: (chart.pane[0].center[1] + chart.plotTop) - customLabel.attr('height') / 2 - 10
            });
            return customLabel;
        }

        // Render the Highcharts chart
        Highcharts.chart(chartRef.current, chartOptions);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [chartHeight]); // Re-run effect when chartHeight changes

    return <div ref={chartRef} style={{ height: chartHeight }} />;
};

export default StellarChart;
