// ToastPopup.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ToastPopup(message) {
  toast.success(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    pauseOnHover: false,
    style: {
      background: '#061731',
      color: '#FFFF', 
    },
  });
}

export default ToastPopup;