import { t } from "i18next";
import { DownloadsScript } from "./DownloadsScript";
import { useEffect, useRef } from "react";

// Component for the Collectors tab
function DownloadsTab() {
  const exportsContainerRef = useRef(null);
  const importsContainerRef = useRef(null);
  useEffect(() => {
    function handleExportsScroll() {
      const container = exportsContainerRef.current;
      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight
      ) {
        DownloadsScript.loadmore("download"); // Assuming this function is defined elsewhere
      }
    }

    function handleImportsScroll() {
      const container = importsContainerRef.current;
      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight
      ) {
        DownloadsScript.loadmore("reports"); // Assuming this function is defined elsewhere
      }
    }

    const exportsContainer = exportsContainerRef.current;
    const importsContainer = importsContainerRef.current;

    exportsContainer?.addEventListener("scroll", handleExportsScroll);
    importsContainer?.addEventListener("scroll", handleImportsScroll);

    return () => {
      exportsContainer?.removeEventListener("scroll", handleExportsScroll);
      importsContainer?.removeEventListener("scroll", handleImportsScroll);
    };
  }, []);

  return (
    <>
      <button
        className="tab active"
        onClick={(event) => DownloadsScript.openTab(event, "downloads-exports")}
      >
        {t("export_title")}
      </button>
      <button
        className="tab"
        onClick={(event) => DownloadsScript.openTab(event, "downloads-imports")}
      >
        {t("report_title")}
      </button>

      <div
        id="downloads-exports"
        className="tab-content active"
        ref={exportsContainerRef}
      >
        {/* <ActiveNode activenodes={activenodes} /> */}
      </div>

      <div
        id="downloads-imports"
        className="tab-content"
        ref={importsContainerRef}
      ></div>
      <div className="offcanvas-footer bg_canvas p-2 text-end">
        <button
          type="button"
          className="me-2"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          {t("close")}
        </button>
      </div>
    </>
  );
}

export default DownloadsTab;
