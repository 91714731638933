import React, { useEffect } from "react";
import { Incidence } from "../IncidencePanel/IncidenceScript";
import { t } from "i18next";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import moment from "moment";
import { Automation } from "../Automations/AutomationScript";
import { PanelScript } from "./PanelScript";

function DashboardDetectionList(props) {
  let entity = props.entity;
  let entitytype = props.entitytype;
  let output = props.result.data.response.result;
let data=props.data
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <>
      <div
        className="tab-pane show active"
        id="risk-tab-pane"
        role="tabpanel"
        aria-labelledby="risk-tab"
        tabIndex="0"
      >
        {output.map((entry, index) => (
          <div className="bxDashboardAction_inner" key={index}>
            <div className="bxDashboardAction_iETitle p-2">
              <div className="row align-items-center">
                <div className="col-2 entityBox">
                  <span className="text-mute">
                    <i>
                      {moment(new Date(entry.lastdetectiontime)).format(
                        "DD  MMM hh:mm"
                      )}
                    </i>
                  </span>
                  <h4 className="m-0 p-0">{entry.totaldetection}X</h4>
                  <small
                    className={CommonTemplate.criticalityColor(
                      entry.detectioncriticality
                    )}
                  >
                    {entry.detectioncriticality}
                  </small>
                </div>
                <div className="col-10">
                  <h5 className="BXf-14 m-0 text-capitalize">
                    {entry.name ? entry.name : "no title"}{" "}
                  </h5>
                  <ul className="bxOC_list d-flex align-items-center my-2 mb-0 p-0">
                    <li className="girpanel BXf-14">{entry.detectiontactic}</li>
                    <li className="girpanel BXf-14">
                      {entry.detectiontechnique}{" "}
                    </li>
                  </ul>
                  <span
                    className="custom_BageStrem incidentclass"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#bxOffcanvasIncidence"
                  >
                    <div
                      id={
                        "bnfxincidentid" +
                        entitytype +
                        entity +
                        entry.detectionid
                      }
                      onClick={(e) => {Incidence.loadmetadata(entitytype +
                        entity +
                        entry.detectionid,"fromdetectionlist",data);
                        Incidence.showClass();
                        CommonTemplate.removePanel();}}
                    ></div>
                    {Incidence.getincidentid(
                      entry,
                      entry.detectionid,
                      entry.streamid,
                      entity,
                      entitytype,
                      "bnfxincidentid"
                    )}
                  </span>
                </div>
              </div>
              <div className="dropdown ms-3">
                <button
                  className="dropdown-toggle arrow-none"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="material-symbols-outlined text-white">
                    more_vert
                  </i>
                </button>
                <ul
                  className="dropdown-menu"
                  data-popper-placement="bottom-start"
                >
                  <li
                    id={
                      "creatincident" + entitytype + entity + entry.detectionid
                    }
                  >
                    <button
                      className="dropdown-item"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        Incidence.loadformselectionoptions(
                          entry,
                          entry.detectionid,
                          entry.streamid,
                          entity,
                          entitytype,
                          entry.name,
                          "detectionlist"
                        );
                        Automation.offcanvasCloseButton();
                      }}
                    >
                      <span className="material-symbols-outlined">
                      destruction
                      </span>{" "}
                      {t("title.createincidence")}
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#bnfxdetctiongraph"
                      onClick={(e) => {
                        PanelScript.graphrender(
                          entry.name ? entry.name : "no title",
                          entry.detectionid,
                          entity,
                          entitytype,
                          props.tenant,
                          entry.totaldetection,
                          entry.streamid
                        );
                        Automation.offcanvasCloseButton();
                      }}
                    >
                      <span className="material-symbols-outlined">
                      tactic
                      </span>{" "}
                      {t("title.attackpattern")}
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={(e) => {
                        PanelScript.convertrequireddteectiondata(
                          entry,
                          "detection",
                          entry.streamid
                        );
                      }}
                    >
                      <span className="material-symbols-outlined">
                      genetics
                      </span>{" "}
                      {t("title.viewentity")}
                    </button>
                  </li>

                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#bnfxAutomationLayout"
                      onClick={() => {
                        Automation.loadAutomationList(
                          0,
                          entry.lastdetectiontime,
                          entry.name,
                          entity,
                          entitytype,
                          entry.streamid
                        );
                        CommonTemplate.showClass();
                        Automation.removeExecutionPanel();
                      }}
                    >
                      <span className="material-symbols-outlined">
                      playlist_play
                      </span>
                      {t("title.playbook.output")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#bnfxPlaybookExecution"
                      onClick={() => {
                        Automation.loadPlaybookAutomationList(
                          0,
                          entity,
                          entitytype,
                          entry.streamid,
                          entry.detectionid,
                          data.score,
                          entry.tenant,
                          entry.lastdetectiontime,
                          entry.name
                        );
                        CommonTemplate.showClass();
                        Automation.removeAutomationPanel();
                      }}
                    >
                      <span className="material-symbols-outlined">
                      playlist_add_check
                      </span>
                      {t("title.playbook.execution")}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default DashboardDetectionList;
