import { SearchTemplate } from "../../../pages/search/SearchScript";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import Select from "react-select";

function SqlQueryModal() {
    return (

        <div className="modal bxPopup fade sqlqueryconvertor" id="bnfxSqlQueryConvertor" tabIndex="-1"
            aria-labelledby="bxFilterTop"
            aria-hidden="true"
            data-bs-backdrop="true">
            <div className="modal-dialog modal-dialog-scrollable sqlqueryConvertorPopup">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="BXf-18 text-white">Query Baker</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body mb-1">
                    <label className="nameTable mb-2">Table </label>
                    <Select
                        id="bnfxqueryMakerDropdown"
                        className="form-control queryMakerDropdown"
                        options={SearchTemplate.tablesList}
                        defaultValue={SearchTemplate.tablesList[0]}
                        onChange={CommonTemplate.clickOnDropdown}
                    ></Select>
                        <div className="from-group mt-4">
                            <label htmlFor="bnfxFreetextInput" className="form-label p-0 w-100">Enter The Free Text</label>
                            <div className="bnfxSqlQueryBox">
                                <input type="text" className="form-control float-start" id="bnfxFreetextInput" placeholder="Enter Free Text" name="name" autoComplete="off" />
                                <button id="generateBtn" className="btn btn-primary float-end" type="button" onClick={CommonTemplate.handlequerytranslator}>Generate</button>
                            </div>
                        </div>
                        <div className="output-group">
                            <div id="bnfxFreetextOutput" ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default SqlQueryModal;