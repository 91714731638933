import { t } from "i18next";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";

function DetectionBubbleWidget(props) {
  const series = props.data.series;
  const categories = props.categories;


  const getSumPercentage = () => {
    return categories.reduce((sum, percentage) => sum + percentage, 0);
  };

  const getRandomColor = (idx) => {


          let color;

          if (idx === 0) {
            color = "#00876c";
          } else if (idx === 1) {
            color = "#63b179";
          } else if (idx === 2) {
            color = "#88c580";
          } else if (idx === 3) {
            color = "#aed987";
          } else if (idx === 4) {
            color = "#d6ec91";
          } 
        
          return color;  };

  return (
    <>
      <div className="veinwidgetdashboard">
        <div className="card-body">
          <div className="row">
            <div className="col-md-2">
            <h5 className="text-uppercase text-white fw-normal" >{t("finding_title")}</h5>
              <p className="total_detectioncount">
                {CommonTemplate.formatNumber(props.data.total[0])}
              </p>
              <i>
                +{CommonTemplate.formatNumber(props.data.lastoneday[0])} {t("last_24_hr")}
              </i>
            </div>
            <div className="col-md-10">
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={`flex items-center justify-center pointer-events-none`}
              >
                {categories.slice(0, 5).map((percentage, idx) => {
                  const circleSize = Math.max(
                    40,
                    120 - (70 / categories.length) * idx
                  ); // Adjust the multiplier as needed

                  return (
                    <div key={idx} style={{ position: "relative" }}>
                      <div
                        key={idx}
                        className={`flex justify-center items-center text-white text-sm rounded-full border-2 border-dark-border`}
                        style={{
                          zIndex: categories.length - idx,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: `${circleSize}px`,
                          height: `${circleSize}px`,
                          borderRadius: "50%",
                          marginLeft: idx !== 0 ? "-0.5rem" : "0",
                          backgroundColor: getRandomColor(idx),
                        }}
                      >
                        {`${((percentage / getSumPercentage()) * 100).toFixed(
                          1
                        )}%`}
                      </div>
                      <div
                        className="vertical-line"
                        style={{
                          borderLeft: "2px solid white",
                          height: "30%",
                          position: "absolute",
                          top: "100%",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      ></div>
                      <div
                        style={{
                          position: "absolute",
                          top: "120%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          textAlign: "center",
                          marginTop: "0.5rem",
                        }}
                      >
                        {series[idx]}
                      </div>
                    </div>
                  );
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetectionBubbleWidget;
