import { t } from "i18next";
import { SpiderScript } from "../SpiderScript";
import ShowErrorToastPopup from "../../../components/common/ShowErrorToastPopup";
import {
  getGlobalState,
  setGlobalState,
  useGlobalState,
} from "../../../components/common/GlobalVariables";
import { useState } from "react";
import CustomDropDown from "../../../components/common/CustomDropDown";

function SpiderDetailContainer({ nodeName }) {
  const handleClickTest = () => {
    SpiderScript.clickTestButton();
  };

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isSaving, setIsSaving] = useGlobalState("spidersavebutton");
  const [options] = useGlobalState("spiderExistingContent");
  const [selectedOption, setSelectedOption] = useState(null);

  const [collapsed] = useGlobalState(
    "global_spider_detail_right_panel_collapsed"
  );

  const handleInputChange = (event) => {
    if (options) {
      const value = event.target.value;
      // setInputValue(value);
      SpiderScript.selectedinputname=value
      setSelectedOption(null); // Reset selected option when input value changes

      SpiderScript.listExistingContent(value, nodeName?.data.label,0);
    }else{
      bydeafultonchange()
}
  };

  const bydeafultonchange=()=>{
    const requiredFields = [
      "spider_content_name",
      "spider_content_description",
    ];
    let areAllFieldsFilled = true;
    requiredFields.forEach((fieldId) => {
      const field = document.getElementById(fieldId);
      if (!field || field.value.trim() === "") {
        areAllFieldsFilled = false;
        field.classList.add("border-danger");
      } else {
        field.classList.remove("border-danger");
      }
    });

    setIsSaveEnabled(areAllFieldsFilled);
  };

  
  const handleClickSave = () => {
    // Check if the "Test" button has been clicked
    let spidertestClicked = getGlobalState("spidertestClicked");
    if (spidertestClicked) {
      setIsSaving(true);
      SpiderScript.setselectedNodeLabel([
        nodeName.data.label,
       null,
      ]);
      SpiderScript.handlecontentaction(nodeName?.data.label, {
        name: "spider_content_name",
        description: "spider_content_description",
        content: SpiderScript.content,
      },"editcontent");
      SpiderScript.handleToggleCollapse();
      setGlobalState("spidertestClicked", false);
    } else {
      // Alert or notify the user that they need to click "Test" first
      ShowErrorToastPopup(
        "Click the 'Test' button to verify your code before proceeding further"
      );
    }
    setGlobalState("spidertestClicked", true);
  };
  const handleCreateContentSave = () => {
       setGlobalState("spidertestClicked", false);
    document.getElementById('spiderloading-icon').style.display = 'block';
    SpiderScript.setcontentalreadyexist(false);
    setGlobalState("spidercontenteditable", true);
    setGlobalState("isEdit", false);
    // Additional logic if needed
    SpiderScript.handlecontentaction(nodeName?.data.label, {
      name: "spider_content_name",
      description:"spider_content_description",
      content: null,
    },"create");
  };

  const clearform = () => {
    setSelectedOption(null)
    // setInputValue("")
    SpiderScript.selectedinputname=""
    document.getElementById("spider_content_name").value = "";
    document.getElementById("spider_content_description").value = "";
  };
  const handleOptionSelect = (option) => {
    // SpiderScript.setcontentalreadyexist(true);
    SpiderScript.alreadyaddingexsitingcontent("alreadyexist");
    setSelectedOption(option);
    SpiderScript.selected_option=option
    SpiderScript.loadselectedcontent(option.value.location,nodeName?.data.label);
    // setInputValue(option); // Set input value to selected option
    SpiderScript.selectedinputname=option
  };

  const handleScrollToBottom = () => {
    // const isBackToTopPresent =StreamsOptions.some(item => {
    //   // Check if item.value exists and is a string
    //   if (typeof item.value === 'string') {
    //     return item.value.includes("No More Streams");
    //   }
    //   return false; // If item.value is not a string
    // });
   
      // setIsLoading(true);
      // SpiderScript.loadMoreStream();
      SpiderScript.loadmorecontent( SpiderScript.selectedinputname, nodeName?.data.label)
     
    
  };

  return (
    <>
    {isSaving && (
              <div className="overlay">
                <div id="loading-icon" className="loading-icon">
                  <div className="spinner-border"></div>
                  <p>Please wait while we are processing your request...</p>
                </div>
              </div>
            )}
      <div
        className={`SpiderDetailsLayout pt-2 active ${
          collapsed ? "collapse-right-spider-detail-panel" : ""
        }`}
      >
        <button
            type="button"
            className="arrow_forward_ios d-none"
            aria-label="Close"
            onClick={() => {
              SpiderScript.handleToggleCollapse();
            }}
          >
            <span className="material-symbols-outlined">arrow_forward_ios</span>
          </button>
          <div className="mb-2">
          <label className="d-block" htmlFor="spider_content_name">
            {nodeName?.data.label} Name
          </label>
          <input
            className="w-100 inputCustom"
            type="text"
            id={"spider_content_name"}
            placeholder={`Enter ${nodeName?.data.label} Name`}
            autoComplete="off"
            value={
              selectedOption?.value?.title
                ? selectedOption.value.title
                :  SpiderScript.selectedinputname
            }
            onChange={handleInputChange}
          />
          {options?.length > 0 && (
            <CustomDropDown
              id="spiderExistingContentList"
              options={options}
              onChange={handleOptionSelect}
              value={
                selectedOption
                  ? {
                      label: selectedOption?.value?.title,
                      value: selectedOption?.value?.title,
                    }
                  : null
              }
              searchrequired={false}
              onMenuScrollToBottom={handleScrollToBottom}
                     
            />
          )}
        </div>
        <div className="mb-2">
          <label className="d-block" htmlFor="spider_content_description">
          {nodeName?.data.label} Description
          </label>
          <textarea
            className="w-100 inputCustom"
            type="text"
            id={"spider_content_description"}
            placeholder={`Enter ${nodeName?.data.label} Description`}
            autoComplete="off"
            onChange={bydeafultonchange}
          ></textarea>
        </div>
        {!nodeName?.value && SpiderScript.selected_option ===null ? (
          <>
          <button
            type="button"
            className="SpiderCustomBtn saveBtn button"
            disabled={!isSaveEnabled}
            onClick={handleCreateContentSave}
          >
            {t("name.save")}
          </button>
          <button
          type="button"
          className="SpiderCustomBtn ms-2 cancelBtn button"
          onClick={() => {
            SpiderScript.handleToggleCollapse();clearform();
            }}
        >
          Cancel
        </button>
        </>
        ) : null}
        <div
          id="spiderloading-icon"
          className="loading-icon"
          style={{ display: "none" }}
        >
          <div className="spinner-border"></div>
        </div>
        <div className="Custom_rightArea_spiderLayout d-none">
          <div className="Custom_leftArea_box_details">
            <div className=" bnfxTaskDataCopy">
              <label className="d-block">
                Python Code
              </label>
              <div className="card dark-bg">
                <div className="card-body">
                  <div className="cmd-output" id="spidercontent"></div>

                  <div
                    id="loading-icon"
                    className="loading-icon"
                    style={{ display: "none" }}
                  >
                    <div className="spinner-border"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="spider-foot mt-2 p-2 text-start">
          <div className="spiderContentButton d-none">
            <button
              type="button"
              className="btn text-white me-3 SpiderCustomBtn testBtn"
              onClick={handleClickTest}
            >
              Test
            </button>
            
            <button
              type="button"
              className="btn btn-primary SpiderCustomBtn saveBtn"
              data-bs-dismiss="offcanvas"
              onClick={!isSaving ? handleClickSave : null}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "name.save"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpiderDetailContainer;
