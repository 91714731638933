import React from "react";
import { useGlobalState } from "../GlobalVariables";
import { t } from "i18next";

function CreateNewBadge() {
  let isBadgeeditable = useGlobalState("badgedit");
  return (
    <>
      <div
        className="bxStreamLstOffCanvas offcanvas offcanvas-end CrateBadgePage"
        id="bnfxAddNewBadge"
        aria-labelledby="bnfxAddNewBadge"
        data-bs-backdrop={true}
      >
        <div id="bnfxCreateNewBadge">
          <div className="BadgeLeftSec_inner p-3 pb-0 h-100">
            <div className="BadgeLeftSec_inner_header pb-2 border-bottom mb-3">
              {isBadgeeditable[0] ? (
                <h4 className="">{t("edit.badge.title")}</h4>
              ) : (
                <h4 className="">{t("add.badge.title")}</h4>
              )}
            </div>

            <div className="BadgeLeftSec_inner_body p-0" id="addtabslist">
              {/* we will render badgetab */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewBadge;
