import { calculatePagesCount } from "./PaginationFunction";
import "../pagination/pagination.css";

function Pagination(props) {
  const pagelist = calculatePagesCount(props.pagination);
  let paginationlist = null;
  const paginationClick = (e, pageObj) => {
    for (let index = 0; index < pagelist.length; index++) {
      pagelist[index].active = false;
      if (index + 1 === pageObj.page) {
        pagelist[index].active = true;
      }
    }

    e.target
      .closest(".pagination")
      .querySelectorAll(".page-item")
      .forEach((element) => {
        element.classList.remove("active");
      });

    e.target.closest(".page-item").classList.add("active");

    if (props.fnArg) {
      props.onClickFn(pageObj.offset, props.fnArg);
    } else {
      props.onClickFn(pageObj.offset);
    }

    e.preventDefault();
  };

  const pageNext = (e) => {
    const parent = e.target.closest(".bxPagination");
    const firsthidden = parent.querySelector(
      ".page-item.d-none:not(.lef-side)"
    );

    if (!firsthidden) {
      return;
    }

    firsthidden.classList.remove("d-none");
    const firstshown = parent.querySelector(
      ".page-item:not(.d-none):not(.prev-link)"
    );
    firstshown.classList.add("d-none");
    firstshown.classList.add("lef-side");
  };

  const pagePrevious = (e) => {
    const parent = e.target.closest(".bxPagination");
    const lefthiddens = parent.querySelectorAll(".page-item.d-none.lef-side");

    if (lefthiddens.length === 0) {
      return;
    }

    const lasthidden = lefthiddens[lefthiddens.length - 1];
    lasthidden.classList.remove("d-none");
    lasthidden.classList.remove("lef-side");

    const rightshowns = parent.querySelectorAll(".page-item:not(.d-none)");
    const firstshown = rightshowns[rightshowns.length - 2];
    firstshown.classList.add("d-none");
  };
  if (pagelist.length > 0) {
    paginationlist = (
      <nav className="bxPagination" aria-label="Page navigation example">
        <ul className="pagination mb-0 py-2">
          <li className="page-item prev-link" onClick={(e) => pagePrevious(e)}>
            <button type="button" className="page-link" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          {pagelist.map((pageObj, i) => (
            <li
              key={pageObj.page}
              className={
                "page-item" +
                (pageObj.active ? " active" : "") +
                (i > 4 ? " d-none" : "")
              }
              onClick={(e) => {
                paginationClick(e, pageObj);
              }}
            >
              <button
                className="page-link"
                offset={pageObj.offset}
                limit={pageObj.limit}
              >
                {pageObj.page}
              </button>
            </li>
          ))}
          <li className="page-item" onClick={(e) => pageNext(e)}>
            <button type="button" className="page-link" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
    );
  }
  return <>{paginationlist}</>;
}

export default Pagination;
