import { useState } from "react";
import { SpiderScript } from "../SpiderScript";
import React from "react";
function AddNewStream() {
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const handleInputChange = () => {
    const requiredFields = ["name", "description", "datasource"];
    let areAllFieldsFilled = true;

    requiredFields.forEach((fieldId) => {
      const field = document.getElementById(fieldId);
      if (!field || field.value.trim() === "") {
        areAllFieldsFilled = false;
        field.classList.add("border-danger");
      } else {
        field.classList.remove("border-danger");
      }
    });

    setIsSaveEnabled(areAllFieldsFilled);
  };

  return (
    <>
      <div className="modal-content bg-dark spiderAddStream">
        <div className="modal-header">
          <h1
            className="modal-title fs-5 text-center w-100 text-white"
            id="spiderstreammodal"
          >
            Create New Stream
          </h1>
        </div>
        <div className="modal-body">
          <form>
            <div className="mb-2">
              <label className="d-block" htmlFor="name">
                Name
              </label>
              <input
                className="w-100 inputCustom"
                type="text"
                id="name"
                placeholder="Stream Name"
                autoComplete="off"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-2">
              <label className="d-block" htmlFor="description">
                Description
              </label>
              <textarea
                className="w-100 inputCustom"
                type="text"
                id="description"
                placeholder="Description"
                autoComplete="off"
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="mb-2 position-relative">
              <label className="d-block" htmlFor="datasource">
                Stream URL
              </label>

              <input
                className="w-100 inputCustom streamurl"
                type="text"
                id="datasource"
                placeholder="Enter Datasource URL"
                autoComplete="off"
                onChange={handleInputChange}
              />
              <div className="bxCustomToolTips">
                <span className="material-symbols-outlined float-end">info</span>

                <div className="bxCustomToolTips_text">
                  <p className="text-muted  p-2 spidernote">
                    Please enter the URL that should point to the location where
                    the data is sourced from. Example:
                    "http://integration-&lt;provider&gt;-&lt;type&gt;/execute?groups=&lt;group-name&gt;&amp;&lt;secret-1&gt;=&lt;$.value_of_secret-1&gt;&amp;&lt;secret-2&gt;=&lt;$.value_of_secret-2&gt;&amp;&lt;frequency=1d&gt;"
                    <br />
                    For more details, refer to the{" "}
                    <a
                      href="https://binaryflux.atlassian.net/l/cp/GoS3kn10"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="streamconfiglink"
                    >
                      Stream Configuration
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn px-4 py-1 btn_CloseAddNewStream"
            data-bs-dismiss="modal"
            onClick={() => {
              SpiderScript.closeeditstreammodal();
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="px-4 py-1 streamcreatesavebutton"
            data-bs-dismiss="modal"
            onClick={() => {
              SpiderScript.handlestreamaction("streamedit");
            }}
            disabled={!isSaveEnabled}
          >
            Save changes
          </button>
        </div>
      </div>
    </>
  );
}

export default AddNewStream;
