import { useLayoutEffect } from "react";
import Search from "../../../components/search/Search";
import { BadgePanelScript } from "./BadgePanelScript";
import { t } from "i18next";


function BadgeList() {

  useLayoutEffect(() => {
  
    const searchbar = document.getElementById("badgelist_search")
    if (searchbar) {
      searchbar.addEventListener("keydown", function () {
        BadgePanelScript.search("badgelist_search");
      });
      searchbar.addEventListener("input", function () {
        if (searchbar.value === "") {
          document.getElementById("badgelist_search").value = null;
          BadgePanelScript.badgeList(0);
        }
      });
    }
  },[]);
    
  return (
    <>
      <div
        className="offcanvas Badge_offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasBadge"
        aria-labelledby="offcanvasBadgeLabel"
      >
        <div className="offcanvas-body p-0">
          <div className=" row">
            <div className="col-12 BadgeRightSec" id="BadgeList">
              {/* <BadgeListLayout/> */}
              <div className="BadgeRightSec_header searchFilterBadge p-3">
                <div className="d-flex align-items-center">
                  <Search id="badgelist_search" defaultValue="" />
                </div>
              </div>
              <div className="BadgeLists" id="bnfxbadgeList">
                {/* we will render badgelist */}
              </div>
            </div>
          </div>
          <div className="bxFloatingAddBadgeBtn d-flex align-item-center justify-content-between ps-3">
            <span
              type="button"
              className="text-white me-3"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                BadgePanelScript.removeBadgePanel();
              }}
            >
              {t("close")}
            </span>
            <button
              title="Add Badge"
              data-bs-toggle="offcanvas"
              data-bs-target="#bnfxAddNewBadge"
              aria-controls="offcanvasRight"
              href="#badgeList"
              className="action-icon"
              onClick={() => {
                BadgePanelScript.loadCreateBadgepanel();BadgePanelScript.removeBadgeListPanel();
              }}
            >
              <span className="material-symbols-outlined">add</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BadgeList;
