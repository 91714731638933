import { useEffect } from "react";
import { t } from "i18next";
import { CollectorsScript } from "./CollectorsScript";
import "../collectors/collectors.css";
import { getGlobalState } from "../../components/common/GlobalVariables";

function Collectors() {
  useEffect(() => {
    CollectorsScript.init();
  }, []);

  let singletenant = getGlobalState("singletenant");
  let tenantoption = null;
  if (singletenant) {
    tenantoption = null;
  } else {
    tenantoption = (
      <div className="dataCollectors_select" id="bnfx-collector-tenantlist">
        {/* select dropdown of tenantlist */}
      </div>
    );
  }
  return (
    <>
      {/* Offcanvas header */}
      <div className="offcanvas-header p-2 border-bottom bxCollectorsCard mb-3">
        <h4 className="BXf-18 text-white m-0">
          <span className="material-symbols-outlined me-2">data_table</span>
          {t("collectors.title")}
        </h4>
        {/* Tenant select dropdown */}
        <div className="d-flex">
        <button
          className="bxUserOnclick"
          onClick={CollectorsScript.handleRefresh}
          title="Refresh"
        >
          <span className="material-symbols-outlined">refresh</span>
        </button>
        {tenantoption}</div>
      </div>
      {/* Offcanvas body */}
      <div className="offcanvas-body p-0 px-2" id="collectors-tab-content">
        {/* rendering atbs and its componenets */}
      </div>
    </>
  );
}

export default Collectors;
