import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsNetworkgraph from "highcharts/modules/networkgraph";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";
import HighchartsAccessibility from "highcharts/modules/accessibility";

// Initialize Highcharts modules
HighchartsNetworkgraph(Highcharts);
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsAccessibility(Highcharts);

function NetworkGraph({ result, nodes, container }) {
  const chartRef = useRef(null);
  useEffect(() => {
    const network = (container) => {
      const parent = container.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );
      const height = parseInt(document.getElementById(parent)?.clientHeight);
      const total = result.length;
      Highcharts.chart(container, {
        chart: {
          type: "networkgraph",
          height: height + "px",
          events: {
            load() {
              let series = this.series[0];
              var colors = [
                "#ff7300",
                "#1391ff",
                "#31ada9",
                "#ad3152",
                "#3179ad",
                "#FF9655",
                "#FFF263",
                "#6AF9C4",
              ];

              series.nodes[0].marker = {
                radius: 20,
              };
              series.nodes[0].color = "#ad3152";

              series.nodes.forEach(function (node, i) {
                if (i >= 1) {
                  let x = Math.floor(Math.random() * 8);
                  node.color = colors[x];
                  if (window.innerHeight > 815) {
                    node.marker = {
                      radius: 10,
                    };
                  } else {
                    node.marker = {
                      radius: 3,
                    };
                  }
                }
              });
            },
          },
        },
        title: {
          text: "", // Remove chart title
        },
        credits: {
          enabled: false, // Remove Highcharts.com link
        },
        plotOptions: {
          networkgraph: {
            layoutAlgorithm: {
              enableSimulation: true,
              maxIterations: 200,
            },
          },
        },
        series: [
          {
            id: "language-tree",
            marker: {
              radius: total / (total / 4),
            },
            dataLabels: {
              enabled: true,
              linkFormat: "",
              style: {
                textOverflow: "ellipsis",
                width: "50%",
                color: "rgba(255, 255, 255, 0.5)",
                textOutline: false,
                verticalAlign: "top",
                textAlign: "left",
              },
              overflow: "justify",
            },
            data: result,
            nodes: nodes,
          },
        ],
      });
    };
    if (chartRef.current) {
      network(container);
    }
  }, [container, nodes, result]);

  return <div ref={chartRef} id={container} />;
}

export default NetworkGraph;
