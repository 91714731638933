import { t } from "i18next";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";

function CriticalWidgetLayout(props) {
    let data=props.criticaldata;

  return (
    <>
      <div className="card_layout">
        <div className="card-body">
          <div className="row">
            <div className="col-md-3">
              <div className="d-flex align-iten-center roiDash_Title_box">
                <h5 className=""> {t("critical_finding_title")}</h5>
                <span className="material-symbols-outlined ms-2 title_icon">warning</span>
              </div>
              
              <p className="total_detectioncount">{CommonTemplate.formatNumber(data[0].total)}</p>
              <i>+{CommonTemplate.formatNumber(data[0].last24hours)} {t("last_24_hr")}</i>
            </div>
            <div className="col-md-9 critical_detection">
              {data.map((item, index) => (
                <div
                  key={index}
                  className="d-flex align-item-center justify-content-between mb-2"
                >
                  <p className="roi detectionname">
                    <b className="detection_count">
                      {CommonTemplate.formatNumber(item.count_per_detection)}
                    </b>
                    &nbsp;
                    {item.detectionname}
                  </p>
                  <span className="badge  px-2 py-2 icon-danger text-uppercase">
                    {item.detectioncriticality}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CriticalWidgetLayout;
