import { useEffect, useState } from "react";
import Select from "react-select";
import { setGlobalState, useGlobalState } from "../GlobalVariables";
import { Incidence } from "./IncidenceScript";
import { t } from "i18next";

function IncidenceAssignBox(props) {

    const [assigntype, setAssignType] = useState("");
    const [assignee, setAssignee] = useState("");
    const [incidenceassigneeoptions] = useGlobalState("incidenceassigneeoptions");
    const [dashboarduseroptions] = useGlobalState("dashboarduseroptions");
    const [dashboardgroupoptions] = useGlobalState("dashboardgroupoptions");

    const handleAssignTypeChange = (selectedOptions) => {

        setAssignType(selectedOptions);

        if (selectedOptions.label === 'USER') {

            setGlobalState("incidenceassigneeoptions", dashboarduseroptions)
        } else if (selectedOptions.label === "GROUP") {

            setGlobalState("incidenceassigneeoptions", dashboardgroupoptions)
        }

    };
    const handleAssignChange = (selectedOptions) => {
        setAssignee(selectedOptions);

    };
    const assigntypeoptions = [
        { value: 'user', label: 'USER' },
        { value: 'group', label: 'GROUP' },
    ];

    const handleFormSubmit = (type) => {

        let result = {}
        let newassignee = assignee
        let newassigntype = assigntype


        if (newassignee['value'] !== null) {
            result["assignee"] = newassignee['value']
        }
        if (newassigntype['value'] !== null) {
            result["asigneetype"] = newassigntype['value']
        }

        Incidence.loadupdate(props.data.id, result)



    };

    useEffect(() => {
        const assigntype ={ value: props.data.asigneetype, label: props.data.asigneetype.toUpperCase() };
        const assignvalue ={ value: props.data.assignee, label: props.data.assignee };
        setAssignType(assigntype);
        setAssignee(assignvalue);
        if (props.data.asigneetype === 'user') {

            setGlobalState("incidenceassigneeoptions", dashboarduseroptions)
        } else if (props.data.asigneetype === "group") {

            setGlobalState("incidenceassigneeoptions", dashboardgroupoptions)
        }
       
    
      },[props.data]);
    
    return (
        <>
            <div className="assignee-box p-3">
                <label htmlFor="assigntype" className="mt-0">{t("assigntypebox")}</label>
                <Select id="assigntype" className="form-control" name="status" options={assigntypeoptions}
                    value={assigntype}
                    onChange={handleAssignTypeChange}
                >
                </Select>
                <label htmlFor="bnfxassign" className="mt-3">{t("assignbox")}</label>
                <Select id="bnfxassign" className="form-control" name="status" options={incidenceassigneeoptions}
                    value={assignee}
                    onChange={handleAssignChange}
                >
                </Select>
                <button type="submit" value="Save" className="btn btn-primary mt-3" onClick={(e) => handleFormSubmit("criticality")} >
                    {t("name.save")}
                </button>
                <button type="button" className="btn text-white mt-3" onClick={(editorclass) => Incidence.displayback('assignee-box')}>{t("cancel")}</button>
            </div>

        </>
    );
}

export default IncidenceAssignBox;