
import React, { useEffect } from 'react';

import "../terminal/TerminalPage.css";
import { Terminal } from './TerminalScript';
import { t } from 'i18next';
import { getGlobalState } from '../../components/common/GlobalVariables';

function TerminalPage() {

  // const [showToggle, setshowToggle] = useState(false);
  // if(showToggle){
  //   // https://ironman.binaryflux.xyz/spiderpage
  //   // http://localhost:3000/spiderpage
  //   let url = "http://localhost:3000/spiderpage";
  //     window.location.href = url;
  // }


  useEffect(() => {
    Terminal.init()
  }, []);
let singletenant=getGlobalState("singletenant")
let tenantoption=null
if(singletenant){
  tenantoption=null
}else{
  tenantoption=<>
  <i> | </i>
  <i className='ml-15'>{t("terminal.tenant.dropdown")}</i>
  <i className='inline_tenant' id='bnfx_terminal_tenants'></i></>
}

  return (
    <div className="terminal_box">
      <div className="bg_t_c">
        <div></div>
        <div className="ph-row float-end">
          {/* <div
            className="ToggleButton terminal me-3"
            id="bnfxspiderToggle"
            onChange={() => setshowToggle(!showToggle)}
          >
            <label class="switch">
              <input type="checkbox" id="togBtn" />
              <div class="slider round">
                <span class="on">Terminal</span>
                <span class="off">Spider</span>
              </div>
            </label>
          </div> */}
          <div className="ph-col bg-danger bnfxclosebutton" title="close">
            <button
              className="material-symbols-outlined close-button"
              onClick={Terminal.closeconfirmation}
            >
             {t("close")}
            </button>
          </div>
          <div
            className="ph-col bg-warning mx-2 exitfullscreen d-none"
            title="exit full screen"
          >
            <span
              className="material-symbols-outlined close-button"
              onClick={Terminal.closeFullscreen}
            >
             close_fullscreen
            </span>
          </div>
          <div
            className="ph-col bg-success mx-2 enterfullscreen"
            title="enter full screen"
          >
            <span
              className="material-symbols-outlined close-button"
              onClick={() => {
                Terminal.openFullscreen(".terminal_box");
              }}
            >
             fullscreen
            </span>
          </div>
        </div>
      </div>
      <div id="terminal_container">
        <div id="header" className="tC_inner" style={{ padding: "0 20px" }}>
          <div className="header_message">
            <p>
              <i>
                {t("terminal.last.login")}{" "}
                <span className="bage" id="bnfx_terminal_lastloggedin"></span>
              </i>
            </p>
            <p>
              <i>
                {t("terminal.for.helps")}{" "}
                <span className="bage"> {t("terminal.help")}</span>
              </i>
              {tenantoption}
            </p>
          </div>
        </div>
        <output id="output" className="tC_inner">
          {/* <div className='d-flex' id="bnfxenteredcommand">efjrgketmg</div> */}
          <div className="cmd-output" id="bnfx_terminal_output"></div>

          <div
            id="loading-icon"
            className="loading-icon"
            style={{ display: "none" }}
          >
            <div className="spinner-border"></div>
          </div>
        </output>
        <div className="d-flex tC_inner mt-4">
          <div id="prompt" className="d-flex">
            <img src="images/gif-1.gif" alt="our doppler" height="20" />{" "}
            {t("terminal.command.section")}
            <span id="bnfx_terminal_tenant_display"></span>:/
          </div>
          <input
            id="cmdline"
            className="bnfx_terminal_cmdline"
            autoFocus=""
            onKeyDownCapture={(e) => {
              Terminal.runCommand(e);
            }}
          />
        </div>
        <div></div>
      </div>
    </div>
  );



}


export default TerminalPage;