import { useLocation } from "react-router";
import { Navigation } from "../../components/common/mainHeader/leftHeader/Navigation";
import { useEffect } from "react";
import "../stream/stream.css";
import "../health/healthpage.css"
import HealthPageLayout from "./HealthPageLayout";

function HealthTemplate() {

  const location = useLocation();
  useEffect(() => {
    Navigation.onnavigate(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className="fullwidth main-inner HealthPage" id="bxFullwidthContent">
        <div className="tab-content healthPageBody">
          <div className="container-fulid">
            <HealthPageLayout/>
          </div>
          
        </div>
      </div>
    </>
  );


}


export default HealthTemplate;