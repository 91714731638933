import { getGlobalState } from "../../GlobalVariables";

export const Navigation = {
  registeredfns: [],
  contentvisiblity:process.env.REACT_APP_SHOW_SPIDER,
  
  register: (fn) => {
    Navigation.registeredfns.push(fn);
  },

  onnavigate: (location) => {
    Navigation.runInitFns(location);
    Navigation.manageHistory(location);
    Navigation.manageDatePickerScroll();
    Navigation.manageGlobalSearchIcon();
    Navigation.clearAllPopUp();
  },
  runInitFns: (location) => {
    for (let index = 0; index < Navigation.registeredfns.length; index++) {
      const registeredfn = Navigation.registeredfns[index];
      registeredfn(location);
    }
  },
  manageHistory: (location) => {
    window.history.replaceState(
      null,
      "",
      location === "/" ? getGlobalState("bnfx_default_uri") : location
    );
  },
  manageGlobalSearchIcon: () => {
    document.getElementsByTagName("title")[0].innerText =
      document.querySelector("#bnfxHeaderTitle").innerText;
    const searchicon = document.getElementById("searchicon");
    if (searchicon) {
      searchicon.classList.remove("active");
      // Change the text color to white
      // searchicon.style.color = "white";

      // Change the innerHTML to include the desired HTML content
      searchicon.innerHTML =
        '<span class="material-symbols-outlined navsearchicon">location_searching</span>';
    }
  },
  manageDatePickerScroll: () => {
    const datePickerContainers = document.querySelectorAll(
      ".datePickerContainer"
    );
    for (let index = 0; index < datePickerContainers.length; index++) {
      const element = datePickerContainers[index];
      element.removeEventListener("scroll", Navigation.removeDateRangePicker);
      element.addEventListener("scroll", Navigation.removeDateRangePicker);
    }
  },

  removeDateRangePicker: () => {
    const daterangepicker = document.querySelectorAll(
      ".daterangepicker[style*='display: block'"
    );
    if (daterangepicker.length > 0) {
      daterangepicker[0].style.display = "none";
    }
  },

  alterHeader: (location) => {
    let parent = document.querySelector("a[href='" + location + "']");
    if (parent) {
      parent.classList.add("bnfxSelectedHeader");
      if (parent.getAttribute("title")) {
        document.querySelector("#bnfxHeaderTitle").innerHTML =
          parent.getAttribute("title");
      }
      if (parent.getAttribute("description")) {
        document.querySelector("#bnfxHeaderDescription").innerHTML =
          parent.getAttribute("description");
      }
    }
  },
  clearAllPopUp: () => {
    document.body.click();
    const closebuttons = document.querySelectorAll(
      "button[data-bs-dismiss='offcanvas'].text-white"
    );
    if (closebuttons && closebuttons.length > 0) {
      for (let index = 0; index < closebuttons.length; index++) {
        const element = closebuttons[index];
        element.click();
      }
    }
    let removecollapse = document.querySelector(".cSSearch_collapse");
    removecollapse?.classList.remove("show");

    let removeBadgeListPanel = document.querySelector(
      ".offcanvas.Badge_offcanvas.offcanvas-end"
    );
    if (removeBadgeListPanel) {
      removeBadgeListPanel.classList.remove("show");
    }
  },


  navLinkData: (t) => {
    const links =[
    {
      iconx: "stream",
      title: t("stream_title"),
      description: t("stream_description"),
      path: "/stream",
    },
    {
      iconx: "dashboard",
      title: t("dashboard_title"),
      description: t("dashboard_description"),
      path: "/dashboard",
    },
    {
      iconx: "corporate_fare",
      title: t("organization_title"),
      description: t("organization_description"),
      path: "/organization",
    },

    // {
    //   iconx: "health_and_safety",
    //   title: t("health_title"),
    //   description: t("health_description"),
    //   path: "/health",
    // },
    // {
    //   iconx: "health_and_safety",
    //   title: t("risk_title"),
    //   description: t("risk_description"),
    //   path: "/risk",
    // }
    // {
    //   iconx: "summarize",
    //   title: t("investigation_title"),
    //   description: t("investigation_description"),
    //   path: "/investigation",
    // },
  ];

  if (Navigation.contentvisiblity === 'true') {
    links.push({
      iconx: "corporate_fare",
      title: "Content Catalog",
      description: "This Gen-AI-backed engine delivers content as code, enabling data engineers to fully control algorithms and generate superior content.",
      path: "/content",
    });
  }

  return links;
}
};
