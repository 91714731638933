import { t } from "i18next";
import { requestURI } from "../../components/common/RequestURIs";
import { ajax } from "../../components/common/Service";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import TerminalOutput from "./TerminalOutput";
import TerminalTenants from "./TerminalTenants";
import Swal from "sweetalert2";
import jwt from 'jwt-decode'
import { getGlobalState } from "../../components/common/GlobalVariables";

const token = localStorage.getItem("token");
export const Terminal = {
 
  selectedTenant: null,
  currentindex: -1,
  content: null,

  init: () => {
    Terminal.TenantsList()

  },
  TenantsList:()=>{
    const userdetails = jwt(token);
    
    let tenantcount=userdetails?.tenants?.length
    if(tenantcount == null || tenantcount===0){
      Terminal.getTenantsList()
    }else{
      let tokentenantlist = userdetails.tenants
      const tenantList = tokentenantlist.map(item => ({
        value: item,
        label: item,
        
      }));
      let singletenant=getGlobalState("singletenant")
      const selectAllOption = {
        value: "default",
        label: "ALL",
      };
  
      if (singletenant === false) {
        tenantList.unshift(selectAllOption);
      }
      const container = document.querySelector("#bnfx_terminal_tenants");
      const data =
  
        <TerminalTenants
          tenants={tenantList}
        />
      CommonTemplate.ContainerAvailability(container, data);
  
      Terminal.selectTenantChange(tenantList[0])
      Terminal.getLastLoggedIn()
    }

  },
  getTenantsList: () => {


    ajax(
      'get',
      requestURI['list.tenant'],
      null,
      null,
      Terminal.renderTenantList,
      Terminal.renderError,

    );

  },

  renderTenantList: (response) => {
    let tenantlist = response.data.response
  


    const tenantList = tenantlist.map(item => ({
      value: item.name,
      label: item.name,
    }));

    const selectAllOption = {
      value: "default",
      label: "ALL",
    };

      tenantList.unshift(selectAllOption);
    const container = document.querySelector("#bnfx_terminal_tenants");
    const data =

      <TerminalTenants
        tenants={tenantList}
      />
    CommonTemplate.ContainerAvailability(container, data);

    Terminal.selectTenantChange(tenantList[0])
    Terminal.getLastLoggedIn()
  },

  selectTenantChange: (selectedTenant) => {
    document.querySelector("#bnfx_terminal_tenant_display").innerHTML = selectedTenant.value==="default"?null:selectedTenant.value.toLowerCase();
    Terminal.selectedTenant = selectedTenant;
  },

  getLastLoggedIn: () => {

    const request = {
      command: "lastloggedin",
      tenant: Terminal.selectedTenant.value
    }

    ajax(
      "post",
      requestURI["terminal.command"],
      null,
      request,
      Terminal.renderLoggedIn,
      Terminal.renderError
    );


  },
  runCommand: (event) => {
    if (event.key === 'Enter' && document.querySelector(".bnfx_terminal_cmdline").value !== "clear") {
      document.getElementById('loading-icon').style.display = 'block';
    }
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      Terminal.getHistory(event.key)
    } else if (event.key === "Enter") {
      Terminal.currentindex = 0;
      const input = document.querySelector(".bnfx_terminal_cmdline");
      const command = input.value;

      if (command === 'clear') {
        document.querySelector("#bnfx_terminal_output").innerHTML = "";
        document.querySelector(".bnfx_terminal_cmdline").value = '';
        return;
      }

      const request = {
        command: command,
        tenant: Terminal.selectedTenant.value
      }
      ajax(
        "post",
        requestURI["terminal.command"],
        null,
        request,
        Terminal.renderOutput,
        Terminal.renderError,
        [input.value]
      );
    }

  },

  renderError: (error,args) => {
    Terminal.renderOutput(null,args, error);
  }, getHistory: (eventkey) => {

    if (eventkey === 'ArrowUp') {
      Terminal.currentindex += 1;
    } else {
      Terminal.currentindex -= 1;
    }

    if (Terminal.currentindex < 0) {
      Terminal.currentindex = 0
    }

    const request = {
      command: "history",
      index: Terminal.currentindex,
      tenant: Terminal.selectedTenant.value
    }

    ajax(
      "post",
      requestURI["terminal.command"],
      null,
      request,
      Terminal.renderHistory,
      Terminal.renderError,
      [eventkey]
    );

  },
  renderHistory: (result, args) => {
    const historycommand = result.data.response.message;
    document.querySelector(".bnfx_terminal_cmdline").value = historycommand;

    if (historycommand === '') {
      Terminal.currentindex = -1;
    }
  },
  renderOutput: (response,args, error) => {
    const command=args[0]

    if (document.querySelector(".save_terminal")) {
      document.querySelector(".save_terminal").remove();
    }
    if (document.querySelector(".bnfxsaveconfirmation")) {
      document.querySelector(".bnfxsaveconfirmation").remove();
    }

    if (error) {
      error = error.replace('java.lang.RuntimeException:', 'error:')
    }


    let outputmap = error ? null : response.data.response

    let container = null;
    let data = null;


    let outputappender = document.querySelectorAll(".bnfx_terminal_output_append");

    if (!outputappender || outputappender.length === 0) {
      container = document.querySelector("#bnfx_terminal_output");
      data = <TerminalOutput output={outputmap} error={error} firsttime={true} command={command}/>
    } else {
      container = outputappender[outputappender.length - 1];
      data = <TerminalOutput output={outputmap} error={error} command={command}/>
      const emptycontainer = document.createElement("div")
      emptycontainer.classList.add("bnfx_terminal_output_append");
      container.parentNode.insertBefore(emptycontainer, container.nextSibling);
    }

    document.getElementById('loading-icon').style.display = 'none';
    CommonTemplate.ContainerAvailability(container, data);
    document.querySelector(".bnfx_terminal_cmdline").value = '';

  },


  renderLoggedIn: (result) => {
    const lastloggedin = result.data.response.message;
    document.querySelector("#bnfx_terminal_lastloggedin").innerHTML = new Date(lastloggedin);
  },

  closeFullscreen: () => {
    let enterfullscreen=document.querySelector(".enterfullscreen")
    enterfullscreen.classList.remove("d-none")

    let exitfullscreen=document.querySelector(".exitfullscreen")
    exitfullscreen.classList.add("d-none")
    if (document.fullscreenElement) {
      if (document?.exitFullscreen) {
        document?.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
    }
  },

  openFullscreen: (className) => {
    let enterfullscreen=document.querySelector(".enterfullscreen")
    enterfullscreen.classList.add("d-none")
    let exitfullscreen=document.querySelector(".exitfullscreen")
    exitfullscreen.classList.remove("d-none")
    const elem = document.querySelector(className)
    if (elem.requestFullscreen) { 
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  },

 
  saveAction: (content, data) => {

    const request = {
      contenttype: data.contenttype,
      code: content ? content : data.content,
      contentid: data.path,
      tenant: Terminal.selectedTenant.value,


    }

    ajax(
      "post",
      requestURI["terminal.save.command"],
      null,
      request,
      Terminal.renderSaveContent,
      Terminal.renderError,
      [data.contenttype]
    );

  },
  renderSaveContent: (response,args) => {
    const contentype=args[0]
    let container = document.querySelector("#bnfxSearchSavedResponse"+contentype);
    let data = <p className="d-flex align-items-center" id="tickIcon">  <span className="material-symbols-outlined me-2" >
      done_all
    </span> Successfully Saved</p>
    CommonTemplate.ContainerAvailability(container, data);
    Terminal.content=null
    setTimeout(() => {
      let tickIcon = document.getElementById("tickIcon");
      if (tickIcon) {
        tickIcon.remove();
      }
    }, 2000); 
  },
  closeconfirmation: () => {
    Swal.fire({
      title:  t("delete.title.confirmation"),
      text:  t("delete.description.confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:  t("delete.yes"),
      cancelButtonText:t("delete.no")
    }).then((result) => {
      if (result.isConfirmed) {
        Terminal.onClickClose();
      }
    });
  },
  onClickClose: () => {

    const title = document.getElementById("bnfxHeaderTitle").innerText;
    const navlink = document.querySelector(
      "a.bxNavlistLink.row[title = '" + title + "']"
    );
    window.location = navlink.getAttribute("href");

  },

};