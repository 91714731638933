import { useState } from "react";
import { Automation } from "./AutomationScript";
import { t } from "i18next";

function AutomationListItem(props) {
  let taskdata = props.taskdata;
  const executionid = props.executionid;
  let status = taskdata.status;
  let icon = Automation.automationStatusIcon(status);
  let taskdescription=taskdata.description?taskdata.description:t("title.nodescription");
  const [isActive] = useState(false);


  const handleLinkClick = (executionId) => {
    const elements = document.querySelectorAll(
      ".Custom_leftTime_line.firstChild"
    );

    elements.forEach((element) => {
      element.classList.remove("active");
    });

    const clickedElement = document.getElementById(`automation${executionid + taskdata.taskname}`);
    if (clickedElement) {
      clickedElement.classList.add("active");
    }
  };

  return (
    <>
      <div
        className={`Custom_leftTime_line firstChild ${isActive ? "active" : ""}`}
        id={`automation${executionid + taskdata.taskname}`}
        onClick={() => {
          Automation.getTaskDetails(taskdata, executionid);
          handleLinkClick(executionid);
        }}
      >
        <div className="Custom_leftTime_line_bar">{icon}</div>
        <div className="d-flex align-items-center task_default_click justify-content-between ps-3 py-3 cm_border">
          <div className="Custom_leftTime_line_text">
            <b
              className="text-truncate text-capitalize text-white"
              title={taskdescription}
            >
              {taskdescription}
            </b>
            {/* <p
              className="text-truncate text-capitalize m-0"
              title={taskdata.taskname}
            >
              {taskdata.taskname}
            </p> */}
          </div>
          <div className="Custom_leftTime_line_Action"></div>
        </div>
      </div>
    </>
  );
}

export default AutomationListItem;
