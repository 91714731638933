import { useLayoutEffect } from "react";
import { Dashboard } from "../DashboardScript";
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.css";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";

function DashboardLayout({ dashboard }) {
  useLayoutEffect(() => {
    Dashboard.loadDashboardDetailsLayout(dashboard);
    Dashboard.grid = GridStack.init({
      float: true,
      disableDrag: true,
      disableResize: true,
    });
  }, [dashboard]);
  const substringsToCheck = ["roi_insights_dashboard"];
  return (
    <>
      <div className="row mb-3 align-items-center g-2" id="bnfxDashboardMetaData">
        {/* here we replace metadata */}
      </div>
      {CommonTemplate.notIncludesAny(dashboard.location, substringsToCheck) ? (
        <div className="grid-stack row" id="bxDashboardWiget">
          {/* here we will append widgets dynamically */}
        </div>
      ) : (
        <div className="row">
          <div className=" col-xxl-10 mx-auto">
            <div className="grid-stack row" id="bxDashboardWiget">
              {/* here we will append widgets dynamically */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DashboardLayout;
