

function CollectorsLayout() {
  return (
    <>
      {/* Offcanvas for data collectors */}
      <div
        className="offcanvas offcanvas-end dataCollectors"
        tabIndex="-1"
        id="offcanvas-collectors-content"
        aria-labelledby="offcanvasRightLabel"
        data-bs-backdrop={true}
      >

        <div id="bnfx-connector-panel"></div>
      </div>
     
    </>
  );
}

export default CollectorsLayout;
