import {
  getGlobalState,
  setGlobalState,
  useGlobalState,
} from "../../components/common/GlobalVariables";
import { SpiderScript } from "./SpiderScript";
import CloseMenuButton from "../../components/common/CloseMenuButton";
import { useEffect, useLayoutEffect, useState } from "react";
import SpiderStreamDropDown from "./SpiderStreamDropDown";
function SpiderHeader() {
  let singletenant = getGlobalState("singletenant");
  let tenantoption = null;
  if (singletenant) {
    tenantoption = null;
  } else {
    tenantoption = (
      <>
        <div id="terminal_tenants"></div>
      </>
    );
  }

  const [selectedStreams, setSelectedStreams] =
    useGlobalState("selectedStreams");
  const [StreamsOptions] = useGlobalState("StreamsOptions");

  const handleStreamSelectChange = (selectedOptions) => {
    if(selectedOptions.value!=="No More Streams") {
    setSelectedStreams(selectedOptions);
    setGlobalState("spiderreoladfreeze", true);
    SpiderScript.loadStreamsComponent(selectedOptions);
  }
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleScrollToBottom = () => {
    const isBackToTopPresent =StreamsOptions.some(item => {
      // Check if item.value exists and is a string
      if (typeof item.value === 'string') {
        return item.value.includes("No More Streams");
      }
      return false; // If item.value is not a string
    });
    if (!isBackToTopPresent) {
      setIsLoading(true);
      SpiderScript.loadMoreStream();
    }
  };
  // const options = isLoading
  //   ? [...StreamsOptions, { label: "Loading...", value: "loading" }]
  //   : StreamsOptions;
  useLayoutEffect(() => {
    if (StreamsOptions.length > 0) {
      setGlobalState("selectedStreams", StreamsOptions[0]);
      SpiderScript.loadStreamsComponent(StreamsOptions[0]);
    }
  }, [StreamsOptions[0]?.value?._id]);

  useEffect(() => {
    let timeoutId;
    if (isLoading) {
      timeoutId = setTimeout(() => {
        setIsLoading(false); // Remove loading after one minute
      }, 20000); // One minute timeout
    }
    return () => {
      clearTimeout(timeoutId); // Cleanup function to clear the timeout
    };
  }, [isLoading]);

  // Function to toggle the menu
  const [showdrafts, setDraftVisbility] = useState(true);
  const toggleMenu = () => {
    const spiderBoxAreas = document.querySelectorAll(".spiderBox_area");
    if (showdrafts) {
      setDraftVisbility(false);
      spiderBoxAreas.forEach((spiderBoxArea) => {
        spiderBoxArea.classList.add("visible");
      });
    } else {
      setDraftVisbility(true);
      spiderBoxAreas.forEach((spiderBoxArea) => {
        spiderBoxArea.classList.remove("visible");
      });
    }
  };
  const [isReload] = useGlobalState("spiderreoladfreeze");

  return (
    <>
      {isReload && (
        <div className="overlay">
          <div id="loading-icon" className="loading-icon">
            <div className="spinner-border"></div>
            <p>Please wait while we are processing your request...</p>
          </div>
        </div>
      )}
      <div className="SpiderHeader pe-3">
        <div className="container-full">
          <div className="row align-items-center">
            {StreamsOptions?<div className="col-auto">
              <div className="topCenterSpiderHead ">
                <div className="d-flex align-items-center">
                  <div className="breadcrumbItem">
                      <SpiderStreamDropDown
                        id="terminalStreamList"
                        options={StreamsOptions}
                        onChange={handleStreamSelectChange}
                        value={selectedStreams}
                        onMenuScrollToBottom={handleScrollToBottom}
                        searchrequired={true}
                      />
                  </div>
                  <button
                    className="breadcrumbItem draftsicon ms-3"
                    onClick={toggleMenu}
                  >
                    <small> Drafts</small>
                  </button>
                </div>
              </div>
            </div>:null}

            <div className="col">
              <div className="topRightSpiderHead">
                <ul className="m-0 p-0 d-flex align-items-center justify-content-end">
                  <li
                    className="me-3"
                    onClick={() => {
                      SpiderScript.draftedContentPublish();
                    }}
                  >
                    <button className="btn_publish spiderpublish px-4 text-white">
                      PUBLISH
                    </button>
                  </li>

                  <li className="tenantoption me-5">{tenantoption}</li>
                  <li>
                    <CloseMenuButton outerclass=".Spider_container" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpiderHeader;
