import { useEffect, useState } from "react";

import Select from "react-select";
import { useGlobalState } from "../GlobalVariables";
import { Incidence } from "./IncidenceScript";
import { t } from "i18next";

function IncidenceStatusBox(props) {
  const [status, setStatus] = useState("");
  const [incidencestatusoptions] = useGlobalState("incidencestatusoptions");

  const handleStatusChange = (selectedOptions) => {
    setStatus(selectedOptions);
  };
  const handleFormSubmit = () => {
    let text = status;
    let data = [];
    if (text["value"] !== null) {
      data["status"] = text["value"];

      Incidence.loadupdate(props.data.id, data);
    }
  };

  useEffect(() => {
    const currentstatus =
    { value: props.data.status, label: props.data.status.toUpperCase()  }
    ;
    setStatus(currentstatus)

  },[props.data.status]);

  return (
    <>
      <div className="status-box p-3">
        <label htmlFor="bnfxincidencestatus" className="mt-0">
          {" "}
          {t("status")} :
        </label>
        <Select
          id="bnfxincidencestatus"
          className="form-control"
          name="status"
          options={incidencestatusoptions}
          value={status}
          onChange={handleStatusChange}
        ></Select>

        <button
          type="submit"
          value="Save"
          className="btn btn-primary mt-3"
          onClick={(e) => handleFormSubmit()}
        >
          {t("name.save")}
        </button>
        <button type="button" className="btn text-white mt-3" onClick={(editorclass) => Incidence.displayback('status-box')}>{t("cancel")}</button>
      </div>
    </>
  );
}

export default IncidenceStatusBox;
