import { SpiderScript } from "../SpiderScript";

function SpiderListLayout() {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <>
      <aside className="SpiderAside">
      <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#SpiderStream"
          className="add_new_stream_btn text-white"
          onClick={() => {
            SpiderScript.clearstreamform();
          }}
        >
          {" "}
          <span className="Spider_font20 text-success">
            {" "}
            +
          </span>{" "}
                    </button>
        <div className="row g-0">
        
          <div className="col-6">
            <div
              className="dndnode input"
              onDragStart={(event) => onDragStart(event, "dictionary")}
              draggable
            >
              <div className="card h-100 borderRB dictionary dndnode input">
                <div className="card-body d-flex flex-column justify-content-center ">
                  <span className="material-symbols-outlined mb-2">
                    menu_book
                  </span>
                  <p className="m-0 Spider_font16">Dictionary</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-6">
            <div
              className="dndnode input"
              onDragStart={(event) => onDragStart(event, "filter")}
              draggable
            >
              <div className="card h-100 borderB filter">
                <div className="card-body d-flex flex-column justify-content-center">
                  <span className="material-symbols-outlined mb-2">
                    filter_alt
                  </span>
                  <p className="m-0 Spider_font16">Filter</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="dndnode input"
              onDragStart={(event) => onDragStart(event, "transformation")}
              draggable
            >
              <div className="card h-100 borderRB transformation">
                <div className="card-body d-flex flex-column justify-content-center">
                  <span className="material-symbols-outlined mb-2">
                    settings_timelapse
                  </span>
                  <p className="m-0 Spider_font16">Transformations</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="dndnode input"
              onDragStart={(event) => onDragStart(event, "detection")}
              draggable
            >
              <div className="card h-100 borderB detection">
                <div className="card-body d-flex flex-column justify-content-center">
                  <span className="material-symbols-outlined mb-2">
                    coronavirus
                  </span>
                  <p className="m-0 Spider_font16">Detection</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div
              className="dndnode input"
              onDragStart={(event) => onDragStart(event, "automation")}
              draggable
            >
              <div className="card h-100 borderRB automation">
                <div className="card-body d-flex flex-column justify-content-center">
                  <span className="material-symbols-outlined mb-2">
                    macro_auto
                  </span>
                  <p className="m-0 Spider_font16">Automation</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="dndnode input"
              onDragStart={(event) => onDragStart(event, "sequence")}
              draggable
            >
              <div className="card h-100 borderB sequence">
                <div className="card-body d-flex flex-column justify-content-center">
                  <span className="material-symbols-outlined mb-2">
                    crossword
                  </span>
                  <p className="m-0 Spider_font16">Sequences</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="dndnode input"
              onDragStart={(event) => onDragStart(event, "correlation")}
              draggable
            >
              <div className="card h-100 borderRB correlation">
                <div className="card-body d-flex flex-column justify-content-center">
                  <span className="material-symbols-outlined mb-2">
                    circles_ext
                  </span>
                  <p className="m-0 Spider_font16">Correlation</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="dndnode input"
              onDragStart={(event) => onDragStart(event, "report")}
              draggable
            >
              <div className="card h-100 borderB report">
                <div className="card-body d-flex flex-column justify-content-center">
                  <span className="material-symbols-outlined mb-2">
                    summarize
                  </span>
                  <p className="m-0 Spider_font16">Reports</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-6">
            <div
              className="dndnode input"
              onDragStart={(event) => onDragStart(event, "fragment")}
              draggable
            >
              <div className="card h-100 borderRB fragment">
                <div className="card-body d-flex flex-column justify-content-center">
                  <span className="material-symbols-outlined mb-2">data_table</span>
                  <p className="m-0 Spider_font16">Fragments</p>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-6">
            <div
              className="dndnode input"
              onDragStart={(event) => onDragStart(event, "stylus_note")}
              draggable
            >
              <div className="card h-100 borderB stylus_note">
                <div className="card-body d-flex flex-column justify-content-center">
                  <span className="material-symbols-outlined mb-2">
                    stylus_note
                  </span>
                  <p className="m-0 Spider_font16">Notes</p>
                </div>
              </div>
            </div>
          </div> */}
          
        </div>
        
      </aside>
    </>
  );
}

export default SpiderListLayout;
