import { useEffect } from "react";

function RedisTemplate(props) {
    let output=props.output;

    useEffect(() => {
        if (props.callback) {
          props.callback();
        }
      });
   
  return (
    <>
      <div className="col-sm-2">
        <div className="HealthCardsInner">
          <span className="HealthCardsSmTitle">Total Keys</span>
          <p className="HealthCardsLgTitle HColor1">{output.totalKeys}</p>
        </div>
      </div>
      <div className="col-sm-2">
        <div className="HealthCardsInner">
          <span className="HealthCardsSmTitle">Status </span>
          <p className="HealthCardsLgTitle HColor2">{output.status}</p>
        </div>
      </div>
      <div className="col-sm-2">
        <div className="HealthCardsInner">
          <span className="HealthCardsSmTitle">Memory </span>
          <p className="HealthCardsLgTitle HColor3">{output.memory}</p>
        </div>
      </div>
    </>
  );
}

export default RedisTemplate;
