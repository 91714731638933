import {  useEffect, useState } from "react";
import Select from "react-select";
import { Incidence } from "./IncidenceScript";
import { t } from "i18next";

function IncidenceCriticalityBox(props) {


    const [criticality, setcriticality] = useState("");



    const handlecriticalityChange = (selectedOptions) => {
        setcriticality(selectedOptions);

    };
    const handleFormSubmit = () => {

        let text = criticality
        let data = []
        if (text['value'] !== null) {
            data['criticality'] = text['value']


            Incidence.loadupdate(props.data.id, data)
        }

    };
    const criticalityoptions = [
        { value: 'HIGH', label: 'HIGH' },
        { value: 'MEDIUM', label: 'MEDIUM' },
        { value: 'LOW', label: 'LOW' }
    ];
    useEffect(() => {
        const currentcriticality =
        { value: props.data.criticality, label: props.data.criticality }
        ;
        setcriticality(currentcriticality)

      },[props.data.criticality]);

    return (
        <>
            <div className="criticality-box p-3">
                <label htmlFor="updatecriticality" className="mt-0"> {t("create.incidence.criticality")} : </label>
                <Select id="updatecriticality" name="" className="form-control" options={criticalityoptions} value={criticality} onChange={handlecriticalityChange} >
                </Select>
                <button type="submit" value="Save" className="btn btn-primary mt-3" onClick={(e) => handleFormSubmit()}>
                    {t("name.save")}
                </button>
                <button type="button" className="btn text-white mt-3" onClick={(editorclass) => Incidence.displayback('criticality-box')}>{t("cancel")}</button>
            </div>

        </>
    );
}

export default IncidenceCriticalityBox;