import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import { Incidence } from "../IncidencePanel/IncidenceScript";

function EntityDisplayPanel(props) {
  const [activeTab, setActiveTab] = useState(props.tab1);

  useEffect(() => {
    props.onClickFn(activeTab, props.row);
    if (props.callback) {
      props.callback();
    }
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const addclossbutton = () => {
    let addshowclass = document.getElementById("bxOffcanvasPanel");
    addshowclass.classList.remove("show");
  };
  return (
    <>
      <div className="offcanvas-header p-0 text-white " id="panelheader">
        <div className="bxSEDHeadDashboard entitypanel">
          <div className="bxOutliersCard" id="bnfxhrdetails_sec">
            <div className="text-end float-end criticalColorBox">
              <h2 className="scoreValue m-0">{ CommonTemplate.formatNumber(props.total)}</h2>
              <small
                className={CommonTemplate.criticalityColor(props.criticality)}
              >
                {props.criticality}
              </small>
            </div>
            <div className="entityUser_list_box">
              <div className="d-flex align-items-center">
                <h4 className="BXf-18 text-white m-0 d-flex align-items-center">
                  {props?.hrdetails[0] !== undefined ? (
                    <div className="entityStatus pe-2 me-2">
                      <small className="px-3">
                        {props.hrdetails[0]?.value}
                      </small>
                    </div>
                  ) : null}{" "}
                  {props.name?.charAt(0).toUpperCase() + props.name?.slice(1)}
                </h4>
                {props?.hrdetails[0] !== undefined ? (
                  <button
                    className="material-symbols-outlined arrowDown mx-3"
                    onClick={() => {
                      Incidence.panelloadetails();
                    }}
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvashrdata"
                    aria-controls="offcanvasTop"
                  >
                    navigate_next
                  </button>
                ) : null}
                <span
                    className="custom_BageStrem incidentclass ms-2"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#bxOffcanvasIncidence"
                  >
                    {" "}
                    <div
                      id={
                        "bnfxincidentid" +
                        props.row.entitytype +
                        props.row.entity
                      }
                      onClick={(e) => {
                        Incidence.loadmetadata(props.row.entitytype +
                          props.row.entity ,"fromentitypanel",props.row);
                        Incidence.showClass();CommonTemplate.removePanel();
                      }}
                    >
                      {" "}
                    </div>
                    {Incidence.getentityincidentid(
                     props.row,
                      "bnfxincidentid"
                    )}
                  </span>
              </div>
              {props?.hrdetails[1] !== undefined ? (
                <ul className="entityUser_list d-flex align-items-center mb-0 mt-2 p-0">
                  <li className="BXf-14">
                    <span className="material-symbols-outlined me-2">
                      badge
                    </span>
                    <p className="m-0" title={props.hrdetails[1]?.value}>
                      {props.hrdetails[1]?.value}
                    </p>
                  </li>
                  <li className="BXf-14">
                    <span className="material-symbols-outlined me-2">
                      business_center
                    </span>
                    <p className="m-0" title={props.hrdetails[3]?.value}>
                      {props.hrdetails[3]?.value}
                    </p>
                  </li>
                  <li className="BXf-14">
                    <span className="material-symbols-outlined me-2">
                      source
                    </span>
                    <p className="m-0" title={props.hrdetails[2]?.value}>
                      {props.hrdetails[2]?.value}
                    </p>
                  </li>
                  <li className="BXf-14">
                    <span className="material-symbols-outlined me-2">
                      distance
                    </span>
                    <p className="m-0" title={props.hrdetails[4]?.value}>
                      {props.hrdetails[4]?.value}
                    </p>
                  </li>
                </ul>
              ) : (
                <div className="MsgNoDescription">{t("title.nodescription")}</div>
              )}
            </div>
            <div
              className="offcanvas offcanvas-top h-50"
              id="offcanvashrdata"
              aria-labelledby="offcanvasTopLabel"
              data-bs-backdrop="false"
              tabIndex="-1"
            >
              <button
                className="material-symbols-outlined arrowDown mx-3"
                data-bs-dismiss="offcanvas"
              >
                navigate_next
              </button>

              <div className="UserDetails_Sec ">
                <div className="d-flex justify-content-between">
                  <h5>
                    {props.name?.charAt(0).toUpperCase() + props.name?.slice(1)}
                  </h5>
                  {props?.hrdetails[0] !== undefined ? (
                    <div className="entityStatus">
                      <small className="px-3">
                        {props.hrdetails[0]?.value}
                      </small>
                    </div>
                  ) : null}
                </div>
                <ul className="bxOC_list_new mb-0 p-0">
                  {props?.filteredData?.map((attr) => (
                    <li className="BXf-14" key={attr.label}>
                      <span className="bxEventKey">{attr.label}:</span>
                      <span className="bxEventValue">{attr.value}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas-body p-1">
        <div className="bfxPanelGraph" id="entitytrendgraph"></div>

        <div className=" text-end float-end custom-mts">
          {activeTab === props.tab1 ? (
            <>
              <div id={props.tab1 + "Pagination"}></div>
            </>
          ) : (
            <>
              <div id={props.tab2 + "Pagination"}></div>
            </>
          )}
        </div>
        <ul className="nav nav-tabs p-0" id="myTab" role="tablist">
          <li className="nav-item " role="presentation">
            <button
              className="nav-link active"
              id="detections-tab"
              data-bs-toggle="tab"
              data-bs-target="#detections-tab-pane"
              type="button"
              role="tab"
              aria-controls="detections-tab-pane"
              aria-selected="false"
              onClick={() => {
                handleTabClick(props.tab1);
              }}
            >
              {props.tab1}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="events-tab"
              data-bs-toggle="tab"
              data-bs-target="#events-tab-pane"
              type="button"
              role="tab"
              aria-controls="events-tab-pane"
              aria-selected="false"
              onClick={() => {
                handleTabClick(props.tab2);
              }}
            >
              {props.tab2}
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          {activeTab === props.tab1 ? (
            <>
              <div id={props.tab1 + "list"}></div>
            </>
          ) : (
            <>
              <div id={props.tab2 + "list"}></div>
            </>
          )}
        </div>
      </div>
      <div className="offcanvas-footer bg_canvas p-2 text-end">
        <span
          type="button"
          className="text-white me-3"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            addclossbutton();
            Incidence.removeClass();
          }}
        >
          {t("close")}{" "}
        </span>
      </div>
    </>
  );
}

export default EntityDisplayPanel;
