import React from "react";
import { CommonTemplate } from "./containerAvailability/CommonScript";
import { t } from "i18next";

function DownloadModal() {
  return (
    <>
      <div
        className="modal bxPopup fade"
        id="bxDownloadModal"
        tabIndex="-1"
        aria-labelledby="bxFilterTop"
        aria-hidden="true"
        data-bs-backdrop="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="filterHead modal-header">
              <p className="downloadresult">{t("results")}</p>
              <div id="bnfxdownloadedpath">
              
            </div>
              <button
                type="button"
                className="material-symbols-outlined bxClose"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {t("close")}
              </button>
            </div>
            <div id="bnfxprefixform">
              <div className="filterBody modal-body px-3 p-0">
                <div className="form-group mt-3">
                  <label htmlFor="prefixInput" className="mb-2">
                    {t("download.modal.haeding")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="prefixInput"
                    autoComplete="off"
                    placeholder="Please enter file name not more than 20 letters"
                    required
                    maxLength="30"
                    rows="1"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={CommonTemplate.downloadcontent}
                >
                 {t("name.save")}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={CommonTemplate.clearfillupdetails}
          
                >
                  {("cancel")}
                </button>
              </div>
            </div>
            
          </div>
         
        </div>
      </div>
    </>
  );
}

export default DownloadModal;
