import { useLayoutEffect } from "react";
import Search from "../../../components/search/Search";
import { Dashboard } from "../DashboardScript";
import { t } from "i18next";

function DashboardListLayout() {

  useLayoutEffect(() => {

    const searchbar = document.getElementById("enabled_dashboard_search")
    if (searchbar) {
      searchbar.addEventListener("keydown", function () {
        Dashboard.search("enabled_dashboard_search",null);
      });
      searchbar.addEventListener("input", function () {
        if (searchbar.value === "") {
          document.getElementById("enabled_dashboard_search").value = null;
          Dashboard.loadEnabledDashboardLayout(0);
        }
      });
    }

  });
  return (
    <>
      <div className="bxStreamLeftSideBar">
        <div className="bxStreamLeftSideBar_searchArea p-2">
          <form className="sidebar-search">
            <Search
              id="enabled_dashboard_search"
              defaultValue=""
            />
          </form>
        </div>

        <div className="bxDList_box" id="bnfxEnabledDashboardList">
          {/* here dashboardlistitem replace */}
        </div>

        <nav
          className="bxPagination float-star d-flex align-items-center"
          aria-label="Page navigation example"
        >
          <span className="ms-2">
            {t("enabled.stream.total")}
            <b className="text-white ms-2 me-4 bnfxEnabledDashboardTotal">
              {/* here we will replace total number of streams */}
            </b>
          </span>
          <div id="bnfxEnabledDashboardPagination">
            {/* here we will replace pagination */}
          </div>
        </nav>

        <div className="bxFloatingAddSteramBtn">
          <a
            title="Add Dashboard"
            data-bs-toggle="offcanvas"
            data-bs-target="#bnfxAddNewDashboard"
            aria-controls="offcanvasRight"
            href="#streamList"
            className="action-icon"
            onClick={() => {
              Dashboard.loadAvailableDashboardLayout(0);
            }}
          >
            <span className="material-symbols-outlined">
            add
            </span>
          </a>
        </div>
      </div>
    </>
  );
}
export default DashboardListLayout;
