import { useEffect, useRef } from "react";
import "../spider/spider.css";
import AddNewStream from "./list/AddNewStream";

import { SpiderScript } from "./SpiderScript";
import "../terminal/TerminalPage.css";
import { Navigation } from "../../components/common/mainHeader/leftHeader/Navigation";
import { useLocation } from "react-router";

function SpiderPage() {
  const pageloaded = useRef(false);

  useEffect(() => {
    if (pageloaded.current) {
      return;
    }
    SpiderScript.init();
    pageloaded.current = true;
  }, []);

  const location = useLocation();
 useEffect(() => {
 Navigation.onnavigate(location.pathname);
 }, [location.pathname]);

  return (
    <>
      <div className="Spider_container">
        <div id="bnfxspiderHeader"></div>
        <div className="row">
          <div className="col-12" id="spiderdetailsection">
            {/* <DragElements /> component render */}
          </div>
        </div>
      </div>

      <div className="modal fade" id="SpiderStream" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> 
        <div className="modal-dialog  modal-dialog-centered">
          <AddNewStream />
        </div>
      </div>
    </>
  );
}

export default SpiderPage;
