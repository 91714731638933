import {  useEffect, useState } from "react";
import Select from "react-select";
import { Incidence } from "./IncidenceScript";
import { t } from "i18next";
import { useGlobalState } from "../GlobalVariables";

function IncidenceWorflowBox(props) {
    
    const [worflow, setworflow] = useState("");
    const [incidenceworflowoptions] = useGlobalState("WorkFlowOptions");
    const handleworflowChange = (selectedOptions) => {
        setworflow(selectedOptions);

    };
    const handleFormSubmit = () => {

        let text = worflow
        let data = []
        if (text['value'] !== null) {
            data['workflowid'] = text['value']
            Incidence.loadupdate(props.data.id, data)
        }

    };

    useEffect(() => {
        const currentworkflow =
        { value: props.data.workflow, label: props.data.workflowname  }
        ;
        setworflow(currentworkflow)
    
      },[props.data.worflow]);
    
    return (
        <>
            <div className="worflow-box p-3">
                <label htmlFor="updateworflow" className="mt-0"> {t("create.incidence.worflow")} : </label>
                <Select id="updateworflow" name="" className="form-control" options={incidenceworflowoptions} value={worflow} onChange={handleworflowChange} >
                </Select>
                <button type="submit" value="Save" className="btn btn-primary mt-3" onClick={(e) => handleFormSubmit()}>
                    {t("name.save")}
                </button>
                <button type="button" className="btn text-white mt-3" onClick={(editorclass) => Incidence.displayback('worflow-box')}>{t("cancel")}</button>
            </div>

        </>
    );
}
export default IncidenceWorflowBox;