import { t } from "i18next";

export const showError = (error) => {

  let message = error.response == null ? error : error.response.data.error;


  if (error.response) {
    const errorcode = error.response.data.errorcode;
    if (errorcode != null) {
      const lowerCaseErrorCode = errorcode.toLowerCase();
      message = t("error_" + lowerCaseErrorCode);
    }
  }
  console.log(message)

  const popUp = document.querySelector(".bxMessageApply");
  popUp.innerHTML = null
    ;
  popUp.classList.remove("d-none");

  setTimeout(() => {
    popUp.classList.add("d-none");
  }, 5000);
};