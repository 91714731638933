import React, { useEffect, useRef } from "react";
import { BadgePanelScript } from "./BadgePanelScript";
import MultiInput from "../MultiInput";
import { t } from "i18next";

function EntityConponent(props) {
  let result = props?.result;
  let type = props?.type;

  const entityTypeInputRef = useRef(null);
  useEffect(() => {
    if (result?.entitytype || BadgePanelScript.entitytype) {
      entityTypeInputRef.current.value =
        (result ? result.entitytype : "") || BadgePanelScript.entitytype;
    }

    BadgePanelScript.clickEntityRadioBtn();
  }, [result]);

  return (
    <>
      <div className="col-6">
        <div className="mb-2">
          <label htmlFor="badgeentitytype" className="form-label">
            {t("entity_type_name")}
          </label>
          <input
            type="text"
            className="form-control"
            id="badgeentitytype"
            placeholder="Please enter Entity Type"
            autoComplete="off"
            name="Entity Type"
            required=""
            // onChange={BadgePanelScript.handleInputChange}
            ref={entityTypeInputRef}
            readOnly={
              BadgePanelScript.entitytype ? BadgePanelScript.entitytype : null
            }
            defaultValue={
              result
                ? result.entitytype
                : BadgePanelScript.entitytype
                ? BadgePanelScript.entitytype
                : null
            }
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-2">
          <label htmlFor="badgeentity" className="form-label">
            {t("entity")}
          </label>
          <div
            type="text"
            className="form-control badgeentityinput"
            id="badgeentity"
          >
            <MultiInput
              entity={
                BadgePanelScript.entity
                  ? BadgePanelScript.entity
                  : result?.entities
              }
              page="badge_Page"
              type={type}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EntityConponent;
