import { useEffect } from "react";
import { DownloadsScript } from "./DownloadsScript";
import "../download/downloads.css";
import DownloadsTab from "./DownloadsTab";
import { t } from "i18next";

function Downloads() {
  useEffect(() => {
    DownloadsScript.init();
  }, []);
  return (
    <>
      {/* Offcanvas header */}
      <div className="offcanvas-header p-2 border-bottom bxCollectorsCard mb-3">
        <h4 className="BXf-18 text-white m-0">
          <span className="material-symbols-outlined me-2">download_2</span>
          {t("download_title")}
        </h4>
      </div>
      {/* Offcanvas body */}
      <div className="offcanvas-body p-0 px-2" id="collectors-tab-content">
        <DownloadsTab />
        {/* rendering atbs and its componenets */}
      </div>
    </>
  );
}

export default Downloads;
