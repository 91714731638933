import React, { useEffect } from 'react';
import DetectionEventLayout from './DetectionEventLayout';
import UserStreamLayout from './UserStreamLayout';
import DatabaseEventLayout from './DatabaseEventLayout';


function DetectionEvent(props) {
  let data = null;

  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  if (props.detectionData) {
    if(props.userInfo === "correlationdata"){
      data =
      props.detectionData?.map((streamObj, index) => (
        <React.Fragment key={index}>
          <UserStreamLayout detectionData={streamObj} searchquery={props.querysearchtext} index={index} streamdetails={props.streamdetails}  />
        </React.Fragment>
      ))
    }else if (props.userInfo === "aggregationdata"){
      data =
      props.detectionData?.result?.map((streamObj, index) => (
      <React.Fragment key={index}>
          <DatabaseEventLayout data={streamObj} searchquery={props.querysearchtext} index={index} streamdetails={props.streamdetails}  />
        </React.Fragment>
      ))
    }else{
      data =
      props.detectionData?.map((streamObj, index) => (
        <React.Fragment key={index}>
          <DetectionEventLayout detectionData={streamObj} searchquery={props.querysearchtext} index={index} streamdetails={props.streamdetails} />
        </React.Fragment>
      ))
    }
    
  }

  return (
    <>

      <div className="bnfxDectionsEvents mt-3" id={"bnfxDectionsEvents" + props.id}>
        {data}
      </div>
    </>
  );
}

export default DetectionEvent;
