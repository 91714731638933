import { useEffect } from "react";
import StreamListItem from "./StreamListItem";

function StreamListData(props) {
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <>
      {props.streams.map((streamObj, i) => (
        <StreamListItem
          key={streamObj?._id}
          stream={streamObj}
          enabled={props?.enabled}
        />
      ))}
    </>
  );
}

export default StreamListData;
