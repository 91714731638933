import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "@codemirror/view";
import { python } from "@codemirror/lang-python";
import { useCallback, useLayoutEffect } from "react";
import { t } from "i18next";
import { Terminal } from "./TerminalScript";

function TerminalOutput(props) {

    const onChangeSql = useCallback((value, viewUpdate) => {
        Terminal.content = value;
    }, []);
    useLayoutEffect(() => {
        const container = document.querySelector(".terminal_box")
        container.scrollTo(0, container.scrollHeight)
    });

    let displayOutput = null;
    let code = false;
    if (props.error) {
        displayOutput = '<p>'+props.command +'</p> <span style="color: #D05E50;">' + props.error + '</span>'
    } else {
        if (props.output.message) {
            displayOutput = props.output?.message;
        } else {
            let contentype = props.output?.path?.indexOf(".properties") !== -1 ? 'properties' : 'python';
            code = true;
            displayOutput =
                <><p>{props?.command} </p>
                    <div className="d-flex align-items-center justify-content-between bnfx_Terminal_head">
                    <p>{props?.output?.name} </p>
                        <div className="bnfxsaveconfirmation" id={"bnfxSearchSavedResponse"+props.output.contenttype}></div>
                        {props.output.mode === 'edit' && <span className="save_terminal" onClick={() => Terminal.saveAction(Terminal.content, props.output)}>{t("name.save")}</span>} </div>
                    <CodeMirror className={"bnfx_terminal_codemirror " + contentype}
                        id="bnfx_terminal_codemirror"
                        value={props.output?.content}
                        basicSetup={{
                            lineNumbers: true,
                            highlightActiveLineGutter: false,
                            highlightActiveLine: false,
                            lineWrapping: true
                        }}
                        editable={props.output.mode === 'edit'}
                        height="100%"
                        style={{ tabSize: 10 }}
                        theme={"dark"}
                        extensions={[python({}), EditorView.lineWrapping]}
                        onChange={onChangeSql}
                    />


                </>
        }
    }

    return (

        <>
            {code ? displayOutput : <div className="bnfx_terminal_output_initial" dangerouslySetInnerHTML={{ __html: displayOutput }} />}
            {props.firsttime && <div className='bnfx_terminal_output_append'></div>}
        </>
    );
}
export default TerminalOutput;