import React, { useEffect } from "react";
import { t } from "i18next";
import { Incidence } from "../IncidencePanel/IncidenceScript";

function DashLeftSidePanel(props) {
  const name = props.result;
  const label=props.label

  const addclossbutton = () => {
    let addshowclass = document.getElementById("bxOffcanvasPanel");
    addshowclass.classList.remove("show");
  };
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  },[]);

  return (
    <>
      <div className="offcanvas-header p-0 text-white " id="panelheader">
        <div className="bxSEDHeadDashboard">
          <div className="text-end float-end"></div>
          <div className="bxOutliersCard">
            <h4 className="BXf-18 text-white">
              {label} : 
              <span className="ms-2">
                {name}
              </span>
            </h4>
          </div>
        </div>
      </div>
      <div className="offcanvas-body p-1">
        <div className="bfxPanelGraph" id="bnfxtrendgraph"></div>

        <div className=" text-end float-end custom-mts">
          <div id={"timelineeventsPagination"}></div>
        </div>
        <ul className="nav nav-tabs p-0" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="events-tab"
              data-bs-toggle="tab"
              data-bs-target="#events-timeline-tab-pane"
              type="button"
              role="tab"
              aria-controls="events-timeline-tab-pane"
              aria-selected="false"
            >
             {t("events.tab")}
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div id={"bnfxtimelineTab"}></div>
        </div>
      </div>
      <div className="offcanvas-footer bg_canvas p-2 text-end">
        <span
          type="button"
          className="text-white me-3"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            addclossbutton();
            Incidence.removeClass();
          }}
        >
          {t("close")}{" "}
        </span>
      </div>
    </>
  );
}

export default DashLeftSidePanel;
