function Required(props) {
    return (
      <>
        <small className="d-block text-end mb-3 mt-2">
                  {props.children}
                </small>
      </>
    );
  }
  
  export default Required;
  