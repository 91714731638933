import React, { useEffect } from "react";
import SearchEventLayout from "./SearchEventLayout";

function SearchEvent(props) {
  let data = null;
  let columns = Array.from(new Set(props.searchData.flatMap(Object.keys)));
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  if (props.searchData) {
    if (!props.aggregated) {
      data = props.searchData.map((datalakeObj, index) => (
        <React.Fragment key={index}>
          <SearchEventLayout
            searchData={datalakeObj}
            querytype={props.querytype}
            tablename={props.tablename}
          />
        </React.Fragment>
      ));
    } else {
      data = (
        <table className="table table-dark">
          <thead>
            <tr>
              {columns.map((colname, index) => (
                <th key={index}>{colname}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.searchData.map((datalakeObj, index) => (
              <tr key={index}>
                {columns.map((key, colIndex) => (
                  <td key={colIndex}>{datalakeObj[key] || ""}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  }

  return (
    <>
      <div className="bnfxDectionsEvents mt-3">{data}</div>
    </>
  );
}

export default SearchEvent;
