import React, { memo, useCallback } from "react";
import { useReactFlow } from "reactflow";
import { Handle, Position } from "reactflow";
import { SpiderScript } from "../SpiderScript";
// import { getGlobalState } from "../../../components/common/GlobalVariables";

const DataSource = memo(({ data, isConnectable }) => {
  let id = data.value;
  const { deleteElements } = useReactFlow();

  const removeNode = useCallback((e) => {
    // let nodes = getGlobalState("spidernodeslist");

    
    SpiderScript.deleteStreamConfirmation();
    deleteElements({ nodes: [{ id }] });
    e.stopPropagation();

  }, [id, deleteElements]);
  return (
    <>
      <div className="Hover_delete_button">
        {data.icon[0] ? (
          <b
            className="material-symbols-outlined Spider_Delete_items"
            onClick={(e) => removeNode(e)}
          >
            delete
          </b>
        ) : null}
        <div className="card spiderBox_area nodraft">
          <div className="card-body d-flex align-items-center p-0 justify-content-between">
            {data.icon ? (
              <div className="StreamLogo me-2">
                <span
                  className={
                    "bxIcon_link " +
                    (data.icon[0] && data.icon[0].toLowerCase() !== "n/a"
                      ? data.icon[0].toLowerCase()
                      : "noicon")
                  }
                >
                  {data.label?.charAt(0)}
                </span>
              </div>
            ) : null}
            <div>
              <p className="m-0 Spider_font16 text-white" title={data.label}>
                {data.label}
              </p>
              <p className="m-0 Spider_font14" title={data.value}>
                {data.value}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="c"
        style={{ bottom: -10, right: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
      {data.icon[1] === "stream" ? null : (
        <Handle
          type="target"
          position={Position.Top}
          id="d"
          style={{ top: -10, left: "50%", background: "#fff" }}
          isConnectable={isConnectable}
        />
      )}
    </>
  );
});

export default DataSource;
