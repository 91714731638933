function Logo(props) {
  return (
    <>
      <a href="/stream" className="order-md-1 navbar-brand" >
        <img src="../images/logo_ironman.png" alt="Binaryflux" height="35"/>
      </a>
    </>
  );
}

export default Logo;
