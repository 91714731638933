import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import {
  ADD_YOUR_CODE,
  ADD_YOUR_TEST_EVENT,
  DATA_EXTRACTION_FROM_CODE,
  STREAMS_DROPDOWN_OPTIONS
} from "./contentConstants";

export const CONTENT_CONSTANTS = {
  RESET: "RESET",
  ON_PANEL_CLICK: "ON_PANEL_CLICK",
  SET_CONTENT_ITEM_LIST: "SET_CONTENT_ITEM_LIST",
  SET_CONTENT_ITEM_LIST_OFFSET: "SET_CONTENT_ITEM_LIST_OFFSET",
  ON_OPEN_CREATE_OR_EDIT: "ON_OPEN_CREATE_OR_EDIT",
  ON_CLOSE_CREATE_OR_EDIT: "ON_CLOSE_CREATE_OR_EDIT",
  ON_CREATE_OR_EDIT_FIELDS: "ON_CREATE_OR_EDIT_FIELDS",
  POPULATE_AUTO_COMPLETE_FIELDS: "POPULATE_AUTO_COMPLETE_FIELDS",
  LOADING_MORE_CONTENT: "LOADING_MORE_CONTENT",
  ON_UPDATE_NON_STREAM_FORM: "ON_UPDATE_NON_STREAM_FORM",
  ON_UPDATE_SECRET_OR_VARIABLE_FORM: "ON_UPDATE_SECRET_OR_VARIABLE_FORM",
  UPDATE_CONTENT_ITEM_SEARCH_TEXT: "UPDATE_CONTENT_ITEM_SEARCH_TEXT",
  SET_TENANTS_LIST: "SET_TENANTS_LIST",
  ON_CODE_GENERATOR_PANEL_CLICK: "ON_CODE_GENERATOR_PANEL_CLICK",
  SET_CODE_GENERATOR_INPUTS: "SET_CODE_GENERATOR_INPUTS",
  SET_CODE_GENERATOR_FIELDS: "SET_CODE_GENERATOR_FIELDS",
  SET_SAVE_FORM_LOADER: "SET_SAVE_FORM_LOADER",
  SET_CODE_GENERATOR_LOADER: "SET_CODE_GENERATOR_LOADER",
  ON_TEST_CODE_FORM_CHANGE: "ON_TEST_CODE_FORM_CHANGE",
  SET_TEST_CODE_LOADER: "SET_TEST_CODE_LOADER",
  ON_UPDATE_STREAM_FORM: "ON_UPDATE_STREAM_FORM",
  SET_CODE_LAYOUT_TAB: "SET_CODE_LAYOUT_TAB",
  ON_CREATE_OR_EDIT_SECRET_OR_VARIABLES_FIELDS:
    "ON_CREATE_OR_EDIT_SECRET_OR_VARIABLES_FIELDS",
  SET_FORM_ERRORS: "SET_FORM_ERRORS"
};

const STREAM_FORM_FIELDS = {
  title: "",
  description: "",
  provider: "",
  type: "",
  group: "",
  path: "",
  aggregations: [],
  dictionaries: [],
  filters: [],
  parsers: [],
  transformations: [],
  correlations: [],
  detections: [],
  sequences: [],
  automations: [],
  reports: []
};

const NON_STREAM_FORM_FIELDS = {
  title: "",
  description: "",
  content: ADD_YOUR_CODE,
  contenttype: "",
  path: "",
  codetested: false,
  activeTab: "event-tab",
  testEvent: ADD_YOUR_TEST_EVENT
};

const SECRET_OR_VARIABLES_FORM_FIELDS = {
  key: "",
  value: "",
  source: "",
  tenant: "",
  contenttype: ""
};

const AUTO_COMPLETE_FIELDS = {
  aggregations: [],
  dictionaries: [],
  filters: [],
  parsers: [],
  transformations: [],
  correlations: [],
  detections: [],
  sequences: [],
  automations: []
};

const TEST_CODE_FORM_FIELDS = {
  testEvent: "",
  output: ""
};

export const initialState = {
  selectedLeftPanelItemName: "streams",
  terminalName: "stream",
  isLoading: false,
  contentItemList: [],
  contentItemOffset: 0,
  contentItemTotal: 0,
  contentItemSearchText: "",
  isCreateOrEdit: false,
  nonStreamFields: { ...NON_STREAM_FORM_FIELDS },
  streamFields: { ...STREAM_FORM_FIELDS },
  offset: 0,
  autocomplete: { ...AUTO_COMPLETE_FIELDS },
  isCodeGeneratorPanelOpen: false,
  codeGeneratorInputs: {},
  codeGeneratorFormFields: {},
  isCodeGeneratorLoading: false,
  testCodeFormValues: { ...TEST_CODE_FORM_FIELDS },
  isTestCodeLoading: false,
  secretsOrVariablesFields: { ...SECRET_OR_VARIABLES_FORM_FIELDS },
  errors: {}
};

export const contentReducer = (state, action) => {
  switch (action.type) {
    case CONTENT_CONSTANTS.RESET:
      return {
        ...initialState
      };

    case CONTENT_CONSTANTS.ON_PANEL_CLICK:
      return {
        ...state,
        ...action.data,
        offset: 0,
        contentItemList: [],
        contentItemOffset: 0,
        contentItemTotal: 0,
        contentItemSearchText: "",
        isLoading: true,
        isCreateOrEdit: false,
        streamFields: { ...STREAM_FORM_FIELDS },
        nonStreamFields: { ...NON_STREAM_FORM_FIELDS },
        isCodeGeneratorPanelOpen: false,
        codeGeneratorInputs: {},
        codeGeneratorFormFields: {},
        isCodeGeneratorLoading: false,
        testCodeFormValues: { ...TEST_CODE_FORM_FIELDS },
        isTestCodeLoading: false,
        errors: {}
      };

    case CONTENT_CONSTANTS.SET_CONTENT_ITEM_LIST:
      return {
        ...state,
        ...action.data,
        isLoading: false
      };
    case CONTENT_CONSTANTS.SET_CONTENT_ITEM_LIST_OFFSET:
      return {
        ...state,
        contentItemOffset: action.data
      };
    case CONTENT_CONSTANTS.UPDATE_CONTENT_ITEM_SEARCH_TEXT:
      return {
        ...state,
        contentItemSearchText: action.data
      };
    case CONTENT_CONSTANTS.ON_OPEN_CREATE_OR_EDIT:
      return {
        ...state,
        isCreateOrEdit: true,
        isLoading: true,
        nonStreamFields: { ...NON_STREAM_FORM_FIELDS },
        secretsOrVariablesFields: { ...SECRET_OR_VARIABLES_FORM_FIELDS }
      };
    case CONTENT_CONSTANTS.ON_CLOSE_CREATE_OR_EDIT:
      return {
        ...state,
        isCreateOrEdit: action.data,
        nonStreamFields: { ...NON_STREAM_FORM_FIELDS },
        streamFields: { ...STREAM_FORM_FIELDS },
        codeGeneratorInputs: {},
        codeGeneratorFormFields: {},
        isCodeGeneratorLoading: false
      };
    case CONTENT_CONSTANTS.ON_CREATE_OR_EDIT_FIELDS:
      let fields = {};
      let tenant =
        state.tenantsList && state.tenantsList.length > 0
          ? state.tenantsList[0].value
          : "";

      // set default tab to event-tab
      if (action.data.fieldData) {
        action.data.fieldData.testEvent =
          action.data.fieldData.testEvent || ADD_YOUR_TEST_EVENT;
        action.data.fieldData.activeTab = "event-tab";
      }

      if (action.data.terminalName !== "stream") {
        fields = {
          nonStreamFields: action.data.fieldData || {
            ...NON_STREAM_FORM_FIELDS,
            tenant: tenant,
            contenttype: action.data.terminalName
          }
        };
      } else {
        if (action.data.fieldData) {
          const urlParts = action.data.fieldData["url"]?.match(
            /http:\/\/integration-([\w-]+)-([\w-]+)/
          );
          const source = urlParts ? urlParts[1] : "";
          const type = urlParts ? urlParts[2] : "";

          const urlParams = new URLSearchParams(
            action.data.fieldData["url"]?.split("?")[1]
          );
          const group = urlParams.get("groups");
          fields = {
            streamFields: {
              ...STREAM_FORM_FIELDS,
              ...action.data.fieldData,
              provider: source || STREAM_FORM_FIELDS.provider,
              type: type || STREAM_FORM_FIELDS.type, // Assuming logtype is mapped to `type`
              group: group || STREAM_FORM_FIELDS.group
            }
          };
        } else {
          fields = {
            streamFields: action.data.fieldData || {
              ...STREAM_FORM_FIELDS,
              tenant: tenant,
              contenttype: action.data.terminalName
            }
          };
        }
      }
      return {
        ...state,
        ...fields,
        isLoading: false
      };
    case CONTENT_CONSTANTS.ON_CREATE_OR_EDIT_SECRET_OR_VARIABLES_FIELDS:
      let secretTenant =
        state.tenantsList && state.tenantsList.length > 0
          ? state.tenantsList[0].value
          : "";

      let secretFields = {
        secretsOrVariablesFields: action.data.fieldData || {
          ...SECRET_OR_VARIABLES_FORM_FIELDS,
          tenant: secretTenant,
          contenttype: action.data.terminalName
        }
      };
      return {
        ...state,
        ...secretFields,
        isLoading: false
      };
    case CONTENT_CONSTANTS.POPULATE_AUTO_COMPLETE_FIELDS:
      let autoCompleteValues = {
        [action.data.contentType]: [
          ...(state.autocomplete[action.data.contentType] || []),
          ...action.data.items
        ]
      };

      if (state.offset === 0) {
        autoCompleteValues = {
          [action.data.contentType]: [...action.data.items]
        };
      }

      return {
        ...state,
        autocomplete: {
          ...state.autocomplete,
          ...autoCompleteValues
        }
      };
    case CONTENT_CONSTANTS.LOADING_MORE_CONTENT:
      return {
        ...state,
        offset: action.data
      };
    case CONTENT_CONSTANTS.ON_UPDATE_NON_STREAM_FORM:
      let newNonStreamFields = { ...state.nonStreamFields, ...action.data };
      if (action.data.tenant) {
        // if tenant is changed reset the selected values from dropdown
        let fieldsToClearObj =
          DATA_EXTRACTION_FROM_CODE[state.nonStreamFields.contenttype];
        if (
          fieldsToClearObj &&
          fieldsToClearObj.loadData &&
          fieldsToClearObj.loadData.length > 0
        ) {
          fieldsToClearObj.loadData.forEach((field) => {
            newNonStreamFields[field.fieldToUpdateName] = [];
          });
        }
      }
      return {
        ...state,
        nonStreamFields: newNonStreamFields,
        isCodeGeneratorLoading: false
      };
    case CONTENT_CONSTANTS.ON_UPDATE_SECRET_OR_VARIABLE_FORM:
      return {
        ...state,
        secretsOrVariablesFields: {
          ...state.secretsOrVariablesFields,
          ...action.data
        }
      };
    case CONTENT_CONSTANTS.SET_TENANTS_LIST:
      return {
        ...state,
        tenantsList: action.data
      };

    case CONTENT_CONSTANTS.ON_CODE_GENERATOR_PANEL_CLICK:
      let codeContent = {};
      if (!action.data) {
        codeContent = {
          codeGeneratorInputs: {},
          codeGeneratorFormFields: {}
        };
      } else {
        codeContent = { isCodeGeneratorLoading: true };
      }
      return {
        ...state,
        isCodeGeneratorPanelOpen: action.data,
        ...codeContent
      };
    case CONTENT_CONSTANTS.SET_CODE_GENERATOR_INPUTS:
      return {
        ...state,
        codeGeneratorInputs: action.data,
        isCodeGeneratorLoading: false
      };
    case CONTENT_CONSTANTS.SET_CODE_GENERATOR_FIELDS:
      return {
        ...state,
        codeGeneratorFormFields: {
          ...state.codeGeneratorFormFields,
          ...action.data
        },
        isCodeGeneratorLoading: false
      };
    case CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER:
      return {
        ...state,
        isLoading: action.data
      };
    case CONTENT_CONSTANTS.SET_CODE_GENERATOR_LOADER:
      return {
        ...state,
        isCodeGeneratorLoading: action.data
      };
    case CONTENT_CONSTANTS.ON_TEST_CODE_FORM_CHANGE:
      return {
        ...state,
        testCodeFormValues: { ...state.testCodeFormValues, ...action.data }
      };
    case CONTENT_CONSTANTS.ON_UPDATE_STREAM_FORM:
      let newStreamFields = { ...state.streamFields, ...action.data };
        if (action.data.tenant) {
          // if tenant is changed reset the selected values from dropdown
          STREAMS_DROPDOWN_OPTIONS.forEach((dropdownOption) => {
            newStreamFields[dropdownOption.name] = [];
          });
        }
        return {
          ...state,
          streamFields: newStreamFields
        };
    
    case CONTENT_CONSTANTS.SET_FORM_ERRORS:
      return {
        ...state,
        errors: action.data
      };
    case CONTENT_CONSTANTS.SET_CODE_LAYOUT_TAB:
      return {
        ...state,
        nonStreamFields: { ...state.nonStreamFields, activeTab: action.data }
      };
    case CONTENT_CONSTANTS.SET_TEST_CODE_LOADER:
      return {
        ...state,
        isTestCodeLoading: action.data
      };
  }
};
