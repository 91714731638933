import Highcharts from "highcharts";
import React, { useLayoutEffect, useRef } from "react";

const SprialChart = (props) => {
  const chartRef = useRef(null);
  const series = props.series;
  const categories = props.categories;
  const container = props.container;
  const layout=props.layout;
  const height=props.height;
  const onclick_param=props.onclick_param;
  const handleRowClick = (data) => {
    props.applyfilteronClick(props.filterid,data.category);
  };



  
  useLayoutEffect(() => {
    let widgetheight=null
    if (chartRef.current) {
      const parent = container.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );
      if(layout==='conciselayout'){
        widgetheight =
        parseInt(document.getElementById(parent)?.clientHeight) - 98;
      }else{
        widgetheight =
        parseInt(document.getElementById(parent)?.clientHeight) - 120;
      } 
       
      Highcharts.chart(chartRef.current, {
        colors: [
          "#00876c",
          "#3d9c73",
          "#63b179",
          "#88c580",
          "#aed987",
          "#d6ec91",
          "#ffff9d",
          "#fee17e",
          "#fcc267",
          "#f7a258",
          "#ef8250",
          "#e4604e",
          "#d43d51"
        ],
        chart: {
          type: "column",
          inverted: true,
          polar: true,
          height:height ? height +"px": widgetheight+ "px",
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          outside: true,
        },
        pane: {
          size: "85%",
          innerSize: "20%",
          endAngle: 270,
        },
        legend: {
          itemStyle: {
            color: "#fff",
            opacity: 0.5,
          },
        },
        xAxis: {
          tickInterval: 1,
          labels: {
            enabled:false,
            align: "right",
            useHTML: true,
            allowOverlap: false,
            step: 1,
            y: 3,
            style: {
              color: "#ffffff",
              fontSize: "13px",
            },
          },
          lineWidth: 0,
          categories: categories,
        },
        yAxis: {
          crosshair: {
            enabled: true,
            color: "#333",
          },
          lineWidth: 0,
          tickInterval: 25,
          reversedStacks: false,
          endOnTick: true,
          showLastLabel: true,
          labels: {
            enabled: false,  // Disable yAxis labels (counts/numbers)
          },
        },
        plotOptions: {
          column: {
            stacking: "normal",
            borderWidth: 0,
            pointPadding: 0,
            groupPadding: 0.15,
            events: {
              click: function (event) {
                if (onclick_param === 'filter_apply') {
                handleRowClick(event.point);
                }
              }
            }
          },
        },
        series: series,
      });
    }
  }, [series, categories, container,layout,height]);

  return <div ref={chartRef} id={container} />;
};

export default SprialChart;
