import React from 'react';
import { Health } from './HealthScript';

function PostgressTemplate(props) {
  const data = props.data;

  return (
    <>
      {data.map((item, index) => (
        <div key={index} className="col-sm-2">
          <div className="HealthCards_outer">
            <p className="HealthCardsSmTitle text-capitalize mb-2">{item.tablename}</p>
            <div className="HealthCardsInner">
              <span className="HealthCardsSmTitle">Records</span>
              <p className="HealthCardsLgTitle" style={{ color:Health.applyCountColor(index) }}>{item.recordcount}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default PostgressTemplate;
