import React, { useLayoutEffect } from "react";
import Search from "../../../components/search/Search";
import { t } from "i18next";
import { Dashboard } from "../DashboardScript";

function AvailableDashboardLayout() {

  useLayoutEffect(() => {

    const searchbar = document.getElementById("available_dashboard_search")
    if (searchbar) {
      searchbar.addEventListener("keydown", function () {
        Dashboard.search("available_dashboard_search",null);
      });
      searchbar.addEventListener("input", function () {
        if (searchbar.value === "") {
          document.getElementById("available_dashboard_search").value = null;
          Dashboard.loadAvailableDashboardLayout(0);
        }
      });
    }

  });
  return (
    <>
      <div className="offcanvas-body p-0">
        <div className="bxSuggestionTopBar px-3 py-2">
          <form className="sidebar-search">
            <Search
              id="available_dashboard_search"
              defaultValue=""
            />
          </form>
        </div>

        <div className="bxSuggestionArea px-3 ">
          <span id="bnfxAvailableDashboardTotal"></span>
          <span> {t("available.dashboard.total")}</span>
        </div>

        <div className="bxDList_box" id="bnfxAvailableDashboardList">
          {/* we will replace available streamlist */}
        </div>
      </div>
      <div className="offcanvas-footer ">
        <div className="d-flex align-items-center justify-content-between px-3 py-2">
          <div id="bnfxAvailableDashboardPagination">
            {/* here we will replace pagination */}
          </div>
          <div>
            <span
              type="button"
              className="text-white me-3"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t("close")}
            </span>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="bxApplyStream"
              onClick={() => {
                Dashboard.addDashboard();
              }}
            >
              {t("apply.add")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AvailableDashboardLayout;
