import React from "react";


function IncidenceCreateContainer() {

    return (
        <>
            <div className="modal bxPopup bxStaticBackdrop fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content" id="createtincidentform">

                    </div>
                </div>
            </div>
        </>
    );
}
export default IncidenceCreateContainer;
