
import React from "react";
import moment from "moment";
import { Stream } from "../../../pages/stream/StreamScript";
import { flatten } from "flat";

function DashboardEventTimeline(props) {
  let output = props.data
  
  const {
    tags,
    "messageid.set": messageIdSet,
    ...requireDetectionData
  } = output;
  
  const processedOutputData = flatten(requireDetectionData);

  const outputarrafterfive = [];
  const outputarrbeforefive = [];

  var counter = 0;
  var mapkeys = Object?.keys(processedOutputData);

  for (let index = 0; index < mapkeys.length; index++) {
    const key = mapkeys[index];
    // const element = processedOutputData[key];

    if (index > 5) {
      outputarrafterfive.push([key, processedOutputData[key]]);
    } else {
      outputarrbeforefive.push([key, processedOutputData[key]]);
    }
  }

  Object?.keys(processedOutputData)?.map((key) => (counter = counter + 1));

  const detectionDate = moment(
    new Date(processedOutputData.timestamp?processedOutputData.timestamp:processedOutputData.detectiontime)
      ).format("DD  MMM hh:mm:ss");


  return (
    <div className="row">
    <div className="col-12">
      <div className="row bxStreamEventDetection m-0 mb-1 d-flex align-items-center">
        <div className="bxSEDBody col-md-12">
          <p className="timer_box rawEventDateTime mt-1 mb-0">
            {detectionDate}
          </p>
          <div className="bxSEDBodyBox_inner">
            <div className="tags_foot mt-1 mb-2">
              {outputarrbeforefive?.map((attr) => (
                <React.Fragment key={attr[0]}>
                  <>
                    <span className="bxRawEventKey">{attr[0]}</span>
                    <span className="bxEventValue">{attr[1]}</span>
                  </>
                </React.Fragment>
              ))}
            </div>
            <div
              className="ExpandCollapseBtn"
              onClick={e => Stream.loadmoredatacoallapse(e.target)}
              data-bs-toggle="collapse"
              data-bs-target={"#collapsebxJsonText" +output.timestamp}
              aria-expanded="false"
              aria-controls="collapsebxJsonText"
            >
              <span className="material-symbols-outlined text-success">
              expand_more
              </span>
            </div>
          </div>
          <div
            className="bxJsonText collapse"
            id={"collapsebxJsonText" +output.timestamp}
          >
            {outputarrafterfive?.map((attr) => (
              <React.Fragment key={attr[0]}>
                <>
                  <span className="bxRawEventKey">{attr[0]}</span>
                  <span className="bxEventValue">{attr[1]}</span>
                </>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default DashboardEventTimeline;