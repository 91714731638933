import moment from "moment";
import { Incidence } from "../../../components/common/IncidencePanel/IncidenceScript";
import { useEffect } from "react";

function MyqueWidget(props) {
  const data = props.data && props.data.length > 0 ? props.data : null;


  const handleRowClick = (row) => {
    props.onClickFn(row,props.id);
  };
  
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <>
      {data?.map((item, index) => (
        <div className="IncidentMgmtBox px-3 py-1" key={index}>
          <div className="incidentIdSatus_sec">
            <div className="incidentIdSatusSec_left">
              <div className="incidentIdSatus justify-content-start">
                <span
                  className={` badge  ${Incidence.badgecriticalityColor(
                    item.criticality
                  )}`}
                >
                  {item.criticality}
                </span>
                <p className="text-uppercase ms-2 px-2"
                onClick={() => handleRowClick(item)}
                data-bs-toggle={
                  item["type"] === "incident"
                    ? "offcanvas"
                    : ""
                }
                data-bs-target={
                  item["type"] === "incident"
                    ? "#bxOffcanvasIncidence"
                    : ""
                }>
                  <span className="cursorclass incidentclass text-white">
                    {item.id}
                  </span>
                </p>
              </div>
              <h6 className="Incident_title my-2 text-capitalize" title={item.name}>{item.name}</h6>
              <ul className="d-flex align-item-center p-0 m-0">
                <li>
                  <p className="IncidentData ">{item.assignee}</p>
                </li>
                <li>
                  <p className="IncidentData">
                    {moment(item.createdon).format("DD MMM hh:mm")}{" "}
                  </p>
                </li>
              </ul>
            </div>

            <div className="text-end IncidenceStatus">
            <p className="text-white mb-2 status text-uppercase">
                {item.status}
              </p>
              <p>
                <span className="text-lowercase badgeEntitytype">
                  {" "}
                  {item.entity}
                </span>
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default MyqueWidget;
