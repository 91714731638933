import { SpiderScript } from "../SpiderScript";
import Select from "react-select";

function SpiderTerminalTenant(props) {
  return (
    <Select
      id="tenants"
      className="form-control"
      options={props.tenants}
      defaultValue={props.tenants[0]}
      onChange={SpiderScript.selectTenantChange}
    ></Select>
  );
}
export default SpiderTerminalTenant;
