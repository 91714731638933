import { Login } from "../../pages/login/LoginScript";

function InputUser(props) {
    return (
        <>
            <input type={props.type} placeholder={props.placeholder} id={props.id} onChange={Login.updateOnChange}
                className="form-control login" autoComplete="off" />
        </>
    )
}

export default InputUser;