import React from "react";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";

const CumulativeStatsLayout = (data) => {
  return (
    <div className="col">
      <div className="card mb-0">
        <div className="card-body p-2">
          <h6 className="text-muted fw-normal mt-0">{data?.title} </h6>
          <h3 className="mt-2 mb-0 d-flex align-items-center text-white">
            <b className="me-2">{CommonTemplate.formatNumber(data?.value)}</b>
            <span className={data?.icon}>{data?.trend}</span>
            <small className="ms-2">{CommonTemplate.formatNumber(data?.diff)}</small>
          </h3>
          <span className="mb-0">{data?.description}</span>
        </div>
      </div>
    </div>
  );
};

export default CumulativeStatsLayout;
