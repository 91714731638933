import React from "react";
import StockTrendChart from "../../../components/highChart/StockTrendChart";

function RawDataLayout() {
  return (
    <>

       
       

        <div className="mb-2" id="bnfxRaweventsloadSearch">{/* here we replace detection search */}</div>

        <div className="raweventstrendchartgraph">
        <StockTrendChart  height={70}  container={"bnfxWidgetStatChart-"}/>
        </div>
      
        
        <div className="mt-2" id="bnfxStreamRawdata">
          {/* render rawevent */}
        </div>
        <div id="bnfxRawDataPagination">

        </div>
    </>
  );
}

export default RawDataLayout;
