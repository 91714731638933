import "./customtooltip.css";

const CustomToolTip = ({ content, customclass, innerText }) => {
  return (
    <div className={"frameTooltip " + customclass}>
      <div className="quote">
        <span onclick="void(0);" className="Cmtooltip">
          {innerText || "i"}
          <span className="info">
            <span className="text">{content}</span>
          </span>
        </span>
      </div>
    </div>
  );
};

export default CustomToolTip;
