import React from "react";
import { Stream } from "../StreamScript";
import "jquery-toast-plugin"; 
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";
import { flatten } from "flat";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";
import moment from "moment";
import { BadgePanelScript } from "../../../components/common/Badge/BadgePanelScript";
import BadgesIcon from "../../../components/common/Badge/BadgeIcon";

const DetectionEventLayout = (props) => {
  let data = props.detectionData;
  let detectionData = data;
  const detectionDate = moment(new Date(detectionData.detectiontime));

  let hourFormat = "hh";
  if (detectionDate.hour() >= 12) {
      hourFormat = "HH";
  }

  const formattedDate = detectionDate.format(`DD  MMM ${hourFormat}:mm`);

  // let message = detectionData["messageid.set"]? detectionData["messageid.set"]: [detectionData.messageid];

  const {
    context,
    criticality,
    score,
    detectionid,
    streamid,
    detectiontime,
    technique,
    tactic,
    entity,
    entitytype,
    streamname,
    tags,
    name,
    messageid: messageIdVar,
    "messageid.set": messageIdSet,
    ...requireDetectionData
  } = detectionData;
  const processedDetectionData = flatten(requireDetectionData);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="bxStreamEventDetection">
            <div className="bxSEDHead bx_step p-2 d-flex align-items-center justify-content-between">
              <div className="divScore d-flex align-items-center">
                <p className="timer_box">{formattedDate}</p>
                {detectionData.score ? (
                  <h2 className="text m-0 mx-2">{detectionData.score} </h2>
                ) : null}
                {detectionData.firstName ? (
                  <h2 className="text m-0 mx-2">
                    {detectionData.firstName}
                    {detectionData.lastName}{" "}
                  </h2>
                ) : null}
                {detectionData.criticality ? (
                  <span
                    className={CommonTemplate.criticalityColor(
                      detectionData.criticality
                    )}
                  >
                    {detectionData.criticality}
                  </span>
                ) : null}
              </div>
              <div className="dropdown">
                <button
                  className="dropdown-toggle arrow-none"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="material-symbols-outlined text-white">
                    more_vert
                  </i>
                </button>
                <ul className="dropdown-menu streamDots">
                  <h6 className="ps-2 border-bottom pb-2">Explore</h6>
                  <li
                    className="bageEntity d-flex align-items-center"
                    data-bs-target="#bxOffcanvasPanel"
                    data-bs-toggle="offcanvas"
                    title="Entity"
                    onClick={() =>
                      Stream.loadStreamrowOnclick(detectionData, "entity")
                    }
                  >
                    <span className="material-symbols-outlined">person</span>
                    {t("entity")}
                  </li>
                  <li
                    className="bageDetection d-flex align-items-center"
                    data-bs-target="#bxOffcanvasPanel"
                    title="Detection"
                    data-bs-toggle="offcanvas"
                    onClick={() =>
                      Stream.loadStreamrowOnclick(detectionData, "detection")
                    }
                  >
                    <span className="material-symbols-outlined">
                    link
                    </span>
                    {t("detection.title")}
                  </li>
                  <li
                    className="bageAttack d-flex align-items-center"
                    data-bs-toggle="offcanvas"
                    title="View Attack"
                    data-bs-target="#bnfxdetctiongraph"
                    onClick={() => {
                      Stream.loadattackpatterngraph(
                        detectionData.name,
                        detectionData.detectionid,
                        detectionData.entity,
                        detectionData.entitytype,
                        detectionData.tenant,
                        detectionData.score,
                        detectionData.streamid
                      );
                    }}
                  >
                    <span className="material-symbols-outlined">
                    tactic
                    </span>
                    {t("attackpath")}
                  </li>
                  <li
                    className="bageEntity d-flex align-items-center"
                    id="createbadgetab"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBadge"
                    aria-controls="offcanvasBadge"
                    data-bs-dismiss="offcanvas"
                    onClick={() =>{
                      BadgePanelScript.badgeAllDetails(
                        detectionData,
                        props.streamdetails,"stream_data"
                      );
                      BadgePanelScript.TenantsList();}
                    }
                  >
                    <span className="material-symbols-outlined">badge</span>
                    {t("managebadge")}
                  </li>
                </ul>
              </div>
            </div>
            <div className="bxSEDBody">
              <div className="bxSEDBody_title_new px-2">
                <p className="mb-2 text-white w-100">{detectionData.context}</p>
              </div>

              <div className="p-2 bxSEDBodyBox mt-2">
                <div className="bxSEDBodyBox_top">
                  <div className="bxSEDBodyBox_inner">
                    <p className="m-0">
                      {data.entity ? (
                        <>
                          <span
                            className="bxEventKey"
                            onDoubleClick={() =>
                              Stream.handleDoubleClick("entity", data.entity)
                            }
                          >
                            {t("title.entity")}
                          </span>
                          <span
                            className="bxEventValue bxhighlight"
                            onDoubleClick={() =>
                              Stream.handleDoubleClick("entity", data.entity)
                            }
                          >
                            {data.entity} ({data.entitytype})
                          </span>
                        </>
                      ) : null}
                      {data.tactic ? (
                        <>
                          <span
                            className="bxEventKey"
                            onDoubleClick={() =>
                              Stream.handleDoubleClick("tactic", data.tactic)
                            }
                          >
                            {t("title.tactic")}
                          </span>
                          <span
                            className="bxEventValue"
                            onDoubleClick={() =>
                              Stream.handleDoubleClick("tactic", data.tactic)
                            }
                          >
                            {data.tactic}
                          </span>
                        </>
                      ) : null}
                      {data.technique ? (
                        <>
                          <span
                            className="bxEventKey"
                            onDoubleClick={() =>
                              Stream.handleDoubleClick(
                                "technique",
                                data.technique
                              )
                            }
                          >
                            {t("title.technique")}
                          </span>
                          <span
                            className="bxEventValue"
                            onDoubleClick={() =>
                              Stream.handleDoubleClick(
                                "technique",
                                data.technique
                              )
                            }
                          >
                            {data.technique}
                          </span>
                        </>
                      ) : null}

                      {data.name ? (
                        <>
                          <span
                            className="bxEventKey"
                            onDoubleClick={() =>
                              Stream.handleDoubleClick("name", data.name)
                            }
                          >
                            {t("title.name")}
                          </span>
                          <span
                            className="bxEventValue"
                            onDoubleClick={() =>
                              Stream.handleDoubleClick("name", data.name)
                            }
                          >
                            {data.name}
                          </span>
                        </>
                      ) : null}
                    </p>
                    <div
                      className="ExpandCollapseBtn"
                      onClick={(e) => Stream.loadmoredatacoallapse(e.target)}
                      data-bs-toggle="collapse"
                      data-bs-target={
                        "#collapsebxJsonText" + detectionData.detectiontime
                      }
                      aria-expanded="false"
                      aria-controls="collapsebxJsonText"
                    >
                      <span className="material-symbols-outlined text-success">
                      expand_more
                      </span>
                    </div>
                  </div>
                  <div
                    className="bxJsonText collapse"
                    id={"collapsebxJsonText" + detectionData.detectiontime}
                  >
                    <p>
                      {Object?.keys(processedDetectionData)?.map((key) => (
                        <React.Fragment key={key}>
                          <>
                            <span
                              className="bxEventKey"
                              onDoubleClick={() =>
                                Stream.handleDoubleClick(
                                  key,
                                  processedDetectionData[key]
                                )
                              }
                            >
                              {key}
                            </span>
                            <span
                              className="bxEventValue"
                              onDoubleClick={() =>
                                Stream.handleDoubleClick(
                                  key,
                                  processedDetectionData[key]
                                )
                              }
                            >{`${processedDetectionData[key]}`}</span>
                          </>
                        </React.Fragment>
                      ))}
                    </p>
                    <div className="Stream_btnall d-flex align-item-centre justify-content-end">
                      <button
                        className="text_Copy_icon me-2"
                        data-bs-toggle="popover"
                        title="Copy"
                        data-bs-content="And here's some amazing content. It's very engaging. Right?"
                        id="data-to-copy"
                        onClick={(e) => {
                          Stream.copyDetection(
                            e,
                            "bxStreamEventDetection",
                            null,
                            detectionData
                          );
                        
                        }}
                      >
                        <span className="material-symbols-outlined">
                          {" "}
                          content_copy
                        </span>
                        {t("copytitle")}
                      </button>

                      {/* <button
                        className="text_RawData_icon"
                        type="button"
                        title="Raw Data"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#bnfxRawDataLayout"
                        data-bs-backdrop={false}
                        onClick={() => {
                          CommonTemplate.renderrawdata(
                            message,
                            detectionData.name
                          );
                        }}
                      >
                        <span className="material-symbols-outlined">
                          home_storage
                        </span>
                        Raw Data
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tags_foot mb-3 mt-2 px-2 d-block"
              >
                 <BadgesIcon output={data.attachedbadges} existingbadge={data.detectedbadge} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetectionEventLayout;
