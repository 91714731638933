import { t } from "i18next";
import jwt from "jwt-decode";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { requestURI } from "../../components/common/RequestURIs";
import { fetchData } from "../../components/common/Service";
import ShowErrorToastPopup from "../../components/common/ShowErrorToastPopup";
import ToastPopup from "../../components/common/ToastPopup";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import {
  ADD_YOUR_CODE,
  ADD_YOUR_TEST_EVENT,
  DATA_EXTRACTION_FROM_CODE,
  HIDE_GENERATE_AND_TEST_FOR_TYPE,
  RIGHT_PANEL_LABELS,
  STREAMS_DROPDOWN_OPTIONS
} from "./contentConstants";
import { CONTENT_CONSTANTS } from "./contentReducer";
import RightPanelContentItemsList from "./contentpanels/RightPanelContentItemsList";

const nonStreamValidationSchema = Yup.object().shape({
  content: Yup.string().when("contenttype", {
    is: (val) => val !== "dashboard",
    then: () => Yup.string().required("Content is required")
  }),
  fragments: Yup.array().when("contenttype", {
    is: "report",
    then: () =>
      Yup.array()
        .min(1, "Min 1 Fragment is required")
        .required("Fragment is required")
  }),
  schedule: Yup.string().when("contenttype", {
    is: "report",
    then: () => Yup.string().required("Schedule is required")
  }),
  contenttype: Yup.string().required("Contenttype is required"),
  description: Yup.string().required("Description is required"),
  title: Yup.string().required("Title is required"),
  tenant: Yup.string().required("Tenant is required")
});
const StreamValidationSchema = Yup.object().shape({
  // dictionaries: Yup.array()
  // .of(
  //   Yup.object().shape({
  //     value: Yup.string().required("Dictionary value is required"),
  //     label: Yup.string() // Optionally validate the label if needed
  //   })
  // )
  // .length(1, "Exactly 1 dictionary is required")
  // .required("Dictionaries are required"),
  type: Yup.string().required("Type is required"),
  group: Yup.string().required("Group is required"),
  provider: Yup.string().required("Provider is required"),
  description: Yup.string().required("Description is required"),
  title: Yup.string().required("Title is required")
});

const secretOrVariableValidationSchema = Yup.object().shape({
  contenttype: Yup.string().required("Contenttype is required"),
  value: Yup.string().required("Value is required"),
  key: Yup.string().required("Key is required"),
  source: Yup.string().required("Source is required"),
  tenant: Yup.string().required("Tenant is required")
});

export const Content = {
  loadContentItemsList: async (params, signal) => {
    let request = {
      limit: 18,
      offset: 0,
      enabled: false,
      sortorder: false,
      sortcol: "lastupdated",
      rawContent: true,
      allContent: true,
      query: undefined,
      count: false,
    };

    request = { ...request, ...params };

    if (request.type === "variables") {
      delete request.enabled;
      delete request.allContent;
      delete request.rawContent;
    }

    const data = await fetchData(
      "POST",
      requestURI["content.list"],
      null,
      request,
      signal
    );
    return data;
  },

  renderContentItemsList: (response, args) => {
    let originalData = response.data.response;
    if (originalData.length > 0) {
      const container = document.querySelector("#bnfxContentStreamList");
      const data = (
        <RightPanelContentItemsList
          originalData={originalData}
          type={args[1]}
          terminalName={args[2]}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    } else if (args[0] !== 0 && originalData.length === 0) {
      //   const selectAllOption = {
      //     value: "No More Streams",
      //     label: "No More Streams",
      //   };
      //   setGlobalState("StreamsOptions", (prevUrlOptions) => [
      //     ...prevUrlOptions,
      //     selectAllOption,
      //   ]);
    }
  },
  loadContentDetailsComponent: async (data, terminalName) => {
    let mode = "edit";
    let script = "--type=script";
    if (terminalName === "stream") {
      mode = "components";
      script = "";
    }

    const request = {
      command: `${terminalName} --mode=${mode} --location=${data?.location} ${script}`,
      tenant: data.tenant,
    };

    const response = await fetchData(
      "POST",
      requestURI["terminal.command"],
      null,
      request
    );
    return response;
    // debugger;
  },
  loadContentInstructionDetailsComponent: async (data, terminalName) => {
    let mode = "edit";
    let script = "--type=instructions";
    if (terminalName === "stream") {
      mode = "components";
      script = "";
    }

    const request = {
      command: `${terminalName} --mode=${mode} --location=${data?.location} ${script}`,
      tenant: data.tenant,
    };

    const response = await fetchData(
      "POST",
      requestURI["terminal.command"],
      null,
      request
    );
    return response;
    // debugger;
  },
  saveSecretOrVariableDetails: async (dispatch, data, terminalName, type) => {
    const isValid = await Content.validateSecretOrVariableForm(dispatch, data);
    if (!isValid) {
      return;
    }
    dispatch({
      type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
      data: true,
    });
    const request = {
      command: `${terminalName} --mode=create --name=${data?.key.trim()} --source=${data?.source.trim()} --value=${data?.value.trim()}`,
      tenant: data.tenant,
    };

    const response = await fetchData(
      "POST",
      requestURI["terminal.command"],
      null,
      request
    );

    const publishRequest = {
      command: `${terminalName} --mode=publish`,
      tenant: data.tenant,
    };

    const publishResponse = await fetchData(
      "POST",
      requestURI["terminal.command"],
      null,
      publishRequest
    );

    if (publishResponse.status === "FAILED") {
      ShowErrorToastPopup(t("content.publish.error.confirmation"));
      console.log(publishResponse.error);
      return;
    } else {
      ToastPopup(
        t("spider.create.succesfirstmsg") +
          data.contenttype +
          t("spider.create.succeslastmsg")
      );
      Content.onLeftPanelItemClick(dispatch, type);
    }
    return response;
  },
  onLeftPanelItemClick: async (dispatch, itemName) => {
    setTimeout(() => {}, 100);
    // get terminal name as this is singular and is needed to call the terminal api's
    const terminalName = RIGHT_PANEL_LABELS[itemName].terminal;
    dispatch({
      type: CONTENT_CONSTANTS.ON_PANEL_CLICK,
      data: { terminalName: terminalName, selectedLeftPanelItemName: itemName },
    });
    let payload = {
      offset: 0,
      type: itemName,
      terminalName: terminalName,
      count: true,
    };
    Content.getItemsListData(dispatch, payload);
  },
  getTenantsList: async (dispatch) => {
    const token = localStorage.getItem("token");
    const userdetails = jwt(token);

    let tenantcount = userdetails?.tenants?.length;
    let tenants = userdetails?.tenants;
    if (tenantcount == null || tenantcount === 0) {
      const tenantResponse = await fetchData(
        "GET",
        requestURI["list.tenant"],
        null,
        null
      );
      tenants = tenantResponse.response;
      const tenantList = tenants.map((item) => ({
        value: item.name,
        label: item.name,
      }));

      const selectAllOption = {
        value: "default",
        label: "ALL",
      };

      tenantList.unshift(selectAllOption);
      dispatch({
        type: CONTENT_CONSTANTS.SET_TENANTS_LIST,
        data: tenantList,
      });
    } else if (tenantcount === 1) {
      const tenantList = [
        {
          value: tenants[0],
          label: tenants[0],
        },
      ];
      dispatch({
        type: CONTENT_CONSTANTS.SET_TENANTS_LIST,
        data: tenantList,
      });
    } else {
      const tenantList = tenants.map((item) => ({
        value: item.name,
        label: item.name,
      }));

      const selectAllOption = {
        value: "default",
        label: "ALL",
      };

      tenantList.unshift(selectAllOption);
      dispatch({
        type: CONTENT_CONSTANTS.SET_TENANTS_LIST,
        data: tenantList,
      });
    }
  },
  onNoStreamItemChange: (event, dispatch) => {
    dispatch({
      type: CONTENT_CONSTANTS.ON_UPDATE_NON_STREAM_FORM,
      data: { [event.target.name]: event.target.value },
    });
  },
  onSecretOrVariableItemChange: (event, dispatch) => {
    dispatch({
      type: CONTENT_CONSTANTS.ON_UPDATE_SECRET_OR_VARIABLE_FORM,
      data: { [event.target.name]: event.target.value },
    });
  },
  saveNonStreamData: async (dispatch, fields, terminalName, type) => {
    if (!fields.contenttype) {
      fields.contenttype = terminalName;
    }
    let isValid = await Content.validateNonStreamForm(dispatch, fields);
    if (!isValid) {
      console.log("error");
      return;
    }
    if (!fields.codetested && !HIDE_GENERATE_AND_TEST_FOR_TYPE.includes(type)) {
      ShowErrorToastPopup(t("content.test.noClicked"));
      return;
    }

    // if (type !== "dashboards") {
    //   await Content.confirmSaveNonStreamData(
    //     dispatch,
    //     fields,
    //     terminalName,
    //     type,
    //     false
    //   );
    //   return;
    // }

    Swal.fire({
      title: t("content.publish.title.confirmation"),
      text: t("content.publish.description.confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("content.publish.confirm.confirmation"),
      cancelButtonText: t("content.publish.cancel.confirmation"),
    }).then((result) => {
      Content.confirmSaveNonStreamData(
        dispatch,
        fields,
        terminalName,
        type,
        result.isConfirmed
      );
    });
  },
  confirmSaveNonStreamData: async (
    dispatch,
    fields,
    terminalName,
    type,
    doPublish
  ) => {
    dispatch({
      type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
      data: true,
    });
    let response;
    try {
      let request = {
        command: `${terminalName} --mode=create --name=${fields.title.trim()} --description=${fields.description.trim()}`,
        tenant: fields.tenant,
      };
      // first update the meta
      const metaResponse = await fetchData(
        "POST",
        requestURI["terminal.command"],
        null,
        request
      );
      console.log(fields);
      // Check if 'instructions' key exists in the 'fields' object
      let instructioncode = fields.instructions ? fields.instructions : null;
      if (instructioncode) {
        let instructionPath = metaResponse.response.path.replace(
          "script.py",
          "instructions.ai"
        );
        let instructionrequest = {
          code: instructioncode,
          contentid: instructionPath,
          contenttype: fields.contenttype,
          tenant: fields.tenant,
        };
        //  update the in instructions
        fetchData(
          "POST",
          requestURI["terminal.save.command"],
          null,
          instructionrequest
        );
      } else {
        console.log("No instructions key found in the fields.");
      }
      let contentCopy = fields.content;
      if (fields.contenttype === "report") {
        contentCopy +=
          '\ndef schedule():\n\treturn "' + fields.schedule + '"\n\n';
        // convert fragments array of object to array of string
        let selectedFragments = [];
        if (fields.fragments) {
          fields.fragments.forEach((opt) => {
            let val = opt.value.split("/packages/")[1];
            selectedFragments.push(val);
          });
        }
        contentCopy +=
          "def fragments():\n\treturn " + JSON.stringify(selectedFragments);
      } else if (fields.contenttype === "dashboard") {
        let selectedWidgets = [];
        if (fields.widgets) {
          fields.widgets.forEach((opt) => {
            let val = opt.value.split("/packages/")[1];
            selectedWidgets.push(val);
          });
        }
        contentCopy =
          "\ndef widgets():\n\treturn " + JSON.stringify(selectedWidgets);
      }
      request = {
        code: contentCopy,
        contentid: metaResponse?.response?.path,
        contenttype: fields.contenttype,
        tenant: fields.tenant,
      };

      // then update the code
      response = await fetchData(
        "POST",
        requestURI["terminal.save.command"],
        null,
        request
      );
      if (response.status === "FAILED") {
        ShowErrorToastPopup("Error saving details for " + fields.contenttype);
        return;
      }

      if (doPublish) {
        // publish it
        const publishResponse = await Content.publishComponent(
          terminalName,
          response?.response?.name,
          fields.tenant,
          false
        );

        if (publishResponse.status === "FAILED") {
          ShowErrorToastPopup(t("content.publish.error.confirmation"));
          console.log(publishResponse.error);
          return;
        }
      }

      ToastPopup(
        t("spider.create.succesfirstmsg") +
          fields.contenttype +
          t("spider.create.succeslastmsg")
      );
      Content.onLeftPanelItemClick(dispatch, type);
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({
        type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
        data: false,
      });
    }

    return response;
  },
  publishComponent: async (component, name, tenant, showSuccessToast) => {
    let request = {
      command: `${component} --mode=publish --name=${name}`,
      tenant: tenant,
    };
    // publish it
    const publishResponse = await fetchData(
      "POST",
      requestURI["terminal.command"],
      null,
      request
    );
    if (publishResponse.status === "FAILED") {
      ShowErrorToastPopup(t("content.publish.error.confirmation"));
      console.log(publishResponse.error);
    } else {
      if (showSuccessToast) {
        ToastPopup(name + " " + component + " has been published successfully");
      }
    }
    return publishResponse;
  },
  validateNonStreamForm: async (dispatch, fields) => {
    let isValid = false;
    dispatch({
      type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
      data: {},
    });
    try {
      await nonStreamValidationSchema.validate(fields, {
        abortEarly: false,
      });
      isValid = true;
    } catch (validationErrors) {
      if (validationErrors instanceof Yup.ValidationError) {
        const errors = {};
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            errors[error.path] = error.message;
          }
        });
        dispatch({
          type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
          data: errors,
        });
      }
    }
    return isValid;
  },
  validateStreamForm: async (dispatch, fields) => {
    let isValid = false;
    dispatch({
      type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
      data: {},
    });
    try {
      await StreamValidationSchema.validate(fields, {
        abortEarly: false,
      });
      isValid = true;
    } catch (validationErrors) {
      if (validationErrors instanceof Yup.ValidationError) {
        const errors = {};
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            errors[error.path] = error.message;
          }
        });
        dispatch({
          type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
          data: errors,
        });
      }
    }
    return isValid;
  },
  validateSecretOrVariableForm: async (dispatch, fields) => {
    let isValid = false;
    dispatch({
      type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
      data: {},
    });
    try {
      await secretOrVariableValidationSchema.validate(fields, {
        abortEarly: false,
      });
      isValid = true;
    } catch (validationErrors) {
      if (validationErrors instanceof Yup.ValidationError) {
        const errors = {};
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            errors[error.path] = error.message;
          }
        });
        dispatch({
          type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
          data: errors,
        });
      }
    }
    return isValid;
  },
  updateNonStreamData: async (dispatch, fields) => {
    let isValid = await Content.validateNonStreamForm(dispatch, fields);
    if (!isValid) {
      return;
    }
    // if (fields.contenttype !== "dashboard") {
    //   await Content.confirmUpdateNonStreamData(dispatch, fields, false);
    //   return;
    // }

    Swal.fire({
      title: t("content.publish.title.confirmation"),
      text: t("content.publish.description.confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("content.publish.confirm.confirmation"),
      cancelButtonText: t("content.publish.cancel.confirmation"),
    }).then((result) => {
      Content.confirmUpdateNonStreamData(dispatch, fields, result.isConfirmed);
    });
  },
  confirmUpdateNonStreamData: async (dispatch, fields, doPublish) => {
    dispatch({
      type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
      data: true,
    });

    let response = null;
    try {
      let metacode = `
      title = ${fields.title.trim()}\n
      description = ${fields.description.trim()}\n
      `;
      let lastIndexOfSlash = fields.path.lastIndexOf("/");
      let metaPath =
        fields.path.substr(0, lastIndexOfSlash + 1) + "message_en.properties";
      let request = {
        code: metacode,
        contentid: metaPath,
        contenttype: fields.contenttype,
        tenant: fields.tenant,
      };
      // first update the meta
      fetchData("POST", requestURI["terminal.save.command"], null, request);

      let instructioncode = fields.instructions ? fields.instructions : null;
      if (instructioncode) {
      let instructionPath =
        fields.path.substr(0, lastIndexOfSlash + 1) + "instructions.ai";
      let instructionrequest = {
        code: instructioncode,
        contentid: instructionPath,
        contenttype: fields.contenttype,
        tenant: fields.tenant,
      };
      //  update instructions
      fetchData(
        "POST",
        requestURI["terminal.save.command"],
        null,
        instructionrequest
      );
      }
      else{
        console.error("Does not  contain feild instructions")
      }
      let contentCopy = fields.content;
      if (fields.contenttype === "report") {
        contentCopy +=
          '\ndef schedule():\n\treturn "' + fields.schedule + '"\n\n';
        // convert fragments array of object to array of string
        let selectedFragments = [];
        if (fields.fragments) {
          fields.fragments.forEach((opt) => {
            let val = opt.value.split("/packages/")[1];
            selectedFragments.push(val);
          });
        }
        contentCopy +=
          "def fragments():\n\treturn " + JSON.stringify(selectedFragments);
      } else if (fields.contenttype === "dashboard") {
        let selectedWidgets = [];
        if (fields.widgets) {
          fields.widgets.forEach((opt) => {
            let val = opt.value.split("/packages/")[1];
            selectedWidgets.push(val);
          });
        }
        contentCopy =
          "\ndef widgets():\n\treturn " + JSON.stringify(selectedWidgets);
      }
      request = {
        code: contentCopy,
        contentid: fields.path,
        contenttype: fields.contenttype,
        tenant: fields.tenant,
      };

      // then update the code
      response = await fetchData(
        "POST",
        requestURI["terminal.save.command"],
        null,
        request
      );

      if (response.status === "FAILED") {
        ShowErrorToastPopup("Error saving details for " + fields.contenttype);
        return;
      }

      if (doPublish) {
        // publish it
        const publishResponse = await Content.publishComponent(
          fields.contenttype,
          fields?.componentData?.name,
          fields.tenant,
          false
        );

        if (publishResponse.status === "FAILED") {
          ShowErrorToastPopup(t("content.publish.error.confirmation"));
          console.log(publishResponse.error);
          return;
        }
      }

      ToastPopup(
        t("spider.update.succesfirstmsg") +
          fields.contenttype +
          t("spider.update.succeslastmsg")
      );
    } catch (e) {
      ShowErrorToastPopup("Error saving details for " + fields.contenttype);
    } finally {
      dispatch({
        type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
        data: false,
      });
    }

    // need to make api call to save content
    return response;
  },
  createOrEditOfPipelineAndDashboard: async (dispatch, data, terminalName) => {
    await Content.getTenantsList(dispatch);
    dispatch({
      type: CONTENT_CONSTANTS.ON_OPEN_CREATE_OR_EDIT,
    });
    if (!data) {
      dispatch({
        type: CONTENT_CONSTANTS.ON_CREATE_OR_EDIT_FIELDS,
        data: { fieldData: null, terminalName: terminalName },
      });
      return;
    }
    const apiResponse = await Content.loadContentDetailsComponent(
      data,
      terminalName
    );
    const apiInstructionResponse =
      await Content.loadContentInstructionDetailsComponent(data, terminalName);

    let instructionResponse = apiInstructionResponse.response
      ? apiInstructionResponse.response.content
      : {};
    let content = apiResponse.response || {};
    if (apiResponse.status === "FAILED") {
      ShowErrorToastPopup(apiResponse.error);
      content.title = data.title;
      content.description = data.description;
      content.tenant = data.tenant;
      content.componentData = data;
      dispatch({
        type: CONTENT_CONSTANTS.ON_CREATE_OR_EDIT_FIELDS,
        data: { fieldData: content, terminalName: terminalName },
      });
      return;
    }
    if (terminalName === "stream") {
      STREAMS_DROPDOWN_OPTIONS.forEach((dropdownOption) => {
        content[dropdownOption.name] = content[dropdownOption.name]
          ? content[dropdownOption.name][dropdownOption.name]
          : [];
      });
    }

    let preProcessor = DATA_EXTRACTION_FROM_CODE[terminalName];
    if (preProcessor) {
      // do extraction first
      preProcessor?.extract?.forEach((fnName) => {
        const extractedValue = CommonTemplate.getPyFunctionData(
          content.content,
          fnName.extractionName
        );
        content[fnName.fieldToUpdateName] = extractedValue;
      });
      // remove functions from content
      let toBeRemoved = preProcessor?.remove || [];
      if (toBeRemoved && toBeRemoved.length > 0) {
        content.content = Content.removeFunctions(content.content, toBeRemoved);
      }

      let loadComps = preProcessor?.loadData || [];
      if (loadComps.length > 0) {
        for (let i = 0; i < loadComps.length; i++) {
          const componentResponse = await CommonTemplate.loadComponents(
            loadComps[i].apiName,
            data.location,
            data.tenant
          );

          let components = componentResponse.response
            ? componentResponse.response[loadComps[i].apiFieldName]
            : [];
          content[loadComps[i].fieldToUpdateName] = components;
        }
      }
    }

    content.title = data.title;
    content.description = data.description;
    content.tenant = data.tenant;
    content.componentData = data;
    content.instructions = instructionResponse;

    dispatch({
      type: CONTENT_CONSTANTS.ON_CREATE_OR_EDIT_FIELDS,
      data: { fieldData: content, terminalName: terminalName },
    });
  },
  createOrEditOfSecretAndVariables: async (dispatch, data, terminalName) => {
    await Content.getTenantsList(dispatch);
    dispatch({
      type: CONTENT_CONSTANTS.ON_OPEN_CREATE_OR_EDIT,
    });
    if (!data) {
      dispatch({
        type: CONTENT_CONSTANTS.ON_CREATE_OR_EDIT_SECRET_OR_VARIABLES_FIELDS,
        data: { fieldData: null, terminalName: terminalName },
      });
      return;
    }

    let content = {};
    content.mode = "edit";
    content.tenant = data.tenant;
    content.source = data.source;
    if (terminalName === "secret") {
      content.key = data.secret;
      content.value = data.value;
      content.contenttype = terminalName;
    } else {
      content.key = data.name;
      content.value = data.custvalue;
      content.contenttype = terminalName;
      content.description = data.description;
      content.title = data.title;
    }

    dispatch({
      type: CONTENT_CONSTANTS.ON_CREATE_OR_EDIT_SECRET_OR_VARIABLES_FIELDS,
      data: { fieldData: content, terminalName: terminalName },
    });
  },
  onCreateOrEditClick: async (dispatch, isOpen, terminalName) => {
    Content.openTab(dispatch, "event-tab");

    await Content.loadCodeGeneratorFormFields(dispatch, terminalName);
  },
  onCodeGeneratorPanelClick: async (dispatch, isOpen, terminalName) => {
    Content.openTab(dispatch, "configuration-tab");

    await Content.loadCodeGeneratorFormFields(dispatch, terminalName);
  },
  loadCodeGeneratorFormFields: async (dispatch, terminalName) => {
    const request = {
      command: `${terminalName} --mode=instructions`,
      tenant: "cisco",
    };

    const fieldsResponse = await fetchData(
      "POST",
      requestURI["terminal.command"],
      null,
      request
    );

    dispatch({
      type: CONTENT_CONSTANTS.SET_CODE_GENERATOR_INPUTS,
      data: fieldsResponse.response,
    });

    // update the form fields values
    let fields = {};
    if (fieldsResponse.response && fieldsResponse.response.content) {
      Object.keys(fieldsResponse.response.content).forEach((fieldName) => {
        fields[fieldName] = "";
      });
    }
    

    dispatch({
      type: CONTENT_CONSTANTS.SET_CODE_GENERATOR_FIELDS,
      data: fields,
    });

    return fieldsResponse.response;
  },
  handleCodeGeneratorInputChange: (dispatch, name, value) => {
    // console.log("name", name);
    // console.log("value", value);
    dispatch({
      type: CONTENT_CONSTANTS.SET_CODE_GENERATOR_FIELDS,
      data: { [name]: value },
    });
  },
  generateCode: async (
    dispatch,
    terminalName,
    nonStreamFields,
    codeGeneratorFormFields
  ) => {
    nonStreamFields.content = nonStreamFields.content || ADD_YOUR_CODE;
    let isValid = await Content.validateNonStreamForm(
      dispatch,
      nonStreamFields
    );
    if (!isValid) {
      return;
    }

    let eventDataJson = nonStreamFields?.testEvent;
    if (!eventDataJson || eventDataJson === ADD_YOUR_TEST_EVENT) {
      dispatch({
        type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
        data: { testEvent: "Test Event is required" },
      });
      Content.openTab(dispatch, "event-tab");
      return;
    }
    // we need to check if each field has some value
    let errors = {};
    delete codeGeneratorFormFields?.event;
    if (
      codeGeneratorFormFields &&
      Object.keys(codeGeneratorFormFields).length > 0
    ) {
      Object.keys(codeGeneratorFormFields).forEach((fieldName) => {
        if (!codeGeneratorFormFields[fieldName]) {
          errors[fieldName] = fieldName + "is required";
        }
      });
    }

    if (Object.keys(errors).length > 0) {
      dispatch({
        type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
        data: errors,
      });
      Content.openTab(dispatch, "configuration-tab");
      return;
    }

    dispatch({
      type: CONTENT_CONSTANTS.SET_CODE_GENERATOR_LOADER,
      data: true,
    });

    let queryTags = "";
    if (
      codeGeneratorFormFields &&
      Object.keys(codeGeneratorFormFields).length > 0
    ) {
      Object.keys(codeGeneratorFormFields).forEach((fieldName) => {
        queryTags +=
          " --" + fieldName + "=" + codeGeneratorFormFields[fieldName];
      });
    }

    // add instructions if present
    if (nonStreamFields.instructions) {
      queryTags += " --context=" + nonStreamFields.instructions;
    }

    const request = {
      command: `${terminalName} --mode=suggest ${queryTags} --event =${eventDataJson}`,
      tenant: nonStreamFields.tenant,
    };

    const codeResponse = await fetchData(
      "POST",
      requestURI["terminal.command"],
      null,
      request
    );

    if (codeResponse.status !== "FAILED") {
      // we need to keep the mode as edit
      delete codeResponse.response.mode;
      dispatch({
        type: CONTENT_CONSTANTS.ON_UPDATE_NON_STREAM_FORM,
        data: { ...codeResponse.response },
      });
    } else {
      ShowErrorToastPopup(codeResponse.error);
    }
  },
  compileAndTestCode: async (
    dispatch,
    terminalName,
    eventDataJson,
    nonStreamFields
  ) => {
    dispatch({
      type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
      data: {},
    });

    if (!eventDataJson || ADD_YOUR_TEST_EVENT === eventDataJson) {
      //ShowErrorToastPopup(t("content.test.noevent"));
      dispatch({
        type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
        data: { testEvent: "Test event is required" },
      });
      Content.openTab(dispatch, "event-tab");
      return;
    }
    if (!nonStreamFields.content || ADD_YOUR_CODE === nonStreamFields.content) {
      Content.openTab(dispatch, "event-tab");
      //ShowErrorToastPopup(t("content.test.nocode"));
      dispatch({
        type: CONTENT_CONSTANTS.SET_FORM_ERRORS,
        data: { content: "Code is required" },
      });
      return;
    }
    try {
      dispatch({
        type: CONTENT_CONSTANTS.SET_TEST_CODE_LOADER,
        data: true,
      });
      let request = { code: nonStreamFields.content };
      const compileResponse = await fetchData(
        "POST",
        requestURI["compile.command"],
        null,
        request
      );

      let result = compileResponse.response.message;
      if (result !== t("successfully.compiled")) {
        ShowErrorToastPopup(result);
        return;
      }
      const encoded = window.btoa(nonStreamFields.content);

      request = {
        command: `${terminalName} --mode=test --event =${eventDataJson} --script=${encoded}`,
        tenant: nonStreamFields.tenant,
      };

      const testResponse = await fetchData(
        "POST",
        requestURI["terminal.command"],
        null,
        request
      );

      result = testResponse.error || testResponse?.response?.content;

      if (testResponse.status !== "FAILED") {
        dispatch({
          type: CONTENT_CONSTANTS.ON_UPDATE_NON_STREAM_FORM,
          data: { codetested: true },
        });
        Content.handleTestCodeResultChange(dispatch, "output", result);
        Content.openTab(dispatch, "testresults-tab");
        //ToastPopup(result);
      } else {
        ShowErrorToastPopup(result);
        Content.handleTestCodeResultChange(dispatch, "output", result);
        Content.openTab(dispatch, "testresults-tab");
        return;
      }
    } catch (e) {
      ShowErrorToastPopup(t("content.test.serverError"));
    } finally {
      dispatch({
        type: CONTENT_CONSTANTS.SET_TEST_CODE_LOADER,
        data: false,
      });
    }
  },
  onTestCodePanelClick: async (
    dispatch,
    isOpen,
    terminalName,
    nonStreamFields
  ) => {
    Content.openTab(dispatch, "testresults-tab");
    Content.compileAndTestCode(
      dispatch,
      terminalName,
      nonStreamFields.testEvent,
      nonStreamFields
    );
  },
  handleTestCodeResultChange: (dispatch, name, value) => {
    dispatch({
      type: CONTENT_CONSTANTS.ON_TEST_CODE_FORM_CHANGE,
      data: { [name]: value },
    });
  },
  onStreamItemChange: (event, dispatch) => {
    dispatch({
      type: CONTENT_CONSTANTS.ON_UPDATE_STREAM_FORM,
      data: { [event.target.name]: event.target.value },
    });
  },

  updateStreamData: async (dispatch, fields, type) => {
    let isValid = await Content.validateStreamForm(dispatch, fields);
    if (!isValid) {
      return;
    }

    Swal.fire({
      title: t("content.publish.title.confirmation"),
      text: t("content.publish.description.confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("content.publish.confirm.confirmation"),
      cancelButtonText: t("content.publish.cancel.confirmation"),
    }).then((result) => {
      Content.confirmUpdateStreamData(
        dispatch,
        fields,
        result.isConfirmed,
        type
      );
    });
  },
  confirmUpdateStreamData: async (dispatch, fields, doPublish, type) => {
    dispatch({
      type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
      data: true,
    });

    let response = null;
    try {
      let lastIndexOfSlash = fields.componentData.location.lastIndexOf("/");

      // "main/packages/tcs/cisco/filters/hjfev/script.py"
      let metaPath =
        fields.componentData.location.substr(0, lastIndexOfSlash + 1) +
        "message_en.properties";
      const root = "content-ootb-repository/";
      let finalpath = metaPath.substring(metaPath.indexOf(root) + root.length);
      console.log(finalpath);
      let metacode = `
      title = ${fields.title.trim()}\n
      description = ${fields.description.trim()}\n
      `;
      console.log(metacode);
      let request = {
        code: metacode,
        contentid: finalpath,
        contenttype: "stream",
        tenant: fields.tenant,
      };
      // first update the meta
      fetchData("POST", requestURI["terminal.save.command"], null, request);

      // let viewrequest = {
      //   command: `stream --mode=view --name=${fields.componentData.name} --type=script`,
      //   tenant: fields.tenant,
      // };
      // // first update the meta
      // let existingresponse = await fetchData(
      //   "POST",
      //   requestURI["terminal.command"],
      //   null,
      //   viewrequest
      // );

      const provider = fields.provider;
      const logtype = fields.type;
      const group = fields.group;
      const transformedFields = Content.transformFields(fields);
      const configurationScript = Content.generateConfigurationScript(
        transformedFields,
        provider,
        logtype,
        group
      );

      console.log(configurationScript);

      // "main/packages/tcs/cisco/filters/hjfev/script.py"
      let scriptPath =
        fields.componentData.location.substr(0, lastIndexOfSlash + 1) +
        "script.py";
      let finalscriptpath = scriptPath.substring(
        metaPath.indexOf(root) + root.length
      );
      console.log(scriptPath);
      request = {
        code: configurationScript,
        contentid: finalscriptpath,
        contenttype: "stream",
        tenant: fields.tenant,
      };

      // then update the code
      response = await fetchData(
        "POST",
        requestURI["terminal.save.command"],
        null,
        request
      );

      if (response.status === "FAILED") {
        ShowErrorToastPopup("Error saving details for stream");
        return;
      }

      if (doPublish) {
        // publish it
        const publishResponse = await Content.publishComponent(
          "stream",
          fields?.componentData?.name,
          fields.tenant,
          false
        );
   

        if (publishResponse.status === "FAILED") {
          ShowErrorToastPopup(t("content.publish.error.confirmation"));
          console.log(publishResponse.error);
          return;
        }
      }

      ToastPopup(
        t("spider.update.succesfirstmsg") +
          "stream" +
          t("spider.update.succeslastmsg")
      );
      Content.onLeftPanelItemClick(dispatch, type);
    } catch (e) {
      ShowErrorToastPopup("Error saving details for stream");
    } finally {
      dispatch({
        type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
        data: false,
      });
    }

    // need to make api call to save content
    return response;
  },
  saveStreamData: async (fields, dispatch, type) => {
    let isValid = await Content.validateStreamForm(dispatch, fields);
    if (!isValid) {
      return;
    }

    Swal.fire({
      title: t("content.publish.title.confirmation"),
      text: t("content.publish.description.confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("content.publish.confirm.confirmation"),
      cancelButtonText: t("content.publish.cancel.confirmation"),
    }).then((result) => {
      Content.confirmSaveStreamData(
        dispatch,
        fields,
        "stream",
        type,
        result.isConfirmed
      );
    });
  },
  confirmSaveStreamData: async (
    dispatch,
    fields,
    terminalName,
    type,
    doPublish
  ) => {
    dispatch({
      type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
      data: true,
    });
    let response;
    try {
      let request = {
        command: `stream --mode=create --name=${fields.title.trim()} --description=${fields.description.trim()}`,
        tenant: fields.tenant,
      };
      // first update the meta
      const metaResponse = await fetchData(
        "POST",
        requestURI["terminal.command"],
        null,
        request
      );

      if (metaResponse.status === "FAILED") {
        ShowErrorToastPopup("Error Creating stream");
        return;
      } else {
        // Generate the script
        const provider = fields.provider;
        const logtype = fields.type;
        const group = fields.group;
        const transformedFields = Content.transformFields(fields);
        const configurationScript = Content.generateConfigurationScript(
          transformedFields,
          provider,
          logtype,
          group
        );
        request = {
          code: configurationScript,
          contentid: metaResponse?.response?.path,
          contenttype: "stream",
          tenant: fields.tenant,
        };

        // then update the code
        let response = await fetchData(
          "POST",
          requestURI["terminal.save.command"],
          null,
          request
        );

        if (response.status === "FAILED") {
          ShowErrorToastPopup("Error saving details for stream");
          return;
        }
        if (doPublish) {
          // publish it
          const publishResponse = await Content.publishComponent(
            "stream",
            response?.response?.name,
            fields.tenant,
            false
          );

          if (publishResponse.status === "FAILED") {
            ShowErrorToastPopup(t("content.publish.error.confirmation"));
            console.log(publishResponse.error);
            return;
          }
        }

        ToastPopup(
          t("spider.create.succesfirstmsg") +
            fields.contenttype +
            t("spider.create.succeslastmsg")
        );
        Content.onLeftPanelItemClick(dispatch, type);
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({
        type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
        data: false,
      });
    }

    return response;
  },
  getPaginatedItems: async (dispatch, offset, type) => {
    let searchText = "";
    if (document.getElementById("contentItemSearch")) {
      searchText = document.getElementById("contentItemSearch").value;
    }
    let payload = {
      offset: offset,
      type: type,
      count: true,
      query: searchText,
    };
    Content.getItemsListData(dispatch, payload);
  },
  getItemsListData: async (dispatch, payload) => {
    const apiResponse = await Content.loadContentItemsList(payload, null);
    let data = {};
    if (apiResponse?.response?.total) {
      data = {
        contentItemList: apiResponse?.response?.result,
        contentItemTotal: apiResponse?.response?.total,
      };
    } else {
      data = {
        contentItemList: apiResponse?.response,
      };
    }

    if (payload.type === "variables") {
      if (data.contentItemList) {
        data.contentItemList.forEach((itemObj) => {
          itemObj.title = itemObj.name || "";
          itemObj.source = itemObj.sourcename || "";
        });
      }
    }

    dispatch({
      type: CONTENT_CONSTANTS.SET_CONTENT_ITEM_LIST,
      data: { ...data, contentItemOffset: payload.offset },
    });
  },
  debounceTimeout: undefined, // to hold the timeout instance inorder to clear it
  //debouncedSearch: Content.debounce(Content.searchItems, 300),
  prevRequestController: undefined,
  debounce: (fn, delay) => {
    return function (...args) {
      clearTimeout(Content.debounceTimeout);
      Content.debounceTimeout = setTimeout(() => fn(...args), delay);
    };
  },
  onSearchPipelineItems: async (dispatch, value, type) => {
    if (value) {
      dispatch({
        type: CONTENT_CONSTANTS.UPDATE_CONTENT_ITEM_SEARCH_TEXT,
        data: value,
      });
    }
    if (!value || value.length > 2) {
      Content.debounce(Content.searchItems(dispatch, value, type), 300);
    }
  },
  searchItems: async (dispatch, value, type) => {
    if (Content.prevRequestController) {
      try {
        Content.prevRequestController.abort(); // Cancel the previous request if present
      } catch (e) {}
    }
    Content.prevRequestController = new AbortController();
    const signal = Content.prevRequestController.signal;
    let payload = {
      offset: 0,
      type: type,
      query: value,
      count: true,
    };
    const apiResponse = await Content.loadContentItemsList(payload, signal);
    let data = {
      contentItemList: apiResponse?.response?.result,
      contentItemTotal: apiResponse?.response?.total,
    };

    dispatch({
      type: CONTENT_CONSTANTS.SET_CONTENT_ITEM_LIST,
      data: { ...data, contentItemOffset: 0 },
    });
  },
  transformFields: (fields) => {
    const result = {};
    const keyMappings = [
      "dictionaries", // This will be renamed to 'dictionary'
      "parsers",
      "filters",
      "transformations",
      "correlations", // This will be renamed to 'correlation'
      "detections",
      "sequences",
      "automations",
      "reports",
      "aggregations",
    ];
  
    keyMappings.forEach((key) => {
      const field = fields[key];
      if (field === undefined) return; // Skip if the field is not present
  
      // Rename 'dictionaries' to 'dictionary' and 'correlations' to 'correlation'
      const outputKey = key === "dictionaries" ? "dictionary" : key === "correlations" ? "correlation" : key;
  
      if (Array.isArray(field)) {
        // Handle arrays
        if (key === "dictionaries" || key === "correlations") {
          if (field.length > 0) {
            result[outputKey] = field[0].value?.split("packages/")[1] || field[0].location?.split("packages/")[1] || "";
          }
        } else {
          if (field.length > 0) {
            result[outputKey] = field.map((item) => item.value?.split("packages/")[1] || item.location?.split("packages/")[1] || "");
          }
        }
      } else {
        // Handle single objects
        result[outputKey] = field.value?.split("packages/")[1] || field.location?.split("packages/")[1] || "";
      }
    });
  
    return result;
  },  
  generateConfigurationScript(transformedFields, provider, type, group) {
    return `
# Sample name -> streams/${provider.trim()}_${type.trim()}/script.py

def configuration():
    return ${JSON.stringify(transformedFields, null, 4)}

def use():
    return 'http://integration-${provider.trim()}-${type.trim()}/execute?groups=${group.trim()}&client_id=$.INTEGRATION_kubernetes_node_metrics_AUTHORIZATION_CLIENTID&client_secret=$.INTEGRATION_kubernetes_node_metrics_AUTHORIZATION_CLIENTSECRET&account_id=$.INTEGRATION_kubernetes_node_metrics_AUTHORIZATION_ACCOUNTID&frequency=1d'
`;
  },
  removeFunctions: (content, fnArray) => {
    if (!content || !fnArray) {
      return content;
    }

    for (let i = 0; i < fnArray.length; i++) {
      const fnRegexStr =
        "def " +
        fnArray[i] +
        "\\(\\):(?:\\s+return\\s+([\"'](.*?)[\"']|\\[.*?\\]|\\{([\\s\\S]*?)\\}\\s))";
      const fnRegex = new RegExp(fnRegexStr, "s");
      const fnMatch = content.match(fnRegex);

      if (!fnMatch) {
        console.error("function not found");
        return content;
      }

      //let dataFn = fnMatch[0];
      content = content.replace(fnMatch[0], "");
    }

    return content;
  },
  openTab: (dispatch, tabName) => {
    dispatch({
      type: CONTENT_CONSTANTS.SET_CODE_LAYOUT_TAB,
      data: tabName,
    });
  },
  handleMenuClick: (event, index) => {
    event.stopPropagation();
    const submenu = event.currentTarget.parentElement.nextElementSibling;
    let isActive = event.currentTarget.parentElement.classList.contains(
      "selectedContentelement"
    );

    document
      .querySelectorAll("#leftside-menu-container .side-nav-link")
      .forEach((sub, subIndex) => {
        sub.classList.remove("selectedContentelement");
      });
    if (!isActive) {
      isActive = event.currentTarget.parentElement.classList.toggle(
        "selectedContentelement"
      );
    } else {
      isActive = false;
    }

    // Hide all other submenus
    document.querySelectorAll(".submenu").forEach((sub, subIndex) => {
      if (subIndex !== index) {
        sub.style.display = "none";
      }
    });

    // Toggle the clicked submenu
    submenu.style.display = isActive ? "block" : "none";
  },
  addSampleToTestEvent: (dispatch, genEvent) => {
    Content.onNoStreamItemChange(
      { target: { name: "testEvent", value: genEvent?.sample } },
      dispatch
    );
  },
  openFullscreen: (className) => {
    let enterfullscreen = document.querySelector(".enterfullscreen");
    enterfullscreen?.classList.add("d-none");
    let contentnonstreamcodemirror = document.querySelector(
      ".content_nonstream_codemirror"
    );
    contentnonstreamcodemirror?.classList.add("expandcontentNonestreamLayout");
    let contentcodepanels = document.querySelector(".contentcodepanels");
    contentcodepanels?.classList.add("expandcontentNonestreamLayout");
    let contentNonestreamLayout = document.querySelector(
      ".contentNonestreamLayout"
    );
    contentNonestreamLayout?.classList.add("expandcontentNonestreamLayout");

    let exitfullscreen = document.querySelector(".exitfullscreen");
    exitfullscreen.classList.remove("d-none");
    const elem = document.querySelector(className);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  },
  closeFullscreen: () => {
    let enterfullscreen = document.querySelector(".enterfullscreen");
    enterfullscreen.classList.remove("d-none");
    let contentnonstreamcodemirror = document.querySelector(
      ".content_nonstream_codemirror"
    );
    contentnonstreamcodemirror?.classList.remove(
      "expandcontentNonestreamLayout"
    );
    let contentcodepanels = document.querySelector(".contentcodepanels");
    contentcodepanels?.classList.remove("expandcontentNonestreamLayout");
    let contentNonestreamLayout = document.querySelector(
      ".contentNonestreamLayout"
    );
    contentNonestreamLayout?.classList.remove("expandcontentNonestreamLayout");

    let exitfullscreen = document.querySelector(".exitfullscreen");
    exitfullscreen.classList.add("d-none");
    if (document.fullscreenElement) {
      if (document?.exitFullscreen) {
        document?.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  },
  deleteNonStreamData: async (dispatch, fields) => {
    let response = null;
    try {
      let metacode = `
      title = ${fields.title}\n
      description = ${fields.description}\n
      `;
      let lastIndexOfSlash = fields.path.lastIndexOf("/");
      let metaPath =
        fields.path.substr(0, lastIndexOfSlash + 1) + "message_en.properties";
      let request = {
        code: metacode,
        contentid: metaPath,
        contenttype: fields.contenttype,
        tenant: fields.tenant,
      };
      // first update the meta
      fetchData("POST", requestURI["terminal.save.command"], null, request);
      let contentCopy = fields.content;
      if (fields.contenttype === "report") {
        contentCopy +=
          '\ndef schedule():\n\treturn "' + fields.schedule + '"\n\n';
        // convert fragments array of object to array of string
        let selectedFragments = [];
        if (fields.fragments) {
          fields.fragments.forEach((opt) => {
            let val = opt.value.split("/packages/")[1];
            selectedFragments.push(val);
          });
        }
        contentCopy +=
          "def fragments():\n\treturn " + JSON.stringify(selectedFragments);
      } else if (fields.contenttype === "dashboard") {
        contentCopy =
          "\ndef widgets():\n\treturn " + JSON.stringify(fields.widgets);
      }
      request = {
        code: contentCopy,
        contentid: fields.path,
        contenttype: fields.contenttype,
        tenant: fields.tenant,
      };

      // then update the code
      response = await fetchData(
        "POST",
        requestURI["terminal.save.command"],
        null,
        request
      );

      if (response.status === "FAILED") {
        ShowErrorToastPopup("Error saving details for " + fields.contenttype);
        return;
      }

      ToastPopup(
        t("spider.update.succesfirstmsg") +
          fields.contenttype +
          t("spider.update.succeslastmsg")
      );
    } catch (e) {
      ShowErrorToastPopup("Error saving details for " + fields.contenttype);
    } finally {
      dispatch({
        type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
        data: false,
      });
    }

    // need to make api call to save content
    return response;
  },

  fetechparentcomponentlist: async (details) => {
    let request = {
      command: `${details.contenttype} --mode=parents --name=${details.componentData.name}`,
      tenant: details.tenant,
    };

    const data = await fetchData(
      "POST",
      requestURI["terminal.command"],
      null,
      request
    );
    return data;
  },
  deleteContentConfirmation: async (dispatch, data, type) => {
    const apiResponse = await Content.fetechparentcomponentlist(data);
    let parents = apiResponse.response;

    if ( parents===undefined || parents?.length === 0) {
      Swal.fire({
        title: t("delete.title.confirmation"),
        text: t("delete.description.confirmation") + data.title,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("stream.delete.yes"),
      }).then((result) => {
        if (result.isConfirmed) {
          Content.removeContent(dispatch, data, type);
        }
      });
    } else {
      const parentsList = parents.join(", ");
      Swal.fire({
        title: `This ${data.contenttype} canot be deleted !`,
        text: ` The following ${data.contenttype} are being used in these streams: ${parentsList}. Please remove them manually.`,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle the case when the user acknowledges the notification
        }
      });
    }
  },
  removeContent: async (dispatch, data, type) => {
    dispatch({
      type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
      data: true,
    });
    let response;
    try {
      let request = null;
      if (type === "streams") {
        request = {
          command: "stream --mode=remove --name=" + data.componentData.name,
          tenant: data.tenant,
        };
      }
      else if (type === "secrets" || type === "variables") {
        request = {
          command: data.contenttype +" --mode=remove --name=" + data.key+" --source="+data.source,
          tenant: data.tenant,
        };
      }  else {
        request = {
          command:
            data.contenttype +
            " --mode=remove --name=" +
            data.componentData.name,
          tenant: data.tenant,
        };
      }
      response = await fetchData(
        "POST",
        requestURI["terminal.command"],
        null,
        request
      );
      if (response.status === "FAILED") {
        ShowErrorToastPopup(response.error);
        return;
      }
      let capitalizedStr =
        data.title.charAt(0).toUpperCase() + data.title.slice(1);
      ToastPopup(capitalizedStr + " " +type + " removed successfully .");
      Content.onLeftPanelItemClick(dispatch, type);
    } catch (e) {
      ShowErrorToastPopup("Error deleting  " + data.contenttype);
    } finally {
      dispatch({
        type: CONTENT_CONSTANTS.SET_SAVE_FORM_LOADER,
        data: false,
      });
    }
    return response;
  },
  deleteSecretOrVariable: (dispatch,secretsOrVariablesFields,type) => {
    Swal.fire({
      title: t("delete.title.confirmation"),
      text: t("delete.description.confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("stream.delete.yes"),
    }).then((result) => {
      if (result.isConfirmed) {
        Content.removeContent(dispatch,secretsOrVariablesFields,type);
      }
    });
  },
};
