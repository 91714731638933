import "../search/SearchPage.css";
import { SearchTemplate } from "./SearchScript";
import { useEffect, useRef } from "react";

function SearchPage() {
  const pageloaded = useRef(false);

  useEffect(() => {
    if (pageloaded.current) {
      return;
    }
    
    SearchTemplate.applyClass();
    SearchTemplate.searchSuggest();
    pageloaded.current = true;
  }, []);
  return (
    <>
      <div className="bxContent SearchPage">
        <div className="fullwidth main-inner" id="bxFullwidthContent">
          <div className="col-lg-11 m-auto">
            <div className="searchPageSec">
              <div className="row g-0">
                <div className="col-md-12">
                  <div className="mainSearchBox">
                    <form className="SuperSearch">
                      <div className="d-flex align-items-center">
                        <div className="form-group w-100" id="bnfxloadSearchPage">
                          {/* here will replace searchpage */}
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="mainSBContentT mt-2">
                    <div id="searchtrendgraph" className="bfxSearchGraph mb-2">
                      {/* {here will replace chart} */}
                    </div>
                    <div className="d-flex align-items-center justify-content-between dateheight">
                      <div id="bnfxseachdate"></div>
                      {/* {here will replace date} */}
                      <div className="mainSBC_right">
                        <div className="d-flex align-items-center justify-content-between">
                          <ul className="d-flex align-items-center m-0 p-0">
                            <li>
                              <div id="bnfxSearchPagePagination">
                                {/* {here will replace pagination} */}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="search_Resultcontent" id="bnfxSearchEvents">
                      {/* {here will replace searchevents} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchPage;
