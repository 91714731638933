import React, {memo, useCallback } from "react";
import { useReactFlow } from "reactflow";
import { Handle, Position } from "reactflow";
import { SpiderScript } from "../SpiderScript";

const ReportNode = memo(({ data, isConnectable }) => {
  let id = data.value;

  let draft = data.output?.draft;

  const { deleteElements ,setNodes,setEdges} = useReactFlow();

  const removeNode = useCallback((e) => {  
  
     
      e.stopPropagation();
      if(data.output!== undefined){
      SpiderScript.deleteNode([data.output, data.label]);
    }
      deleteElements({ nodes: [{id }] });

  }, [id, deleteElements]);
  let edge2 = {
    id: `e2-defaultfragment`,
    source: `${id}-fragment`,
    target:`defaultfragment-0`,
    targetHandle: "d",
    animated: false,
    style: { stroke: "#fff" },
    type: "default",
  };
  let newNode = {
    id: `defaultfragment-0`,
    type: "streamnode",
    data: { label: `fragment`, value: id },
    position: { x: 100, y: 100 },
};


const addNode = useCallback((event) => {
  SpiderScript.streamreportglobal=data
    // const position = reactFlowInstance.project({
    //     x: event.clientX,
    //     y: event.clientY,
    //   });
     

    setNodes((prevNodes) => [...prevNodes, newNode]);
    setEdges((prevEdges) => [...prevEdges, edge2])
},[id, setNodes,setEdges]);
  
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        id="d"
        style={{ top: -10, left: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
      <div className="Hover_delete_button">

          <b
            className="material-symbols-outlined Spider_Delete_items"
            onClick={(e) => removeNode(e)}
          >
            delete
          </b>
    
        <div
          className={`card spiderBox_area ${draft ? "draft" : "nodraft"} ${
            data.label
          }`}
        >
          <div
            className="card-body d-flex align-items-center p-0 justify-content-between"
            onClick={() => {
              SpiderScript.handleToggleCollapse();
            }}
          >
            <span className="material-symbols-outlined me-2">summarize</span>
            <div>
              <p
                className="m-0 Spider_font16 text-white"
                title={data.output?.title}
              >
                {data.output?.title
                  ? data.output?.title.charAt(0).toUpperCase() +
                    data.output?.title.slice(1)
                  : data.label.charAt(0).toUpperCase() + data.label.slice(1)}
              </p>
              {data.output?.description ? (
                <p
                  className="m-0 Spider_font14"
                  title={data.output?.description}
                >
                  {data.output?.description}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <b
            className="material-symbols-outlined Spider_Add_Fragment"
            onClick={(e) => addNode(e)}
          >
            add
          </b>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="e"
        style={{ bottom: -10, right: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
    </>
  );
});

export default ReportNode;
