import { Terminal } from "./TerminalScript";
import Select from 'react-select'

function TerminalTenants(props) {
    return (
        <Select id="tenants" className="form-control" options={props.tenants}
            defaultValue={props.tenants[0]}
            onChange={Terminal.selectTenantChange}>
        </Select>

    );
}
export default TerminalTenants;