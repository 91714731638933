import { t } from "i18next";
import { Stream } from "../StreamScript";
import SummaryTab from "./SummaryTab";
import TimelineTab from "./TimelineTab";
import RawDataLayout from "./RawDataLayout";

function StreamDetailTab() {
  return (
    <>
      <div className="Tabs_streams_Time_Summary">
        <div className="Tabs_streams_Time_Summary_btns">
          <button
            className="tab_summary active"
            onClick={(event) => Stream.openTab(event, "tabSummary")}
          >
            {t("summary")}
          </button>
          <button
            className="tab_timeline"
            onClick={(event) => Stream.openTab(event, "tabTimeline")}
          >
            {t("timeline")}
          </button>
          <button
            className="tab_rawevents"
            onClick={(event) => Stream.openTab(event, "tabRawevents")}
          >
            Realtime
          </button>
          {/* <button type="button" className="summaryDownload_Btn">
              <span className="material-symbols-outlined">download</span>{" "}
              {t("download")}
            </button> */}
        </div>

        <div
          id="tabTimeline"
          className="tab-content tabTimeline"
          style={{ display: "flex" }}
        >
          <TimelineTab />
        </div>

        <div id="tabSummary" className="tab-content tabSummary active">
          <SummaryTab />
        </div>
        <div
          id="tabRawevents"
          className="tab-content tabRawevents"
          style={{ display: "flex" }}
        >
          <RawDataLayout />
        </div>
      </div>
    </>
  );
}

export default StreamDetailTab;
