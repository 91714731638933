import React, { memo } from "react";
import { Handle, Position } from "reactflow";

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        id="b"
        style={{ top: -10, left: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
      <div className="BFNX_Bg_color">
        <div className="dataSource cardBox cardDName">
          <span className="material-symbols-outlined iconImg">
          detection_and_zone
          </span>
        </div>
      </div>
      <div className="BFNX_data_attack">
        <strong>
          {data?.score}
         X
        </strong>
        <p>{data?.label}</p>
      </div>

      <Handle
        type="source"
        position={Position.Right}
        id="right"
        style={{ right: -10, top: "50%", background: "#fff" }}
        isConnectable={isConnectable}
      />
    </>
  );
});
