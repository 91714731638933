import { useTranslation } from "react-i18next";
import FooterInput from "../FooterInput";
import "../mainFooter/footer.css";

function Footer() {
  const { t } = useTranslation();


  return (
    <>
      <footer id="bxFooter" className="footer">
        <div className="container-fluid">
          <p className="text-end m-0">
          {t("copyright")} <a href="https://binaryflux.xyz/">{t("binaryflux")}.</a>{t("footer_statement")}
          </p>
        </div>
      </footer> 
      <FooterInput />
    </>
  );
}

export default Footer;
