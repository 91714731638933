import { useTranslation } from "react-i18next";
import Logo from "../../components/logo/LogoImage";
import LoginForm from "../../components/form/LoginForm";
import { useEffect } from "react";
import "../login/login.css";

function LoginTemplate() {
  /*It is used to access the translation functions provided by the "react-i18next" library. */
  const { t } = useTranslation();

  useEffect(() => {
    window.history.replaceState(null, "", "/");
  }, []);

  return (
    <div className="row align-items-center bxLoginArea">
      <div className="col-lg-5 col-xxl-4 mx-auto">
        <div className="bxLeftBox text-white p-lg-0 p-3 text-center">
          <Logo />
          <h2 className="bxLgSize">{t("login.title")}</h2>

          <div className="bxRightBox text-white">
            <LoginForm />
          </div>
          <div className="loginfoot mt-3">
            <p className="logDis">{t("login.description")}</p>
            <span className="">{t("login.footer")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginTemplate;
