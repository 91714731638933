import { t } from "i18next";
import { Dashboard } from "../DashboardScript";

function NoDashboardAvailable() {
  return (
    <>
      <div
        className="rightSideCardLgBox bxContentEmpty pt-3"
        id="NoDectectionEvent"
      >
        <div className=" container mt-5">
          <div className="d-flex justify-content-end w-100">
            <a
              data-bs-toggle="offcanvas"
              data-bs-target="#bnfxAddNewDashboard"
              aria-controls="offcanvasRight"
              href="#streamList"
              className="action-icon btn btn-primary"
              onClick={() => {
                Dashboard.loadAvailableDashboardLayout(0);
              }}
            >
              {" "}
              {t("enabled.adddashboard")}
            </a>
          </div>
        </div>
        <div className="container mt-4">
          <div className="card mb-0">
            <div className="card-body p-0">
              <div className="p-md-5 p-0">
                <img
                  className="mb-3"
                  src="images/gif-a1.gif"
                  alt="no_events_yet"
                  width="120"
                />

                <h5 className="text-white">{t("no.dashboard.title")}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoDashboardAvailable;
