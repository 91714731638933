import { t } from "i18next";
import OrganizationGroupLayout from "./OrganizationGroupLayout";
import { Organization } from "./OrganizationScript";
import jwt from 'jwt-decode'

function OrganizationLayout() {

  const token = localStorage.getItem("token");
  let isAdmin=false;
    const userdetails = jwt(token);
    let roleslist=userdetails.roles
    roleslist.forEach(item => {
      if (item.name === 'ROLE_ADMIN' ||item.name ==='ROLE_CUSTOMER_ADMIN') {
        isAdmin = true;
      } else {
        isAdmin = false;
      }
    });
  return (
    <div className="bxContent">
      <div className="bxContent_head">
        <h4 className="m-0 text-white"> {t("organization_title")}</h4>
      </div>
      <div id="" className="bxSettingPage_listBox w--icon">
        <div className="col-sm-12">
          <ul className="nav nav-tabs bxNavTabs" id="myTab" role="tablist">
           {isAdmin? <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="bxTenants-tab"
                data-bs-toggle="tab"
                data-bs-target="#bxTenants-tab-pane"
                type="button"
                role="tab"
                aria-controls="bxTenants-tab-pane"
                aria-selected="false"
                onClick={() => {
                  Organization.loadTenant(0);
                }}
              >
                {t("organization.tenants")}
              </button>
            </li>:null}

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="bxRoles-tab"
                data-bs-toggle="tab"
                data-bs-target="#bxRoles-tab-pane"
                type="button"
                role="tab"
                aria-controls="bxRoles-tab-pane"
                aria-selected="false"
                onClick={() => {
                  Organization.loadRole(0);
                }}
              >
                {t("organization.roles")}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="bxGroups-tab"
                data-bs-toggle="tab"
                data-bs-target="#bxGroups-tab-pane"
                type="button"
                role="tab"
                aria-controls="bxGroups-tab-pane"
                aria-selected="false"
                onClick={() => {
                  Organization.loadGroup(0);
                }}
              >
                {t("organization.groups")}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="bxUsers-tab"
                data-bs-toggle="tab"
                data-bs-target="#bxUsers-tab-pane"
                type="button"
                role="tab"
                aria-controls="bxUsers-tab-pane"
                aria-selected="true"
                ref={(button) => {
                  if (button) {
                    button.click();
                  }
                }}
                onClick={() => {
                  Organization.loadUser(0);
                }}
              >
                {t("organization.users")}
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <OrganizationGroupLayout />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationLayout;
